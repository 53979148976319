@use '../base' as *;
/* -------------------------------- 

File#: _1_social-sharing
Title: Social Sharing
Descr: Social sharing plugin
Usage: codyhouse.co/license

-------------------------------- */

.sharebar {}

.sharebar__btn {
  display: inline-block;
  padding: var(--space-sm);
  background: var(--color-contrast-lower);
  border-radius: 50%;
  transition: .2s;

  .icon {
    display: block;
    color: var(--color-contrast-high);
    transition: color .2s;
    font-size: 1.2em;
  }

  &:hover {
    background-color: var(--color-bg);
    box-shadow: var(--shadow-sm);

    .icon {
      color: var(--color-primary);
    }
  }
}