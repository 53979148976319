@use '../base' as *;
/* -------------------------------- 

File#: _4_intro
Title: Intro
Descr: Intro section including the main header and a hero component
Usage: codyhouse.co/license

-------------------------------- */

.intro {
  padding-top: var(--mega-nav-height); // update variable according to the header component you're using
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.intro--top-overlay {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: linear-gradient(alpha(var(--color-black), 0.85), alpha(var(--color-black), 0));
    mix-blend-mode: multiply;
  }
}