@use '../base' as *;

/* -------------------------------- 

File#: _1_password
Title: Password Visibility Control
Descr: Password input field with option to toggle password visibility
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --password-btn-width: 3.5em;
  --password-icon-size: 1.5em;
}

.password {
  position: relative;
}

.password__input {
  height: 100%;
}

.password__btn {
  @include reset;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: var(--password-btn-width);
  background-color: rgba(#FFF, 0); // fix issue on IE9/10 - button not clickable
  justify-content: center;
  align-items: center;

  display: none; // hide button if js is not enabled
  
  &:focus {
    color: var(--color-primary);
  }
}

.password__btn-label {
  &:last-child {
    display: none;
  }

  .icon {
    width: var(--password-icon-size);
    height: var(--password-icon-size);
  }
}

.password--text-is-visible {
  .password__btn-label:first-child {
    display: none;
  }

  .password__btn-label:last-child {
    display: inline-block;
  }
}

.password__input {
  padding-right: calc(var(--space-sm) + var(--password-btn-width));
}

.password__input::-ms-reveal {
  display: none;
}

.password__btn { // show button if js is enabled
  display: flex;
}