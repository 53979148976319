@use '../base' as *;
// --------------------------------

// --------------------------------

// Transform

// --------------------------------

// translate y - used to fix 1px separation between sections with same background color
.-translate-y-1 {
  transform: translateY(-1px);
}

.-translate-y-2 {
  transform: translateY(-2px);
}

.translate-y-1 {
  transform: translateY(1px);
}

.translate-y-2 {
  transform: translateY(2px);
}

// --------------------------------

// Padding

// --------------------------------

.padding-top-header {
  // padding top = main header height
  padding-top: var(--mega-nav-height);
}

.padding-y-header {
  --mega-nav-height: 3rem;
  padding: var(--mega-nav-height);
}

// --------------------------------

// Border

// --------------------------------

:root,
[data-theme="dark"] {
  --color-border-alpha: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 0.1);
}

.border-alpha {
  border-color: var(--color-border-alpha);
}
