@use '../base' as *;
/* -------------------------------- 

File#: _1_thank-you
Title: Thank You
Descr: Order confirmation template
Usage: codyhouse.co/license

-------------------------------- */

.thank-you {
  position: relative;
  z-index: 1;
}

.thank-you__icon {
  width: 80px; // icon size
  height: 80px; // icon size
  animation: thank-you-icon .5s var(--ease-out);
}

.thank-you__icon-group {
  transform-origin: 50% 50%;
  
  > *:last-child {
    transform-origin: 50% 50%;
    stroke-dashoffset: 55;
    stroke-dasharray: 55;
    animation: thank-you-icon-check .5s var(--ease-out) forwards;
  }
}

@keyframes thank-you-icon {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes thank-you-icon-check {
  from {
    stroke-dashoffset: 55;
  }
  to {
    stroke-dashoffset: 0;
  }
}