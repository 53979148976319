// don't modify this file -> edit 📁 custom-style/_typography.scss to set your custom typography

@use 'breakpoints' as *;

:root {
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  font-size: var(--text-base-size, 1rem);
  font-family: var(--font-primary, sans-serif);
  color: var(--color-contrast-high, hsl(210, 7%, 21%));
  font-weight: var(--body-font-weight, normal);
}

h1, h2, h3, h4 {
  color: var(--color-contrast-higher, hsl(204, 28%, 7%));
  line-height: var(--heading-line-height, 1.2);
  font-weight: var(--heading-font-weight, 700);
}

h1 {
  font-size: var(--text-xxl, 2rem);
}

h2 {
  font-size: var(--text-xl, 1.75rem);
}

h3 {
  font-size: var(--text-lg, 1.375rem);
}

h4 {
  font-size: var(--text-md, 1.125rem);
}

small {
  font-size: var(--text-sm, 0.75rem);
}

// --------------------------------

// Inline Text

// --------------------------------

a, .link {
  color: var(--color-primary, hsl(250, 84%, 54%));
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

s {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

// --------------------------------

// Text Component - Class used to stylize text blocks

// --------------------------------

.text-component {
  h1, h2, h3, h4 {
    line-height: calc(var(--heading-line-height) * var(--line-height-multiplier, 1));
    margin-bottom: calc(var(--space-unit) * 0.3125 * var(--text-space-y-multiplier, 1));
  }

  h2, h3, h4 {
    margin-top: calc(var(--space-unit) * 0.9375 * var(--text-space-y-multiplier, 1));
  }

  p, blockquote, ul li, ol li {
    line-height: calc(var(--body-line-height) * var(--line-height-multiplier, 1));
  }
  
  ul, ol, p, blockquote, .text-component__block {
    margin-bottom: calc(var(--space-unit) * 0.9375 * var(--text-space-y-multiplier, 1));
  }

  ul, ol {
    list-style-position: inside;

    ul, ol {
      padding-left: 1em;
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  figcaption {
    text-align: center;
    margin-top: calc(var(--space-unit) * 0.5);
  }
  
  em {
    font-style: italic;
  }

  hr {
    margin-top: calc(var(--space-unit) * 1.875 * var(--text-space-y-multiplier, 1));
    margin-bottom: calc(var(--space-unit) * 1.875 * var(--text-space-y-multiplier, 1));
    margin-left: auto;
    margin-right: auto;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

// text block container
.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@include breakpoint(sm) {
  .text-component__block--left, 
  .text-component__block--right {
    width: 45%;

    img {
      width: 100%;
    }
  }

  .text-component__block--left {
    float: left;
    margin-right: calc(var(--space-unit) * 0.9375 * var(--text-space-y-multiplier, 1));
  }

  .text-component__block--right {
    float: right;
    margin-left: calc(var(--space-unit) * 0.9375 * var(--text-space-y-multiplier, 1));
  }
}

// outset content
@include breakpoint(xl) {
  .text-component__block--outset {
    width: calc(100% + 10.5 * var(--space-unit));

    img {
      width: 100%;
    }
  }

  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: calc(-5.25 * var(--space-unit));
  }

  .text-component__block--left, .text-component__block--right {
    width: 50%;
  }

  .text-component__block--right.text-component__block--outset {
    margin-right: calc(-5.25 * var(--space-unit));
  }
}