@use '../base' as *;
// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/spacing

// --------------------------------

@supports(--css: variables) {
  :root {
    @include breakpoint(md) {
      --space-unit:  1.25rem;
    }
  }
}

// --------------------------------

// (END) Global Editor Code

// --------------------------------