@use '../base' as *;
@use '../components/1_input-group' as *;
@use '../components/1_input-merger' as *;
// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/forms

// --------------------------------

:root {
  --form-control-font-size: 1em;
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0px;
}

.form-control {
  background-color: var(--color-bg);
  border: 1px solid var(--color-contrast-lower);
  transition: all 0.3s ease;

  &::placeholder {
    opacity: .5;
    color: var(--color-contrast-medium);
  }

  &:focus {
    border-color: var(--color-primary);
    box-shadow: inset 0 0 0 1px var(--color-primary), 0px 0px 0px 2px alpha(var(--color-primary), 0.2);
    outline: none;
  }
}

.form-control[disabled],
.form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);

  &:focus {
    box-shadow: inset 0 0 0 1px var(--color-error), 0px 0px 0px 2px alpha(var(--color-error), 0.2);
  }
}

.form-legend {
  color: var(--color-contrast-higher);
  font-size: var(--text-xs);
  line-height: 1.2;
  margin-bottom: var(--space-xxs);
}

.form-label {
  font-size: var(--text-xs);
}

.product-total {
  background-color: var(--color-contrast-high);
  color: var(--color-white);

  span {
    font-size: var(--text-sm);
  }
}

// --------------------------------

// (END) Global Editor Code

// --------------------------------
