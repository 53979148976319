@use '../base' as *;
/* -------------------------------- 

File#: _2_contact-v2
Title: Contact v2
Descr: Contact block w/ info about how to get in touch
Usage: codyhouse.co/license

-------------------------------- */

.contact-v2 {
  position: relative;
  z-index: 1;
}