@use '../base' as *;
// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/buttons

// --------------------------------

:root {
  --btn-font-size: 1em;
  --btn-padding-x: var(--space-md);
  --btn-padding-y: var(--space-xs);
  --btn-radius: 0.1em;
}

.btn {
  cursor: pointer;
  text-decoration: none;
  line-height: 1;
  @include fontSmooth;
  transition: all 0.3s ease;

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-black), 0.2);
    outline: none;
  }

  &:active {
    transform: translateY(2px);
  }
}

// themes
.btn--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  box-shadow: 0px 2px 8px alpha(var(--color-primary-darker), 0.3), 0px 1px 1px alpha(var(--color-black), 0.1);

  &:hover {
    background-color: var(--color-primary-dark);
    box-shadow: 0px 4px 10px alpha(var(--color-primary-darker), 0.3), 0px 2px 4px alpha(var(--color-black), 0.1);
  }

  &:focus {
    box-shadow: 0px 0px 0px 3px alpha(var(--color-primary), 0.75);
  }
}

.btn--subtle {
  background-color: transparent;
  color: var(--color-contrast-higher);
  border: 1px solid alpha(var(--color-contrast-higher), 0.6);

  &:hover {
    border-color: currentColor;
    box-shadow: inset 0 0 0 1px currentColor;
  }
}

.btn--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
  box-shadow: 0px 2px 8px alpha(var(--color-accent), 0.3), 0px 1px 1px alpha(var(--color-black), 0.1);

  &:hover {
    background-color: var(--color-accent-dark);
    box-shadow: 0px 4px 10px alpha(var(--color-accent-darker), 0.3), 0px 2px 4px alpha(var(--color-black), 0.1);
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-accent), 0.2);
  }
}

.btn--contrast {
  background-color: alpha(var(--color-contrast-higher), 0.9);
  color: var(--color-bg);

  &:hover {
    background-color: var(--color-contrast-higher);
  }
}

// feedback
.btn--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

// size
.btn--xs {
  font-size: 0.5em;
}

.btn--sm {
  font-size: 0.8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
}

// --------------------------------

// (END) Global Editor Code

// --------------------------------
