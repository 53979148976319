@use '../base' as *;
/* -------------------------------- 

File#: _1_tabs
Title: Tabs
Descr: A list of content sections (panels), accessible one at a time using control labels
Usage: codyhouse.co/license

-------------------------------- */

.tabs {}

.tabs__control {
  text-decoration: none;
  color: var(--color-contrast-medium);

  &:focus {
    outline: 2px solid alpha(var(--color-primary), 0.2);
    outline-offset: 2px;
  }

  &:hover {
    color: var(--color-contrast-high);
  }
}

.js {
  .tabs__control[aria-selected="true"] {
    color: var(--color-contrast-high);
    text-decoration: underline;
  }
}