@use '../base' as *;
/* -------------------------------- 

File#: _1_feature-v9
Title: Feature v9
Descr: A feature section with 2 blocks
Usage: codyhouse.co/license

-------------------------------- */

.feature-v9 {
  &:hover .feature-v9__block:not(:hover) .feature-v9__content {
    opacity: 0.5;
  }

  &:hover .feature-v9__block:not(:hover)::after {
    background-color: alpha(var(--color-bg), 0.8);
    backdrop-filter: grayscale(100%);
  }
}

.feature-v9__block {
  position: relative;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: var(--space-xl) var(--space-md);
  min-height: 350px;

  &::before, &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: .2s;
  }

  &::before { // gradient under the content
    height: 70%;
    background: linear-gradient(alpha(var(--color-bg), 0), alpha(var(--color-bg), 0.8));
    mix-blend-mode: multiply;
  }

  &::after { // overlay layer
    height: 100%;
    background-color: alpha(var(--color-bg), 0);
  }

  &:hover .feature-v9__content {
    transform: translateY(-10px);
  }

  @include breakpoint(sm) {
    min-height: 450px;
  }

  @include breakpoint(md) {
    min-height: 600px;
  }

  @include breakpoint(lg) {
    min-height: 700px;
  }
}

.feature-v9__content {
  position: relative;
  z-index: 2;
  @include fontSmooth;
  margin: auto auto 0;
  transition: .2s;
}

.feature-v9__link {
  color: var(--color-bg);
  text-decoration: none;
  display: inline-block;
  padding: var(--space-xs) var(--space-md);
  background-color: alpha(var(--color-contrast-higher), 0.8);
  backdrop-filter: blur(5px);
  transition: background-color .2s;

  &:hover {
    background-color: alpha(var(--color-contrast-higher), 1);
  }
}