@use '../base' as *;
/* -------------------------------- 

File#: _3_testimonial-slideshow
Title: Testimonial Slideshow
Descr: A Slideshow to display a gallery of testimonials
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --testimonial-slideshow-profile-img-size: 48px;

  @include breakpoint(md) {
    --testimonial-slideshow-profile-img-size: 64px;
  }
}

.testimonial-slideshow {
  --slideshow-height: 320px; // update slideshow height - inherited from Slideshow component

  @include breakpoint(md) {
    --slideshow-height: 420px;
  }
}

.testimonial-slideshow__profile-img {
  width: var(--testimonial-slideshow-profile-img-size);
  height: var(--testimonial-slideshow-profile-img-size);
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
}