@use '../base' as *;
/* -------------------------------- 

File#: _1_password-reset-form
Title: Password Reset Form
Descr: Password reset form template
Usage: codyhouse.co/license

-------------------------------- */

.password-reset-form {}