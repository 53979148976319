@use '../base' as *;
/* -------------------------------- 

File#: _1_login-form
Title: Login Form
Descr: Login form template
Usage: codyhouse.co/license

-------------------------------- */

.login-form {}