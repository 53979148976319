@use '../base' as *;
/* -------------------------------- 

File#: _1_feature-v4
Title: Feature v4
Descr: Content group containing media element + text that outsets its container at a specific breakpoint
Usage: codyhouse.co/license

-------------------------------- */

.feature-v4 {
  position: relative;
  z-index: 1;
  --feature-text-offset: 65%; // if percentage = higher -> overlapping text takes more space
}

@each $breakpoint, $value in $breakpoints {
  @include breakpoint(#{$breakpoint}) {
    .feature-v4__text-offset\@#{$breakpoint} {
      width: calc(100% + var(--feature-text-offset));
    }
  
    .feature-v4--invert\@#{$breakpoint} {
      .feature-v4__grid > *:last-child {
        order: -1;
      }
  
      .feature-v4__text-offset\@#{$breakpoint} {
        margin-left: calc(var(--feature-text-offset) * -1);
      }
    }
  }
}