@use '../base' as *;
/* -------------------------------- 

File#: _1_drop-cap
Title: Drop Cap
Descr: Make the first letter of a paragraph larger in size
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --drop-cap-lines: 3; // number of lines occupied by the first letter
}

.drop-cap::first-letter {
  float: left;
  line-height: 1;
  font-size: calc(1em * var(--drop-cap-lines) * var(--body-line-height));
  padding: 0 0.125em 0 0;
  text-transform: uppercase;
  color: var(--color-contrast-higher);
}

.text-component .drop-cap::first-letter {
  font-size: calc(1em * var(--drop-cap-lines) * var(--body-line-height) * var(--line-height-multiplier));
}