@use '../base' as *;
/* --------------------------------

File#: _1_main-footer
Title: Main Footer
Descr: Footer navigation
Usage: codyhouse.co/license

-------------------------------- */

.main-footer {
  position: relative;
  z-index: 1;
}

.main-footer__logo {
  flex-shrink: 0;
  // display: block

  svg,
  img {
    display: block;
    width: inherit;
    height: inherit;
  }
}

.main-footer__items {
  @include breakpoint(md) {
    font-size: 0.8em;
  }
}

.main-footer__item {
  margin-top: var(--space-sm);
}

.main-footer__link {
  color: var(--color-contrast-medium);
  text-decoration: none;

  &:hover {
    color: var(--color-contrast-high);
    text-decoration: underline;
  }
}

// colophon
.main-footer__colophon {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main-footer__social {
  text-decoration: none;
  display: inline-block;
  color: var(--color-contrast-medium);

  &:hover {
    color: var(--color-contrast-high);
  }
}
