@use '../base' as *;

:root {
  // border radius
  --radius: 0.25em; // border radius base size (e.g., images, blocks)
}

.text-style-1 {
  &::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1px;
    background-color: currentColor;
    vertical-align: middle;
    margin-right: var(--space-xxxs);
  }
}

.sew-shack__logo {
  width: var(--space-xxxl);
  height: var(--space-lg);

  @include breakpoint(md) {
    height: var(--space-xl);
  }
}

.rotate-10 {
  --rotate: -10deg;
}

.bg-decoration-v2 {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 67%;
  }
}

.customizer--height {
  height: 100vh;
  // height: calc(100vh - 3.2rem);
  // @include breakpoint(md) {
  //   height: calc(100vh - 4.5rem);
  // }
}

// .sidebar--filters > .sidebar__panel--filters {
//   padding-top: var(--space-xxl);
// }

//footer logos
.main-footer__ata-logo {
  width: var(--width-md, 2rem);
  opacity: 0.75;
  transition: all 0.3s ease-in-out;
  filter: grayscale(100%);

  &:hover {
    opacity: 1;
    filter: grayscale(0%);
  }
}

// badge
.version-badge {
  margin-left: auto;
  background-color: var(--color-success);
  border-radius: var(--radius-md);

  height: var(--space-unit);
  line-height: var(--space-unit);
  padding: 0 var(--space-xxs);
  color: var(--color-white);
  font-size: var(--text-xs);
}

// chrome lazy loading with content-visiblity
.content-visibility-auto{
  content-visibility: auto;
  contain-intrinsic-size: 1px;
}
