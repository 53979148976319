@use '../base' as *;
// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/typography

// --------------------------------

:root {
  // font family
  --font-primary: Inter, sans-serif;
  --font-secondary: 'Playfair Display', Georgia, serif;

  // body font size
  --text-base-size: 1rem;
  // type scale
  --text-scale-ratio: 1.2;

  // if Em units → --text-unit: 1em;
  // if not Em units (e.g., Rem/Px) → --text-unit: var(--text-base-size);
  --text-unit: var(--text-base-size);

  // line-height
  --body-line-height: 1.4;
  --heading-line-height: 1.2;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;
}

:root,
* {
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

@supports(--css: variables) {
  :root {
    @include breakpoint(md) {
      --text-base-size: 1.25rem;
      --text-scale-ratio: 1.25;
    }
  }
}

body {
  font-family: var(--font-primary);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-secondary);
  font-weight: 500;
}

// font family
.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
}

// --------------------------------

// (END) Global Editor Code

// --------------------------------

// link style
a,
.link {}

mark,
code {
  background-color: alpha(var(--color-accent), 0.2);
  color: inherit;
}

.text-component {
  --line-height-multiplier: 1;
  --text-space-y-multiplier: 1;

  blockquote {
    padding-left: 1em;
    border-left: 4px solid var(--color-contrast-low);
  }

  hr {
    background: var(--color-contrast-lower);
    height: 1px;
  }

  figcaption {
    font-size: var(--text-sm);
    color: var(--color-contrast-medium);
  }
}

.article {
  // e.g., blog posts
  --body-line-height: 1.58; // set body line-height
  --text-space-y-multiplier: 1.4; // increase vertical spacing
}
