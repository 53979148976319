@use '../base' as *;
/* --------------------------------

File#: _1_background-decoration-v2
Title: Background Decoration v2
Descr: A collection of background effects
Usage: codyhouse.co/license

-------------------------------- */

.bg-decoration-v2 {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bg-decoration-v2__svg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 134%;
  min-width: 1280px;
  max-width: 1920px;
  height: auto;
}
