@use '../base' as *;

/* --------------------------------

File#: _1_card
Title: Card
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card {
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  border-radius: var(--radius-md);
  overflow: hidden;
}

.card__img {
  img {
    display: block;
    width: 100%;
  }
}

.card__content {
  padding: var(--space-xs);
}

// --link
.card--is-link {
  will-change: box-shadow;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: var(--inner-glow), var(--shadow-md);

    .card__img::after {
      opacity: 1;
    }
  }

  .card__img {
    position: relative;

    &::after {
      // overlay layer
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: alpha(var(--color-black), 0.6);
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
}

.card__link {
  text-decoration: none;
  display: block;
  color: inherit;
}

.card__link-icon {
  position: absolute;
  z-index: 1;
  width: 64px;
  height: 64px;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  background-color: alpha(var(--color-white), 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: opacity 0.2s;

  .icon {
    display: block;
    width: 32px;
    height: 32px;
    color: var(--color-black); // icon color
  }
}

.card--is-link:hover .card__link-icon {
  opacity: 1;
}
