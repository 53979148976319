@use '../base' as *;
/* -------------------------------- 

File#: _2_tabs-v4
Title: Tabs v4
Descr: Variation of the tabs component
Usage: codyhouse.co/license

-------------------------------- */

.tabs-v4 {}

.tabs-v4__control {
  text-decoration: none;
  color: var(--color-contrast-medium);
  font-size: var(--text-xs);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: var(--space-xs) var(--space-sm);

  &:focus {
    outline: 2px solid alpha(var(--color-primary), 0.2);
  }

  &:hover {
    color: var(--color-contrast-high);
  }
}

.js {
  .tabs-v4__control[aria-selected="true"] {
    color: var(--color-contrast-higher);
    box-shadow: inset 0 0 0 1px var(--color-contrast-higher);
  }
}

.tabs-v4__panel--is-visible {
  animation: tabs-v4-panel-translate-up .5s var(--ease-out);
}

@keyframes tabs-v4-panel-translate-up {
  from {
    transform: translateY(20px);
    opacity: 0.5;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}