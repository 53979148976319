.container {
  width: calc(100% - 2 * var(--component-padding));
  margin-left: auto;
  margin-right: auto;
}

.grid, .flex, .inline-flex, [class^="flex@"], [class*=" flex@"], [class^="inline-flex@"], [class*=" inline-flex@"] {
  --gap: 0px;
  --gap-x: var(--gap);
  --gap-y: var(--gap);
  gap: var(--gap-y) var(--gap-x);
}

.grid > *, .flex > *, .inline-flex > *, [class^="flex@"] > *, [class*=" flex@"] > *, [class^="inline-flex@"] > *, [class*=" inline-flex@"] > * {
  --sub-gap: 0px;
  --sub-gap-x: var(--sub-gap);
  --sub-gap-y: var(--sub-gap);
}

.grid {
  --grid-columns: 12;
  flex-wrap: wrap;
  display: flex;
}

.grid > * {
  min-width: 0;
  max-width: 100%;
  flex-basis: 100%;
}

@media not all and (min-resolution: .001dpcm) {
  @supports not (translate: none) {
    .grid, .flex[class*="gap-"], .inline-flex[class*="gap-"] {
      margin-bottom: calc(-1 * var(--gap-y));
      margin-left: calc(-1 * var(--gap-x));
      gap: 0;
    }

    .grid > *, .flex[class*="gap-"] > *, .inline-flex[class*="gap-"] > * {
      margin-bottom: var(--sub-gap-y);
    }

    .grid {
      --offset: var(--gap-x);
      --gap-modifier: 0;
      --offset-modifier: 1;
    }

    .grid > * {
      margin-left: var(--offset);
    }

    .flex[class*="gap-"] > *, .inline-flex[class*="gap-"] > * {
      margin-left: var(--sub-gap-x);
    }
  }
}

.gap-xxxxs {
  --gap-x: var(--space-xxxxs);
  --gap-y: var(--space-xxxxs);
}

.gap-xxxxs > * {
  --sub-gap-x: var(--space-xxxxs);
  --sub-gap-y: var(--space-xxxxs);
}

.gap-xxxs {
  --gap-x: var(--space-xxxs);
  --gap-y: var(--space-xxxs);
}

.gap-xxxs > * {
  --sub-gap-x: var(--space-xxxs);
  --sub-gap-y: var(--space-xxxs);
}

.gap-xxs {
  --gap-x: var(--space-xxs);
  --gap-y: var(--space-xxs);
}

.gap-xxs > * {
  --sub-gap-x: var(--space-xxs);
  --sub-gap-y: var(--space-xxs);
}

.gap-xs {
  --gap-x: var(--space-xs);
  --gap-y: var(--space-xs);
}

.gap-xs > * {
  --sub-gap-x: var(--space-xs);
  --sub-gap-y: var(--space-xs);
}

.gap-sm {
  --gap-x: var(--space-sm);
  --gap-y: var(--space-sm);
}

.gap-sm > * {
  --sub-gap-x: var(--space-sm);
  --sub-gap-y: var(--space-sm);
}

.gap-md {
  --gap-x: var(--space-md);
  --gap-y: var(--space-md);
}

.gap-md > * {
  --sub-gap-x: var(--space-md);
  --sub-gap-y: var(--space-md);
}

.gap-lg {
  --gap-x: var(--space-lg);
  --gap-y: var(--space-lg);
}

.gap-lg > * {
  --sub-gap-x: var(--space-lg);
  --sub-gap-y: var(--space-lg);
}

.gap-xl {
  --gap-x: var(--space-xl);
  --gap-y: var(--space-xl);
}

.gap-xl > * {
  --sub-gap-x: var(--space-xl);
  --sub-gap-y: var(--space-xl);
}

.gap-xxl {
  --gap-x: var(--space-xxl);
  --gap-y: var(--space-xxl);
}

.gap-xxl > * {
  --sub-gap-x: var(--space-xxl);
  --sub-gap-y: var(--space-xxl);
}

.gap-xxxl {
  --gap-x: var(--space-xxxl);
  --gap-y: var(--space-xxxl);
}

.gap-xxxl > * {
  --sub-gap-x: var(--space-xxxl);
  --sub-gap-y: var(--space-xxxl);
}

.gap-xxxxl {
  --gap-x: var(--space-xxxxl);
  --gap-y: var(--space-xxxxl);
}

.gap-xxxxl > * {
  --sub-gap-x: var(--space-xxxxl);
  --sub-gap-y: var(--space-xxxxl);
}

.gap-0 {
  --gap-x: 0px;
  --gap-y: 0px;
}

.gap-0 > * {
  --sub-gap-x: 0px;
  --sub-gap-y: 0px;
}

.gap-x-xxxxs {
  --gap-x: var(--space-xxxxs);
}

.gap-x-xxxxs > * {
  --sub-gap-x: var(--space-xxxxs);
}

.gap-x-xxxs {
  --gap-x: var(--space-xxxs);
}

.gap-x-xxxs > * {
  --sub-gap-x: var(--space-xxxs);
}

.gap-x-xxs {
  --gap-x: var(--space-xxs);
}

.gap-x-xxs > * {
  --sub-gap-x: var(--space-xxs);
}

.gap-x-xs {
  --gap-x: var(--space-xs);
}

.gap-x-xs > * {
  --sub-gap-x: var(--space-xs);
}

.gap-x-sm {
  --gap-x: var(--space-sm);
}

.gap-x-sm > * {
  --sub-gap-x: var(--space-sm);
}

.gap-x-md {
  --gap-x: var(--space-md);
}

.gap-x-md > * {
  --sub-gap-x: var(--space-md);
}

.gap-x-lg {
  --gap-x: var(--space-lg);
}

.gap-x-lg > * {
  --sub-gap-x: var(--space-lg);
}

.gap-x-xl {
  --gap-x: var(--space-xl);
}

.gap-x-xl > * {
  --sub-gap-x: var(--space-xl);
}

.gap-x-xxl {
  --gap-x: var(--space-xxl);
}

.gap-x-xxl > * {
  --sub-gap-x: var(--space-xxl);
}

.gap-x-xxxl {
  --gap-x: var(--space-xxxl);
}

.gap-x-xxxl > * {
  --sub-gap-x: var(--space-xxxl);
}

.gap-x-xxxxl {
  --gap-x: var(--space-xxxxl);
}

.gap-x-xxxxl > * {
  --sub-gap-x: var(--space-xxxxl);
}

.gap-x-0 {
  --gap-x: 0px;
}

.gap-x-0 > * {
  --sub-gap-x: 0px;
}

.gap-y-xxxxs {
  --gap-y: var(--space-xxxxs);
}

.gap-y-xxxxs > * {
  --sub-gap-y: var(--space-xxxxs);
}

.gap-y-xxxs {
  --gap-y: var(--space-xxxs);
}

.gap-y-xxxs > * {
  --sub-gap-y: var(--space-xxxs);
}

.gap-y-xxs {
  --gap-y: var(--space-xxs);
}

.gap-y-xxs > * {
  --sub-gap-y: var(--space-xxs);
}

.gap-y-xs {
  --gap-y: var(--space-xs);
}

.gap-y-xs > * {
  --sub-gap-y: var(--space-xs);
}

.gap-y-sm {
  --gap-y: var(--space-sm);
}

.gap-y-sm > * {
  --sub-gap-y: var(--space-sm);
}

.gap-y-md {
  --gap-y: var(--space-md);
}

.gap-y-md > * {
  --sub-gap-y: var(--space-md);
}

.gap-y-lg {
  --gap-y: var(--space-lg);
}

.gap-y-lg > * {
  --sub-gap-y: var(--space-lg);
}

.gap-y-xl {
  --gap-y: var(--space-xl);
}

.gap-y-xl > * {
  --sub-gap-y: var(--space-xl);
}

.gap-y-xxl {
  --gap-y: var(--space-xxl);
}

.gap-y-xxl > * {
  --sub-gap-y: var(--space-xxl);
}

.gap-y-xxxl {
  --gap-y: var(--space-xxxl);
}

.gap-y-xxxl > * {
  --sub-gap-y: var(--space-xxxl);
}

.gap-y-xxxxl {
  --gap-y: var(--space-xxxxl);
}

.gap-y-xxxxl > * {
  --sub-gap-y: var(--space-xxxxl);
}

.gap-y-0 {
  --gap-y: 0px;
}

.gap-y-0 > * {
  --sub-gap-y: 0px;
}

.grid-col-1 {
  --grid-columns: 1;
}

.col-1 {
  --span: 1;
}

.grid-col-2 {
  --grid-columns: 2;
}

.col-2 {
  --span: 2;
}

.grid-col-3 {
  --grid-columns: 3;
}

.col-3 {
  --span: 3;
}

.grid-col-4 {
  --grid-columns: 4;
}

.col-4 {
  --span: 4;
}

.grid-col-5 {
  --grid-columns: 5;
}

.col-5 {
  --span: 5;
}

.grid-col-6 {
  --grid-columns: 6;
}

.col-6 {
  --span: 6;
}

.grid-col-7 {
  --grid-columns: 7;
}

.col-7 {
  --span: 7;
}

.grid-col-8 {
  --grid-columns: 8;
}

.col-8 {
  --span: 8;
}

.grid-col-9 {
  --grid-columns: 9;
}

.col-9 {
  --span: 9;
}

.grid-col-10 {
  --grid-columns: 10;
}

.col-10 {
  --span: 10;
}

.grid-col-11 {
  --grid-columns: 11;
}

.col-11 {
  --span: 11;
}

.grid-col-12 {
  --grid-columns: 12;
}

.col-12 {
  --span: 12;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  flex-basis: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
  max-width: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.col-content {
  flex-grow: 0;
  flex-basis: initial;
  max-width: initial;
}

.offset-1 {
  --offset: 1;
}

.offset-2 {
  --offset: 2;
}

.offset-3 {
  --offset: 3;
}

.offset-4 {
  --offset: 4;
}

.offset-5 {
  --offset: 5;
}

.offset-6 {
  --offset: 6;
}

.offset-7 {
  --offset: 7;
}

.offset-8 {
  --offset: 8;
}

.offset-9 {
  --offset: 9;
}

.offset-10 {
  --offset: 10;
}

.offset-11 {
  --offset: 11;
}

.offset-1, .offset-2, .offset-3, .offset-4, .offset-5, .offset-6, .offset-7, .offset-8, .offset-9, .offset-10, .offset-11 {
  margin-left: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns)  + (var(--offset)  + var(--offset-modifier, 0)) * var(--sub-gap-x));
}

@media (min-width: 32rem) {
  .gap-xxxxs\@xs {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }

  .gap-xxxxs\@xs > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@xs {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }

  .gap-xxxs\@xs > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@xs {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }

  .gap-xxs\@xs > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@xs {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }

  .gap-xs\@xs > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@xs {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }

  .gap-sm\@xs > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@xs {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }

  .gap-md\@xs > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@xs {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }

  .gap-lg\@xs > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@xs {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }

  .gap-xl\@xs > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@xs {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }

  .gap-xxl\@xs > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@xs {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }

  .gap-xxxl\@xs > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@xs {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }

  .gap-xxxxl\@xs > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@xs {
    --gap-x: 0px;
    --gap-y: 0px;
  }

  .gap-0\@xs > * {
    --sub-gap-x: 0px;
    --sub-gap-y: 0px;
  }

  .gap-x-xxxxs\@xs {
    --gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxxs\@xs > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@xs {
    --gap-x: var(--space-xxxs);
  }

  .gap-x-xxxs\@xs > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@xs {
    --gap-x: var(--space-xxs);
  }

  .gap-x-xxs\@xs > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@xs {
    --gap-x: var(--space-xs);
  }

  .gap-x-xs\@xs > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@xs {
    --gap-x: var(--space-sm);
  }

  .gap-x-sm\@xs > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@xs {
    --gap-x: var(--space-md);
  }

  .gap-x-md\@xs > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@xs {
    --gap-x: var(--space-lg);
  }

  .gap-x-lg\@xs > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@xs {
    --gap-x: var(--space-xl);
  }

  .gap-x-xl\@xs > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@xs {
    --gap-x: var(--space-xxl);
  }

  .gap-x-xxl\@xs > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@xs {
    --gap-x: var(--space-xxxl);
  }

  .gap-x-xxxl\@xs > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@xs {
    --gap-x: var(--space-xxxxl);
  }

  .gap-x-xxxxl\@xs > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@xs {
    --gap-x: 0px;
  }

  .gap-x-0\@xs > * {
    --sub-gap-x: 0px;
  }

  .gap-y-xxxxs\@xs {
    --gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxxs\@xs > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@xs {
    --gap-y: var(--space-xxxs);
  }

  .gap-y-xxxs\@xs > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@xs {
    --gap-y: var(--space-xxs);
  }

  .gap-y-xxs\@xs > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@xs {
    --gap-y: var(--space-xs);
  }

  .gap-y-xs\@xs > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@xs {
    --gap-y: var(--space-sm);
  }

  .gap-y-sm\@xs > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@xs {
    --gap-y: var(--space-md);
  }

  .gap-y-md\@xs > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@xs {
    --gap-y: var(--space-lg);
  }

  .gap-y-lg\@xs > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@xs {
    --gap-y: var(--space-xl);
  }

  .gap-y-xl\@xs > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@xs {
    --gap-y: var(--space-xxl);
  }

  .gap-y-xxl\@xs > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@xs {
    --gap-y: var(--space-xxxl);
  }

  .gap-y-xxxl\@xs > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@xs {
    --gap-y: var(--space-xxxxl);
  }

  .gap-y-xxxxl\@xs > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@xs {
    --gap-y: 0px;
  }

  .gap-y-0\@xs > * {
    --sub-gap-y: 0px;
  }

  .grid-col-1\@xs {
    --grid-columns: 1;
  }

  .col-1\@xs {
    --span: 1;
  }

  .grid-col-2\@xs {
    --grid-columns: 2;
  }

  .col-2\@xs {
    --span: 2;
  }

  .grid-col-3\@xs {
    --grid-columns: 3;
  }

  .col-3\@xs {
    --span: 3;
  }

  .grid-col-4\@xs {
    --grid-columns: 4;
  }

  .col-4\@xs {
    --span: 4;
  }

  .grid-col-5\@xs {
    --grid-columns: 5;
  }

  .col-5\@xs {
    --span: 5;
  }

  .grid-col-6\@xs {
    --grid-columns: 6;
  }

  .col-6\@xs {
    --span: 6;
  }

  .grid-col-7\@xs {
    --grid-columns: 7;
  }

  .col-7\@xs {
    --span: 7;
  }

  .grid-col-8\@xs {
    --grid-columns: 8;
  }

  .col-8\@xs {
    --span: 8;
  }

  .grid-col-9\@xs {
    --grid-columns: 9;
  }

  .col-9\@xs {
    --span: 9;
  }

  .grid-col-10\@xs {
    --grid-columns: 10;
  }

  .col-10\@xs {
    --span: 10;
  }

  .grid-col-11\@xs {
    --grid-columns: 11;
  }

  .col-11\@xs {
    --span: 11;
  }

  .grid-col-12\@xs {
    --grid-columns: 12;
  }

  .col-12\@xs {
    --span: 12;
  }

  .col-1\@xs, .col-2\@xs, .col-3\@xs, .col-4\@xs, .col-5\@xs, .col-6\@xs, .col-7\@xs, .col-8\@xs, .col-9\@xs, .col-10\@xs, .col-11\@xs, .col-12\@xs {
    flex-basis: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
  }

  .col\@xs {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-content\@xs {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@xs {
    --offset: 1;
  }

  .offset-2\@xs {
    --offset: 2;
  }

  .offset-3\@xs {
    --offset: 3;
  }

  .offset-4\@xs {
    --offset: 4;
  }

  .offset-5\@xs {
    --offset: 5;
  }

  .offset-6\@xs {
    --offset: 6;
  }

  .offset-7\@xs {
    --offset: 7;
  }

  .offset-8\@xs {
    --offset: 8;
  }

  .offset-9\@xs {
    --offset: 9;
  }

  .offset-10\@xs {
    --offset: 10;
  }

  .offset-11\@xs {
    --offset: 11;
  }

  .offset-1\@xs, .offset-2\@xs, .offset-3\@xs, .offset-4\@xs, .offset-5\@xs, .offset-6\@xs, .offset-7\@xs, .offset-8\@xs, .offset-9\@xs, .offset-10\@xs, .offset-11\@xs {
    margin-left: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns)  + (var(--offset)  + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@xs {
    margin-left: 0;
  }

  @media not all and (min-resolution: .001dpcm) {
    @supports not (translate: none) {
      .offset-0\@xs {
        margin-left: var(--gap-x);
      }
    }
  }
}

@media (min-width: 48rem) {
  .gap-xxxxs\@sm {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }

  .gap-xxxxs\@sm > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@sm {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }

  .gap-xxxs\@sm > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@sm {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }

  .gap-xxs\@sm > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@sm {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }

  .gap-xs\@sm > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@sm {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }

  .gap-sm\@sm > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@sm {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }

  .gap-md\@sm > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@sm {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }

  .gap-lg\@sm > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@sm {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }

  .gap-xl\@sm > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@sm {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }

  .gap-xxl\@sm > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@sm {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }

  .gap-xxxl\@sm > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@sm {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }

  .gap-xxxxl\@sm > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@sm {
    --gap-x: 0px;
    --gap-y: 0px;
  }

  .gap-0\@sm > * {
    --sub-gap-x: 0px;
    --sub-gap-y: 0px;
  }

  .gap-x-xxxxs\@sm {
    --gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxxs\@sm > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@sm {
    --gap-x: var(--space-xxxs);
  }

  .gap-x-xxxs\@sm > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@sm {
    --gap-x: var(--space-xxs);
  }

  .gap-x-xxs\@sm > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@sm {
    --gap-x: var(--space-xs);
  }

  .gap-x-xs\@sm > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@sm {
    --gap-x: var(--space-sm);
  }

  .gap-x-sm\@sm > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@sm {
    --gap-x: var(--space-md);
  }

  .gap-x-md\@sm > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@sm {
    --gap-x: var(--space-lg);
  }

  .gap-x-lg\@sm > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@sm {
    --gap-x: var(--space-xl);
  }

  .gap-x-xl\@sm > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@sm {
    --gap-x: var(--space-xxl);
  }

  .gap-x-xxl\@sm > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@sm {
    --gap-x: var(--space-xxxl);
  }

  .gap-x-xxxl\@sm > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@sm {
    --gap-x: var(--space-xxxxl);
  }

  .gap-x-xxxxl\@sm > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@sm {
    --gap-x: 0px;
  }

  .gap-x-0\@sm > * {
    --sub-gap-x: 0px;
  }

  .gap-y-xxxxs\@sm {
    --gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxxs\@sm > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@sm {
    --gap-y: var(--space-xxxs);
  }

  .gap-y-xxxs\@sm > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@sm {
    --gap-y: var(--space-xxs);
  }

  .gap-y-xxs\@sm > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@sm {
    --gap-y: var(--space-xs);
  }

  .gap-y-xs\@sm > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@sm {
    --gap-y: var(--space-sm);
  }

  .gap-y-sm\@sm > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@sm {
    --gap-y: var(--space-md);
  }

  .gap-y-md\@sm > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@sm {
    --gap-y: var(--space-lg);
  }

  .gap-y-lg\@sm > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@sm {
    --gap-y: var(--space-xl);
  }

  .gap-y-xl\@sm > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@sm {
    --gap-y: var(--space-xxl);
  }

  .gap-y-xxl\@sm > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@sm {
    --gap-y: var(--space-xxxl);
  }

  .gap-y-xxxl\@sm > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@sm {
    --gap-y: var(--space-xxxxl);
  }

  .gap-y-xxxxl\@sm > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@sm {
    --gap-y: 0px;
  }

  .gap-y-0\@sm > * {
    --sub-gap-y: 0px;
  }

  .grid-col-1\@sm {
    --grid-columns: 1;
  }

  .col-1\@sm {
    --span: 1;
  }

  .grid-col-2\@sm {
    --grid-columns: 2;
  }

  .col-2\@sm {
    --span: 2;
  }

  .grid-col-3\@sm {
    --grid-columns: 3;
  }

  .col-3\@sm {
    --span: 3;
  }

  .grid-col-4\@sm {
    --grid-columns: 4;
  }

  .col-4\@sm {
    --span: 4;
  }

  .grid-col-5\@sm {
    --grid-columns: 5;
  }

  .col-5\@sm {
    --span: 5;
  }

  .grid-col-6\@sm {
    --grid-columns: 6;
  }

  .col-6\@sm {
    --span: 6;
  }

  .grid-col-7\@sm {
    --grid-columns: 7;
  }

  .col-7\@sm {
    --span: 7;
  }

  .grid-col-8\@sm {
    --grid-columns: 8;
  }

  .col-8\@sm {
    --span: 8;
  }

  .grid-col-9\@sm {
    --grid-columns: 9;
  }

  .col-9\@sm {
    --span: 9;
  }

  .grid-col-10\@sm {
    --grid-columns: 10;
  }

  .col-10\@sm {
    --span: 10;
  }

  .grid-col-11\@sm {
    --grid-columns: 11;
  }

  .col-11\@sm {
    --span: 11;
  }

  .grid-col-12\@sm {
    --grid-columns: 12;
  }

  .col-12\@sm {
    --span: 12;
  }

  .col-1\@sm, .col-2\@sm, .col-3\@sm, .col-4\@sm, .col-5\@sm, .col-6\@sm, .col-7\@sm, .col-8\@sm, .col-9\@sm, .col-10\@sm, .col-11\@sm, .col-12\@sm {
    flex-basis: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
  }

  .col\@sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-content\@sm {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@sm {
    --offset: 1;
  }

  .offset-2\@sm {
    --offset: 2;
  }

  .offset-3\@sm {
    --offset: 3;
  }

  .offset-4\@sm {
    --offset: 4;
  }

  .offset-5\@sm {
    --offset: 5;
  }

  .offset-6\@sm {
    --offset: 6;
  }

  .offset-7\@sm {
    --offset: 7;
  }

  .offset-8\@sm {
    --offset: 8;
  }

  .offset-9\@sm {
    --offset: 9;
  }

  .offset-10\@sm {
    --offset: 10;
  }

  .offset-11\@sm {
    --offset: 11;
  }

  .offset-1\@sm, .offset-2\@sm, .offset-3\@sm, .offset-4\@sm, .offset-5\@sm, .offset-6\@sm, .offset-7\@sm, .offset-8\@sm, .offset-9\@sm, .offset-10\@sm, .offset-11\@sm {
    margin-left: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns)  + (var(--offset)  + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@sm {
    margin-left: 0;
  }

  @media not all and (min-resolution: .001dpcm) {
    @supports not (translate: none) {
      .offset-0\@sm {
        margin-left: var(--gap-x);
      }
    }
  }
}

@media (min-width: 64rem) {
  .gap-xxxxs\@md {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }

  .gap-xxxxs\@md > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@md {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }

  .gap-xxxs\@md > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@md {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }

  .gap-xxs\@md > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@md {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }

  .gap-xs\@md > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@md {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }

  .gap-sm\@md > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@md {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }

  .gap-md\@md > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@md {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }

  .gap-lg\@md > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@md {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }

  .gap-xl\@md > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@md {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }

  .gap-xxl\@md > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@md {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }

  .gap-xxxl\@md > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@md {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }

  .gap-xxxxl\@md > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@md {
    --gap-x: 0px;
    --gap-y: 0px;
  }

  .gap-0\@md > * {
    --sub-gap-x: 0px;
    --sub-gap-y: 0px;
  }

  .gap-x-xxxxs\@md {
    --gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxxs\@md > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@md {
    --gap-x: var(--space-xxxs);
  }

  .gap-x-xxxs\@md > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@md {
    --gap-x: var(--space-xxs);
  }

  .gap-x-xxs\@md > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@md {
    --gap-x: var(--space-xs);
  }

  .gap-x-xs\@md > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@md {
    --gap-x: var(--space-sm);
  }

  .gap-x-sm\@md > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@md {
    --gap-x: var(--space-md);
  }

  .gap-x-md\@md > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@md {
    --gap-x: var(--space-lg);
  }

  .gap-x-lg\@md > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@md {
    --gap-x: var(--space-xl);
  }

  .gap-x-xl\@md > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@md {
    --gap-x: var(--space-xxl);
  }

  .gap-x-xxl\@md > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@md {
    --gap-x: var(--space-xxxl);
  }

  .gap-x-xxxl\@md > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@md {
    --gap-x: var(--space-xxxxl);
  }

  .gap-x-xxxxl\@md > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@md {
    --gap-x: 0px;
  }

  .gap-x-0\@md > * {
    --sub-gap-x: 0px;
  }

  .gap-y-xxxxs\@md {
    --gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxxs\@md > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@md {
    --gap-y: var(--space-xxxs);
  }

  .gap-y-xxxs\@md > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@md {
    --gap-y: var(--space-xxs);
  }

  .gap-y-xxs\@md > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@md {
    --gap-y: var(--space-xs);
  }

  .gap-y-xs\@md > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@md {
    --gap-y: var(--space-sm);
  }

  .gap-y-sm\@md > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@md {
    --gap-y: var(--space-md);
  }

  .gap-y-md\@md > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@md {
    --gap-y: var(--space-lg);
  }

  .gap-y-lg\@md > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@md {
    --gap-y: var(--space-xl);
  }

  .gap-y-xl\@md > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@md {
    --gap-y: var(--space-xxl);
  }

  .gap-y-xxl\@md > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@md {
    --gap-y: var(--space-xxxl);
  }

  .gap-y-xxxl\@md > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@md {
    --gap-y: var(--space-xxxxl);
  }

  .gap-y-xxxxl\@md > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@md {
    --gap-y: 0px;
  }

  .gap-y-0\@md > * {
    --sub-gap-y: 0px;
  }

  .grid-col-1\@md {
    --grid-columns: 1;
  }

  .col-1\@md {
    --span: 1;
  }

  .grid-col-2\@md {
    --grid-columns: 2;
  }

  .col-2\@md {
    --span: 2;
  }

  .grid-col-3\@md {
    --grid-columns: 3;
  }

  .col-3\@md {
    --span: 3;
  }

  .grid-col-4\@md {
    --grid-columns: 4;
  }

  .col-4\@md {
    --span: 4;
  }

  .grid-col-5\@md {
    --grid-columns: 5;
  }

  .col-5\@md {
    --span: 5;
  }

  .grid-col-6\@md {
    --grid-columns: 6;
  }

  .col-6\@md {
    --span: 6;
  }

  .grid-col-7\@md {
    --grid-columns: 7;
  }

  .col-7\@md {
    --span: 7;
  }

  .grid-col-8\@md {
    --grid-columns: 8;
  }

  .col-8\@md {
    --span: 8;
  }

  .grid-col-9\@md {
    --grid-columns: 9;
  }

  .col-9\@md {
    --span: 9;
  }

  .grid-col-10\@md {
    --grid-columns: 10;
  }

  .col-10\@md {
    --span: 10;
  }

  .grid-col-11\@md {
    --grid-columns: 11;
  }

  .col-11\@md {
    --span: 11;
  }

  .grid-col-12\@md {
    --grid-columns: 12;
  }

  .col-12\@md {
    --span: 12;
  }

  .col-1\@md, .col-2\@md, .col-3\@md, .col-4\@md, .col-5\@md, .col-6\@md, .col-7\@md, .col-8\@md, .col-9\@md, .col-10\@md, .col-11\@md, .col-12\@md {
    flex-basis: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
  }

  .col\@md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-content\@md {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@md {
    --offset: 1;
  }

  .offset-2\@md {
    --offset: 2;
  }

  .offset-3\@md {
    --offset: 3;
  }

  .offset-4\@md {
    --offset: 4;
  }

  .offset-5\@md {
    --offset: 5;
  }

  .offset-6\@md {
    --offset: 6;
  }

  .offset-7\@md {
    --offset: 7;
  }

  .offset-8\@md {
    --offset: 8;
  }

  .offset-9\@md {
    --offset: 9;
  }

  .offset-10\@md {
    --offset: 10;
  }

  .offset-11\@md {
    --offset: 11;
  }

  .offset-1\@md, .offset-2\@md, .offset-3\@md, .offset-4\@md, .offset-5\@md, .offset-6\@md, .offset-7\@md, .offset-8\@md, .offset-9\@md, .offset-10\@md, .offset-11\@md {
    margin-left: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns)  + (var(--offset)  + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@md {
    margin-left: 0;
  }

  @media not all and (min-resolution: .001dpcm) {
    @supports not (translate: none) {
      .offset-0\@md {
        margin-left: var(--gap-x);
      }
    }
  }
}

@media (min-width: 80rem) {
  .gap-xxxxs\@lg {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }

  .gap-xxxxs\@lg > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@lg {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }

  .gap-xxxs\@lg > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@lg {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }

  .gap-xxs\@lg > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@lg {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }

  .gap-xs\@lg > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@lg {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }

  .gap-sm\@lg > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@lg {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }

  .gap-md\@lg > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@lg {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }

  .gap-lg\@lg > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@lg {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }

  .gap-xl\@lg > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@lg {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }

  .gap-xxl\@lg > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@lg {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }

  .gap-xxxl\@lg > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@lg {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }

  .gap-xxxxl\@lg > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@lg {
    --gap-x: 0px;
    --gap-y: 0px;
  }

  .gap-0\@lg > * {
    --sub-gap-x: 0px;
    --sub-gap-y: 0px;
  }

  .gap-x-xxxxs\@lg {
    --gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxxs\@lg > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@lg {
    --gap-x: var(--space-xxxs);
  }

  .gap-x-xxxs\@lg > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@lg {
    --gap-x: var(--space-xxs);
  }

  .gap-x-xxs\@lg > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@lg {
    --gap-x: var(--space-xs);
  }

  .gap-x-xs\@lg > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@lg {
    --gap-x: var(--space-sm);
  }

  .gap-x-sm\@lg > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@lg {
    --gap-x: var(--space-md);
  }

  .gap-x-md\@lg > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@lg {
    --gap-x: var(--space-lg);
  }

  .gap-x-lg\@lg > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@lg {
    --gap-x: var(--space-xl);
  }

  .gap-x-xl\@lg > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@lg {
    --gap-x: var(--space-xxl);
  }

  .gap-x-xxl\@lg > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@lg {
    --gap-x: var(--space-xxxl);
  }

  .gap-x-xxxl\@lg > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@lg {
    --gap-x: var(--space-xxxxl);
  }

  .gap-x-xxxxl\@lg > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@lg {
    --gap-x: 0px;
  }

  .gap-x-0\@lg > * {
    --sub-gap-x: 0px;
  }

  .gap-y-xxxxs\@lg {
    --gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxxs\@lg > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@lg {
    --gap-y: var(--space-xxxs);
  }

  .gap-y-xxxs\@lg > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@lg {
    --gap-y: var(--space-xxs);
  }

  .gap-y-xxs\@lg > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@lg {
    --gap-y: var(--space-xs);
  }

  .gap-y-xs\@lg > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@lg {
    --gap-y: var(--space-sm);
  }

  .gap-y-sm\@lg > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@lg {
    --gap-y: var(--space-md);
  }

  .gap-y-md\@lg > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@lg {
    --gap-y: var(--space-lg);
  }

  .gap-y-lg\@lg > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@lg {
    --gap-y: var(--space-xl);
  }

  .gap-y-xl\@lg > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@lg {
    --gap-y: var(--space-xxl);
  }

  .gap-y-xxl\@lg > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@lg {
    --gap-y: var(--space-xxxl);
  }

  .gap-y-xxxl\@lg > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@lg {
    --gap-y: var(--space-xxxxl);
  }

  .gap-y-xxxxl\@lg > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@lg {
    --gap-y: 0px;
  }

  .gap-y-0\@lg > * {
    --sub-gap-y: 0px;
  }

  .grid-col-1\@lg {
    --grid-columns: 1;
  }

  .col-1\@lg {
    --span: 1;
  }

  .grid-col-2\@lg {
    --grid-columns: 2;
  }

  .col-2\@lg {
    --span: 2;
  }

  .grid-col-3\@lg {
    --grid-columns: 3;
  }

  .col-3\@lg {
    --span: 3;
  }

  .grid-col-4\@lg {
    --grid-columns: 4;
  }

  .col-4\@lg {
    --span: 4;
  }

  .grid-col-5\@lg {
    --grid-columns: 5;
  }

  .col-5\@lg {
    --span: 5;
  }

  .grid-col-6\@lg {
    --grid-columns: 6;
  }

  .col-6\@lg {
    --span: 6;
  }

  .grid-col-7\@lg {
    --grid-columns: 7;
  }

  .col-7\@lg {
    --span: 7;
  }

  .grid-col-8\@lg {
    --grid-columns: 8;
  }

  .col-8\@lg {
    --span: 8;
  }

  .grid-col-9\@lg {
    --grid-columns: 9;
  }

  .col-9\@lg {
    --span: 9;
  }

  .grid-col-10\@lg {
    --grid-columns: 10;
  }

  .col-10\@lg {
    --span: 10;
  }

  .grid-col-11\@lg {
    --grid-columns: 11;
  }

  .col-11\@lg {
    --span: 11;
  }

  .grid-col-12\@lg {
    --grid-columns: 12;
  }

  .col-12\@lg {
    --span: 12;
  }

  .col-1\@lg, .col-2\@lg, .col-3\@lg, .col-4\@lg, .col-5\@lg, .col-6\@lg, .col-7\@lg, .col-8\@lg, .col-9\@lg, .col-10\@lg, .col-11\@lg, .col-12\@lg {
    flex-basis: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
  }

  .col\@lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-content\@lg {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@lg {
    --offset: 1;
  }

  .offset-2\@lg {
    --offset: 2;
  }

  .offset-3\@lg {
    --offset: 3;
  }

  .offset-4\@lg {
    --offset: 4;
  }

  .offset-5\@lg {
    --offset: 5;
  }

  .offset-6\@lg {
    --offset: 6;
  }

  .offset-7\@lg {
    --offset: 7;
  }

  .offset-8\@lg {
    --offset: 8;
  }

  .offset-9\@lg {
    --offset: 9;
  }

  .offset-10\@lg {
    --offset: 10;
  }

  .offset-11\@lg {
    --offset: 11;
  }

  .offset-1\@lg, .offset-2\@lg, .offset-3\@lg, .offset-4\@lg, .offset-5\@lg, .offset-6\@lg, .offset-7\@lg, .offset-8\@lg, .offset-9\@lg, .offset-10\@lg, .offset-11\@lg {
    margin-left: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns)  + (var(--offset)  + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@lg {
    margin-left: 0;
  }

  @media not all and (min-resolution: .001dpcm) {
    @supports not (translate: none) {
      .offset-0\@lg {
        margin-left: var(--gap-x);
      }
    }
  }
}

@media (min-width: 90rem) {
  .gap-xxxxs\@xl {
    --gap-x: var(--space-xxxxs);
    --gap-y: var(--space-xxxxs);
  }

  .gap-xxxxs\@xl > * {
    --sub-gap-x: var(--space-xxxxs);
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-xxxs\@xl {
    --gap-x: var(--space-xxxs);
    --gap-y: var(--space-xxxs);
  }

  .gap-xxxs\@xl > * {
    --sub-gap-x: var(--space-xxxs);
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-xxs\@xl {
    --gap-x: var(--space-xxs);
    --gap-y: var(--space-xxs);
  }

  .gap-xxs\@xl > * {
    --sub-gap-x: var(--space-xxs);
    --sub-gap-y: var(--space-xxs);
  }

  .gap-xs\@xl {
    --gap-x: var(--space-xs);
    --gap-y: var(--space-xs);
  }

  .gap-xs\@xl > * {
    --sub-gap-x: var(--space-xs);
    --sub-gap-y: var(--space-xs);
  }

  .gap-sm\@xl {
    --gap-x: var(--space-sm);
    --gap-y: var(--space-sm);
  }

  .gap-sm\@xl > * {
    --sub-gap-x: var(--space-sm);
    --sub-gap-y: var(--space-sm);
  }

  .gap-md\@xl {
    --gap-x: var(--space-md);
    --gap-y: var(--space-md);
  }

  .gap-md\@xl > * {
    --sub-gap-x: var(--space-md);
    --sub-gap-y: var(--space-md);
  }

  .gap-lg\@xl {
    --gap-x: var(--space-lg);
    --gap-y: var(--space-lg);
  }

  .gap-lg\@xl > * {
    --sub-gap-x: var(--space-lg);
    --sub-gap-y: var(--space-lg);
  }

  .gap-xl\@xl {
    --gap-x: var(--space-xl);
    --gap-y: var(--space-xl);
  }

  .gap-xl\@xl > * {
    --sub-gap-x: var(--space-xl);
    --sub-gap-y: var(--space-xl);
  }

  .gap-xxl\@xl {
    --gap-x: var(--space-xxl);
    --gap-y: var(--space-xxl);
  }

  .gap-xxl\@xl > * {
    --sub-gap-x: var(--space-xxl);
    --sub-gap-y: var(--space-xxl);
  }

  .gap-xxxl\@xl {
    --gap-x: var(--space-xxxl);
    --gap-y: var(--space-xxxl);
  }

  .gap-xxxl\@xl > * {
    --sub-gap-x: var(--space-xxxl);
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-xxxxl\@xl {
    --gap-x: var(--space-xxxxl);
    --gap-y: var(--space-xxxxl);
  }

  .gap-xxxxl\@xl > * {
    --sub-gap-x: var(--space-xxxxl);
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-0\@xl {
    --gap-x: 0px;
    --gap-y: 0px;
  }

  .gap-0\@xl > * {
    --sub-gap-x: 0px;
    --sub-gap-y: 0px;
  }

  .gap-x-xxxxs\@xl {
    --gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxxs\@xl > * {
    --sub-gap-x: var(--space-xxxxs);
  }

  .gap-x-xxxs\@xl {
    --gap-x: var(--space-xxxs);
  }

  .gap-x-xxxs\@xl > * {
    --sub-gap-x: var(--space-xxxs);
  }

  .gap-x-xxs\@xl {
    --gap-x: var(--space-xxs);
  }

  .gap-x-xxs\@xl > * {
    --sub-gap-x: var(--space-xxs);
  }

  .gap-x-xs\@xl {
    --gap-x: var(--space-xs);
  }

  .gap-x-xs\@xl > * {
    --sub-gap-x: var(--space-xs);
  }

  .gap-x-sm\@xl {
    --gap-x: var(--space-sm);
  }

  .gap-x-sm\@xl > * {
    --sub-gap-x: var(--space-sm);
  }

  .gap-x-md\@xl {
    --gap-x: var(--space-md);
  }

  .gap-x-md\@xl > * {
    --sub-gap-x: var(--space-md);
  }

  .gap-x-lg\@xl {
    --gap-x: var(--space-lg);
  }

  .gap-x-lg\@xl > * {
    --sub-gap-x: var(--space-lg);
  }

  .gap-x-xl\@xl {
    --gap-x: var(--space-xl);
  }

  .gap-x-xl\@xl > * {
    --sub-gap-x: var(--space-xl);
  }

  .gap-x-xxl\@xl {
    --gap-x: var(--space-xxl);
  }

  .gap-x-xxl\@xl > * {
    --sub-gap-x: var(--space-xxl);
  }

  .gap-x-xxxl\@xl {
    --gap-x: var(--space-xxxl);
  }

  .gap-x-xxxl\@xl > * {
    --sub-gap-x: var(--space-xxxl);
  }

  .gap-x-xxxxl\@xl {
    --gap-x: var(--space-xxxxl);
  }

  .gap-x-xxxxl\@xl > * {
    --sub-gap-x: var(--space-xxxxl);
  }

  .gap-x-0\@xl {
    --gap-x: 0px;
  }

  .gap-x-0\@xl > * {
    --sub-gap-x: 0px;
  }

  .gap-y-xxxxs\@xl {
    --gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxxs\@xl > * {
    --sub-gap-y: var(--space-xxxxs);
  }

  .gap-y-xxxs\@xl {
    --gap-y: var(--space-xxxs);
  }

  .gap-y-xxxs\@xl > * {
    --sub-gap-y: var(--space-xxxs);
  }

  .gap-y-xxs\@xl {
    --gap-y: var(--space-xxs);
  }

  .gap-y-xxs\@xl > * {
    --sub-gap-y: var(--space-xxs);
  }

  .gap-y-xs\@xl {
    --gap-y: var(--space-xs);
  }

  .gap-y-xs\@xl > * {
    --sub-gap-y: var(--space-xs);
  }

  .gap-y-sm\@xl {
    --gap-y: var(--space-sm);
  }

  .gap-y-sm\@xl > * {
    --sub-gap-y: var(--space-sm);
  }

  .gap-y-md\@xl {
    --gap-y: var(--space-md);
  }

  .gap-y-md\@xl > * {
    --sub-gap-y: var(--space-md);
  }

  .gap-y-lg\@xl {
    --gap-y: var(--space-lg);
  }

  .gap-y-lg\@xl > * {
    --sub-gap-y: var(--space-lg);
  }

  .gap-y-xl\@xl {
    --gap-y: var(--space-xl);
  }

  .gap-y-xl\@xl > * {
    --sub-gap-y: var(--space-xl);
  }

  .gap-y-xxl\@xl {
    --gap-y: var(--space-xxl);
  }

  .gap-y-xxl\@xl > * {
    --sub-gap-y: var(--space-xxl);
  }

  .gap-y-xxxl\@xl {
    --gap-y: var(--space-xxxl);
  }

  .gap-y-xxxl\@xl > * {
    --sub-gap-y: var(--space-xxxl);
  }

  .gap-y-xxxxl\@xl {
    --gap-y: var(--space-xxxxl);
  }

  .gap-y-xxxxl\@xl > * {
    --sub-gap-y: var(--space-xxxxl);
  }

  .gap-y-0\@xl {
    --gap-y: 0px;
  }

  .gap-y-0\@xl > * {
    --sub-gap-y: 0px;
  }

  .grid-col-1\@xl {
    --grid-columns: 1;
  }

  .col-1\@xl {
    --span: 1;
  }

  .grid-col-2\@xl {
    --grid-columns: 2;
  }

  .col-2\@xl {
    --span: 2;
  }

  .grid-col-3\@xl {
    --grid-columns: 3;
  }

  .col-3\@xl {
    --span: 3;
  }

  .grid-col-4\@xl {
    --grid-columns: 4;
  }

  .col-4\@xl {
    --span: 4;
  }

  .grid-col-5\@xl {
    --grid-columns: 5;
  }

  .col-5\@xl {
    --span: 5;
  }

  .grid-col-6\@xl {
    --grid-columns: 6;
  }

  .col-6\@xl {
    --span: 6;
  }

  .grid-col-7\@xl {
    --grid-columns: 7;
  }

  .col-7\@xl {
    --span: 7;
  }

  .grid-col-8\@xl {
    --grid-columns: 8;
  }

  .col-8\@xl {
    --span: 8;
  }

  .grid-col-9\@xl {
    --grid-columns: 9;
  }

  .col-9\@xl {
    --span: 9;
  }

  .grid-col-10\@xl {
    --grid-columns: 10;
  }

  .col-10\@xl {
    --span: 10;
  }

  .grid-col-11\@xl {
    --grid-columns: 11;
  }

  .col-11\@xl {
    --span: 11;
  }

  .grid-col-12\@xl {
    --grid-columns: 12;
  }

  .col-12\@xl {
    --span: 12;
  }

  .col-1\@xl, .col-2\@xl, .col-3\@xl, .col-4\@xl, .col-5\@xl, .col-6\@xl, .col-7\@xl, .col-8\@xl, .col-9\@xl, .col-10\@xl, .col-11\@xl, .col-12\@xl {
    flex-basis: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
    max-width: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--span) / var(--grid-columns)  + (var(--span)  - 1) * var(--sub-gap-x));
  }

  .col\@xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .col-content\@xl {
    flex-grow: 0;
    flex-basis: initial;
    max-width: initial;
  }

  .offset-1\@xl {
    --offset: 1;
  }

  .offset-2\@xl {
    --offset: 2;
  }

  .offset-3\@xl {
    --offset: 3;
  }

  .offset-4\@xl {
    --offset: 4;
  }

  .offset-5\@xl {
    --offset: 5;
  }

  .offset-6\@xl {
    --offset: 6;
  }

  .offset-7\@xl {
    --offset: 7;
  }

  .offset-8\@xl {
    --offset: 8;
  }

  .offset-9\@xl {
    --offset: 9;
  }

  .offset-10\@xl {
    --offset: 10;
  }

  .offset-11\@xl {
    --offset: 11;
  }

  .offset-1\@xl, .offset-2\@xl, .offset-3\@xl, .offset-4\@xl, .offset-5\@xl, .offset-6\@xl, .offset-7\@xl, .offset-8\@xl, .offset-9\@xl, .offset-10\@xl, .offset-11\@xl {
    margin-left: calc((100% - (var(--grid-columns)  - var(--gap-modifier, 1)) * var(--sub-gap-x)) * var(--offset) / var(--grid-columns)  + (var(--offset)  + var(--offset-modifier, 0)) * var(--sub-gap-x));
  }

  .offset-0\@xl {
    margin-left: 0;
  }

  @media not all and (min-resolution: .001dpcm) {
    @supports not (translate: none) {
      .offset-0\@xl {
        margin-left: var(--gap-x);
      }
    }
  }
}

*, :after, :before {
  box-sizing: inherit;
}

* {
  font: inherit;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, hr {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}

body {
  background-color: var(--color-bg, white);
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main, form legend {
  display: block;
}

ol, ul, menu {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

button, input, textarea, select {
  margin: 0;
}

.btn, .form-control, .link, .reset {
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  line-height: inherit;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  padding: 0;
}

select.form-control::-ms-expand {
  display: none;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, video, svg {
  max-width: 100%;
}

[data-theme] {
  background-color: var(--color-bg, #fff);
  color: var(--color-contrast-high, #323639);
}

:root {
  --space-unit: 1rem;
}

:root, * {
  --space-xxxxs: calc(.125 * var(--space-unit));
  --space-xxxs: calc(.25 * var(--space-unit));
  --space-xxs: calc(.375 * var(--space-unit));
  --space-xs: calc(.5 * var(--space-unit));
  --space-sm: calc(.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}

:root {
  --radius-sm: calc(var(--radius, .375em) / 2);
  --radius-md: var(--radius, .375em);
  --radius-lg: calc(var(--radius, .375em) * 2);
  --shadow-ring: 0 0 0 1px #0000000d;
  --shadow-xs: 0 .1px .3px #0000000f, 0 1px 2px #0000001f;
  --shadow-sm: 0 .3px .4px #00000006, 0 .9px 1.5px #0000000d, 0 3.5px 6px #0000001a;
  --shadow-md: 0 .9px 1.5px #00000008, 0 3.1px 5.5px #00000014, 0 14px 25px #0000001f;
  --shadow-lg: 0 1.2px 1.9px -1px #00000004, 0 3.3px 5.3px -1px #0000000a, 0 8.5px 12.7px -1px #00000016, 0 30px 42px -1px #00000026;
  --shadow-xl: 0 1.5px 2.1px -6px #00000003, 0 3.6px 5.2px -6px #00000009, 0 7.3px 10.6px -6px #00000012, 0 16.2px 21.9px -6px #0000001e, 0 46px 60px -6px #0003;
  --inner-glow: inset 0 0 .5px 1px #ffffff13;
  --inner-glow-top: inset 0 1px .5px #ffffff13;
  --ease-in-out: cubic-bezier(.645, .045, .355, 1);
  --ease-in: cubic-bezier(.55, .055, .675, .19);
  --ease-out: cubic-bezier(.215, .61, .355, 1);
  --ease-out-back: cubic-bezier(.34, 1.56, .64, 1);
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

body {
  color: var(--color-contrast-high, #323639);
  font-weight: var(--body-font-weight, normal);
  font-size: var(--text-base-size, 1rem);
  font-family: var(--font-primary, sans-serif);
}

h1, h2, h3, h4 {
  color: var(--color-contrast-higher, #0d1317);
  font-weight: var(--heading-font-weight, 700);
  line-height: var(--heading-line-height, 1.2);
}

h1 {
  font-size: var(--text-xxl, 2rem);
}

h2 {
  font-size: var(--text-xl, 1.75rem);
}

h3 {
  font-size: var(--text-lg, 1.375rem);
}

h4 {
  font-size: var(--text-md, 1.125rem);
}

small {
  font-size: var(--text-sm, .75rem);
}

a, .link {
  color: var(--color-primary, #4827ec);
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

s {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

.text-component h1, .text-component h2, .text-component h3, .text-component h4 {
  margin-bottom: calc(var(--space-unit) * .3125 * var(--text-space-y-multiplier, 1));
  line-height: calc(var(--heading-line-height) * var(--line-height-multiplier, 1));
}

.text-component h2, .text-component h3, .text-component h4 {
  margin-top: calc(var(--space-unit) * .9375 * var(--text-space-y-multiplier, 1));
}

.text-component p, .text-component blockquote, .text-component ul li, .text-component ol li {
  line-height: calc(var(--body-line-height) * var(--line-height-multiplier, 1));
}

.text-component ul, .text-component ol, .text-component p, .text-component blockquote, .text-component .text-component__block {
  margin-bottom: calc(var(--space-unit) * .9375 * var(--text-space-y-multiplier, 1));
}

.text-component ul, .text-component ol {
  list-style-position: inside;
}

.text-component ul ul, .text-component ul ol, .text-component ol ul, .text-component ol ol {
  margin-bottom: 0;
  padding-left: 1em;
}

.text-component ul {
  list-style-type: disc;
}

.text-component ol {
  list-style-type: decimal;
}

.text-component img {
  margin: 0 auto;
  display: block;
}

.text-component figcaption {
  margin-top: calc(var(--space-unit) * .5);
  text-align: center;
}

.text-component em {
  font-style: italic;
}

.text-component hr {
  margin-top: calc(var(--space-unit) * 1.875 * var(--text-space-y-multiplier, 1));
  margin-right: auto;
  margin-bottom: calc(var(--space-unit) * 1.875 * var(--text-space-y-multiplier, 1));
  margin-left: auto;
}

.text-component > :first-child {
  margin-top: 0;
}

.text-component > :last-child {
  margin-bottom: 0;
}

.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

@media (min-width: 48rem) {
  .text-component__block--left, .text-component__block--right {
    width: 45%;
  }

  .text-component__block--left img, .text-component__block--right img {
    width: 100%;
  }

  .text-component__block--left {
    margin-right: calc(var(--space-unit) * .9375 * var(--text-space-y-multiplier, 1));
    float: left;
  }

  .text-component__block--right {
    margin-left: calc(var(--space-unit) * .9375 * var(--text-space-y-multiplier, 1));
    float: right;
  }
}

@media (min-width: 90rem) {
  .text-component__block--outset {
    width: calc(100% + 10.5 * var(--space-unit));
  }

  .text-component__block--outset img {
    width: 100%;
  }

  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: calc(-5.25 * var(--space-unit));
  }

  .text-component__block--left, .text-component__block--right {
    width: 50%;
  }

  .text-component__block--right.text-component__block--outset {
    margin-right: calc(-5.25 * var(--space-unit));
  }
}

:root {
  --icon-xxxs: 8px;
  --icon-xxs: 12px;
  --icon-xs: 16px;
  --icon-sm: 24px;
  --icon-md: 32px;
  --icon-lg: 48px;
  --icon-xl: 64px;
  --icon-xxl: 96px;
  --icon-xxxl: 128px;
}

.icon {
  --size: 1em;
  fill: currentColor;
  width: 1em;
  max-width: initial;
  height: 1em;
  color: inherit;
  font-size: var(--size);
  flex-shrink: 0;
  line-height: 1;
  display: inline-block;
}

.icon--xxxs {
  --size: var(--icon-xxxs);
}

.icon--xxs {
  --size: var(--icon-xxs);
}

.icon--xs {
  --size: var(--icon-xs);
}

.icon--sm {
  --size: var(--icon-sm);
}

.icon--md {
  --size: var(--icon-md);
}

.icon--lg {
  --size: var(--icon-lg);
}

.icon--xl {
  --size: var(--icon-xl);
}

.icon--xxl {
  --size: var(--icon-xxl);
}

.icon--xxxl {
  --size: var(--icon-xxxl);
}

.icon--is-spinning {
  animation: 1s linear infinite icon-spin;
}

@keyframes icon-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.icon use {
  fill: currentColor;
  color: inherit;
}

.btn {
  padding-top: var(--btn-padding-y, .5em);
  padding-right: var(--btn-padding-x, .75em);
  padding-bottom: var(--btn-padding-y, .5em);
  padding-left: var(--btn-padding-x, .75em);
  border-radius: var(--btn-radius, .25em);
  font-size: var(--btn-font-size, 1em);
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.btn--sm {
  font-size: var(--btn-font-size-sm, .8em);
}

.btn--md {
  font-size: var(--btn-font-size-md, 1.2em);
}

.btn--lg {
  font-size: var(--btn-font-size-lg, 1.4em);
}

.btn--icon {
  padding: var(--btn-padding-y, .5em);
}

.form-control {
  padding-top: var(--form-control-padding-y, .5em);
  padding-right: var(--form-control-padding-x, .75em);
  padding-bottom: var(--form-control-padding-y, .5em);
  padding-left: var(--form-control-padding-x, .75em);
  border-radius: var(--form-control-radius, .25em);
  font-size: var(--form-control-font-size, 1em);
}

.form-legend {
  margin-bottom: var(--space-sm);
  color: var(--color-contrast-higher, #0d1317);
  font-size: var(--text-md, 1.125rem);
  line-height: var(--heading-line-height, 1.2);
}

.form-label {
  font-size: var(--text-sm, .75rem);
  display: inline-block;
}

:root {
  --z-index-header: 3;
  --z-index-popover: 5;
  --z-index-fixed-element: 10;
  --z-index-overlay: 15;
  --display: block;
}

.is-visible {
  display: var(--display) !important;
}

.is-hidden, html:not(.js) .no-js\:is-hidden {
  display: none !important;
}

@media print {
  .print\:is-hidden {
    display: none !important;
  }
}

.sr-only, .sr-only-focusable:not(:focus):not(:focus-within) {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-basis-0 {
  flex-basis: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

[class^="aspect-ratio"], [class*=" aspect-ratio"] {
  --aspect-ratio: calc(16 / 9);
  height: 0;
  padding-bottom: calc(100% / (var(--aspect-ratio)) );
  position: relative;
}

[class^="aspect-ratio"] > *, [class*=" aspect-ratio"] > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

[class^="aspect-ratio"] > :not(iframe), [class*=" aspect-ratio"] > :not(iframe) {
  object-fit: cover;
}

.aspect-ratio-16\:9 {
  --aspect-ratio: calc(16 / 9);
}

.aspect-ratio-3\:2 {
  --aspect-ratio: calc(3 / 2);
}

.aspect-ratio-4\:3 {
  --aspect-ratio: calc(4 / 3);
}

.aspect-ratio-5\:4 {
  --aspect-ratio: calc(5 / 4);
}

.aspect-ratio-1\:1 {
  --aspect-ratio: calc(1 / 1);
}

.aspect-ratio-4\:5 {
  --aspect-ratio: calc(4 / 5);
}

.aspect-ratio-3\:4 {
  --aspect-ratio: calc(3 / 4);
}

.aspect-ratio-2\:3 {
  --aspect-ratio: calc(2 / 3);
}

.aspect-ratio-9\:16 {
  --aspect-ratio: calc(9 / 16);
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.contents {
  display: contents;
}

.hide {
  display: none;
}

.space-unit-rem {
  --space-unit: 1rem;
}

.space-unit-em {
  --space-unit: 1em;
}

.space-unit-px {
  --space-unit: 16px;
}

.margin-xxxxs {
  margin: var(--space-xxxxs);
}

.margin-xxxs {
  margin: var(--space-xxxs);
}

.margin-xxs {
  margin: var(--space-xxs);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-md {
  margin: var(--space-md);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-xl {
  margin: var(--space-xl);
}

.margin-xxl {
  margin: var(--space-xxl);
}

.margin-xxxl {
  margin: var(--space-xxxl);
}

.margin-xxxxl {
  margin: var(--space-xxxxl);
}

.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0;
}

.margin-top-xxxxs {
  margin-top: var(--space-xxxxs);
}

.margin-top-xxxs {
  margin-top: var(--space-xxxs);
}

.margin-top-xxs {
  margin-top: var(--space-xxs);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-top-xxl {
  margin-top: var(--space-xxl);
}

.margin-top-xxxl {
  margin-top: var(--space-xxxl);
}

.margin-top-xxxxl {
  margin-top: var(--space-xxxxl);
}

.margin-top-auto {
  margin-top: auto;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-bottom-xxxxs {
  margin-bottom: var(--space-xxxxs);
}

.margin-bottom-xxxs {
  margin-bottom: var(--space-xxxs);
}

.margin-bottom-xxs {
  margin-bottom: var(--space-xxs);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom-xxxl {
  margin-bottom: var(--space-xxxl);
}

.margin-bottom-xxxxl {
  margin-bottom: var(--space-xxxxl);
}

.margin-bottom-auto {
  margin-bottom: auto;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-right-xxxxs {
  margin-right: var(--space-xxxxs);
}

.margin-right-xxxs {
  margin-right: var(--space-xxxs);
}

.margin-right-xxs {
  margin-right: var(--space-xxs);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-right-xxl {
  margin-right: var(--space-xxl);
}

.margin-right-xxxl {
  margin-right: var(--space-xxxl);
}

.margin-right-xxxxl {
  margin-right: var(--space-xxxxl);
}

.margin-right-auto {
  margin-right: auto;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-left-xxxxs {
  margin-left: var(--space-xxxxs);
}

.margin-left-xxxs {
  margin-left: var(--space-xxxs);
}

.margin-left-xxs {
  margin-left: var(--space-xxs);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-left-xxl {
  margin-left: var(--space-xxl);
}

.margin-left-xxxl {
  margin-left: var(--space-xxxl);
}

.margin-left-xxxxl {
  margin-left: var(--space-xxxxl);
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-x-xxxxs {
  margin-right: var(--space-xxxxs);
  margin-left: var(--space-xxxxs);
}

.margin-x-xxxs {
  margin-right: var(--space-xxxs);
  margin-left: var(--space-xxxs);
}

.margin-x-xxs {
  margin-right: var(--space-xxs);
  margin-left: var(--space-xxs);
}

.margin-x-xs {
  margin-right: var(--space-xs);
  margin-left: var(--space-xs);
}

.margin-x-sm {
  margin-right: var(--space-sm);
  margin-left: var(--space-sm);
}

.margin-x-md {
  margin-right: var(--space-md);
  margin-left: var(--space-md);
}

.margin-x-lg {
  margin-right: var(--space-lg);
  margin-left: var(--space-lg);
}

.margin-x-xl {
  margin-right: var(--space-xl);
  margin-left: var(--space-xl);
}

.margin-x-xxl {
  margin-right: var(--space-xxl);
  margin-left: var(--space-xxl);
}

.margin-x-xxxl {
  margin-right: var(--space-xxxl);
  margin-left: var(--space-xxxl);
}

.margin-x-xxxxl {
  margin-right: var(--space-xxxxl);
  margin-left: var(--space-xxxxl);
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-x-0 {
  margin-left: 0;
  margin-right: 0;
}

.margin-y-xxxxs {
  margin-top: var(--space-xxxxs);
  margin-bottom: var(--space-xxxxs);
}

.margin-y-xxxs {
  margin-top: var(--space-xxxs);
  margin-bottom: var(--space-xxxs);
}

.margin-y-xxs {
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-xxs);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-y-xxl {
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.margin-y-xxxl {
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxxl);
}

.margin-y-xxxxl {
  margin-top: var(--space-xxxxl);
  margin-bottom: var(--space-xxxxl);
}

.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-y-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.padding-xxxxs {
  padding: var(--space-xxxxs);
}

.padding-xxxs {
  padding: var(--space-xxxs);
}

.padding-xxs {
  padding: var(--space-xxs);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-md {
  padding: var(--space-md);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-xl {
  padding: var(--space-xl);
}

.padding-xxl {
  padding: var(--space-xxl);
}

.padding-xxxl {
  padding: var(--space-xxxl);
}

.padding-xxxxl {
  padding: var(--space-xxxxl);
}

.padding-0 {
  padding: 0;
}

.padding-component {
  padding: var(--component-padding);
}

.padding-top-xxxxs {
  padding-top: var(--space-xxxxs);
}

.padding-top-xxxs {
  padding-top: var(--space-xxxs);
}

.padding-top-xxs {
  padding-top: var(--space-xxs);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-top-xxl {
  padding-top: var(--space-xxl);
}

.padding-top-xxxl {
  padding-top: var(--space-xxxl);
}

.padding-top-xxxxl {
  padding-top: var(--space-xxxxl);
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-component {
  padding-top: var(--component-padding);
}

.padding-bottom-xxxxs {
  padding-bottom: var(--space-xxxxs);
}

.padding-bottom-xxxs {
  padding-bottom: var(--space-xxxs);
}

.padding-bottom-xxs {
  padding-bottom: var(--space-xxs);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-bottom-xxl {
  padding-bottom: var(--space-xxl);
}

.padding-bottom-xxxl {
  padding-bottom: var(--space-xxxl);
}

.padding-bottom-xxxxl {
  padding-bottom: var(--space-xxxxl);
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-component {
  padding-bottom: var(--component-padding);
}

.padding-right-xxxxs {
  padding-right: var(--space-xxxxs);
}

.padding-right-xxxs {
  padding-right: var(--space-xxxs);
}

.padding-right-xxs {
  padding-right: var(--space-xxs);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-right-xxl {
  padding-right: var(--space-xxl);
}

.padding-right-xxxl {
  padding-right: var(--space-xxxl);
}

.padding-right-xxxxl {
  padding-right: var(--space-xxxxl);
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-component {
  padding-right: var(--component-padding);
}

.padding-left-xxxxs {
  padding-left: var(--space-xxxxs);
}

.padding-left-xxxs {
  padding-left: var(--space-xxxs);
}

.padding-left-xxs {
  padding-left: var(--space-xxs);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-left-xxl {
  padding-left: var(--space-xxl);
}

.padding-left-xxxl {
  padding-left: var(--space-xxxl);
}

.padding-left-xxxxl {
  padding-left: var(--space-xxxxl);
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-component {
  padding-left: var(--component-padding);
}

.padding-x-xxxxs {
  padding-right: var(--space-xxxxs);
  padding-left: var(--space-xxxxs);
}

.padding-x-xxxs {
  padding-right: var(--space-xxxs);
  padding-left: var(--space-xxxs);
}

.padding-x-xxs {
  padding-right: var(--space-xxs);
  padding-left: var(--space-xxs);
}

.padding-x-xs {
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
}

.padding-x-sm {
  padding-right: var(--space-sm);
  padding-left: var(--space-sm);
}

.padding-x-md {
  padding-right: var(--space-md);
  padding-left: var(--space-md);
}

.padding-x-lg {
  padding-right: var(--space-lg);
  padding-left: var(--space-lg);
}

.padding-x-xl {
  padding-right: var(--space-xl);
  padding-left: var(--space-xl);
}

.padding-x-xxl {
  padding-right: var(--space-xxl);
  padding-left: var(--space-xxl);
}

.padding-x-xxxl {
  padding-right: var(--space-xxxl);
  padding-left: var(--space-xxxl);
}

.padding-x-xxxxl {
  padding-right: var(--space-xxxxl);
  padding-left: var(--space-xxxxl);
}

.padding-x-0 {
  padding-left: 0;
  padding-right: 0;
}

.padding-x-component {
  padding-right: var(--component-padding);
  padding-left: var(--component-padding);
}

.padding-y-xxxxs {
  padding-top: var(--space-xxxxs);
  padding-bottom: var(--space-xxxxs);
}

.padding-y-xxxs {
  padding-top: var(--space-xxxs);
  padding-bottom: var(--space-xxxs);
}

.padding-y-xxs {
  padding-top: var(--space-xxs);
  padding-bottom: var(--space-xxs);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-y-xxl {
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.padding-y-xxxl {
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxxl);
}

.padding-y-xxxxl {
  padding-top: var(--space-xxxxl);
  padding-bottom: var(--space-xxxxl);
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-y-component {
  padding-top: var(--component-padding);
  padding-bottom: var(--component-padding);
}

.align-baseline {
  vertical-align: baseline;
}

.align-sub {
  vertical-align: sub;
}

.align-super {
  vertical-align: super;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.truncate, .text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-replace {
  color: #0000;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.break-word {
  min-width: 0;
  overflow-wrap: break-word;
}

.text-unit-rem, .text-unit-em, .text-unit-px {
  font-size: var(--text-unit);
}

.text-unit-rem {
  --text-unit: 1rem;
}

.text-unit-em {
  --text-unit: 1em;
}

.text-unit-px {
  --text-unit: 16px;
}

.text-xs {
  font-size: var(--text-xs, .6875rem);
}

.text-sm {
  font-size: var(--text-sm, .75rem);
}

.text-base {
  font-size: var(--text-unit, 1rem);
}

.text-md {
  font-size: var(--text-md, 1.125rem);
}

.text-lg {
  font-size: var(--text-lg, 1.375rem);
}

.text-xl {
  font-size: var(--text-xl, 1.75rem);
}

.text-xxl {
  font-size: var(--text-xxl, 2rem);
}

.text-xxxl {
  font-size: var(--text-xxxl, 2.5rem);
}

.text-xxxxl {
  font-size: var(--text-xxxxl, 3rem);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.letter-spacing-xs {
  letter-spacing: -.1em;
}

.letter-spacing-sm {
  letter-spacing: -.05em;
}

.letter-spacing-md {
  letter-spacing: .05em;
}

.letter-spacing-lg {
  letter-spacing: .1em;
}

.letter-spacing-xl {
  letter-spacing: .2em;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold, .text-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}

.font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: var(--font-primary);
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

.text-shadow-xs {
  text-shadow: 0 1px 1px #00000026;
}

.text-shadow-sm {
  text-shadow: 0 1px 2px #00000040;
}

.text-shadow-md {
  text-shadow: 0 1px 2px #0000001a, 0 2px 4px #0003;
}

.text-shadow-lg {
  text-shadow: 0 1px 4px #0000001a, 0 2px 8px #00000026, 0 4px 16px #0003;
}

.text-shadow-xl {
  text-shadow: 0 1px 4px #0000001a, 0 2px 8px #00000026, 0 4px 16px #0003, 0 6px 24px #00000040;
}

.text-shadow-none {
  text-shadow: none;
}

.text-space-y-xxs {
  --text-space-y-multiplier: .25 !important;
}

.text-space-y-xs {
  --text-space-y-multiplier: .5 !important;
}

.text-space-y-sm {
  --text-space-y-multiplier: .75 !important;
}

.text-space-y-md {
  --text-space-y-multiplier: 1.25 !important;
}

.text-space-y-lg {
  --text-space-y-multiplier: 1.5 !important;
}

.text-space-y-xl {
  --text-space-y-multiplier: 1.75 !important;
}

.text-space-y-xxl {
  --text-space-y-multiplier: 2 !important;
}

.line-height-xs {
  --heading-line-height: 1;
  --body-line-height: 1.1;
}

.line-height-xs:not(.text-component) {
  line-height: 1.1;
}

.line-height-sm {
  --heading-line-height: 1.1;
  --body-line-height: 1.2;
}

.line-height-sm:not(.text-component) {
  line-height: 1.2;
}

.line-height-md {
  --heading-line-height: 1.15;
  --body-line-height: 1.4;
}

.line-height-md:not(.text-component) {
  line-height: 1.4;
}

.line-height-lg {
  --heading-line-height: 1.22;
  --body-line-height: 1.58;
}

.line-height-lg:not(.text-component) {
  line-height: 1.58;
}

.line-height-xl {
  --heading-line-height: 1.3;
  --body-line-height: 1.72;
}

.line-height-xl:not(.text-component) {
  line-height: 1.72;
}

.line-height-body {
  line-height: var(--body-line-height);
}

.line-height-heading {
  line-height: var(--heading-line-height);
}

.line-height-normal {
  line-height: normal !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.column-count-1 {
  column-count: 1;
}

.column-count-2 {
  column-count: 2;
}

.column-count-3 {
  column-count: 3;
}

.column-count-4 {
  column-count: 4;
}

.ws-nowrap, .text-nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.user-select-all {
  -webkit-user-select: all;
  user-select: all;
}

[class^="color-"], [class*=" color-"] {
  --color-o: 1;
}

.color-inherit {
  color: inherit;
}

.color-bg-darker {
  color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--color-o, 1));
}

.color-bg-dark {
  color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--color-o, 1));
}

.color-bg {
  color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--color-o, 1));
}

.color-bg-light {
  color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--color-o, 1));
}

.color-bg-lighter {
  color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--color-o, 1));
}

.color-contrast-lower {
  color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--color-o, 1));
}

.color-contrast-low {
  color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--color-o, 1));
}

.color-contrast-medium {
  color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--color-o, 1));
}

.color-contrast-high {
  color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--color-o, 1));
}

.color-contrast-higher {
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--color-o, 1));
}

.color-primary-darker {
  color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--color-o, 1));
}

.color-primary-dark {
  color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--color-o, 1));
}

.color-primary {
  color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--color-o, 1));
}

.color-primary-light {
  color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--color-o, 1));
}

.color-primary-lighter {
  color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--color-o, 1));
}

.color-accent-darker {
  color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--color-o, 1));
}

.color-accent-dark {
  color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--color-o, 1));
}

.color-accent {
  color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--color-o, 1));
}

.color-accent-light {
  color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--color-o, 1));
}

.color-accent-lighter {
  color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--color-o, 1));
}

.color-success-darker {
  color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--color-o, 1));
}

.color-success-dark {
  color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--color-o, 1));
}

.color-success {
  color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--color-o, 1));
}

.color-success-light {
  color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--color-o, 1));
}

.color-success-lighter {
  color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--color-o, 1));
}

.color-warning-darker {
  color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--color-o, 1));
}

.color-warning-dark {
  color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--color-o, 1));
}

.color-warning {
  color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--color-o, 1));
}

.color-warning-light {
  color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--color-o, 1));
}

.color-warning-lighter {
  color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--color-o, 1));
}

.color-error-darker {
  color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--color-o, 1));
}

.color-error-dark {
  color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--color-o, 1));
}

.color-error {
  color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--color-o, 1));
}

.color-error-light {
  color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--color-o, 1));
}

.color-error-lighter {
  color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--color-o, 1));
}

.color-white {
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--color-o, 1));
}

.color-black {
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--color-o, 1));
}

.color-opacity-0 {
  --color-o: 0;
}

.color-opacity-5\% {
  --color-o: .05;
}

.color-opacity-10\% {
  --color-o: .1;
}

.color-opacity-15\% {
  --color-o: .15;
}

.color-opacity-20\% {
  --color-o: .2;
}

.color-opacity-25\% {
  --color-o: .25;
}

.color-opacity-30\% {
  --color-o: .3;
}

.color-opacity-40\% {
  --color-o: .4;
}

.color-opacity-50\% {
  --color-o: .5;
}

.color-opacity-60\% {
  --color-o: .6;
}

.color-opacity-70\% {
  --color-o: .7;
}

.color-opacity-75\% {
  --color-o: .75;
}

.color-opacity-80\% {
  --color-o: .8;
}

.color-opacity-85\% {
  --color-o: .85;
}

.color-opacity-90\% {
  --color-o: .9;
}

.color-opacity-95\% {
  --color-o: .95;
}

[class^="color-gradient"], [class*=" color-gradient"] {
  -webkit-background-clip: text;
  background-clip: text;
  color: #0000 !important;
}

.width-xxxxs {
  width: var(--size-xxxxs, .25rem);
}

.width-xxxs {
  width: var(--size-xxxs, .5rem);
}

.width-xxs {
  width: var(--size-xxs, .75rem);
}

.width-xs {
  width: var(--size-xs, 1rem);
}

.width-sm {
  width: var(--size-sm, 1.5rem);
}

.width-md {
  width: var(--size-md, 2rem);
}

.width-lg {
  width: var(--size-lg, 3rem);
}

.width-xl {
  width: var(--size-xl, 4rem);
}

.width-xxl {
  width: var(--size-xxl, 6rem);
}

.width-xxxl {
  width: var(--size-xxxl, 8rem);
}

.width-xxxxl {
  width: var(--size-xxxxl, 16rem);
}

.width-0 {
  width: 0;
}

.width-10\% {
  width: 10%;
}

.width-20\% {
  width: 20%;
}

.width-25\% {
  width: 25%;
}

.width-30\% {
  width: 30%;
}

.width-33\% {
  width: 33.3333%;
}

.width-40\% {
  width: 40%;
}

.width-50\% {
  width: 50%;
}

.width-60\% {
  width: 60%;
}

.width-66\% {
  width: 66.6667%;
}

.width-70\% {
  width: 70%;
}

.width-75\% {
  width: 75%;
}

.width-80\% {
  width: 80%;
}

.width-90\% {
  width: 90%;
}

.width-100\% {
  width: 100%;
}

.width-100vw {
  width: 100vw;
}

.width-auto {
  width: auto;
}

.width-inherit {
  width: inherit;
}

.height-xxxxs {
  height: var(--size-xxxxs, .25rem);
}

.height-xxxs {
  height: var(--size-xxxs, .5rem);
}

.height-xxs {
  height: var(--size-xxs, .75rem);
}

.height-xs {
  height: var(--size-xs, 1rem);
}

.height-sm {
  height: var(--size-sm, 1.5rem);
}

.height-md {
  height: var(--size-md, 2rem);
}

.height-lg {
  height: var(--size-lg, 3rem);
}

.height-xl {
  height: var(--size-xl, 4rem);
}

.height-xxl {
  height: var(--size-xxl, 6rem);
}

.height-xxxl {
  height: var(--size-xxxl, 8rem);
}

.height-xxxxl {
  height: var(--size-xxxxl, 16rem);
}

.height-0 {
  height: 0;
}

.height-10\% {
  height: 10%;
}

.height-20\% {
  height: 20%;
}

.height-25\% {
  height: 25%;
}

.height-30\% {
  height: 30%;
}

.height-33\% {
  height: 33.3333%;
}

.height-40\% {
  height: 40%;
}

.height-50\% {
  height: 50%;
}

.height-60\% {
  height: 60%;
}

.height-66\% {
  height: 66.6667%;
}

.height-70\% {
  height: 70%;
}

.height-75\% {
  height: 75%;
}

.height-80\% {
  height: 80%;
}

.height-90\% {
  height: 90%;
}

.height-100\% {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.height-auto {
  height: auto;
}

.height-inherit {
  height: inherit;
}

.min-width-0 {
  min-width: 0;
}

.min-width-25\% {
  min-width: 25%;
}

.min-width-33\% {
  min-width: 33.3333%;
}

.min-width-50\% {
  min-width: 50%;
}

.min-width-66\% {
  min-width: 66.6667%;
}

.min-width-75\% {
  min-width: 75%;
}

.min-width-100\% {
  min-width: 100%;
}

.min-width-100vw {
  min-width: 100vw;
}

.min-height-100\% {
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

:root {
  --max-width-xxxxxs: 17.5rem;
  --max-width-xxxxs: 20rem;
  --max-width-xxxs: 26rem;
  --max-width-xxs: 32rem;
  --max-width-xs: 38rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --max-width-xxl: 100rem;
  --max-width-xxxl: 120rem;
  --max-width-xxxxl: 150rem;
}

.max-width-xxxxxs {
  max-width: var(--max-width-xxxxxs);
}

.max-width-xxxxs {
  max-width: var(--max-width-xxxxs);
}

.max-width-xxxs {
  max-width: var(--max-width-xxxs);
}

.max-width-xxs {
  max-width: var(--max-width-xxs);
}

.max-width-xs {
  max-width: var(--max-width-xs);
}

.max-width-sm {
  max-width: var(--max-width-sm);
}

.max-width-md {
  max-width: var(--max-width-md);
}

.max-width-lg {
  max-width: var(--max-width-lg);
}

.max-width-xl {
  max-width: var(--max-width-xl);
}

.max-width-xxl {
  max-width: var(--max-width-xxl);
}

.max-width-xxxl {
  max-width: var(--max-width-xxxl);
}

.max-width-xxxxl {
  max-width: var(--max-width-xxxxl);
}

.max-width-100\% {
  max-width: 100%;
}

.max-width-none {
  max-width: none;
}

[class^="max-width-adaptive"], [class*=" max-width-adaptive"] {
  max-width: 32rem;
}

@media (min-width: 48rem) {
  .max-width-adaptive-sm, .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 48rem;
  }
}

@media (min-width: 64rem) {
  .max-width-adaptive-md, .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 64rem;
  }
}

@media (min-width: 80rem) {
  .max-width-adaptive-lg, .max-width-adaptive-xl, .max-width-adaptive {
    max-width: 80rem;
  }
}

@media (min-width: 90rem) {
  .max-width-adaptive-xl {
    max-width: 90rem;
  }
}

.max-height-100\% {
  max-height: 100%;
}

.max-height-100vh {
  max-height: 100vh;
}

.shadow-ring {
  box-shadow: var(--shadow-ring);
}

.shadow-xs {
  box-shadow: var(--shadow-xs);
}

.shadow-xs.shadow-ring {
  box-shadow: var(--shadow-xs), var(--shadow-ring);
}

.shadow-sm {
  box-shadow: var(--shadow-sm);
}

.shadow-sm.shadow-ring {
  box-shadow: var(--shadow-sm), var(--shadow-ring);
}

.shadow-md {
  box-shadow: var(--shadow-md);
}

.shadow-md.shadow-ring {
  box-shadow: var(--shadow-md), var(--shadow-ring);
}

.shadow-lg {
  box-shadow: var(--shadow-lg);
}

.shadow-lg.shadow-ring {
  box-shadow: var(--shadow-lg), var(--shadow-ring);
}

.shadow-xl {
  box-shadow: var(--shadow-xl);
}

.shadow-xl.shadow-ring {
  box-shadow: var(--shadow-xl), var(--shadow-ring);
}

.shadow-none {
  box-shadow: none;
}

:where(.inner-glow, .inner-glow-top) {
  position: relative;
}

:where(.inner-glow, .inner-glow-top):after {
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.inner-glow:after {
  box-shadow: var(--inner-glow);
}

.inner-glow-top:after {
  box-shadow: var(--inner-glow-top);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.top-50\% {
  top: 50%;
}

.top-xxxxs {
  top: var(--space-xxxxs);
}

.top-xxxs {
  top: var(--space-xxxs);
}

.top-xxs {
  top: var(--space-xxs);
}

.top-xs {
  top: var(--space-xs);
}

.top-sm {
  top: var(--space-sm);
}

.top-md {
  top: var(--space-md);
}

.top-lg {
  top: var(--space-lg);
}

.top-xl {
  top: var(--space-xl);
}

.top-xxl {
  top: var(--space-xxl);
}

.top-xxxl {
  top: var(--space-xxxl);
}

.top-xxxxl {
  top: var(--space-xxxxl);
}

.bottom-0 {
  bottom: 0;
}

.bottom-50\% {
  bottom: 50%;
}

.bottom-xxxxs {
  bottom: var(--space-xxxxs);
}

.bottom-xxxs {
  bottom: var(--space-xxxs);
}

.bottom-xxs {
  bottom: var(--space-xxs);
}

.bottom-xs {
  bottom: var(--space-xs);
}

.bottom-sm {
  bottom: var(--space-sm);
}

.bottom-md {
  bottom: var(--space-md);
}

.bottom-lg {
  bottom: var(--space-lg);
}

.bottom-xl {
  bottom: var(--space-xl);
}

.bottom-xxl {
  bottom: var(--space-xxl);
}

.bottom-xxxl {
  bottom: var(--space-xxxl);
}

.bottom-xxxxl {
  bottom: var(--space-xxxxl);
}

.right-0 {
  right: 0;
}

.right-50\% {
  right: 50%;
}

.right-xxxxs {
  right: var(--space-xxxxs);
}

.right-xxxs {
  right: var(--space-xxxs);
}

.right-xxs {
  right: var(--space-xxs);
}

.right-xs {
  right: var(--space-xs);
}

.right-sm {
  right: var(--space-sm);
}

.right-md {
  right: var(--space-md);
}

.right-lg {
  right: var(--space-lg);
}

.right-xl {
  right: var(--space-xl);
}

.right-xxl {
  right: var(--space-xxl);
}

.right-xxxl {
  right: var(--space-xxxl);
}

.right-xxxxl {
  right: var(--space-xxxxl);
}

.left-0 {
  left: 0;
}

.left-50\% {
  left: 50%;
}

.left-xxxxs {
  left: var(--space-xxxxs);
}

.left-xxxs {
  left: var(--space-xxxs);
}

.left-xxs {
  left: var(--space-xxs);
}

.left-xs {
  left: var(--space-xs);
}

.left-sm {
  left: var(--space-sm);
}

.left-md {
  left: var(--space-md);
}

.left-lg {
  left: var(--space-lg);
}

.left-xl {
  left: var(--space-xl);
}

.left-xxl {
  left: var(--space-xxl);
}

.left-xxxl {
  left: var(--space-xxxl);
}

.left-xxxxl {
  left: var(--space-xxxxl);
}

.z-index-header {
  z-index: var(--z-index-header);
}

.z-index-popover {
  z-index: var(--z-index-popover);
}

.z-index-fixed-element {
  z-index: var(--z-index-fixed-element);
}

.z-index-overlay {
  z-index: var(--z-index-overlay);
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.momentum-scrolling {
  -webkit-overflow-scrolling: touch;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.scroll-padding-xxxxs {
  scroll-padding: var(--space-xxxxs);
}

.scroll-padding-xxxs {
  scroll-padding: var(--space-xxxs);
}

.scroll-padding-xxs {
  scroll-padding: var(--space-xxs);
}

.scroll-padding-xs {
  scroll-padding: var(--space-xs);
}

.scroll-padding-sm {
  scroll-padding: var(--space-sm);
}

.scroll-padding-md {
  scroll-padding: var(--space-md);
}

.scroll-padding-lg {
  scroll-padding: var(--space-lg);
}

.scroll-padding-xl {
  scroll-padding: var(--space-xl);
}

.scroll-padding-xxl {
  scroll-padding: var(--space-xxl);
}

.scroll-padding-xxxl {
  scroll-padding: var(--space-xxxl);
}

.scroll-padding-xxxxl {
  scroll-padding: var(--space-xxxxl);
}

.opacity-0 {
  opacity: 0;
}

.opacity-5\% {
  opacity: .05;
}

.opacity-10\% {
  opacity: .1;
}

.opacity-15\% {
  opacity: .15;
}

.opacity-20\% {
  opacity: .2;
}

.opacity-25\% {
  opacity: .25;
}

.opacity-30\% {
  opacity: .3;
}

.opacity-40\% {
  opacity: .4;
}

.opacity-50\% {
  opacity: .5;
}

.opacity-60\% {
  opacity: .6;
}

.opacity-70\% {
  opacity: .7;
}

.opacity-75\% {
  opacity: .75;
}

.opacity-80\% {
  opacity: .8;
}

.opacity-85\% {
  opacity: .85;
}

.opacity-90\% {
  opacity: .9;
}

.opacity-95\% {
  opacity: .95;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.border {
  border: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o-base, .1));
}

.border-top {
  border-top: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o-base, .1));
}

.border-bottom {
  border-bottom: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o-base, .1));
}

.border-left {
  border-left: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o-base, .1));
}

.border-right {
  border-right: var(--border-width, 1px) var(--border-style, solid) hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o-base, .1));
}

.border-2 {
  --border-width: 2px;
}

.border-3 {
  --border-width: 3px;
}

.border-4 {
  --border-width: 4px;
}

.border-dotted {
  --border-style: dotted;
}

.border-dashed {
  --border-style: dashed;
}

[class^="border-"], [class*=" border-"] {
  --border-o: 1;
}

.border-bg-darker {
  border-color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--border-o, 1));
}

.border-bg-dark {
  border-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--border-o, 1));
}

.border-bg {
  border-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--border-o, 1));
}

.border-bg-light {
  border-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--border-o, 1));
}

.border-bg-lighter {
  border-color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--border-o, 1));
}

.border-contrast-lower {
  border-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--border-o, 1));
}

.border-contrast-low {
  border-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--border-o, 1));
}

.border-contrast-medium {
  border-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--border-o, 1));
}

.border-contrast-high {
  border-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--border-o, 1));
}

.border-contrast-higher {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--border-o, 1));
}

.border-primary-darker {
  border-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--border-o, 1));
}

.border-primary-dark {
  border-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--border-o, 1));
}

.border-primary {
  border-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--border-o, 1));
}

.border-primary-light {
  border-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--border-o, 1));
}

.border-primary-lighter {
  border-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--border-o, 1));
}

.border-accent-darker {
  border-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--border-o, 1));
}

.border-accent-dark {
  border-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--border-o, 1));
}

.border-accent {
  border-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--border-o, 1));
}

.border-accent-light {
  border-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--border-o, 1));
}

.border-accent-lighter {
  border-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--border-o, 1));
}

.border-success-darker {
  border-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--border-o, 1));
}

.border-success-dark {
  border-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--border-o, 1));
}

.border-success {
  border-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--border-o, 1));
}

.border-success-light {
  border-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--border-o, 1));
}

.border-success-lighter {
  border-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--border-o, 1));
}

.border-warning-darker {
  border-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--border-o, 1));
}

.border-warning-dark {
  border-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--border-o, 1));
}

.border-warning {
  border-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--border-o, 1));
}

.border-warning-light {
  border-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--border-o, 1));
}

.border-warning-lighter {
  border-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--border-o, 1));
}

.border-error-darker {
  border-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--border-o, 1));
}

.border-error-dark {
  border-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--border-o, 1));
}

.border-error {
  border-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--border-o, 1));
}

.border-error-light {
  border-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--border-o, 1));
}

.border-error-lighter {
  border-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--border-o, 1));
}

.border-white {
  border-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--border-o, 1));
}

.border-black {
  border-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--border-o, 1));
}

.border-opacity-0 {
  --border-o: 0;
}

.border-opacity-5\% {
  --border-o: .05;
}

.border-opacity-10\% {
  --border-o: .1;
}

.border-opacity-15\% {
  --border-o: .15;
}

.border-opacity-20\% {
  --border-o: .2;
}

.border-opacity-25\% {
  --border-o: .25;
}

.border-opacity-30\% {
  --border-o: .3;
}

.border-opacity-40\% {
  --border-o: .4;
}

.border-opacity-50\% {
  --border-o: .5;
}

.border-opacity-60\% {
  --border-o: .6;
}

.border-opacity-70\% {
  --border-o: .7;
}

.border-opacity-75\% {
  --border-o: .75;
}

.border-opacity-80\% {
  --border-o: .8;
}

.border-opacity-85\% {
  --border-o: .85;
}

.border-opacity-90\% {
  --border-o: .9;
}

.border-opacity-95\% {
  --border-o: .95;
}

.radius-sm {
  border-radius: var(--radius-sm);
}

.radius-md {
  border-radius: var(--radius-md);
}

.radius-lg {
  border-radius: var(--radius-lg);
}

.radius-50\% {
  border-radius: 50%;
}

.radius-full {
  border-radius: 50em;
}

.radius-0 {
  border-radius: 0;
}

.radius-inherit {
  border-radius: inherit;
}

.radius-top-left-0 {
  border-top-left-radius: 0;
}

.radius-top-right-0 {
  border-top-right-radius: 0;
}

.radius-bottom-right-0 {
  border-bottom-right-radius: 0;
}

.radius-bottom-left-0 {
  border-bottom-left-radius: 0;
}

.bg, [class^="bg-"], [class*=" bg-"] {
  --bg-o: 1;
}

.bg-transparent {
  background-color: #0000;
}

.bg-inherit {
  background-color: inherit;
}

.bg-darker {
  background-color: hsla(var(--color-bg-darker-h), var(--color-bg-darker-s), var(--color-bg-darker-l), var(--bg-o));
}

.bg-dark {
  background-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), var(--bg-o));
}

.bg {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), var(--bg-o));
}

.bg-light {
  background-color: hsla(var(--color-bg-light-h), var(--color-bg-light-s), var(--color-bg-light-l), var(--bg-o));
}

.bg-lighter {
  background-color: hsla(var(--color-bg-lighter-h), var(--color-bg-lighter-s), var(--color-bg-lighter-l), var(--bg-o));
}

.bg-contrast-lower {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), var(--bg-o, 1));
}

.bg-contrast-low {
  background-color: hsla(var(--color-contrast-low-h), var(--color-contrast-low-s), var(--color-contrast-low-l), var(--bg-o, 1));
}

.bg-contrast-medium {
  background-color: hsla(var(--color-contrast-medium-h), var(--color-contrast-medium-s), var(--color-contrast-medium-l), var(--bg-o, 1));
}

.bg-contrast-high {
  background-color: hsla(var(--color-contrast-high-h), var(--color-contrast-high-s), var(--color-contrast-high-l), var(--bg-o, 1));
}

.bg-contrast-higher {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), var(--bg-o, 1));
}

.bg-primary-darker {
  background-color: hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), var(--bg-o, 1));
}

.bg-primary-dark {
  background-color: hsla(var(--color-primary-dark-h), var(--color-primary-dark-s), var(--color-primary-dark-l), var(--bg-o, 1));
}

.bg-primary {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), var(--bg-o, 1));
}

.bg-primary-light {
  background-color: hsla(var(--color-primary-light-h), var(--color-primary-light-s), var(--color-primary-light-l), var(--bg-o, 1));
}

.bg-primary-lighter {
  background-color: hsla(var(--color-primary-lighter-h), var(--color-primary-lighter-s), var(--color-primary-lighter-l), var(--bg-o, 1));
}

.bg-accent-darker {
  background-color: hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), var(--bg-o, 1));
}

.bg-accent-dark {
  background-color: hsla(var(--color-accent-dark-h), var(--color-accent-dark-s), var(--color-accent-dark-l), var(--bg-o, 1));
}

.bg-accent {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), var(--bg-o, 1));
}

.bg-accent-light {
  background-color: hsla(var(--color-accent-light-h), var(--color-accent-light-s), var(--color-accent-light-l), var(--bg-o, 1));
}

.bg-accent-lighter {
  background-color: hsla(var(--color-accent-lighter-h), var(--color-accent-lighter-s), var(--color-accent-lighter-l), var(--bg-o, 1));
}

.bg-success-darker {
  background-color: hsla(var(--color-success-darker-h), var(--color-success-darker-s), var(--color-success-darker-l), var(--bg-o, 1));
}

.bg-success-dark {
  background-color: hsla(var(--color-success-dark-h), var(--color-success-dark-s), var(--color-success-dark-l), var(--bg-o, 1));
}

.bg-success {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), var(--bg-o, 1));
}

.bg-success-light {
  background-color: hsla(var(--color-success-light-h), var(--color-success-light-s), var(--color-success-light-l), var(--bg-o, 1));
}

.bg-success-lighter {
  background-color: hsla(var(--color-success-lighter-h), var(--color-success-lighter-s), var(--color-success-lighter-l), var(--bg-o, 1));
}

.bg-warning-darker {
  background-color: hsla(var(--color-warning-darker-h), var(--color-warning-darker-s), var(--color-warning-darker-l), var(--bg-o, 1));
}

.bg-warning-dark {
  background-color: hsla(var(--color-warning-dark-h), var(--color-warning-dark-s), var(--color-warning-dark-l), var(--bg-o, 1));
}

.bg-warning {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), var(--bg-o, 1));
}

.bg-warning-light {
  background-color: hsla(var(--color-warning-light-h), var(--color-warning-light-s), var(--color-warning-light-l), var(--bg-o, 1));
}

.bg-warning-lighter {
  background-color: hsla(var(--color-warning-lighter-h), var(--color-warning-lighter-s), var(--color-warning-lighter-l), var(--bg-o, 1));
}

.bg-error-darker {
  background-color: hsla(var(--color-error-darker-h), var(--color-error-darker-s), var(--color-error-darker-l), var(--bg-o, 1));
}

.bg-error-dark {
  background-color: hsla(var(--color-error-dark-h), var(--color-error-dark-s), var(--color-error-dark-l), var(--bg-o, 1));
}

.bg-error {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), var(--bg-o, 1));
}

.bg-error-light {
  background-color: hsla(var(--color-error-light-h), var(--color-error-light-s), var(--color-error-light-l), var(--bg-o, 1));
}

.bg-error-lighter {
  background-color: hsla(var(--color-error-lighter-h), var(--color-error-lighter-s), var(--color-error-lighter-l), var(--bg-o, 1));
}

.bg-white {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), var(--bg-o, 1));
}

.bg-black {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), var(--bg-o, 1));
}

.bg-opacity-0 {
  --bg-o: 0;
}

.bg-opacity-5\% {
  --bg-o: .05;
}

.bg-opacity-10\% {
  --bg-o: .1;
}

.bg-opacity-15\% {
  --bg-o: .15;
}

.bg-opacity-20\% {
  --bg-o: .2;
}

.bg-opacity-25\% {
  --bg-o: .25;
}

.bg-opacity-30\% {
  --bg-o: .3;
}

.bg-opacity-40\% {
  --bg-o: .4;
}

.bg-opacity-50\% {
  --bg-o: .5;
}

.bg-opacity-60\% {
  --bg-o: .6;
}

.bg-opacity-70\% {
  --bg-o: .7;
}

.bg-opacity-75\% {
  --bg-o: .75;
}

.bg-opacity-80\% {
  --bg-o: .8;
}

.bg-opacity-85\% {
  --bg-o: .85;
}

.bg-opacity-90\% {
  --bg-o: .9;
}

.bg-opacity-95\% {
  --bg-o: .95;
}

.bg-center {
  background-position: center;
}

.bg-top {
  background-position: top;
}

.bg-right {
  background-position: 100%;
}

.bg-bottom {
  background-position: bottom;
}

.bg-left {
  background-position: 0;
}

.bg-top-left {
  background-position: 0 0;
}

.bg-top-right {
  background-position: 100% 0;
}

.bg-bottom-left {
  background-position: 0 100%;
}

.bg-bottom-right {
  background-position: 100% 100%;
}

.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.backdrop-blur-10 {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.backdrop-blur-20 {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.isolate {
  isolation: isolate;
}

.blend-multiply {
  mix-blend-mode: multiply;
}

.blend-overlay {
  mix-blend-mode: overlay;
}

.blend-difference {
  mix-blend-mode: difference;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.perspective-xs {
  perspective: 250px;
}

.perspective-sm {
  perspective: 500px;
}

.perspective-md {
  perspective: 1000px;
}

.perspective-lg {
  perspective: 1500px;
}

.perspective-xl {
  perspective: 3000px;
}

[class^="flip"], [class*=" flip"], [class^="-rotate"], [class*=" -rotate"], [class^="rotate"], [class*=" rotate"], [class^="-translate"], [class*=" -translate"], [class^="translate"], [class*=" translate"], [class^="-scale"], [class*=" -scale"], [class^="scale"], [class*=" scale"], [class^="-skew"], [class*=" -skew"] [class^="skew"], [class*=" skew"] {
  --translate: 0;
  --rotate: 0;
  --skew: 0;
  --scale: 1;
  transform: translate3d(var(--translate-x, var(--translate)), var(--translate-y, var(--translate)), var(--translate-z, 0)) rotateX(var(--rotate-x, 0)) rotateY(var(--rotate-y, 0)) rotateZ(var(--rotate-z, var(--rotate))) skewX(var(--skew-x, var(--skew))) skewY(var(--skew-y, 0)) scaleX(var(--scale-x, var(--scale))) scaleY(var(--scale-y, var(--scale)));
}

.flip {
  --scale: -1;
}

.flip-x {
  --scale-x: -1;
}

.flip-y {
  --scale-y: -1;
}

.rotate-90 {
  --rotate: 90deg;
}

.rotate-180 {
  --rotate: 180deg;
}

.rotate-270 {
  --rotate: 270deg;
}

.-translate-50\% {
  --translate: -50%;
}

.-translate-x-50\% {
  --translate-x: -50%;
}

.-translate-y-50\% {
  --translate-y: -50%;
}

.translate-50\% {
  --translate: 50%;
}

.translate-x-50\% {
  --translate-x: 50%;
}

.translate-y-50\% {
  --translate-y: 50%;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: top;
}

.origin-right {
  transform-origin: 100%;
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-left {
  transform-origin: 0;
}

.origin-top-left {
  transform-origin: 0 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.transition {
  transition-delay: var(--transition-delay, 0s);
  transition-duration: var(--transition-duration, .2s);
  transition-property: var(--transition-property, all);
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-2 {
  stroke-width: 2px;
}

.stroke-3 {
  stroke-width: 3px;
}

.stroke-4 {
  stroke-width: 4px;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

@media (min-width: 32rem) {
  .flex\@xs {
    display: flex;
  }

  .inline-flex\@xs {
    display: inline-flex;
  }

  .flex-wrap\@xs {
    flex-wrap: wrap;
  }

  .flex-nowrap\@xs {
    flex-wrap: nowrap;
  }

  .flex-column\@xs {
    flex-direction: column;
  }

  .flex-column-reverse\@xs {
    flex-direction: column-reverse;
  }

  .flex-row\@xs {
    flex-direction: row;
  }

  .flex-row-reverse\@xs {
    flex-direction: row-reverse;
  }

  .flex-center\@xs {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@xs {
    flex-grow: 1;
  }

  .flex-grow-0\@xs {
    flex-grow: 0;
  }

  .flex-shrink\@xs {
    flex-shrink: 1;
  }

  .flex-shrink-0\@xs {
    flex-shrink: 0;
  }

  .flex-basis-0\@xs {
    flex-basis: 0;
  }

  .justify-start\@xs {
    justify-content: flex-start;
  }

  .justify-end\@xs {
    justify-content: flex-end;
  }

  .justify-center\@xs {
    justify-content: center;
  }

  .justify-between\@xs {
    justify-content: space-between;
  }

  .items-center\@xs {
    align-items: center;
  }

  .items-start\@xs {
    align-items: flex-start;
  }

  .items-end\@xs {
    align-items: flex-end;
  }

  .items-baseline\@xs {
    align-items: baseline;
  }

  .order-1\@xs {
    order: 1;
  }

  .order-2\@xs {
    order: 2;
  }

  .order-3\@xs {
    order: 3;
  }

  .block\@xs {
    display: block;
  }

  .inline-block\@xs {
    display: inline-block;
  }

  .inline\@xs {
    display: inline;
  }

  .contents\@xs {
    display: contents;
  }

  .hide\@xs {
    display: none !important;
  }

  .margin-xxxxs\@xs {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@xs {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@xs {
    margin: var(--space-xxs);
  }

  .margin-xs\@xs {
    margin: var(--space-xs);
  }

  .margin-sm\@xs {
    margin: var(--space-sm);
  }

  .margin-md\@xs {
    margin: var(--space-md);
  }

  .margin-lg\@xs {
    margin: var(--space-lg);
  }

  .margin-xl\@xs {
    margin: var(--space-xl);
  }

  .margin-xxl\@xs {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@xs {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@xs {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@xs {
    margin: auto;
  }

  .margin-0\@xs {
    margin: 0;
  }

  .margin-top-xxxxs\@xs {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@xs {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@xs {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@xs {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@xs {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@xs {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@xs {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@xs {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@xs {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@xs {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@xs {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@xs {
    margin-top: auto;
  }

  .margin-top-0\@xs {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@xs {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@xs {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@xs {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@xs {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@xs {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@xs {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@xs {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@xs {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@xs {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@xs {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@xs {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@xs {
    margin-bottom: auto;
  }

  .margin-bottom-0\@xs {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@xs {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@xs {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@xs {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@xs {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@xs {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@xs {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@xs {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@xs {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@xs {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@xs {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@xs {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@xs {
    margin-right: auto;
  }

  .margin-right-0\@xs {
    margin-right: 0;
  }

  .margin-left-xxxxs\@xs {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@xs {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@xs {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@xs {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@xs {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@xs {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@xs {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@xs {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@xs {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@xs {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@xs {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@xs {
    margin-left: auto;
  }

  .margin-left-0\@xs {
    margin-left: 0;
  }

  .margin-x-xxxxs\@xs {
    margin-right: var(--space-xxxxs);
    margin-left: var(--space-xxxxs);
  }

  .margin-x-xxxs\@xs {
    margin-right: var(--space-xxxs);
    margin-left: var(--space-xxxs);
  }

  .margin-x-xxs\@xs {
    margin-right: var(--space-xxs);
    margin-left: var(--space-xxs);
  }

  .margin-x-xs\@xs {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }

  .margin-x-sm\@xs {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }

  .margin-x-md\@xs {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }

  .margin-x-lg\@xs {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }

  .margin-x-xl\@xs {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }

  .margin-x-xxl\@xs {
    margin-right: var(--space-xxl);
    margin-left: var(--space-xxl);
  }

  .margin-x-xxxl\@xs {
    margin-right: var(--space-xxxl);
    margin-left: var(--space-xxxl);
  }

  .margin-x-xxxxl\@xs {
    margin-right: var(--space-xxxxl);
    margin-left: var(--space-xxxxl);
  }

  .margin-x-auto\@xs {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@xs {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@xs {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@xs {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@xs {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@xs {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@xs {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@xs {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@xs {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@xs {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@xs {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@xs {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@xs {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@xs {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@xs {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@xs {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@xs {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@xs {
    padding: var(--space-xxs);
  }

  .padding-xs\@xs {
    padding: var(--space-xs);
  }

  .padding-sm\@xs {
    padding: var(--space-sm);
  }

  .padding-md\@xs {
    padding: var(--space-md);
  }

  .padding-lg\@xs {
    padding: var(--space-lg);
  }

  .padding-xl\@xs {
    padding: var(--space-xl);
  }

  .padding-xxl\@xs {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@xs {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@xs {
    padding: var(--space-xxxxl);
  }

  .padding-0\@xs {
    padding: 0;
  }

  .padding-component\@xs {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@xs {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@xs {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@xs {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@xs {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@xs {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@xs {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@xs {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@xs {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@xs {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@xs {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@xs {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@xs {
    padding-top: 0;
  }

  .padding-top-component\@xs {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@xs {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@xs {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@xs {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@xs {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@xs {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@xs {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@xs {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@xs {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@xs {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@xs {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@xs {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@xs {
    padding-bottom: 0;
  }

  .padding-bottom-component\@xs {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@xs {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@xs {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@xs {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@xs {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@xs {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@xs {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@xs {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@xs {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@xs {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@xs {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@xs {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@xs {
    padding-right: 0;
  }

  .padding-right-component\@xs {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@xs {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@xs {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@xs {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@xs {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@xs {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@xs {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@xs {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@xs {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@xs {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@xs {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@xs {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@xs {
    padding-left: 0;
  }

  .padding-left-component\@xs {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@xs {
    padding-right: var(--space-xxxxs);
    padding-left: var(--space-xxxxs);
  }

  .padding-x-xxxs\@xs {
    padding-right: var(--space-xxxs);
    padding-left: var(--space-xxxs);
  }

  .padding-x-xxs\@xs {
    padding-right: var(--space-xxs);
    padding-left: var(--space-xxs);
  }

  .padding-x-xs\@xs {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }

  .padding-x-sm\@xs {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }

  .padding-x-md\@xs {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }

  .padding-x-lg\@xs {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }

  .padding-x-xl\@xs {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }

  .padding-x-xxl\@xs {
    padding-right: var(--space-xxl);
    padding-left: var(--space-xxl);
  }

  .padding-x-xxxl\@xs {
    padding-right: var(--space-xxxl);
    padding-left: var(--space-xxxl);
  }

  .padding-x-xxxxl\@xs {
    padding-right: var(--space-xxxxl);
    padding-left: var(--space-xxxxl);
  }

  .padding-x-0\@xs {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@xs {
    padding-right: var(--component-padding);
    padding-left: var(--component-padding);
  }

  .padding-y-xxxxs\@xs {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@xs {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@xs {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@xs {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@xs {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@xs {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@xs {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@xs {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@xs {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@xs {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@xs {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@xs {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@xs {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@xs {
    text-align: center;
  }

  .text-left\@xs {
    text-align: left;
  }

  .text-right\@xs {
    text-align: right;
  }

  .text-justify\@xs {
    text-align: justify;
  }

  .text-xs\@xs {
    font-size: var(--text-xs, .6875rem);
  }

  .text-sm\@xs {
    font-size: var(--text-sm, .75rem);
  }

  .text-base\@xs {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@xs {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@xs {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@xs {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@xs {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@xs {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@xs {
    font-size: var(--text-xxxxl, 3rem);
  }

  .column-count-1\@xs {
    column-count: 1;
  }

  .column-count-2\@xs {
    column-count: 2;
  }

  .column-count-3\@xs {
    column-count: 3;
  }

  .column-count-4\@xs {
    column-count: 4;
  }

  .width-xxxxs\@xs {
    width: var(--size-xxxxs, .25rem);
  }

  .width-xxxs\@xs {
    width: var(--size-xxxs, .5rem);
  }

  .width-xxs\@xs {
    width: var(--size-xxs, .75rem);
  }

  .width-xs\@xs {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@xs {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@xs {
    width: var(--size-md, 2rem);
  }

  .width-lg\@xs {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@xs {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@xs {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@xs {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@xs {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@xs {
    width: 0;
  }

  .width-10\%\@xs {
    width: 10%;
  }

  .width-20\%\@xs {
    width: 20%;
  }

  .width-25\%\@xs {
    width: 25%;
  }

  .width-30\%\@xs {
    width: 30%;
  }

  .width-33\%\@xs {
    width: 33.3333%;
  }

  .width-40\%\@xs {
    width: 40%;
  }

  .width-50\%\@xs {
    width: 50%;
  }

  .width-60\%\@xs {
    width: 60%;
  }

  .width-66\%\@xs {
    width: 66.6667%;
  }

  .width-70\%\@xs {
    width: 70%;
  }

  .width-75\%\@xs {
    width: 75%;
  }

  .width-80\%\@xs {
    width: 80%;
  }

  .width-90\%\@xs {
    width: 90%;
  }

  .width-100\%\@xs {
    width: 100%;
  }

  .width-100vw\@xs {
    width: 100vw;
  }

  .width-auto\@xs {
    width: auto;
  }

  .width-inherit\@xs {
    width: inherit;
  }

  .height-xxxxs\@xs {
    height: var(--size-xxxxs, .25rem);
  }

  .height-xxxs\@xs {
    height: var(--size-xxxs, .5rem);
  }

  .height-xxs\@xs {
    height: var(--size-xxs, .75rem);
  }

  .height-xs\@xs {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@xs {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@xs {
    height: var(--size-md, 2rem);
  }

  .height-lg\@xs {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@xs {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@xs {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@xs {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@xs {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@xs {
    height: 0;
  }

  .height-10\%\@xs {
    height: 10%;
  }

  .height-20\%\@xs {
    height: 20%;
  }

  .height-25\%\@xs {
    height: 25%;
  }

  .height-30\%\@xs {
    height: 30%;
  }

  .height-33\%\@xs {
    height: 33.3333%;
  }

  .height-40\%\@xs {
    height: 40%;
  }

  .height-50\%\@xs {
    height: 50%;
  }

  .height-60\%\@xs {
    height: 60%;
  }

  .height-66\%\@xs {
    height: 66.6667%;
  }

  .height-70\%\@xs {
    height: 70%;
  }

  .height-75\%\@xs {
    height: 75%;
  }

  .height-80\%\@xs {
    height: 80%;
  }

  .height-90\%\@xs {
    height: 90%;
  }

  .height-100\%\@xs {
    height: 100%;
  }

  .height-100vh\@xs {
    height: 100vh;
  }

  .height-auto\@xs {
    height: auto;
  }

  .height-inherit\@xs {
    height: inherit;
  }

  .max-width-xxxxxs\@xs {
    max-width: var(--max-width-xxxxxs);
  }

  .max-width-xxxxs\@xs {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@xs {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@xs {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@xs {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@xs {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@xs {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@xs {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@xs {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@xs {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@xs {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@xs {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@xs {
    max-width: 100%;
  }

  .max-width-none\@xs {
    max-width: none;
  }

  .position-relative\@xs {
    position: relative;
  }

  .position-absolute\@xs {
    position: absolute;
  }

  .position-fixed\@xs {
    position: fixed;
  }

  .position-sticky\@xs {
    position: sticky;
  }

  .position-static\@xs {
    position: static;
  }

  .inset-0\@xs {
    inset: 0;
  }

  .top-0\@xs {
    top: 0;
  }

  .top-50\%\@xs {
    top: 50%;
  }

  .top-xxxxs\@xs {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@xs {
    top: var(--space-xxxs);
  }

  .top-xxs\@xs {
    top: var(--space-xxs);
  }

  .top-xs\@xs {
    top: var(--space-xs);
  }

  .top-sm\@xs {
    top: var(--space-sm);
  }

  .top-md\@xs {
    top: var(--space-md);
  }

  .top-lg\@xs {
    top: var(--space-lg);
  }

  .top-xl\@xs {
    top: var(--space-xl);
  }

  .top-xxl\@xs {
    top: var(--space-xxl);
  }

  .top-xxxl\@xs {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@xs {
    top: var(--space-xxxxl);
  }

  .bottom-0\@xs {
    bottom: 0;
  }

  .bottom-50\%\@xs {
    bottom: 50%;
  }

  .bottom-xxxxs\@xs {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@xs {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@xs {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@xs {
    bottom: var(--space-xs);
  }

  .bottom-sm\@xs {
    bottom: var(--space-sm);
  }

  .bottom-md\@xs {
    bottom: var(--space-md);
  }

  .bottom-lg\@xs {
    bottom: var(--space-lg);
  }

  .bottom-xl\@xs {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@xs {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@xs {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@xs {
    bottom: var(--space-xxxxl);
  }

  .right-0\@xs {
    right: 0;
  }

  .right-50\%\@xs {
    right: 50%;
  }

  .right-xxxxs\@xs {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@xs {
    right: var(--space-xxxs);
  }

  .right-xxs\@xs {
    right: var(--space-xxs);
  }

  .right-xs\@xs {
    right: var(--space-xs);
  }

  .right-sm\@xs {
    right: var(--space-sm);
  }

  .right-md\@xs {
    right: var(--space-md);
  }

  .right-lg\@xs {
    right: var(--space-lg);
  }

  .right-xl\@xs {
    right: var(--space-xl);
  }

  .right-xxl\@xs {
    right: var(--space-xxl);
  }

  .right-xxxl\@xs {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@xs {
    right: var(--space-xxxxl);
  }

  .left-0\@xs {
    left: 0;
  }

  .left-50\%\@xs {
    left: 50%;
  }

  .left-xxxxs\@xs {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@xs {
    left: var(--space-xxxs);
  }

  .left-xxs\@xs {
    left: var(--space-xxs);
  }

  .left-xs\@xs {
    left: var(--space-xs);
  }

  .left-sm\@xs {
    left: var(--space-sm);
  }

  .left-md\@xs {
    left: var(--space-md);
  }

  .left-lg\@xs {
    left: var(--space-lg);
  }

  .left-xl\@xs {
    left: var(--space-xl);
  }

  .left-xxl\@xs {
    left: var(--space-xxl);
  }

  .left-xxxl\@xs {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@xs {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@xs {
    overflow: hidden;
  }

  .overflow-auto\@xs {
    overflow: auto;
  }

  .momentum-scrolling\@xs {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@xs {
    overscroll-behavior: contain;
  }

  .visible\@xs {
    visibility: visible;
  }

  .invisible\@xs {
    visibility: hidden;
  }
}

@media not all and (min-width: 32rem) {
  .display\@xs {
    display: none !important;
  }
}

@media (min-width: 48rem) {
  .flex\@sm {
    display: flex;
  }

  .inline-flex\@sm {
    display: inline-flex;
  }

  .flex-wrap\@sm {
    flex-wrap: wrap;
  }

  .flex-nowrap\@sm {
    flex-wrap: nowrap;
  }

  .flex-column\@sm {
    flex-direction: column;
  }

  .flex-column-reverse\@sm {
    flex-direction: column-reverse;
  }

  .flex-row\@sm {
    flex-direction: row;
  }

  .flex-row-reverse\@sm {
    flex-direction: row-reverse;
  }

  .flex-center\@sm {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@sm {
    flex-grow: 1;
  }

  .flex-grow-0\@sm {
    flex-grow: 0;
  }

  .flex-shrink\@sm {
    flex-shrink: 1;
  }

  .flex-shrink-0\@sm {
    flex-shrink: 0;
  }

  .flex-basis-0\@sm {
    flex-basis: 0;
  }

  .justify-start\@sm {
    justify-content: flex-start;
  }

  .justify-end\@sm {
    justify-content: flex-end;
  }

  .justify-center\@sm {
    justify-content: center;
  }

  .justify-between\@sm {
    justify-content: space-between;
  }

  .items-center\@sm {
    align-items: center;
  }

  .items-start\@sm {
    align-items: flex-start;
  }

  .items-end\@sm {
    align-items: flex-end;
  }

  .items-baseline\@sm {
    align-items: baseline;
  }

  .order-1\@sm {
    order: 1;
  }

  .order-2\@sm {
    order: 2;
  }

  .order-3\@sm {
    order: 3;
  }

  .block\@sm {
    display: block;
  }

  .inline-block\@sm {
    display: inline-block;
  }

  .inline\@sm {
    display: inline;
  }

  .contents\@sm {
    display: contents;
  }

  .hide\@sm {
    display: none !important;
  }

  .margin-xxxxs\@sm {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@sm {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@sm {
    margin: var(--space-xxs);
  }

  .margin-xs\@sm {
    margin: var(--space-xs);
  }

  .margin-sm\@sm {
    margin: var(--space-sm);
  }

  .margin-md\@sm {
    margin: var(--space-md);
  }

  .margin-lg\@sm {
    margin: var(--space-lg);
  }

  .margin-xl\@sm {
    margin: var(--space-xl);
  }

  .margin-xxl\@sm {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@sm {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@sm {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@sm {
    margin: auto;
  }

  .margin-0\@sm {
    margin: 0;
  }

  .margin-top-xxxxs\@sm {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@sm {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@sm {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@sm {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@sm {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@sm {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@sm {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@sm {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@sm {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@sm {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@sm {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@sm {
    margin-top: auto;
  }

  .margin-top-0\@sm {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@sm {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@sm {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@sm {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@sm {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@sm {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@sm {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@sm {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@sm {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@sm {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@sm {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@sm {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@sm {
    margin-bottom: auto;
  }

  .margin-bottom-0\@sm {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@sm {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@sm {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@sm {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@sm {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@sm {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@sm {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@sm {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@sm {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@sm {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@sm {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@sm {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@sm {
    margin-right: auto;
  }

  .margin-right-0\@sm {
    margin-right: 0;
  }

  .margin-left-xxxxs\@sm {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@sm {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@sm {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@sm {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@sm {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@sm {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@sm {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@sm {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@sm {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@sm {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@sm {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@sm {
    margin-left: auto;
  }

  .margin-left-0\@sm {
    margin-left: 0;
  }

  .margin-x-xxxxs\@sm {
    margin-right: var(--space-xxxxs);
    margin-left: var(--space-xxxxs);
  }

  .margin-x-xxxs\@sm {
    margin-right: var(--space-xxxs);
    margin-left: var(--space-xxxs);
  }

  .margin-x-xxs\@sm {
    margin-right: var(--space-xxs);
    margin-left: var(--space-xxs);
  }

  .margin-x-xs\@sm {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }

  .margin-x-sm\@sm {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }

  .margin-x-md\@sm {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }

  .margin-x-lg\@sm {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }

  .margin-x-xl\@sm {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }

  .margin-x-xxl\@sm {
    margin-right: var(--space-xxl);
    margin-left: var(--space-xxl);
  }

  .margin-x-xxxl\@sm {
    margin-right: var(--space-xxxl);
    margin-left: var(--space-xxxl);
  }

  .margin-x-xxxxl\@sm {
    margin-right: var(--space-xxxxl);
    margin-left: var(--space-xxxxl);
  }

  .margin-x-auto\@sm {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@sm {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@sm {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@sm {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@sm {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@sm {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@sm {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@sm {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@sm {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@sm {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@sm {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@sm {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@sm {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@sm {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@sm {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@sm {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@sm {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@sm {
    padding: var(--space-xxs);
  }

  .padding-xs\@sm {
    padding: var(--space-xs);
  }

  .padding-sm\@sm {
    padding: var(--space-sm);
  }

  .padding-md\@sm {
    padding: var(--space-md);
  }

  .padding-lg\@sm {
    padding: var(--space-lg);
  }

  .padding-xl\@sm {
    padding: var(--space-xl);
  }

  .padding-xxl\@sm {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@sm {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@sm {
    padding: var(--space-xxxxl);
  }

  .padding-0\@sm {
    padding: 0;
  }

  .padding-component\@sm {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@sm {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@sm {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@sm {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@sm {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@sm {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@sm {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@sm {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@sm {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@sm {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@sm {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@sm {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@sm {
    padding-top: 0;
  }

  .padding-top-component\@sm {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@sm {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@sm {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@sm {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@sm {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@sm {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@sm {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@sm {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@sm {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@sm {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@sm {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@sm {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@sm {
    padding-bottom: 0;
  }

  .padding-bottom-component\@sm {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@sm {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@sm {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@sm {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@sm {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@sm {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@sm {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@sm {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@sm {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@sm {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@sm {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@sm {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@sm {
    padding-right: 0;
  }

  .padding-right-component\@sm {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@sm {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@sm {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@sm {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@sm {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@sm {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@sm {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@sm {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@sm {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@sm {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@sm {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@sm {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@sm {
    padding-left: 0;
  }

  .padding-left-component\@sm {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@sm {
    padding-right: var(--space-xxxxs);
    padding-left: var(--space-xxxxs);
  }

  .padding-x-xxxs\@sm {
    padding-right: var(--space-xxxs);
    padding-left: var(--space-xxxs);
  }

  .padding-x-xxs\@sm {
    padding-right: var(--space-xxs);
    padding-left: var(--space-xxs);
  }

  .padding-x-xs\@sm {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }

  .padding-x-sm\@sm {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }

  .padding-x-md\@sm {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }

  .padding-x-lg\@sm {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }

  .padding-x-xl\@sm {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }

  .padding-x-xxl\@sm {
    padding-right: var(--space-xxl);
    padding-left: var(--space-xxl);
  }

  .padding-x-xxxl\@sm {
    padding-right: var(--space-xxxl);
    padding-left: var(--space-xxxl);
  }

  .padding-x-xxxxl\@sm {
    padding-right: var(--space-xxxxl);
    padding-left: var(--space-xxxxl);
  }

  .padding-x-0\@sm {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@sm {
    padding-right: var(--component-padding);
    padding-left: var(--component-padding);
  }

  .padding-y-xxxxs\@sm {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@sm {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@sm {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@sm {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@sm {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@sm {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@sm {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@sm {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@sm {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@sm {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@sm {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@sm {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@sm {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@sm {
    text-align: center;
  }

  .text-left\@sm {
    text-align: left;
  }

  .text-right\@sm {
    text-align: right;
  }

  .text-justify\@sm {
    text-align: justify;
  }

  .text-xs\@sm {
    font-size: var(--text-xs, .6875rem);
  }

  .text-sm\@sm {
    font-size: var(--text-sm, .75rem);
  }

  .text-base\@sm {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@sm {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@sm {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@sm {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@sm {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@sm {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@sm {
    font-size: var(--text-xxxxl, 3rem);
  }

  .column-count-1\@sm {
    column-count: 1;
  }

  .column-count-2\@sm {
    column-count: 2;
  }

  .column-count-3\@sm {
    column-count: 3;
  }

  .column-count-4\@sm {
    column-count: 4;
  }

  .width-xxxxs\@sm {
    width: var(--size-xxxxs, .25rem);
  }

  .width-xxxs\@sm {
    width: var(--size-xxxs, .5rem);
  }

  .width-xxs\@sm {
    width: var(--size-xxs, .75rem);
  }

  .width-xs\@sm {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@sm {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@sm {
    width: var(--size-md, 2rem);
  }

  .width-lg\@sm {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@sm {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@sm {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@sm {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@sm {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@sm {
    width: 0;
  }

  .width-10\%\@sm {
    width: 10%;
  }

  .width-20\%\@sm {
    width: 20%;
  }

  .width-25\%\@sm {
    width: 25%;
  }

  .width-30\%\@sm {
    width: 30%;
  }

  .width-33\%\@sm {
    width: 33.3333%;
  }

  .width-40\%\@sm {
    width: 40%;
  }

  .width-50\%\@sm {
    width: 50%;
  }

  .width-60\%\@sm {
    width: 60%;
  }

  .width-66\%\@sm {
    width: 66.6667%;
  }

  .width-70\%\@sm {
    width: 70%;
  }

  .width-75\%\@sm {
    width: 75%;
  }

  .width-80\%\@sm {
    width: 80%;
  }

  .width-90\%\@sm {
    width: 90%;
  }

  .width-100\%\@sm {
    width: 100%;
  }

  .width-100vw\@sm {
    width: 100vw;
  }

  .width-auto\@sm {
    width: auto;
  }

  .width-inherit\@sm {
    width: inherit;
  }

  .height-xxxxs\@sm {
    height: var(--size-xxxxs, .25rem);
  }

  .height-xxxs\@sm {
    height: var(--size-xxxs, .5rem);
  }

  .height-xxs\@sm {
    height: var(--size-xxs, .75rem);
  }

  .height-xs\@sm {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@sm {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@sm {
    height: var(--size-md, 2rem);
  }

  .height-lg\@sm {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@sm {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@sm {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@sm {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@sm {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@sm {
    height: 0;
  }

  .height-10\%\@sm {
    height: 10%;
  }

  .height-20\%\@sm {
    height: 20%;
  }

  .height-25\%\@sm {
    height: 25%;
  }

  .height-30\%\@sm {
    height: 30%;
  }

  .height-33\%\@sm {
    height: 33.3333%;
  }

  .height-40\%\@sm {
    height: 40%;
  }

  .height-50\%\@sm {
    height: 50%;
  }

  .height-60\%\@sm {
    height: 60%;
  }

  .height-66\%\@sm {
    height: 66.6667%;
  }

  .height-70\%\@sm {
    height: 70%;
  }

  .height-75\%\@sm {
    height: 75%;
  }

  .height-80\%\@sm {
    height: 80%;
  }

  .height-90\%\@sm {
    height: 90%;
  }

  .height-100\%\@sm {
    height: 100%;
  }

  .height-100vh\@sm {
    height: 100vh;
  }

  .height-auto\@sm {
    height: auto;
  }

  .height-inherit\@sm {
    height: inherit;
  }

  .max-width-xxxxxs\@sm {
    max-width: var(--max-width-xxxxxs);
  }

  .max-width-xxxxs\@sm {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@sm {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@sm {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@sm {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@sm {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@sm {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@sm {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@sm {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@sm {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@sm {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@sm {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@sm {
    max-width: 100%;
  }

  .max-width-none\@sm {
    max-width: none;
  }

  .position-relative\@sm {
    position: relative;
  }

  .position-absolute\@sm {
    position: absolute;
  }

  .position-fixed\@sm {
    position: fixed;
  }

  .position-sticky\@sm {
    position: sticky;
  }

  .position-static\@sm {
    position: static;
  }

  .inset-0\@sm {
    inset: 0;
  }

  .top-0\@sm {
    top: 0;
  }

  .top-50\%\@sm {
    top: 50%;
  }

  .top-xxxxs\@sm {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@sm {
    top: var(--space-xxxs);
  }

  .top-xxs\@sm {
    top: var(--space-xxs);
  }

  .top-xs\@sm {
    top: var(--space-xs);
  }

  .top-sm\@sm {
    top: var(--space-sm);
  }

  .top-md\@sm {
    top: var(--space-md);
  }

  .top-lg\@sm {
    top: var(--space-lg);
  }

  .top-xl\@sm {
    top: var(--space-xl);
  }

  .top-xxl\@sm {
    top: var(--space-xxl);
  }

  .top-xxxl\@sm {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@sm {
    top: var(--space-xxxxl);
  }

  .bottom-0\@sm {
    bottom: 0;
  }

  .bottom-50\%\@sm {
    bottom: 50%;
  }

  .bottom-xxxxs\@sm {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@sm {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@sm {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@sm {
    bottom: var(--space-xs);
  }

  .bottom-sm\@sm {
    bottom: var(--space-sm);
  }

  .bottom-md\@sm {
    bottom: var(--space-md);
  }

  .bottom-lg\@sm {
    bottom: var(--space-lg);
  }

  .bottom-xl\@sm {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@sm {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@sm {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@sm {
    bottom: var(--space-xxxxl);
  }

  .right-0\@sm {
    right: 0;
  }

  .right-50\%\@sm {
    right: 50%;
  }

  .right-xxxxs\@sm {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@sm {
    right: var(--space-xxxs);
  }

  .right-xxs\@sm {
    right: var(--space-xxs);
  }

  .right-xs\@sm {
    right: var(--space-xs);
  }

  .right-sm\@sm {
    right: var(--space-sm);
  }

  .right-md\@sm {
    right: var(--space-md);
  }

  .right-lg\@sm {
    right: var(--space-lg);
  }

  .right-xl\@sm {
    right: var(--space-xl);
  }

  .right-xxl\@sm {
    right: var(--space-xxl);
  }

  .right-xxxl\@sm {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@sm {
    right: var(--space-xxxxl);
  }

  .left-0\@sm {
    left: 0;
  }

  .left-50\%\@sm {
    left: 50%;
  }

  .left-xxxxs\@sm {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@sm {
    left: var(--space-xxxs);
  }

  .left-xxs\@sm {
    left: var(--space-xxs);
  }

  .left-xs\@sm {
    left: var(--space-xs);
  }

  .left-sm\@sm {
    left: var(--space-sm);
  }

  .left-md\@sm {
    left: var(--space-md);
  }

  .left-lg\@sm {
    left: var(--space-lg);
  }

  .left-xl\@sm {
    left: var(--space-xl);
  }

  .left-xxl\@sm {
    left: var(--space-xxl);
  }

  .left-xxxl\@sm {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@sm {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@sm {
    overflow: hidden;
  }

  .overflow-auto\@sm {
    overflow: auto;
  }

  .momentum-scrolling\@sm {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@sm {
    overscroll-behavior: contain;
  }

  .visible\@sm {
    visibility: visible;
  }

  .invisible\@sm {
    visibility: hidden;
  }
}

@media not all and (min-width: 48rem) {
  .display\@sm {
    display: none !important;
  }
}

@media (min-width: 64rem) {
  .flex\@md {
    display: flex;
  }

  .inline-flex\@md {
    display: inline-flex;
  }

  .flex-wrap\@md {
    flex-wrap: wrap;
  }

  .flex-nowrap\@md {
    flex-wrap: nowrap;
  }

  .flex-column\@md {
    flex-direction: column;
  }

  .flex-column-reverse\@md {
    flex-direction: column-reverse;
  }

  .flex-row\@md {
    flex-direction: row;
  }

  .flex-row-reverse\@md {
    flex-direction: row-reverse;
  }

  .flex-center\@md {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@md {
    flex-grow: 1;
  }

  .flex-grow-0\@md {
    flex-grow: 0;
  }

  .flex-shrink\@md {
    flex-shrink: 1;
  }

  .flex-shrink-0\@md {
    flex-shrink: 0;
  }

  .flex-basis-0\@md {
    flex-basis: 0;
  }

  .justify-start\@md {
    justify-content: flex-start;
  }

  .justify-end\@md {
    justify-content: flex-end;
  }

  .justify-center\@md {
    justify-content: center;
  }

  .justify-between\@md {
    justify-content: space-between;
  }

  .items-center\@md {
    align-items: center;
  }

  .items-start\@md {
    align-items: flex-start;
  }

  .items-end\@md {
    align-items: flex-end;
  }

  .items-baseline\@md {
    align-items: baseline;
  }

  .order-1\@md {
    order: 1;
  }

  .order-2\@md {
    order: 2;
  }

  .order-3\@md {
    order: 3;
  }

  .block\@md {
    display: block;
  }

  .inline-block\@md {
    display: inline-block;
  }

  .inline\@md {
    display: inline;
  }

  .contents\@md {
    display: contents;
  }

  .hide\@md {
    display: none !important;
  }

  .margin-xxxxs\@md {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@md {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@md {
    margin: var(--space-xxs);
  }

  .margin-xs\@md {
    margin: var(--space-xs);
  }

  .margin-sm\@md {
    margin: var(--space-sm);
  }

  .margin-md\@md {
    margin: var(--space-md);
  }

  .margin-lg\@md {
    margin: var(--space-lg);
  }

  .margin-xl\@md {
    margin: var(--space-xl);
  }

  .margin-xxl\@md {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@md {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@md {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@md {
    margin: auto;
  }

  .margin-0\@md {
    margin: 0;
  }

  .margin-top-xxxxs\@md {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@md {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@md {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@md {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@md {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@md {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@md {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@md {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@md {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@md {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@md {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@md {
    margin-top: auto;
  }

  .margin-top-0\@md {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@md {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@md {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@md {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@md {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@md {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@md {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@md {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@md {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@md {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@md {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@md {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@md {
    margin-bottom: auto;
  }

  .margin-bottom-0\@md {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@md {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@md {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@md {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@md {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@md {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@md {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@md {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@md {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@md {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@md {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@md {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@md {
    margin-right: auto;
  }

  .margin-right-0\@md {
    margin-right: 0;
  }

  .margin-left-xxxxs\@md {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@md {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@md {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@md {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@md {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@md {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@md {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@md {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@md {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@md {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@md {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@md {
    margin-left: auto;
  }

  .margin-left-0\@md {
    margin-left: 0;
  }

  .margin-x-xxxxs\@md {
    margin-right: var(--space-xxxxs);
    margin-left: var(--space-xxxxs);
  }

  .margin-x-xxxs\@md {
    margin-right: var(--space-xxxs);
    margin-left: var(--space-xxxs);
  }

  .margin-x-xxs\@md {
    margin-right: var(--space-xxs);
    margin-left: var(--space-xxs);
  }

  .margin-x-xs\@md {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }

  .margin-x-sm\@md {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }

  .margin-x-md\@md {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }

  .margin-x-lg\@md {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }

  .margin-x-xl\@md {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }

  .margin-x-xxl\@md {
    margin-right: var(--space-xxl);
    margin-left: var(--space-xxl);
  }

  .margin-x-xxxl\@md {
    margin-right: var(--space-xxxl);
    margin-left: var(--space-xxxl);
  }

  .margin-x-xxxxl\@md {
    margin-right: var(--space-xxxxl);
    margin-left: var(--space-xxxxl);
  }

  .margin-x-auto\@md {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@md {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@md {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@md {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@md {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@md {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@md {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@md {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@md {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@md {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@md {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@md {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@md {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@md {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@md {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@md {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@md {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@md {
    padding: var(--space-xxs);
  }

  .padding-xs\@md {
    padding: var(--space-xs);
  }

  .padding-sm\@md {
    padding: var(--space-sm);
  }

  .padding-md\@md {
    padding: var(--space-md);
  }

  .padding-lg\@md {
    padding: var(--space-lg);
  }

  .padding-xl\@md {
    padding: var(--space-xl);
  }

  .padding-xxl\@md {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@md {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@md {
    padding: var(--space-xxxxl);
  }

  .padding-0\@md {
    padding: 0;
  }

  .padding-component\@md {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@md {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@md {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@md {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@md {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@md {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@md {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@md {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@md {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@md {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@md {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@md {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@md {
    padding-top: 0;
  }

  .padding-top-component\@md {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@md {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@md {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@md {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@md {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@md {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@md {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@md {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@md {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@md {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@md {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@md {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@md {
    padding-bottom: 0;
  }

  .padding-bottom-component\@md {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@md {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@md {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@md {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@md {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@md {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@md {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@md {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@md {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@md {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@md {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@md {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@md {
    padding-right: 0;
  }

  .padding-right-component\@md {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@md {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@md {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@md {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@md {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@md {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@md {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@md {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@md {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@md {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@md {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@md {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@md {
    padding-left: 0;
  }

  .padding-left-component\@md {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@md {
    padding-right: var(--space-xxxxs);
    padding-left: var(--space-xxxxs);
  }

  .padding-x-xxxs\@md {
    padding-right: var(--space-xxxs);
    padding-left: var(--space-xxxs);
  }

  .padding-x-xxs\@md {
    padding-right: var(--space-xxs);
    padding-left: var(--space-xxs);
  }

  .padding-x-xs\@md {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }

  .padding-x-sm\@md {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }

  .padding-x-md\@md {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }

  .padding-x-lg\@md {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }

  .padding-x-xl\@md {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }

  .padding-x-xxl\@md {
    padding-right: var(--space-xxl);
    padding-left: var(--space-xxl);
  }

  .padding-x-xxxl\@md {
    padding-right: var(--space-xxxl);
    padding-left: var(--space-xxxl);
  }

  .padding-x-xxxxl\@md {
    padding-right: var(--space-xxxxl);
    padding-left: var(--space-xxxxl);
  }

  .padding-x-0\@md {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@md {
    padding-right: var(--component-padding);
    padding-left: var(--component-padding);
  }

  .padding-y-xxxxs\@md {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@md {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@md {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@md {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@md {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@md {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@md {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@md {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@md {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@md {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@md {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@md {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@md {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@md {
    text-align: center;
  }

  .text-left\@md {
    text-align: left;
  }

  .text-right\@md {
    text-align: right;
  }

  .text-justify\@md {
    text-align: justify;
  }

  .text-xs\@md {
    font-size: var(--text-xs, .6875rem);
  }

  .text-sm\@md {
    font-size: var(--text-sm, .75rem);
  }

  .text-base\@md {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@md {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@md {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@md {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@md {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@md {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@md {
    font-size: var(--text-xxxxl, 3rem);
  }

  .column-count-1\@md {
    column-count: 1;
  }

  .column-count-2\@md {
    column-count: 2;
  }

  .column-count-3\@md {
    column-count: 3;
  }

  .column-count-4\@md {
    column-count: 4;
  }

  .width-xxxxs\@md {
    width: var(--size-xxxxs, .25rem);
  }

  .width-xxxs\@md {
    width: var(--size-xxxs, .5rem);
  }

  .width-xxs\@md {
    width: var(--size-xxs, .75rem);
  }

  .width-xs\@md {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@md {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@md {
    width: var(--size-md, 2rem);
  }

  .width-lg\@md {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@md {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@md {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@md {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@md {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@md {
    width: 0;
  }

  .width-10\%\@md {
    width: 10%;
  }

  .width-20\%\@md {
    width: 20%;
  }

  .width-25\%\@md {
    width: 25%;
  }

  .width-30\%\@md {
    width: 30%;
  }

  .width-33\%\@md {
    width: 33.3333%;
  }

  .width-40\%\@md {
    width: 40%;
  }

  .width-50\%\@md {
    width: 50%;
  }

  .width-60\%\@md {
    width: 60%;
  }

  .width-66\%\@md {
    width: 66.6667%;
  }

  .width-70\%\@md {
    width: 70%;
  }

  .width-75\%\@md {
    width: 75%;
  }

  .width-80\%\@md {
    width: 80%;
  }

  .width-90\%\@md {
    width: 90%;
  }

  .width-100\%\@md {
    width: 100%;
  }

  .width-100vw\@md {
    width: 100vw;
  }

  .width-auto\@md {
    width: auto;
  }

  .width-inherit\@md {
    width: inherit;
  }

  .height-xxxxs\@md {
    height: var(--size-xxxxs, .25rem);
  }

  .height-xxxs\@md {
    height: var(--size-xxxs, .5rem);
  }

  .height-xxs\@md {
    height: var(--size-xxs, .75rem);
  }

  .height-xs\@md {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@md {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@md {
    height: var(--size-md, 2rem);
  }

  .height-lg\@md {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@md {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@md {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@md {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@md {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@md {
    height: 0;
  }

  .height-10\%\@md {
    height: 10%;
  }

  .height-20\%\@md {
    height: 20%;
  }

  .height-25\%\@md {
    height: 25%;
  }

  .height-30\%\@md {
    height: 30%;
  }

  .height-33\%\@md {
    height: 33.3333%;
  }

  .height-40\%\@md {
    height: 40%;
  }

  .height-50\%\@md {
    height: 50%;
  }

  .height-60\%\@md {
    height: 60%;
  }

  .height-66\%\@md {
    height: 66.6667%;
  }

  .height-70\%\@md {
    height: 70%;
  }

  .height-75\%\@md {
    height: 75%;
  }

  .height-80\%\@md {
    height: 80%;
  }

  .height-90\%\@md {
    height: 90%;
  }

  .height-100\%\@md {
    height: 100%;
  }

  .height-100vh\@md {
    height: 100vh;
  }

  .height-auto\@md {
    height: auto;
  }

  .height-inherit\@md {
    height: inherit;
  }

  .max-width-xxxxxs\@md {
    max-width: var(--max-width-xxxxxs);
  }

  .max-width-xxxxs\@md {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@md {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@md {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@md {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@md {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@md {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@md {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@md {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@md {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@md {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@md {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@md {
    max-width: 100%;
  }

  .max-width-none\@md {
    max-width: none;
  }

  .position-relative\@md {
    position: relative;
  }

  .position-absolute\@md {
    position: absolute;
  }

  .position-fixed\@md {
    position: fixed;
  }

  .position-sticky\@md {
    position: sticky;
  }

  .position-static\@md {
    position: static;
  }

  .inset-0\@md {
    inset: 0;
  }

  .top-0\@md {
    top: 0;
  }

  .top-50\%\@md {
    top: 50%;
  }

  .top-xxxxs\@md {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@md {
    top: var(--space-xxxs);
  }

  .top-xxs\@md {
    top: var(--space-xxs);
  }

  .top-xs\@md {
    top: var(--space-xs);
  }

  .top-sm\@md {
    top: var(--space-sm);
  }

  .top-md\@md {
    top: var(--space-md);
  }

  .top-lg\@md {
    top: var(--space-lg);
  }

  .top-xl\@md {
    top: var(--space-xl);
  }

  .top-xxl\@md {
    top: var(--space-xxl);
  }

  .top-xxxl\@md {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@md {
    top: var(--space-xxxxl);
  }

  .bottom-0\@md {
    bottom: 0;
  }

  .bottom-50\%\@md {
    bottom: 50%;
  }

  .bottom-xxxxs\@md {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@md {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@md {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@md {
    bottom: var(--space-xs);
  }

  .bottom-sm\@md {
    bottom: var(--space-sm);
  }

  .bottom-md\@md {
    bottom: var(--space-md);
  }

  .bottom-lg\@md {
    bottom: var(--space-lg);
  }

  .bottom-xl\@md {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@md {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@md {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@md {
    bottom: var(--space-xxxxl);
  }

  .right-0\@md {
    right: 0;
  }

  .right-50\%\@md {
    right: 50%;
  }

  .right-xxxxs\@md {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@md {
    right: var(--space-xxxs);
  }

  .right-xxs\@md {
    right: var(--space-xxs);
  }

  .right-xs\@md {
    right: var(--space-xs);
  }

  .right-sm\@md {
    right: var(--space-sm);
  }

  .right-md\@md {
    right: var(--space-md);
  }

  .right-lg\@md {
    right: var(--space-lg);
  }

  .right-xl\@md {
    right: var(--space-xl);
  }

  .right-xxl\@md {
    right: var(--space-xxl);
  }

  .right-xxxl\@md {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@md {
    right: var(--space-xxxxl);
  }

  .left-0\@md {
    left: 0;
  }

  .left-50\%\@md {
    left: 50%;
  }

  .left-xxxxs\@md {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@md {
    left: var(--space-xxxs);
  }

  .left-xxs\@md {
    left: var(--space-xxs);
  }

  .left-xs\@md {
    left: var(--space-xs);
  }

  .left-sm\@md {
    left: var(--space-sm);
  }

  .left-md\@md {
    left: var(--space-md);
  }

  .left-lg\@md {
    left: var(--space-lg);
  }

  .left-xl\@md {
    left: var(--space-xl);
  }

  .left-xxl\@md {
    left: var(--space-xxl);
  }

  .left-xxxl\@md {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@md {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@md {
    overflow: hidden;
  }

  .overflow-auto\@md {
    overflow: auto;
  }

  .momentum-scrolling\@md {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@md {
    overscroll-behavior: contain;
  }

  .visible\@md {
    visibility: visible;
  }

  .invisible\@md {
    visibility: hidden;
  }
}

@media not all and (min-width: 64rem) {
  .display\@md {
    display: none !important;
  }
}

@media (min-width: 80rem) {
  .flex\@lg {
    display: flex;
  }

  .inline-flex\@lg {
    display: inline-flex;
  }

  .flex-wrap\@lg {
    flex-wrap: wrap;
  }

  .flex-nowrap\@lg {
    flex-wrap: nowrap;
  }

  .flex-column\@lg {
    flex-direction: column;
  }

  .flex-column-reverse\@lg {
    flex-direction: column-reverse;
  }

  .flex-row\@lg {
    flex-direction: row;
  }

  .flex-row-reverse\@lg {
    flex-direction: row-reverse;
  }

  .flex-center\@lg {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@lg {
    flex-grow: 1;
  }

  .flex-grow-0\@lg {
    flex-grow: 0;
  }

  .flex-shrink\@lg {
    flex-shrink: 1;
  }

  .flex-shrink-0\@lg {
    flex-shrink: 0;
  }

  .flex-basis-0\@lg {
    flex-basis: 0;
  }

  .justify-start\@lg {
    justify-content: flex-start;
  }

  .justify-end\@lg {
    justify-content: flex-end;
  }

  .justify-center\@lg {
    justify-content: center;
  }

  .justify-between\@lg {
    justify-content: space-between;
  }

  .items-center\@lg {
    align-items: center;
  }

  .items-start\@lg {
    align-items: flex-start;
  }

  .items-end\@lg {
    align-items: flex-end;
  }

  .items-baseline\@lg {
    align-items: baseline;
  }

  .order-1\@lg {
    order: 1;
  }

  .order-2\@lg {
    order: 2;
  }

  .order-3\@lg {
    order: 3;
  }

  .block\@lg {
    display: block;
  }

  .inline-block\@lg {
    display: inline-block;
  }

  .inline\@lg {
    display: inline;
  }

  .contents\@lg {
    display: contents;
  }

  .hide\@lg {
    display: none !important;
  }

  .margin-xxxxs\@lg {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@lg {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@lg {
    margin: var(--space-xxs);
  }

  .margin-xs\@lg {
    margin: var(--space-xs);
  }

  .margin-sm\@lg {
    margin: var(--space-sm);
  }

  .margin-md\@lg {
    margin: var(--space-md);
  }

  .margin-lg\@lg {
    margin: var(--space-lg);
  }

  .margin-xl\@lg {
    margin: var(--space-xl);
  }

  .margin-xxl\@lg {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@lg {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@lg {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@lg {
    margin: auto;
  }

  .margin-0\@lg {
    margin: 0;
  }

  .margin-top-xxxxs\@lg {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@lg {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@lg {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@lg {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@lg {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@lg {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@lg {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@lg {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@lg {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@lg {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@lg {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@lg {
    margin-top: auto;
  }

  .margin-top-0\@lg {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@lg {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@lg {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@lg {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@lg {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@lg {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@lg {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@lg {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@lg {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@lg {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@lg {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@lg {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@lg {
    margin-bottom: auto;
  }

  .margin-bottom-0\@lg {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@lg {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@lg {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@lg {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@lg {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@lg {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@lg {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@lg {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@lg {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@lg {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@lg {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@lg {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@lg {
    margin-right: auto;
  }

  .margin-right-0\@lg {
    margin-right: 0;
  }

  .margin-left-xxxxs\@lg {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@lg {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@lg {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@lg {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@lg {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@lg {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@lg {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@lg {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@lg {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@lg {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@lg {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@lg {
    margin-left: auto;
  }

  .margin-left-0\@lg {
    margin-left: 0;
  }

  .margin-x-xxxxs\@lg {
    margin-right: var(--space-xxxxs);
    margin-left: var(--space-xxxxs);
  }

  .margin-x-xxxs\@lg {
    margin-right: var(--space-xxxs);
    margin-left: var(--space-xxxs);
  }

  .margin-x-xxs\@lg {
    margin-right: var(--space-xxs);
    margin-left: var(--space-xxs);
  }

  .margin-x-xs\@lg {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }

  .margin-x-sm\@lg {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }

  .margin-x-md\@lg {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }

  .margin-x-lg\@lg {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }

  .margin-x-xl\@lg {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }

  .margin-x-xxl\@lg {
    margin-right: var(--space-xxl);
    margin-left: var(--space-xxl);
  }

  .margin-x-xxxl\@lg {
    margin-right: var(--space-xxxl);
    margin-left: var(--space-xxxl);
  }

  .margin-x-xxxxl\@lg {
    margin-right: var(--space-xxxxl);
    margin-left: var(--space-xxxxl);
  }

  .margin-x-auto\@lg {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@lg {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@lg {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@lg {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@lg {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@lg {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@lg {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@lg {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@lg {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@lg {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@lg {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@lg {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@lg {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@lg {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@lg {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@lg {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@lg {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@lg {
    padding: var(--space-xxs);
  }

  .padding-xs\@lg {
    padding: var(--space-xs);
  }

  .padding-sm\@lg {
    padding: var(--space-sm);
  }

  .padding-md\@lg {
    padding: var(--space-md);
  }

  .padding-lg\@lg {
    padding: var(--space-lg);
  }

  .padding-xl\@lg {
    padding: var(--space-xl);
  }

  .padding-xxl\@lg {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@lg {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@lg {
    padding: var(--space-xxxxl);
  }

  .padding-0\@lg {
    padding: 0;
  }

  .padding-component\@lg {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@lg {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@lg {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@lg {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@lg {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@lg {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@lg {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@lg {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@lg {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@lg {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@lg {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@lg {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@lg {
    padding-top: 0;
  }

  .padding-top-component\@lg {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@lg {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@lg {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@lg {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@lg {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@lg {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@lg {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@lg {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@lg {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@lg {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@lg {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@lg {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@lg {
    padding-bottom: 0;
  }

  .padding-bottom-component\@lg {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@lg {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@lg {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@lg {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@lg {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@lg {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@lg {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@lg {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@lg {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@lg {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@lg {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@lg {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@lg {
    padding-right: 0;
  }

  .padding-right-component\@lg {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@lg {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@lg {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@lg {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@lg {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@lg {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@lg {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@lg {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@lg {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@lg {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@lg {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@lg {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@lg {
    padding-left: 0;
  }

  .padding-left-component\@lg {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@lg {
    padding-right: var(--space-xxxxs);
    padding-left: var(--space-xxxxs);
  }

  .padding-x-xxxs\@lg {
    padding-right: var(--space-xxxs);
    padding-left: var(--space-xxxs);
  }

  .padding-x-xxs\@lg {
    padding-right: var(--space-xxs);
    padding-left: var(--space-xxs);
  }

  .padding-x-xs\@lg {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }

  .padding-x-sm\@lg {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }

  .padding-x-md\@lg {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }

  .padding-x-lg\@lg {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }

  .padding-x-xl\@lg {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }

  .padding-x-xxl\@lg {
    padding-right: var(--space-xxl);
    padding-left: var(--space-xxl);
  }

  .padding-x-xxxl\@lg {
    padding-right: var(--space-xxxl);
    padding-left: var(--space-xxxl);
  }

  .padding-x-xxxxl\@lg {
    padding-right: var(--space-xxxxl);
    padding-left: var(--space-xxxxl);
  }

  .padding-x-0\@lg {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@lg {
    padding-right: var(--component-padding);
    padding-left: var(--component-padding);
  }

  .padding-y-xxxxs\@lg {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@lg {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@lg {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@lg {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@lg {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@lg {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@lg {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@lg {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@lg {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@lg {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@lg {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@lg {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@lg {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@lg {
    text-align: center;
  }

  .text-left\@lg {
    text-align: left;
  }

  .text-right\@lg {
    text-align: right;
  }

  .text-justify\@lg {
    text-align: justify;
  }

  .text-xs\@lg {
    font-size: var(--text-xs, .6875rem);
  }

  .text-sm\@lg {
    font-size: var(--text-sm, .75rem);
  }

  .text-base\@lg {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@lg {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@lg {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@lg {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@lg {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@lg {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@lg {
    font-size: var(--text-xxxxl, 3rem);
  }

  .column-count-1\@lg {
    column-count: 1;
  }

  .column-count-2\@lg {
    column-count: 2;
  }

  .column-count-3\@lg {
    column-count: 3;
  }

  .column-count-4\@lg {
    column-count: 4;
  }

  .width-xxxxs\@lg {
    width: var(--size-xxxxs, .25rem);
  }

  .width-xxxs\@lg {
    width: var(--size-xxxs, .5rem);
  }

  .width-xxs\@lg {
    width: var(--size-xxs, .75rem);
  }

  .width-xs\@lg {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@lg {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@lg {
    width: var(--size-md, 2rem);
  }

  .width-lg\@lg {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@lg {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@lg {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@lg {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@lg {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@lg {
    width: 0;
  }

  .width-10\%\@lg {
    width: 10%;
  }

  .width-20\%\@lg {
    width: 20%;
  }

  .width-25\%\@lg {
    width: 25%;
  }

  .width-30\%\@lg {
    width: 30%;
  }

  .width-33\%\@lg {
    width: 33.3333%;
  }

  .width-40\%\@lg {
    width: 40%;
  }

  .width-50\%\@lg {
    width: 50%;
  }

  .width-60\%\@lg {
    width: 60%;
  }

  .width-66\%\@lg {
    width: 66.6667%;
  }

  .width-70\%\@lg {
    width: 70%;
  }

  .width-75\%\@lg {
    width: 75%;
  }

  .width-80\%\@lg {
    width: 80%;
  }

  .width-90\%\@lg {
    width: 90%;
  }

  .width-100\%\@lg {
    width: 100%;
  }

  .width-100vw\@lg {
    width: 100vw;
  }

  .width-auto\@lg {
    width: auto;
  }

  .width-inherit\@lg {
    width: inherit;
  }

  .height-xxxxs\@lg {
    height: var(--size-xxxxs, .25rem);
  }

  .height-xxxs\@lg {
    height: var(--size-xxxs, .5rem);
  }

  .height-xxs\@lg {
    height: var(--size-xxs, .75rem);
  }

  .height-xs\@lg {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@lg {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@lg {
    height: var(--size-md, 2rem);
  }

  .height-lg\@lg {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@lg {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@lg {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@lg {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@lg {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@lg {
    height: 0;
  }

  .height-10\%\@lg {
    height: 10%;
  }

  .height-20\%\@lg {
    height: 20%;
  }

  .height-25\%\@lg {
    height: 25%;
  }

  .height-30\%\@lg {
    height: 30%;
  }

  .height-33\%\@lg {
    height: 33.3333%;
  }

  .height-40\%\@lg {
    height: 40%;
  }

  .height-50\%\@lg {
    height: 50%;
  }

  .height-60\%\@lg {
    height: 60%;
  }

  .height-66\%\@lg {
    height: 66.6667%;
  }

  .height-70\%\@lg {
    height: 70%;
  }

  .height-75\%\@lg {
    height: 75%;
  }

  .height-80\%\@lg {
    height: 80%;
  }

  .height-90\%\@lg {
    height: 90%;
  }

  .height-100\%\@lg {
    height: 100%;
  }

  .height-100vh\@lg {
    height: 100vh;
  }

  .height-auto\@lg {
    height: auto;
  }

  .height-inherit\@lg {
    height: inherit;
  }

  .max-width-xxxxxs\@lg {
    max-width: var(--max-width-xxxxxs);
  }

  .max-width-xxxxs\@lg {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@lg {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@lg {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@lg {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@lg {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@lg {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@lg {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@lg {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@lg {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@lg {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@lg {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@lg {
    max-width: 100%;
  }

  .max-width-none\@lg {
    max-width: none;
  }

  .position-relative\@lg {
    position: relative;
  }

  .position-absolute\@lg {
    position: absolute;
  }

  .position-fixed\@lg {
    position: fixed;
  }

  .position-sticky\@lg {
    position: sticky;
  }

  .position-static\@lg {
    position: static;
  }

  .inset-0\@lg {
    inset: 0;
  }

  .top-0\@lg {
    top: 0;
  }

  .top-50\%\@lg {
    top: 50%;
  }

  .top-xxxxs\@lg {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@lg {
    top: var(--space-xxxs);
  }

  .top-xxs\@lg {
    top: var(--space-xxs);
  }

  .top-xs\@lg {
    top: var(--space-xs);
  }

  .top-sm\@lg {
    top: var(--space-sm);
  }

  .top-md\@lg {
    top: var(--space-md);
  }

  .top-lg\@lg {
    top: var(--space-lg);
  }

  .top-xl\@lg {
    top: var(--space-xl);
  }

  .top-xxl\@lg {
    top: var(--space-xxl);
  }

  .top-xxxl\@lg {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@lg {
    top: var(--space-xxxxl);
  }

  .bottom-0\@lg {
    bottom: 0;
  }

  .bottom-50\%\@lg {
    bottom: 50%;
  }

  .bottom-xxxxs\@lg {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@lg {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@lg {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@lg {
    bottom: var(--space-xs);
  }

  .bottom-sm\@lg {
    bottom: var(--space-sm);
  }

  .bottom-md\@lg {
    bottom: var(--space-md);
  }

  .bottom-lg\@lg {
    bottom: var(--space-lg);
  }

  .bottom-xl\@lg {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@lg {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@lg {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@lg {
    bottom: var(--space-xxxxl);
  }

  .right-0\@lg {
    right: 0;
  }

  .right-50\%\@lg {
    right: 50%;
  }

  .right-xxxxs\@lg {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@lg {
    right: var(--space-xxxs);
  }

  .right-xxs\@lg {
    right: var(--space-xxs);
  }

  .right-xs\@lg {
    right: var(--space-xs);
  }

  .right-sm\@lg {
    right: var(--space-sm);
  }

  .right-md\@lg {
    right: var(--space-md);
  }

  .right-lg\@lg {
    right: var(--space-lg);
  }

  .right-xl\@lg {
    right: var(--space-xl);
  }

  .right-xxl\@lg {
    right: var(--space-xxl);
  }

  .right-xxxl\@lg {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@lg {
    right: var(--space-xxxxl);
  }

  .left-0\@lg {
    left: 0;
  }

  .left-50\%\@lg {
    left: 50%;
  }

  .left-xxxxs\@lg {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@lg {
    left: var(--space-xxxs);
  }

  .left-xxs\@lg {
    left: var(--space-xxs);
  }

  .left-xs\@lg {
    left: var(--space-xs);
  }

  .left-sm\@lg {
    left: var(--space-sm);
  }

  .left-md\@lg {
    left: var(--space-md);
  }

  .left-lg\@lg {
    left: var(--space-lg);
  }

  .left-xl\@lg {
    left: var(--space-xl);
  }

  .left-xxl\@lg {
    left: var(--space-xxl);
  }

  .left-xxxl\@lg {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@lg {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@lg {
    overflow: hidden;
  }

  .overflow-auto\@lg {
    overflow: auto;
  }

  .momentum-scrolling\@lg {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@lg {
    overscroll-behavior: contain;
  }

  .visible\@lg {
    visibility: visible;
  }

  .invisible\@lg {
    visibility: hidden;
  }
}

@media not all and (min-width: 80rem) {
  .display\@lg {
    display: none !important;
  }
}

@media (min-width: 90rem) {
  .flex\@xl {
    display: flex;
  }

  .inline-flex\@xl {
    display: inline-flex;
  }

  .flex-wrap\@xl {
    flex-wrap: wrap;
  }

  .flex-nowrap\@xl {
    flex-wrap: nowrap;
  }

  .flex-column\@xl {
    flex-direction: column;
  }

  .flex-column-reverse\@xl {
    flex-direction: column-reverse;
  }

  .flex-row\@xl {
    flex-direction: row;
  }

  .flex-row-reverse\@xl {
    flex-direction: row-reverse;
  }

  .flex-center\@xl {
    justify-content: center;
    align-items: center;
  }

  .flex-grow\@xl {
    flex-grow: 1;
  }

  .flex-grow-0\@xl {
    flex-grow: 0;
  }

  .flex-shrink\@xl {
    flex-shrink: 1;
  }

  .flex-shrink-0\@xl {
    flex-shrink: 0;
  }

  .flex-basis-0\@xl {
    flex-basis: 0;
  }

  .justify-start\@xl {
    justify-content: flex-start;
  }

  .justify-end\@xl {
    justify-content: flex-end;
  }

  .justify-center\@xl {
    justify-content: center;
  }

  .justify-between\@xl {
    justify-content: space-between;
  }

  .items-center\@xl {
    align-items: center;
  }

  .items-start\@xl {
    align-items: flex-start;
  }

  .items-end\@xl {
    align-items: flex-end;
  }

  .items-baseline\@xl {
    align-items: baseline;
  }

  .order-1\@xl {
    order: 1;
  }

  .order-2\@xl {
    order: 2;
  }

  .order-3\@xl {
    order: 3;
  }

  .block\@xl {
    display: block;
  }

  .inline-block\@xl {
    display: inline-block;
  }

  .inline\@xl {
    display: inline;
  }

  .contents\@xl {
    display: contents;
  }

  .hide\@xl {
    display: none !important;
  }

  .margin-xxxxs\@xl {
    margin: var(--space-xxxxs);
  }

  .margin-xxxs\@xl {
    margin: var(--space-xxxs);
  }

  .margin-xxs\@xl {
    margin: var(--space-xxs);
  }

  .margin-xs\@xl {
    margin: var(--space-xs);
  }

  .margin-sm\@xl {
    margin: var(--space-sm);
  }

  .margin-md\@xl {
    margin: var(--space-md);
  }

  .margin-lg\@xl {
    margin: var(--space-lg);
  }

  .margin-xl\@xl {
    margin: var(--space-xl);
  }

  .margin-xxl\@xl {
    margin: var(--space-xxl);
  }

  .margin-xxxl\@xl {
    margin: var(--space-xxxl);
  }

  .margin-xxxxl\@xl {
    margin: var(--space-xxxxl);
  }

  .margin-auto\@xl {
    margin: auto;
  }

  .margin-0\@xl {
    margin: 0;
  }

  .margin-top-xxxxs\@xl {
    margin-top: var(--space-xxxxs);
  }

  .margin-top-xxxs\@xl {
    margin-top: var(--space-xxxs);
  }

  .margin-top-xxs\@xl {
    margin-top: var(--space-xxs);
  }

  .margin-top-xs\@xl {
    margin-top: var(--space-xs);
  }

  .margin-top-sm\@xl {
    margin-top: var(--space-sm);
  }

  .margin-top-md\@xl {
    margin-top: var(--space-md);
  }

  .margin-top-lg\@xl {
    margin-top: var(--space-lg);
  }

  .margin-top-xl\@xl {
    margin-top: var(--space-xl);
  }

  .margin-top-xxl\@xl {
    margin-top: var(--space-xxl);
  }

  .margin-top-xxxl\@xl {
    margin-top: var(--space-xxxl);
  }

  .margin-top-xxxxl\@xl {
    margin-top: var(--space-xxxxl);
  }

  .margin-top-auto\@xl {
    margin-top: auto;
  }

  .margin-top-0\@xl {
    margin-top: 0;
  }

  .margin-bottom-xxxxs\@xl {
    margin-bottom: var(--space-xxxxs);
  }

  .margin-bottom-xxxs\@xl {
    margin-bottom: var(--space-xxxs);
  }

  .margin-bottom-xxs\@xl {
    margin-bottom: var(--space-xxs);
  }

  .margin-bottom-xs\@xl {
    margin-bottom: var(--space-xs);
  }

  .margin-bottom-sm\@xl {
    margin-bottom: var(--space-sm);
  }

  .margin-bottom-md\@xl {
    margin-bottom: var(--space-md);
  }

  .margin-bottom-lg\@xl {
    margin-bottom: var(--space-lg);
  }

  .margin-bottom-xl\@xl {
    margin-bottom: var(--space-xl);
  }

  .margin-bottom-xxl\@xl {
    margin-bottom: var(--space-xxl);
  }

  .margin-bottom-xxxl\@xl {
    margin-bottom: var(--space-xxxl);
  }

  .margin-bottom-xxxxl\@xl {
    margin-bottom: var(--space-xxxxl);
  }

  .margin-bottom-auto\@xl {
    margin-bottom: auto;
  }

  .margin-bottom-0\@xl {
    margin-bottom: 0;
  }

  .margin-right-xxxxs\@xl {
    margin-right: var(--space-xxxxs);
  }

  .margin-right-xxxs\@xl {
    margin-right: var(--space-xxxs);
  }

  .margin-right-xxs\@xl {
    margin-right: var(--space-xxs);
  }

  .margin-right-xs\@xl {
    margin-right: var(--space-xs);
  }

  .margin-right-sm\@xl {
    margin-right: var(--space-sm);
  }

  .margin-right-md\@xl {
    margin-right: var(--space-md);
  }

  .margin-right-lg\@xl {
    margin-right: var(--space-lg);
  }

  .margin-right-xl\@xl {
    margin-right: var(--space-xl);
  }

  .margin-right-xxl\@xl {
    margin-right: var(--space-xxl);
  }

  .margin-right-xxxl\@xl {
    margin-right: var(--space-xxxl);
  }

  .margin-right-xxxxl\@xl {
    margin-right: var(--space-xxxxl);
  }

  .margin-right-auto\@xl {
    margin-right: auto;
  }

  .margin-right-0\@xl {
    margin-right: 0;
  }

  .margin-left-xxxxs\@xl {
    margin-left: var(--space-xxxxs);
  }

  .margin-left-xxxs\@xl {
    margin-left: var(--space-xxxs);
  }

  .margin-left-xxs\@xl {
    margin-left: var(--space-xxs);
  }

  .margin-left-xs\@xl {
    margin-left: var(--space-xs);
  }

  .margin-left-sm\@xl {
    margin-left: var(--space-sm);
  }

  .margin-left-md\@xl {
    margin-left: var(--space-md);
  }

  .margin-left-lg\@xl {
    margin-left: var(--space-lg);
  }

  .margin-left-xl\@xl {
    margin-left: var(--space-xl);
  }

  .margin-left-xxl\@xl {
    margin-left: var(--space-xxl);
  }

  .margin-left-xxxl\@xl {
    margin-left: var(--space-xxxl);
  }

  .margin-left-xxxxl\@xl {
    margin-left: var(--space-xxxxl);
  }

  .margin-left-auto\@xl {
    margin-left: auto;
  }

  .margin-left-0\@xl {
    margin-left: 0;
  }

  .margin-x-xxxxs\@xl {
    margin-right: var(--space-xxxxs);
    margin-left: var(--space-xxxxs);
  }

  .margin-x-xxxs\@xl {
    margin-right: var(--space-xxxs);
    margin-left: var(--space-xxxs);
  }

  .margin-x-xxs\@xl {
    margin-right: var(--space-xxs);
    margin-left: var(--space-xxs);
  }

  .margin-x-xs\@xl {
    margin-right: var(--space-xs);
    margin-left: var(--space-xs);
  }

  .margin-x-sm\@xl {
    margin-right: var(--space-sm);
    margin-left: var(--space-sm);
  }

  .margin-x-md\@xl {
    margin-right: var(--space-md);
    margin-left: var(--space-md);
  }

  .margin-x-lg\@xl {
    margin-right: var(--space-lg);
    margin-left: var(--space-lg);
  }

  .margin-x-xl\@xl {
    margin-right: var(--space-xl);
    margin-left: var(--space-xl);
  }

  .margin-x-xxl\@xl {
    margin-right: var(--space-xxl);
    margin-left: var(--space-xxl);
  }

  .margin-x-xxxl\@xl {
    margin-right: var(--space-xxxl);
    margin-left: var(--space-xxxl);
  }

  .margin-x-xxxxl\@xl {
    margin-right: var(--space-xxxxl);
    margin-left: var(--space-xxxxl);
  }

  .margin-x-auto\@xl {
    margin-left: auto;
    margin-right: auto;
  }

  .margin-x-0\@xl {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-y-xxxxs\@xl {
    margin-top: var(--space-xxxxs);
    margin-bottom: var(--space-xxxxs);
  }

  .margin-y-xxxs\@xl {
    margin-top: var(--space-xxxs);
    margin-bottom: var(--space-xxxs);
  }

  .margin-y-xxs\@xl {
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-xxs);
  }

  .margin-y-xs\@xl {
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }

  .margin-y-sm\@xl {
    margin-top: var(--space-sm);
    margin-bottom: var(--space-sm);
  }

  .margin-y-md\@xl {
    margin-top: var(--space-md);
    margin-bottom: var(--space-md);
  }

  .margin-y-lg\@xl {
    margin-top: var(--space-lg);
    margin-bottom: var(--space-lg);
  }

  .margin-y-xl\@xl {
    margin-top: var(--space-xl);
    margin-bottom: var(--space-xl);
  }

  .margin-y-xxl\@xl {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  .margin-y-xxxl\@xl {
    margin-top: var(--space-xxxl);
    margin-bottom: var(--space-xxxl);
  }

  .margin-y-xxxxl\@xl {
    margin-top: var(--space-xxxxl);
    margin-bottom: var(--space-xxxxl);
  }

  .margin-y-auto\@xl {
    margin-top: auto;
    margin-bottom: auto;
  }

  .margin-y-0\@xl {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-xxxxs\@xl {
    padding: var(--space-xxxxs);
  }

  .padding-xxxs\@xl {
    padding: var(--space-xxxs);
  }

  .padding-xxs\@xl {
    padding: var(--space-xxs);
  }

  .padding-xs\@xl {
    padding: var(--space-xs);
  }

  .padding-sm\@xl {
    padding: var(--space-sm);
  }

  .padding-md\@xl {
    padding: var(--space-md);
  }

  .padding-lg\@xl {
    padding: var(--space-lg);
  }

  .padding-xl\@xl {
    padding: var(--space-xl);
  }

  .padding-xxl\@xl {
    padding: var(--space-xxl);
  }

  .padding-xxxl\@xl {
    padding: var(--space-xxxl);
  }

  .padding-xxxxl\@xl {
    padding: var(--space-xxxxl);
  }

  .padding-0\@xl {
    padding: 0;
  }

  .padding-component\@xl {
    padding: var(--component-padding);
  }

  .padding-top-xxxxs\@xl {
    padding-top: var(--space-xxxxs);
  }

  .padding-top-xxxs\@xl {
    padding-top: var(--space-xxxs);
  }

  .padding-top-xxs\@xl {
    padding-top: var(--space-xxs);
  }

  .padding-top-xs\@xl {
    padding-top: var(--space-xs);
  }

  .padding-top-sm\@xl {
    padding-top: var(--space-sm);
  }

  .padding-top-md\@xl {
    padding-top: var(--space-md);
  }

  .padding-top-lg\@xl {
    padding-top: var(--space-lg);
  }

  .padding-top-xl\@xl {
    padding-top: var(--space-xl);
  }

  .padding-top-xxl\@xl {
    padding-top: var(--space-xxl);
  }

  .padding-top-xxxl\@xl {
    padding-top: var(--space-xxxl);
  }

  .padding-top-xxxxl\@xl {
    padding-top: var(--space-xxxxl);
  }

  .padding-top-0\@xl {
    padding-top: 0;
  }

  .padding-top-component\@xl {
    padding-top: var(--component-padding);
  }

  .padding-bottom-xxxxs\@xl {
    padding-bottom: var(--space-xxxxs);
  }

  .padding-bottom-xxxs\@xl {
    padding-bottom: var(--space-xxxs);
  }

  .padding-bottom-xxs\@xl {
    padding-bottom: var(--space-xxs);
  }

  .padding-bottom-xs\@xl {
    padding-bottom: var(--space-xs);
  }

  .padding-bottom-sm\@xl {
    padding-bottom: var(--space-sm);
  }

  .padding-bottom-md\@xl {
    padding-bottom: var(--space-md);
  }

  .padding-bottom-lg\@xl {
    padding-bottom: var(--space-lg);
  }

  .padding-bottom-xl\@xl {
    padding-bottom: var(--space-xl);
  }

  .padding-bottom-xxl\@xl {
    padding-bottom: var(--space-xxl);
  }

  .padding-bottom-xxxl\@xl {
    padding-bottom: var(--space-xxxl);
  }

  .padding-bottom-xxxxl\@xl {
    padding-bottom: var(--space-xxxxl);
  }

  .padding-bottom-0\@xl {
    padding-bottom: 0;
  }

  .padding-bottom-component\@xl {
    padding-bottom: var(--component-padding);
  }

  .padding-right-xxxxs\@xl {
    padding-right: var(--space-xxxxs);
  }

  .padding-right-xxxs\@xl {
    padding-right: var(--space-xxxs);
  }

  .padding-right-xxs\@xl {
    padding-right: var(--space-xxs);
  }

  .padding-right-xs\@xl {
    padding-right: var(--space-xs);
  }

  .padding-right-sm\@xl {
    padding-right: var(--space-sm);
  }

  .padding-right-md\@xl {
    padding-right: var(--space-md);
  }

  .padding-right-lg\@xl {
    padding-right: var(--space-lg);
  }

  .padding-right-xl\@xl {
    padding-right: var(--space-xl);
  }

  .padding-right-xxl\@xl {
    padding-right: var(--space-xxl);
  }

  .padding-right-xxxl\@xl {
    padding-right: var(--space-xxxl);
  }

  .padding-right-xxxxl\@xl {
    padding-right: var(--space-xxxxl);
  }

  .padding-right-0\@xl {
    padding-right: 0;
  }

  .padding-right-component\@xl {
    padding-right: var(--component-padding);
  }

  .padding-left-xxxxs\@xl {
    padding-left: var(--space-xxxxs);
  }

  .padding-left-xxxs\@xl {
    padding-left: var(--space-xxxs);
  }

  .padding-left-xxs\@xl {
    padding-left: var(--space-xxs);
  }

  .padding-left-xs\@xl {
    padding-left: var(--space-xs);
  }

  .padding-left-sm\@xl {
    padding-left: var(--space-sm);
  }

  .padding-left-md\@xl {
    padding-left: var(--space-md);
  }

  .padding-left-lg\@xl {
    padding-left: var(--space-lg);
  }

  .padding-left-xl\@xl {
    padding-left: var(--space-xl);
  }

  .padding-left-xxl\@xl {
    padding-left: var(--space-xxl);
  }

  .padding-left-xxxl\@xl {
    padding-left: var(--space-xxxl);
  }

  .padding-left-xxxxl\@xl {
    padding-left: var(--space-xxxxl);
  }

  .padding-left-0\@xl {
    padding-left: 0;
  }

  .padding-left-component\@xl {
    padding-left: var(--component-padding);
  }

  .padding-x-xxxxs\@xl {
    padding-right: var(--space-xxxxs);
    padding-left: var(--space-xxxxs);
  }

  .padding-x-xxxs\@xl {
    padding-right: var(--space-xxxs);
    padding-left: var(--space-xxxs);
  }

  .padding-x-xxs\@xl {
    padding-right: var(--space-xxs);
    padding-left: var(--space-xxs);
  }

  .padding-x-xs\@xl {
    padding-right: var(--space-xs);
    padding-left: var(--space-xs);
  }

  .padding-x-sm\@xl {
    padding-right: var(--space-sm);
    padding-left: var(--space-sm);
  }

  .padding-x-md\@xl {
    padding-right: var(--space-md);
    padding-left: var(--space-md);
  }

  .padding-x-lg\@xl {
    padding-right: var(--space-lg);
    padding-left: var(--space-lg);
  }

  .padding-x-xl\@xl {
    padding-right: var(--space-xl);
    padding-left: var(--space-xl);
  }

  .padding-x-xxl\@xl {
    padding-right: var(--space-xxl);
    padding-left: var(--space-xxl);
  }

  .padding-x-xxxl\@xl {
    padding-right: var(--space-xxxl);
    padding-left: var(--space-xxxl);
  }

  .padding-x-xxxxl\@xl {
    padding-right: var(--space-xxxxl);
    padding-left: var(--space-xxxxl);
  }

  .padding-x-0\@xl {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-x-component\@xl {
    padding-right: var(--component-padding);
    padding-left: var(--component-padding);
  }

  .padding-y-xxxxs\@xl {
    padding-top: var(--space-xxxxs);
    padding-bottom: var(--space-xxxxs);
  }

  .padding-y-xxxs\@xl {
    padding-top: var(--space-xxxs);
    padding-bottom: var(--space-xxxs);
  }

  .padding-y-xxs\@xl {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .padding-y-xs\@xl {
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
  }

  .padding-y-sm\@xl {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
  }

  .padding-y-md\@xl {
    padding-top: var(--space-md);
    padding-bottom: var(--space-md);
  }

  .padding-y-lg\@xl {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }

  .padding-y-xl\@xl {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }

  .padding-y-xxl\@xl {
    padding-top: var(--space-xxl);
    padding-bottom: var(--space-xxl);
  }

  .padding-y-xxxl\@xl {
    padding-top: var(--space-xxxl);
    padding-bottom: var(--space-xxxl);
  }

  .padding-y-xxxxl\@xl {
    padding-top: var(--space-xxxxl);
    padding-bottom: var(--space-xxxxl);
  }

  .padding-y-0\@xl {
    padding-top: 0;
    padding-bottom: 0;
  }

  .padding-y-component\@xl {
    padding-top: var(--component-padding);
    padding-bottom: var(--component-padding);
  }

  .text-center\@xl {
    text-align: center;
  }

  .text-left\@xl {
    text-align: left;
  }

  .text-right\@xl {
    text-align: right;
  }

  .text-justify\@xl {
    text-align: justify;
  }

  .text-xs\@xl {
    font-size: var(--text-xs, .6875rem);
  }

  .text-sm\@xl {
    font-size: var(--text-sm, .75rem);
  }

  .text-base\@xl {
    font-size: var(--text-unit, 1rem);
  }

  .text-md\@xl {
    font-size: var(--text-md, 1.125rem);
  }

  .text-lg\@xl {
    font-size: var(--text-lg, 1.375rem);
  }

  .text-xl\@xl {
    font-size: var(--text-xl, 1.75rem);
  }

  .text-xxl\@xl {
    font-size: var(--text-xxl, 2rem);
  }

  .text-xxxl\@xl {
    font-size: var(--text-xxxl, 2.5rem);
  }

  .text-xxxxl\@xl {
    font-size: var(--text-xxxxl, 3rem);
  }

  .column-count-1\@xl {
    column-count: 1;
  }

  .column-count-2\@xl {
    column-count: 2;
  }

  .column-count-3\@xl {
    column-count: 3;
  }

  .column-count-4\@xl {
    column-count: 4;
  }

  .width-xxxxs\@xl {
    width: var(--size-xxxxs, .25rem);
  }

  .width-xxxs\@xl {
    width: var(--size-xxxs, .5rem);
  }

  .width-xxs\@xl {
    width: var(--size-xxs, .75rem);
  }

  .width-xs\@xl {
    width: var(--size-xs, 1rem);
  }

  .width-sm\@xl {
    width: var(--size-sm, 1.5rem);
  }

  .width-md\@xl {
    width: var(--size-md, 2rem);
  }

  .width-lg\@xl {
    width: var(--size-lg, 3rem);
  }

  .width-xl\@xl {
    width: var(--size-xl, 4rem);
  }

  .width-xxl\@xl {
    width: var(--size-xxl, 6rem);
  }

  .width-xxxl\@xl {
    width: var(--size-xxxl, 8rem);
  }

  .width-xxxxl\@xl {
    width: var(--size-xxxxl, 16rem);
  }

  .width-0\@xl {
    width: 0;
  }

  .width-10\%\@xl {
    width: 10%;
  }

  .width-20\%\@xl {
    width: 20%;
  }

  .width-25\%\@xl {
    width: 25%;
  }

  .width-30\%\@xl {
    width: 30%;
  }

  .width-33\%\@xl {
    width: 33.3333%;
  }

  .width-40\%\@xl {
    width: 40%;
  }

  .width-50\%\@xl {
    width: 50%;
  }

  .width-60\%\@xl {
    width: 60%;
  }

  .width-66\%\@xl {
    width: 66.6667%;
  }

  .width-70\%\@xl {
    width: 70%;
  }

  .width-75\%\@xl {
    width: 75%;
  }

  .width-80\%\@xl {
    width: 80%;
  }

  .width-90\%\@xl {
    width: 90%;
  }

  .width-100\%\@xl {
    width: 100%;
  }

  .width-100vw\@xl {
    width: 100vw;
  }

  .width-auto\@xl {
    width: auto;
  }

  .width-inherit\@xl {
    width: inherit;
  }

  .height-xxxxs\@xl {
    height: var(--size-xxxxs, .25rem);
  }

  .height-xxxs\@xl {
    height: var(--size-xxxs, .5rem);
  }

  .height-xxs\@xl {
    height: var(--size-xxs, .75rem);
  }

  .height-xs\@xl {
    height: var(--size-xs, 1rem);
  }

  .height-sm\@xl {
    height: var(--size-sm, 1.5rem);
  }

  .height-md\@xl {
    height: var(--size-md, 2rem);
  }

  .height-lg\@xl {
    height: var(--size-lg, 3rem);
  }

  .height-xl\@xl {
    height: var(--size-xl, 4rem);
  }

  .height-xxl\@xl {
    height: var(--size-xxl, 6rem);
  }

  .height-xxxl\@xl {
    height: var(--size-xxxl, 8rem);
  }

  .height-xxxxl\@xl {
    height: var(--size-xxxxl, 16rem);
  }

  .height-0\@xl {
    height: 0;
  }

  .height-10\%\@xl {
    height: 10%;
  }

  .height-20\%\@xl {
    height: 20%;
  }

  .height-25\%\@xl {
    height: 25%;
  }

  .height-30\%\@xl {
    height: 30%;
  }

  .height-33\%\@xl {
    height: 33.3333%;
  }

  .height-40\%\@xl {
    height: 40%;
  }

  .height-50\%\@xl {
    height: 50%;
  }

  .height-60\%\@xl {
    height: 60%;
  }

  .height-66\%\@xl {
    height: 66.6667%;
  }

  .height-70\%\@xl {
    height: 70%;
  }

  .height-75\%\@xl {
    height: 75%;
  }

  .height-80\%\@xl {
    height: 80%;
  }

  .height-90\%\@xl {
    height: 90%;
  }

  .height-100\%\@xl {
    height: 100%;
  }

  .height-100vh\@xl {
    height: 100vh;
  }

  .height-auto\@xl {
    height: auto;
  }

  .height-inherit\@xl {
    height: inherit;
  }

  .max-width-xxxxxs\@xl {
    max-width: var(--max-width-xxxxxs);
  }

  .max-width-xxxxs\@xl {
    max-width: var(--max-width-xxxxs);
  }

  .max-width-xxxs\@xl {
    max-width: var(--max-width-xxxs);
  }

  .max-width-xxs\@xl {
    max-width: var(--max-width-xxs);
  }

  .max-width-xs\@xl {
    max-width: var(--max-width-xs);
  }

  .max-width-sm\@xl {
    max-width: var(--max-width-sm);
  }

  .max-width-md\@xl {
    max-width: var(--max-width-md);
  }

  .max-width-lg\@xl {
    max-width: var(--max-width-lg);
  }

  .max-width-xl\@xl {
    max-width: var(--max-width-xl);
  }

  .max-width-xxl\@xl {
    max-width: var(--max-width-xxl);
  }

  .max-width-xxxl\@xl {
    max-width: var(--max-width-xxxl);
  }

  .max-width-xxxxl\@xl {
    max-width: var(--max-width-xxxxl);
  }

  .max-width-100\%\@xl {
    max-width: 100%;
  }

  .max-width-none\@xl {
    max-width: none;
  }

  .position-relative\@xl {
    position: relative;
  }

  .position-absolute\@xl {
    position: absolute;
  }

  .position-fixed\@xl {
    position: fixed;
  }

  .position-sticky\@xl {
    position: sticky;
  }

  .position-static\@xl {
    position: static;
  }

  .inset-0\@xl {
    inset: 0;
  }

  .top-0\@xl {
    top: 0;
  }

  .top-50\%\@xl {
    top: 50%;
  }

  .top-xxxxs\@xl {
    top: var(--space-xxxxs);
  }

  .top-xxxs\@xl {
    top: var(--space-xxxs);
  }

  .top-xxs\@xl {
    top: var(--space-xxs);
  }

  .top-xs\@xl {
    top: var(--space-xs);
  }

  .top-sm\@xl {
    top: var(--space-sm);
  }

  .top-md\@xl {
    top: var(--space-md);
  }

  .top-lg\@xl {
    top: var(--space-lg);
  }

  .top-xl\@xl {
    top: var(--space-xl);
  }

  .top-xxl\@xl {
    top: var(--space-xxl);
  }

  .top-xxxl\@xl {
    top: var(--space-xxxl);
  }

  .top-xxxxl\@xl {
    top: var(--space-xxxxl);
  }

  .bottom-0\@xl {
    bottom: 0;
  }

  .bottom-50\%\@xl {
    bottom: 50%;
  }

  .bottom-xxxxs\@xl {
    bottom: var(--space-xxxxs);
  }

  .bottom-xxxs\@xl {
    bottom: var(--space-xxxs);
  }

  .bottom-xxs\@xl {
    bottom: var(--space-xxs);
  }

  .bottom-xs\@xl {
    bottom: var(--space-xs);
  }

  .bottom-sm\@xl {
    bottom: var(--space-sm);
  }

  .bottom-md\@xl {
    bottom: var(--space-md);
  }

  .bottom-lg\@xl {
    bottom: var(--space-lg);
  }

  .bottom-xl\@xl {
    bottom: var(--space-xl);
  }

  .bottom-xxl\@xl {
    bottom: var(--space-xxl);
  }

  .bottom-xxxl\@xl {
    bottom: var(--space-xxxl);
  }

  .bottom-xxxxl\@xl {
    bottom: var(--space-xxxxl);
  }

  .right-0\@xl {
    right: 0;
  }

  .right-50\%\@xl {
    right: 50%;
  }

  .right-xxxxs\@xl {
    right: var(--space-xxxxs);
  }

  .right-xxxs\@xl {
    right: var(--space-xxxs);
  }

  .right-xxs\@xl {
    right: var(--space-xxs);
  }

  .right-xs\@xl {
    right: var(--space-xs);
  }

  .right-sm\@xl {
    right: var(--space-sm);
  }

  .right-md\@xl {
    right: var(--space-md);
  }

  .right-lg\@xl {
    right: var(--space-lg);
  }

  .right-xl\@xl {
    right: var(--space-xl);
  }

  .right-xxl\@xl {
    right: var(--space-xxl);
  }

  .right-xxxl\@xl {
    right: var(--space-xxxl);
  }

  .right-xxxxl\@xl {
    right: var(--space-xxxxl);
  }

  .left-0\@xl {
    left: 0;
  }

  .left-50\%\@xl {
    left: 50%;
  }

  .left-xxxxs\@xl {
    left: var(--space-xxxxs);
  }

  .left-xxxs\@xl {
    left: var(--space-xxxs);
  }

  .left-xxs\@xl {
    left: var(--space-xxs);
  }

  .left-xs\@xl {
    left: var(--space-xs);
  }

  .left-sm\@xl {
    left: var(--space-sm);
  }

  .left-md\@xl {
    left: var(--space-md);
  }

  .left-lg\@xl {
    left: var(--space-lg);
  }

  .left-xl\@xl {
    left: var(--space-xl);
  }

  .left-xxl\@xl {
    left: var(--space-xxl);
  }

  .left-xxxl\@xl {
    left: var(--space-xxxl);
  }

  .left-xxxxl\@xl {
    left: var(--space-xxxxl);
  }

  .overflow-hidden\@xl {
    overflow: hidden;
  }

  .overflow-auto\@xl {
    overflow: auto;
  }

  .momentum-scrolling\@xl {
    -webkit-overflow-scrolling: touch;
  }

  .overscroll-contain\@xl {
    overscroll-behavior: contain;
  }

  .visible\@xl {
    visibility: visible;
  }

  .invisible\@xl {
    visibility: hidden;
  }
}

@media not all and (min-width: 90rem) {
  .display\@xl {
    display: none !important;
  }
}

:root, [data-theme="default"] {
  --color-primary-darker: #267182;
  --color-primary-darker-h: 191;
  --color-primary-darker-s: 55%;
  --color-primary-darker-l: 33%;
  --color-primary-dark: #2d869a;
  --color-primary-dark-h: 191;
  --color-primary-dark-s: 55%;
  --color-primary-dark-l: 39%;
  --color-primary: #3894a8;
  --color-primary-h: 191;
  --color-primary-s: 50%;
  --color-primary-l: 44%;
  --color-primary-light: #41a3b9;
  --color-primary-light-h: 191;
  --color-primary-light-s: 48%;
  --color-primary-light-l: 49%;
  --color-primary-lighter: #64b2c4;
  --color-primary-lighter-h: 191;
  --color-primary-lighter-s: 45%;
  --color-primary-lighter-l: 58%;
  --color-accent-darker: #a41e32;
  --color-accent-darker-h: 351;
  --color-accent-darker-s: 69%;
  --color-accent-darker-l: 38%;
  --color-accent-dark: #c2243b;
  --color-accent-dark-h: 351;
  --color-accent-dark-s: 69%;
  --color-accent-dark-l: 45%;
  --color-accent: #da344d;
  --color-accent-h: 351;
  --color-accent-s: 69%;
  --color-accent-l: 53%;
  --color-accent-light: #e0576c;
  --color-accent-light-h: 351;
  --color-accent-light-s: 69%;
  --color-accent-light-l: 61%;
  --color-accent-lighter: #e6798a;
  --color-accent-lighter-h: 351;
  --color-accent-lighter-s: 69%;
  --color-accent-lighter-l: 69%;
  --color-black: #081420;
  --color-black-h: 211;
  --color-black-s: 59%;
  --color-black-l: 8%;
  --color-white: #fff;
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-warning-darker: #da8f16;
  --color-warning-darker-h: 37;
  --color-warning-darker-s: 82%;
  --color-warning-darker-l: 47%;
  --color-warning-dark: #e99e25;
  --color-warning-dark-h: 37;
  --color-warning-dark-s: 82%;
  --color-warning-dark-l: 53%;
  --color-warning: #edaf4a;
  --color-warning-h: 37;
  --color-warning-s: 82%;
  --color-warning-l: 61%;
  --color-warning-light: #f1bf6f;
  --color-warning-light-h: 37;
  --color-warning-light-s: 82%;
  --color-warning-light-l: 69%;
  --color-warning-lighter: #f4ce90;
  --color-warning-lighter-h: 37;
  --color-warning-lighter-s: 82%;
  --color-warning-lighter-l: 76%;
  --color-success-darker: #46a07d;
  --color-success-darker-h: 157;
  --color-success-darker-s: 39%;
  --color-success-darker-l: 45%;
  --color-success-dark: #51b38d;
  --color-success-dark-h: 157;
  --color-success-dark-s: 39%;
  --color-success-dark-l: 51%;
  --color-success: #6abe9e;
  --color-success-h: 157;
  --color-success-s: 39%;
  --color-success-l: 58%;
  --color-success-light: #86cab0;
  --color-success-light-h: 157;
  --color-success-light-s: 39%;
  --color-success-light-l: 66%;
  --color-success-lighter: #a6d8c5;
  --color-success-lighter-h: 157;
  --color-success-lighter-s: 39%;
  --color-success-lighter-l: 75%;
  --color-error-darker: #a41e32;
  --color-error-darker-h: 351;
  --color-error-darker-s: 69%;
  --color-error-darker-l: 38%;
  --color-error-dark: #c2243b;
  --color-error-dark-h: 351;
  --color-error-dark-s: 69%;
  --color-error-dark-l: 45%;
  --color-error: #da344d;
  --color-error-h: 351;
  --color-error-s: 69%;
  --color-error-l: 53%;
  --color-error-light: #e0576c;
  --color-error-light-h: 351;
  --color-error-light-s: 69%;
  --color-error-light-l: 61%;
  --color-error-lighter: #e6798a;
  --color-error-lighter-h: 351;
  --color-error-lighter-s: 69%;
  --color-error-lighter-l: 69%;
  --color-bg-darker: #e4e5e7;
  --color-bg-darker-h: 220;
  --color-bg-darker-s: 6%;
  --color-bg-darker-l: 90%;
  --color-bg-dark: #eff0f1;
  --color-bg-dark-h: 210;
  --color-bg-dark-s: 6%;
  --color-bg-dark-l: 94%;
  --color-bg: #fff;
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-bg-light: #fff;
  --color-bg-light-h: 210;
  --color-bg-light-s: 6%;
  --color-bg-light-l: 100%;
  --color-bg-lighter: #fff;
  --color-bg-lighter-h: 220;
  --color-bg-lighter-s: 6%;
  --color-bg-lighter-l: 100%;
  --color-contrast-lower: #d6d8db;
  --color-contrast-lower-h: 216;
  --color-contrast-lower-s: 6%;
  --color-contrast-lower-l: 85%;
  --color-contrast-low: #a0a5ab;
  --color-contrast-low-h: 216;
  --color-contrast-low-s: 6%;
  --color-contrast-low-l: 65%;
  --color-contrast-medium: #71767f;
  --color-contrast-medium-h: 219;
  --color-contrast-medium-s: 6%;
  --color-contrast-medium-l: 47%;
  --color-contrast-high: #2e3642;
  --color-contrast-high-h: 216;
  --color-contrast-high-s: 18%;
  --color-contrast-high-l: 22%;
  --color-contrast-higher: #081421;
  --color-contrast-higher-h: 211;
  --color-contrast-higher-s: 61%;
  --color-contrast-higher-l: 8%;
}

@supports (--css: variables) {
  [data-theme="dark-1"] {
    --color-primary-darker: #267182;
    --color-primary-darker-h: 191;
    --color-primary-darker-s: 55%;
    --color-primary-darker-l: 33%;
    --color-primary-dark: #2d869a;
    --color-primary-dark-h: 191;
    --color-primary-dark-s: 55%;
    --color-primary-dark-l: 39%;
    --color-primary: #3894a8;
    --color-primary-h: 191;
    --color-primary-s: 50%;
    --color-primary-l: 44%;
    --color-primary-light: #41a3b9;
    --color-primary-light-h: 191;
    --color-primary-light-s: 48%;
    --color-primary-light-l: 49%;
    --color-primary-lighter: #64b2c4;
    --color-primary-lighter-h: 191;
    --color-primary-lighter-s: 45%;
    --color-primary-lighter-l: 58%;
    --color-accent-darker: #a41e32;
    --color-accent-darker-h: 351;
    --color-accent-darker-s: 69%;
    --color-accent-darker-l: 38%;
    --color-accent-dark: #c2243b;
    --color-accent-dark-h: 351;
    --color-accent-dark-s: 69%;
    --color-accent-dark-l: 45%;
    --color-accent: #da344d;
    --color-accent-h: 351;
    --color-accent-s: 69%;
    --color-accent-l: 53%;
    --color-accent-light: #e0576c;
    --color-accent-light-h: 351;
    --color-accent-light-s: 69%;
    --color-accent-light-l: 61%;
    --color-accent-lighter: #e6798a;
    --color-accent-lighter-h: 351;
    --color-accent-lighter-s: 69%;
    --color-accent-lighter-l: 69%;
    --color-black: #081421;
    --color-black-h: 210;
    --color-black-s: 60%;
    --color-black-l: 8%;
    --color-white: #fff;
    --color-white-h: 0;
    --color-white-s: 0%;
    --color-white-l: 100%;
    --color-warning-darker: #da8f16;
    --color-warning-darker-h: 37;
    --color-warning-darker-s: 82%;
    --color-warning-darker-l: 47%;
    --color-warning-dark: #e99e25;
    --color-warning-dark-h: 37;
    --color-warning-dark-s: 82%;
    --color-warning-dark-l: 53%;
    --color-warning: #edaf4a;
    --color-warning-h: 37;
    --color-warning-s: 82%;
    --color-warning-l: 61%;
    --color-warning-light: #f1bf6f;
    --color-warning-light-h: 37;
    --color-warning-light-s: 82%;
    --color-warning-light-l: 69%;
    --color-warning-lighter: #f4ce90;
    --color-warning-lighter-h: 37;
    --color-warning-lighter-s: 82%;
    --color-warning-lighter-l: 76%;
    --color-success-darker: #46a07d;
    --color-success-darker-h: 157;
    --color-success-darker-s: 39%;
    --color-success-darker-l: 45%;
    --color-success-dark: #51b38d;
    --color-success-dark-h: 157;
    --color-success-dark-s: 39%;
    --color-success-dark-l: 51%;
    --color-success: #6abe9e;
    --color-success-h: 157;
    --color-success-s: 39%;
    --color-success-l: 58%;
    --color-success-light: #86cab0;
    --color-success-light-h: 157;
    --color-success-light-s: 39%;
    --color-success-light-l: 66%;
    --color-success-lighter: #a6d8c5;
    --color-success-lighter-h: 157;
    --color-success-lighter-s: 39%;
    --color-success-lighter-l: 75%;
    --color-error-darker: #a41e32;
    --color-error-darker-h: 351;
    --color-error-darker-s: 69%;
    --color-error-darker-l: 38%;
    --color-error-dark: #c2243b;
    --color-error-dark-h: 351;
    --color-error-dark-s: 69%;
    --color-error-dark-l: 45%;
    --color-error: #da344d;
    --color-error-h: 351;
    --color-error-s: 69%;
    --color-error-l: 53%;
    --color-error-light: #e0576c;
    --color-error-light-h: 351;
    --color-error-light-s: 69%;
    --color-error-light-l: 61%;
    --color-error-lighter: #e6798a;
    --color-error-lighter-h: 351;
    --color-error-lighter-s: 69%;
    --color-error-lighter-l: 69%;
    --color-bg-darker: #000;
    --color-bg-darker-h: 215;
    --color-bg-darker-s: 27%;
    --color-bg-darker-l: 0%;
    --color-bg-dark: #05070a;
    --color-bg-dark-h: 215;
    --color-bg-dark-s: 33%;
    --color-bg-dark-l: 3%;
    --color-bg: #081421;
    --color-bg-h: 211;
    --color-bg-s: 61%;
    --color-bg-l: 8%;
    --color-bg-light: #161f2c;
    --color-bg-light-h: 215;
    --color-bg-light-s: 33%;
    --color-bg-light-l: 13%;
    --color-bg-lighter: #1e2734;
    --color-bg-lighter-h: 215;
    --color-bg-lighter-s: 27%;
    --color-bg-lighter-l: 16%;
    --color-contrast-lower: #29313d;
    --color-contrast-lower-h: 216;
    --color-contrast-lower-s: 20%;
    --color-contrast-lower-l: 20%;
    --color-contrast-low: #565c67;
    --color-contrast-low-h: 218;
    --color-contrast-low-s: 9%;
    --color-contrast-low-l: 37%;
    --color-contrast-medium: #848890;
    --color-contrast-medium-h: 220;
    --color-contrast-medium-s: 5%;
    --color-contrast-medium-l: 54%;
    --color-contrast-high: #ccced1;
    --color-contrast-high-h: 216;
    --color-contrast-high-s: 5%;
    --color-contrast-high-l: 81%;
    --color-contrast-higher: #fff;
    --color-contrast-higher-h: 0;
    --color-contrast-higher-s: 0%;
    --color-contrast-higher-l: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [data-theme="light-1"] {
    --color-primary-darker: #020508;
    --color-primary-darker-h: 210;
    --color-primary-darker-s: 60%;
    --color-primary-darker-l: 2%;
    --color-primary-dark: #060f18;
    --color-primary-dark-h: 210;
    --color-primary-dark-s: 60%;
    --color-primary-dark-l: 6%;
    --color-primary: #081421;
    --color-primary-h: 210;
    --color-primary-s: 60%;
    --color-primary-l: 8%;
    --color-primary-light: #0c1f31;
    --color-primary-light-h: 210;
    --color-primary-light-s: 60%;
    --color-primary-light-l: 12%;
    --color-primary-lighter: #102941;
    --color-primary-lighter-h: 210;
    --color-primary-lighter-s: 60%;
    --color-primary-lighter-l: 16%;
    --color-accent-darker: #a41e32;
    --color-accent-darker-h: 351;
    --color-accent-darker-s: 69%;
    --color-accent-darker-l: 38%;
    --color-accent-dark: #c2243b;
    --color-accent-dark-h: 351;
    --color-accent-dark-s: 69%;
    --color-accent-dark-l: 45%;
    --color-accent: #da344d;
    --color-accent-h: 351;
    --color-accent-s: 69%;
    --color-accent-l: 53%;
    --color-accent-light: #e0576c;
    --color-accent-light-h: 351;
    --color-accent-light-s: 69%;
    --color-accent-light-l: 61%;
    --color-accent-lighter: #e6798a;
    --color-accent-lighter-h: 351;
    --color-accent-lighter-s: 69%;
    --color-accent-lighter-l: 69%;
    --color-black: #081420;
    --color-black-h: 211;
    --color-black-s: 59%;
    --color-black-l: 8%;
    --color-white: #fff;
    --color-white-h: 0;
    --color-white-s: 0%;
    --color-white-l: 100%;
    --color-warning-darker: #da8f16;
    --color-warning-darker-h: 37;
    --color-warning-darker-s: 82%;
    --color-warning-darker-l: 47%;
    --color-warning-dark: #e99e25;
    --color-warning-dark-h: 37;
    --color-warning-dark-s: 82%;
    --color-warning-dark-l: 53%;
    --color-warning: #edaf4a;
    --color-warning-h: 37;
    --color-warning-s: 82%;
    --color-warning-l: 61%;
    --color-warning-light: #f1bf6f;
    --color-warning-light-h: 37;
    --color-warning-light-s: 82%;
    --color-warning-light-l: 69%;
    --color-warning-lighter: #f4ce90;
    --color-warning-lighter-h: 37;
    --color-warning-lighter-s: 82%;
    --color-warning-lighter-l: 76%;
    --color-success-darker: #46a07d;
    --color-success-darker-h: 157;
    --color-success-darker-s: 39%;
    --color-success-darker-l: 45%;
    --color-success-dark: #51b38d;
    --color-success-dark-h: 157;
    --color-success-dark-s: 39%;
    --color-success-dark-l: 51%;
    --color-success: #6abe9e;
    --color-success-h: 157;
    --color-success-s: 39%;
    --color-success-l: 58%;
    --color-success-light: #86cab0;
    --color-success-light-h: 157;
    --color-success-light-s: 39%;
    --color-success-light-l: 66%;
    --color-success-lighter: #a6d8c5;
    --color-success-lighter-h: 157;
    --color-success-lighter-s: 39%;
    --color-success-lighter-l: 75%;
    --color-error-darker: #a41e32;
    --color-error-darker-h: 351;
    --color-error-darker-s: 69%;
    --color-error-darker-l: 38%;
    --color-error-dark: #c2243b;
    --color-error-dark-h: 351;
    --color-error-dark-s: 69%;
    --color-error-dark-l: 45%;
    --color-error: #da344d;
    --color-error-h: 351;
    --color-error-s: 69%;
    --color-error-l: 53%;
    --color-error-light: #e0576c;
    --color-error-light-h: 351;
    --color-error-light-s: 69%;
    --color-error-light-l: 61%;
    --color-error-lighter: #e6798a;
    --color-error-lighter-h: 351;
    --color-error-lighter-s: 69%;
    --color-error-lighter-l: 69%;
    --color-bg-darker: #d7a989;
    --color-bg-darker-h: 25;
    --color-bg-darker-s: 49%;
    --color-bg-darker-l: 69%;
    --color-bg-dark: #e3b392;
    --color-bg-dark-h: 25;
    --color-bg-dark-s: 59%;
    --color-bg-dark-l: 73%;
    --color-bg: #f1be98;
    --color-bg-h: 26;
    --color-bg-s: 76%;
    --color-bg-l: 77%;
    --color-bg-light: #ebcab2;
    --color-bg-light-h: 25;
    --color-bg-light-s: 59%;
    --color-bg-light-l: 81%;
    --color-bg-lighter: #ebd6c6;
    --color-bg-lighter-h: 25;
    --color-bg-lighter-s: 49%;
    --color-bg-lighter-l: 85%;
    --color-contrast-lower: #cba186;
    --color-contrast-lower-h: 24;
    --color-contrast-lower-s: 40%;
    --color-contrast-lower-l: 66%;
    --color-contrast-low: #997d6c;
    --color-contrast-low-h: 23;
    --color-contrast-low-s: 18%;
    --color-contrast-low-l: 51%;
    --color-contrast-medium: #6d5b55;
    --color-contrast-medium-h: 15;
    --color-contrast-medium-s: 12%;
    --color-contrast-medium-l: 38%;
    --color-contrast-high: #2f2d34;
    --color-contrast-high-h: 257;
    --color-contrast-high-s: 7%;
    --color-contrast-high-l: 19%;
    --color-contrast-higher: #081421;
    --color-contrast-higher-h: 211;
    --color-contrast-higher-s: 61%;
    --color-contrast-higher-l: 8%;
  }

  [data-theme="light-2"] {
    --color-primary-darker: #020508;
    --color-primary-darker-h: 211;
    --color-primary-darker-s: 61%;
    --color-primary-darker-l: 2%;
    --color-primary-dark: #060f19;
    --color-primary-dark-h: 211;
    --color-primary-dark-s: 61%;
    --color-primary-dark-l: 6%;
    --color-primary: #081421;
    --color-primary-h: 211;
    --color-primary-s: 61%;
    --color-primary-l: 8%;
    --color-primary-light: #0c1e31;
    --color-primary-light-h: 211;
    --color-primary-light-s: 61%;
    --color-primary-light-l: 12%;
    --color-primary-lighter: #102842;
    --color-primary-lighter-h: 211;
    --color-primary-lighter-s: 61%;
    --color-primary-lighter-l: 16%;
    --color-accent-darker: #a41e32;
    --color-accent-darker-h: 351;
    --color-accent-darker-s: 69%;
    --color-accent-darker-l: 38%;
    --color-accent-dark: #c2243b;
    --color-accent-dark-h: 351;
    --color-accent-dark-s: 69%;
    --color-accent-dark-l: 45%;
    --color-accent: #da344d;
    --color-accent-h: 351;
    --color-accent-s: 69%;
    --color-accent-l: 53%;
    --color-accent-light: #e0576c;
    --color-accent-light-h: 351;
    --color-accent-light-s: 69%;
    --color-accent-light-l: 61%;
    --color-accent-lighter: #e6798a;
    --color-accent-lighter-h: 351;
    --color-accent-lighter-s: 69%;
    --color-accent-lighter-l: 69%;
    --color-black: #081421;
    --color-black-h: 210;
    --color-black-s: 60%;
    --color-black-l: 8%;
    --color-white: #fff;
    --color-white-h: 0;
    --color-white-s: 0%;
    --color-white-l: 100%;
    --color-warning-darker: #da8f16;
    --color-warning-darker-h: 37;
    --color-warning-darker-s: 82%;
    --color-warning-darker-l: 47%;
    --color-warning-dark: #e99e25;
    --color-warning-dark-h: 37;
    --color-warning-dark-s: 82%;
    --color-warning-dark-l: 53%;
    --color-warning: #edaf4a;
    --color-warning-h: 37;
    --color-warning-s: 82%;
    --color-warning-l: 61%;
    --color-warning-light: #f1bf6f;
    --color-warning-light-h: 37;
    --color-warning-light-s: 82%;
    --color-warning-light-l: 69%;
    --color-warning-lighter: #f4ce90;
    --color-warning-lighter-h: 37;
    --color-warning-lighter-s: 82%;
    --color-warning-lighter-l: 76%;
    --color-success-darker: #46a07d;
    --color-success-darker-h: 157;
    --color-success-darker-s: 39%;
    --color-success-darker-l: 45%;
    --color-success-dark: #51b38d;
    --color-success-dark-h: 157;
    --color-success-dark-s: 39%;
    --color-success-dark-l: 51%;
    --color-success: #6abe9e;
    --color-success-h: 157;
    --color-success-s: 39%;
    --color-success-l: 58%;
    --color-success-light: #86cab0;
    --color-success-light-h: 157;
    --color-success-light-s: 39%;
    --color-success-light-l: 66%;
    --color-success-lighter: #a6d8c5;
    --color-success-lighter-h: 157;
    --color-success-lighter-s: 39%;
    --color-success-lighter-l: 75%;
    --color-error-darker: #a41e32;
    --color-error-darker-h: 351;
    --color-error-darker-s: 69%;
    --color-error-darker-l: 38%;
    --color-error-dark: #c2243b;
    --color-error-dark-h: 351;
    --color-error-dark-s: 69%;
    --color-error-dark-l: 45%;
    --color-error: #da344d;
    --color-error-h: 351;
    --color-error-s: 69%;
    --color-error-l: 53%;
    --color-error-light: #e0576c;
    --color-error-light-h: 351;
    --color-error-light-s: 69%;
    --color-error-light-l: 61%;
    --color-error-lighter: #e6798a;
    --color-error-lighter-h: 351;
    --color-error-lighter-s: 69%;
    --color-error-lighter-l: 69%;
    --color-bg-darker: #96a29c;
    --color-bg-darker-h: 153;
    --color-bg-darker-s: 6%;
    --color-bg-darker-l: 61%;
    --color-bg-dark: #9ea9a3;
    --color-bg-dark-h: 147;
    --color-bg-dark-s: 6%;
    --color-bg-dark-l: 64%;
    --color-bg: #a8b3ab;
    --color-bg-h: 136;
    --color-bg-s: 7%;
    --color-bg-l: 68%;
    --color-bg-light: #b3bcb7;
    --color-bg-light-h: 147;
    --color-bg-light-s: 6%;
    --color-bg-light-l: 72%;
    --color-bg-lighter: #bbc3c0;
    --color-bg-lighter-h: 153;
    --color-bg-lighter-s: 6%;
    --color-bg-lighter-l: 75%;
    --color-contrast-lower: #8f9995;
    --color-contrast-lower-h: 156;
    --color-contrast-lower-s: 5%;
    --color-contrast-lower-l: 58%;
    --color-contrast-low: #6d7878;
    --color-contrast-low-h: 185;
    --color-contrast-low-s: 5%;
    --color-contrast-low-l: 45%;
    --color-contrast-medium: #4d575c;
    --color-contrast-medium-h: 200;
    --color-contrast-medium-s: 9%;
    --color-contrast-medium-l: 33%;
    --color-contrast-high: #242e38;
    --color-contrast-high-h: 210;
    --color-contrast-high-s: 22%;
    --color-contrast-high-l: 18%;
    --color-contrast-higher: #081421;
    --color-contrast-higher-h: 211;
    --color-contrast-higher-s: 61%;
    --color-contrast-higher-l: 8%;
  }

  [data-theme="dark-2"] {
    --color-primary-darker: #2a6f7e;
    --color-primary-darker-h: 191;
    --color-primary-darker-s: 50%;
    --color-primary-darker-l: 33%;
    --color-primary-dark: #328395;
    --color-primary-dark-h: 191;
    --color-primary-dark-s: 50%;
    --color-primary-dark-l: 39%;
    --color-primary: #3894a8;
    --color-primary-h: 191;
    --color-primary-s: 50%;
    --color-primary-l: 44%;
    --color-primary-light: #3ea5bb;
    --color-primary-light-h: 191;
    --color-primary-light-s: 50%;
    --color-primary-light-l: 49%;
    --color-primary-lighter: #5eb6c9;
    --color-primary-lighter-h: 191;
    --color-primary-lighter-s: 50%;
    --color-primary-lighter-l: 58%;
    --color-accent-darker: #a41e32;
    --color-accent-darker-h: 351;
    --color-accent-darker-s: 69%;
    --color-accent-darker-l: 38%;
    --color-accent-dark: #c2243b;
    --color-accent-dark-h: 351;
    --color-accent-dark-s: 69%;
    --color-accent-dark-l: 45%;
    --color-accent: #da344d;
    --color-accent-h: 351;
    --color-accent-s: 69%;
    --color-accent-l: 53%;
    --color-accent-light: #e0576c;
    --color-accent-light-h: 351;
    --color-accent-light-s: 69%;
    --color-accent-light-l: 61%;
    --color-accent-lighter: #e6798a;
    --color-accent-lighter-h: 351;
    --color-accent-lighter-s: 69%;
    --color-accent-lighter-l: 69%;
    --color-black: #081421;
    --color-black-h: 210;
    --color-black-s: 60%;
    --color-black-l: 8%;
    --color-white: #fff;
    --color-white-h: 0;
    --color-white-s: 0%;
    --color-white-l: 100%;
    --color-warning-darker: #da8f16;
    --color-warning-darker-h: 37;
    --color-warning-darker-s: 82%;
    --color-warning-darker-l: 47%;
    --color-warning-dark: #e99e25;
    --color-warning-dark-h: 37;
    --color-warning-dark-s: 82%;
    --color-warning-dark-l: 53%;
    --color-warning: #edaf4a;
    --color-warning-h: 37;
    --color-warning-s: 82%;
    --color-warning-l: 61%;
    --color-warning-light: #f1bf6f;
    --color-warning-light-h: 37;
    --color-warning-light-s: 82%;
    --color-warning-light-l: 69%;
    --color-warning-lighter: #f4ce90;
    --color-warning-lighter-h: 37;
    --color-warning-lighter-s: 82%;
    --color-warning-lighter-l: 76%;
    --color-success-darker: #46a07d;
    --color-success-darker-h: 157;
    --color-success-darker-s: 39%;
    --color-success-darker-l: 45%;
    --color-success-dark: #51b38d;
    --color-success-dark-h: 157;
    --color-success-dark-s: 39%;
    --color-success-dark-l: 51%;
    --color-success: #6abe9e;
    --color-success-h: 157;
    --color-success-s: 39%;
    --color-success-l: 58%;
    --color-success-light: #86cab0;
    --color-success-light-h: 157;
    --color-success-light-s: 39%;
    --color-success-light-l: 66%;
    --color-success-lighter: #a6d8c5;
    --color-success-lighter-h: 157;
    --color-success-lighter-s: 39%;
    --color-success-lighter-l: 75%;
    --color-error-darker: #a41e32;
    --color-error-darker-h: 351;
    --color-error-darker-s: 69%;
    --color-error-darker-l: 38%;
    --color-error-dark: #c2243b;
    --color-error-dark-h: 351;
    --color-error-dark-s: 69%;
    --color-error-dark-l: 45%;
    --color-error: #da344d;
    --color-error-h: 351;
    --color-error-s: 69%;
    --color-error-l: 53%;
    --color-error-light: #e0576c;
    --color-error-light-h: 351;
    --color-error-light-s: 69%;
    --color-error-light-l: 61%;
    --color-error-lighter: #e6798a;
    --color-error-lighter-h: 351;
    --color-error-lighter-s: 69%;
    --color-error-lighter-l: 69%;
    --color-bg-darker: #1e1f29;
    --color-bg-darker-h: 237;
    --color-bg-darker-s: 15%;
    --color-bg-darker-l: 14%;
    --color-bg-dark: #242533;
    --color-bg-dark-h: 235;
    --color-bg-dark-s: 17%;
    --color-bg-dark-l: 17%;
    --color-bg: #2a2c41;
    --color-bg-h: 235;
    --color-bg-s: 21%;
    --color-bg-l: 21%;
    --color-bg-light: #35374b;
    --color-bg-light-h: 235;
    --color-bg-light-s: 17%;
    --color-bg-light-l: 25%;
    --color-bg-lighter: #3d3e52;
    --color-bg-lighter-h: 237;
    --color-bg-lighter-s: 15%;
    --color-bg-lighter-l: 28%;
    --color-contrast-lower: #47485c;
    --color-contrast-lower-h: 237;
    --color-contrast-lower-s: 13%;
    --color-contrast-lower-l: 32%;
    --color-contrast-low: #6d6e7e;
    --color-contrast-low-h: 236;
    --color-contrast-low-s: 7%;
    --color-contrast-low-l: 46%;
    --color-contrast-medium: #9696a2;
    --color-contrast-medium-h: 240;
    --color-contrast-medium-s: 6%;
    --color-contrast-medium-l: 61%;
    --color-contrast-high: #d6d6db;
    --color-contrast-high-h: 240;
    --color-contrast-high-s: 6%;
    --color-contrast-high-l: 85%;
    --color-contrast-higher: #fff;
    --color-contrast-higher-h: 0;
    --color-contrast-higher-s: 0%;
    --color-contrast-higher-l: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media (min-width: 64rem) {
    :root {
      --space-unit: 1.25rem;
    }
  }
}

:root {
  --radius: .25em;
}

.text-style-1:before {
  width: 1em;
  height: 1px;
  margin-right: var(--space-xxxs);
  content: "";
  vertical-align: middle;
  background-color: currentColor;
  display: inline-block;
}

.sew-shack__logo {
  width: var(--space-xxxl);
  height: var(--space-lg);
}

@media (min-width: 64rem) {
  .sew-shack__logo {
    height: var(--space-xl);
  }
}

.rotate-10 {
  --rotate: -10deg;
}

.bg-decoration-v2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 67%;
}

.customizer--height {
  height: 100vh;
}

.main-footer__ata-logo {
  width: var(--width-md, 2rem);
  filter: grayscale();
  opacity: .75;
  transition: all .3s ease-in-out;
}

.main-footer__ata-logo:hover {
  filter: grayscale(0%);
  opacity: 1;
}

.version-badge {
  height: var(--space-unit);
  padding: 0 var(--space-xxs);
  border-radius: var(--radius-md);
  background-color: var(--color-success);
  color: var(--color-white);
  font-size: var(--text-xs);
  line-height: var(--space-unit);
  margin-left: auto;
}

.content-visibility-auto {
  contain-intrinsic-size: 1px;
  content-visibility: auto;
}

:root {
  --font-primary: Inter, sans-serif;
  --font-secondary: "Playfair Display", Georgia, serif;
  --text-base-size: 1rem;
  --text-scale-ratio: 1.2;
  --text-unit: var(--text-base-size);
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 1;
  --font-secondary-capital-letter: 1;
}

:root, * {
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --text-base-size: 1.25rem;
      --text-scale-ratio: 1.25;
    }
  }
}

body {
  font-family: var(--font-primary);
}

h1, h2, h3, h4 {
  font-weight: 500;
  font-family: var(--font-secondary);
}

.font-primary {
  font-family: var(--font-primary);
}

.font-secondary {
  font-family: var(--font-secondary);
}

mark, code {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), .2);
  color: inherit;
}

.text-component {
  --line-height-multiplier: 1;
  --text-space-y-multiplier: 1;
}

.text-component blockquote {
  border-left: 4px solid var(--color-contrast-low);
  padding-left: 1em;
}

.text-component hr {
  height: 1px;
  background: var(--color-contrast-lower);
}

.text-component figcaption {
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
}

.article {
  --body-line-height: 1.58;
  --text-space-y-multiplier: 1.4;
}

:root {
  --btn-font-size: 1em;
  --btn-padding-x: var(--space-md);
  --btn-padding-y: var(--space-xs);
  --btn-radius: .1em;
}

.btn {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  transition: all .3s;
}

.btn:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .2);
  outline: none;
}

.btn:active {
  transform: translateY(2px);
}

.btn--primary {
  background-color: var(--color-primary);
  box-shadow: 0px 2px 8px hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), .3), 0px 1px 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .1);
  color: var(--color-white);
}

.btn--primary:hover {
  background-color: var(--color-primary-dark);
  box-shadow: 0px 4px 10px hsla(var(--color-primary-darker-h), var(--color-primary-darker-s), var(--color-primary-darker-l), .3), 0px 2px 4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .1);
}

.btn--primary:focus {
  box-shadow: 0px 0px 0px 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .75);
}

.btn--subtle {
  border: 1px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .6);
  color: var(--color-contrast-higher);
  background-color: #0000;
}

.btn--subtle:hover {
  border-color: currentColor;
  box-shadow: inset 0 0 0 1px;
}

.btn--accent {
  background-color: var(--color-accent);
  box-shadow: 0px 2px 8px hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), .3), 0px 1px 1px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .1);
  color: var(--color-white);
}

.btn--accent:hover {
  background-color: var(--color-accent-dark);
  box-shadow: 0px 4px 10px hsla(var(--color-accent-darker-h), var(--color-accent-darker-s), var(--color-accent-darker-l), .3), 0px 2px 4px hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .1);
}

.btn--accent:focus {
  box-shadow: 0px 0px 0px 2px hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), .2);
}

.btn--contrast {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .9);
  color: var(--color-bg);
}

.btn--contrast:hover {
  background-color: var(--color-contrast-higher);
}

.btn--disabled {
  cursor: not-allowed;
  opacity: .6;
}

.btn--xs {
  font-size: .5em;
}

.btn--sm {
  font-size: .8em;
}

.btn--md {
  font-size: 1.2em;
}

.btn--lg {
  font-size: 1.4em;
}

.input-group {
  display: flex;
}

.input-group > * {
  position: relative;
}

.input-group > :not(:last-child):not(:first-child) {
  border-radius: 0;
}

.input-group > :first-child, .input-group > :first-child .select__input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :last-child, .input-group > :last-child .select__input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > :focus, .input-group :focus-within {
  z-index: 1;
}

.input-group__tag {
  padding: 0 var(--space-sm);
  border: 1px solid var(--color-contrast-lower);
  border-radius: var(--radius-md);
  background-color: var(--color-bg);
  color: var(--color-contrast-medium);
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.input-group__tag:first-child {
  border-right-width: 0;
}

.input-group__tag:last-child {
  border-left-width: 0;
}

.input-group .select {
  --select-icon-size: 16px;
  --select-icon-right-margin: var(--space-sm);
  --select-text-icon-gap: var(--space-xxxs);
}

.input-group .select .input-group__tag:focus {
  outline: 2px solid var(--color-primary);
}

.input-merger__input {
  width: 100%;
  background: linear-gradient(to right, transparent calc(100% - 1px), var(--color-contrast-low) 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 50%;
  font-size: 1em;
}

.input-merger__input:not([class^="padding-"]):not([class*=" padding-"]) {
  padding: var(--form-control-padding-y) var(--form-control-padding-x);
}

.input-merger__input:focus {
  outline: none;
}

.input-merger__input:last-of-type {
  background: none;
}

:root {
  --form-control-font-size: 1em;
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0px;
}

.form-control {
  border: 1px solid var(--color-contrast-lower);
  background-color: var(--color-bg);
  transition: all .3s;
}

.form-control::placeholder {
  color: var(--color-contrast-medium);
  opacity: .5;
}

.form-control:focus {
  border-color: var(--color-primary);
  box-shadow: inset 0 0 0 1px var(--color-primary), 0px 0px 0px 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
  outline: none;
}

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);
}

.form-control[aria-invalid="true"]:focus {
  box-shadow: inset 0 0 0 1px var(--color-error), 0px 0px 0px 2px hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), .2);
}

.form-legend {
  margin-bottom: var(--space-xxs);
  color: var(--color-contrast-higher);
  font-size: var(--text-xs);
  line-height: 1.2;
}

.form-label {
  font-size: var(--text-xs);
}

.product-total {
  background-color: var(--color-contrast-high);
  color: var(--color-white);
}

.product-total span {
  font-size: var(--text-sm);
}

.-translate-y-1 {
  transform: translateY(-1px);
}

.-translate-y-2 {
  transform: translateY(-2px);
}

.translate-y-1 {
  transform: translateY(1px);
}

.translate-y-2 {
  transform: translateY(2px);
}

.padding-top-header {
  padding-top: var(--mega-nav-height);
}

.padding-y-header {
  --mega-nav-height: 3rem;
  padding: var(--mega-nav-height);
}

:root, [data-theme="dark"] {
  --color-border-alpha: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .1);
}

.border-alpha {
  border-color: var(--color-border-alpha);
}

:root {
  --accordion-border-width: 1px;
  --accordion-border-color: var(--color-contrast-lower);
  --accordion-icon-size: 1em;
  --accordion-icon-stroke-width: 1.5px;
}

.accordion__item {
  border-bottom-width: var(--accordion-border-width);
  border-style: solid;
  border-color: var(--accordion-border-color);
}

.accordion__item:first-child {
  border-top-width: var(--accordion-border-width);
}

.accordion__header {
  width: 100%;
  text-align: left;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.accordion__header .icon {
  font-size: var(--accordion-icon-size);
}

.accordion__header .icon .icon__group {
  stroke-width: var(--accordion-icon-stroke-width);
}

.accordion__icon-arrow .icon__group, .accordion__icon-arrow-v2 .icon__group, .accordion__icon-plus .icon__group {
  transform-origin: 8px 8px;
  will-change: transform;
}

.accordion__icon-arrow .icon__group > *, .accordion__icon-arrow-v2 .icon__group > *, .accordion__icon-plus .icon__group > * {
  transform-origin: 8px 8px;
}

.accordion__icon-arrow .icon__group > *, .accordion__icon-arrow-v2 .icon__group > * {
  stroke-dasharray: 17;
  transform: translateY(3px);
}

.accordion__icon-arrow .icon__group > :first-child, .accordion__icon-arrow-v2 .icon__group > :first-child, .accordion__icon-arrow .icon__group > :last-child, .accordion__icon-arrow-v2 .icon__group > :last-child {
  stroke-dashoffset: 8.5px;
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group > :first-child {
  transform: translateY(-3px)rotate(-90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow .icon__group > :last-child {
  transform: translateY(-3px)rotate(90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group {
  transform: rotate(-90deg);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group > :first-child, .accordion__item--is-open > .accordion__header > .accordion__icon-arrow-v2 .icon__group :last-child {
  stroke-dashoffset: 0;
  transform: translateY(0);
}

.accordion__item--is-open > .accordion__header > .accordion__icon-plus .icon__group, .accordion__item--is-open > .accordion__header > .accordion__icon-plus .icon__group > :first-child {
  transform: rotate(-90deg);
}

.js .accordion__panel {
  will-change: height;
  display: none;
  transform: translateZ(0);
}

.js .accordion__item--is-open > .accordion__panel {
  display: block;
}

.accordion[data-animation="on"] .accordion__item--is-open .accordion__panel > * {
  animation: accordion-entry-animation .4s var(--ease-out);
}

.accordion[data-animation="on"] .accordion__icon-arrow .icon__group, .accordion[data-animation="on"] .accordion__icon-arrow-v2 .icon__group, .accordion[data-animation="on"] .accordion__icon-plus .icon__group {
  transition: transform .3s var(--ease-out);
}

.accordion[data-animation="on"] .accordion__icon-arrow .icon__group > *, .accordion[data-animation="on"] .accordion__icon-arrow-v2 .icon__group > *, .accordion[data-animation="on"] .accordion__icon-plus .icon__group > * {
  transition: transform .3s, stroke-dashoffset .3s;
  transition-timing-function: var(--ease-out);
}

@keyframes accordion-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.adapt-nav {
  position: relative;
}

.adapt-nav__list {
  align-items: center;
  display: flex;
}

.adapt-nav__item {
  margin-right: var(--space-xs);
  flex-shrink: 0;
}

.adapt-nav__link {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--space-xxs) var(--space-xs);
  color: var(--color-contrast-high);
  border-radius: .1em;
  text-decoration: none;
  transition: all .2s;
  display: block;
}

.adapt-nav__link:hover {
  background-color: var(--color-contrast-lower);
}

.adapt-nav__link[aria-current="page"] {
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
}

.adapt-nav__item--hidden {
  visibility: hidden;
}

.js .adapt-nav {
  opacity: 0;
}

.js .adapt-nav--is-visible {
  opacity: 1;
}

.js .adapt-nav__item--more {
  margin-right: 0;
  position: relative;
}

.js .adapt-nav__btn {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.js .adapt-nav__btn .icon {
  width: 16px;
  height: 16px;
  display: block;
}

.js .adapt-nav__btn:hover {
  background-color: var(--color-contrast-lower);
}

.js .adapt-nav__btn:active {
  background-color: var(--color-contrast-low);
}

.js .adapt-nav__dropdown {
  visibility: hidden;
  z-index: var(--z-index-popover);
  width: 180px;
  padding: var(--space-xxs) 0;
  border-radius: var(--radius-md);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  transition: visibility 0s .2s, opacity .2s;
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
}

.js .adapt-nav__dropdown--is-visible {
  visibility: visible;
  opacity: 1;
  transition: visibility, opacity .2s;
}

.js .adapt-nav__dropdown-link {
  padding: var(--space-xxs) var(--space-sm);
  color: var(--color-contrast-high);
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  transition: all .2s;
  display: block;
  overflow: hidden;
}

.js .adapt-nav__dropdown-link:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .075);
}

.js .adapt-nav__dropdown-link[aria-current="page"] {
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
}

html:not(.js) .adapt-nav {
  justify-content: center;
  display: flex;
  overflow: hidden;
}

html:not(.js) .adapt-nav__list {
  justify-content: left;
  overflow: auto;
}

.text-anim__wrapper {
  display: inline-block;
  position: relative;
}

.text-anim__word {
  z-index: 1;
  white-space: nowrap;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.text-anim__word--in {
  z-index: 2;
  position: relative;
}

.text-anim--rotate {
  --text-anim-duration: .85s;
  --text-anim-pause: 2.5s;
}

.text-anim--rotate .text-anim__wrapper {
  perspective: 300px;
}

.text-anim--rotate .text-anim__word {
  transform-origin: 50% 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  transition: opacity calc(var(--text-anim-duration) / 3) var(--ease-out), transform var(--text-anim-duration) var(--ease-out-back);
  transform: rotateX(-180deg);
}

.text-anim--rotate .text-anim__word--in {
  opacity: 1;
  transform: rotateX(0);
}

.text-anim--rotate .text-anim__word--out {
  opacity: 0;
  transition: opacity calc(var(--text-anim-duration) / 6) var(--ease-out), transform var(--text-anim-duration) var(--ease-out-back);
  transform: rotateX(180deg);
}

.text-anim--loader {
  --text-anim-duration: 2.5s;
  --text-anim-pause: 0s;
}

.text-anim--loader .text-anim__word {
  color: #0000;
  transition: color .2s;
}

.text-anim--loader .text-anim__word:after {
  width: 100%;
  height: 2px;
  transform-origin: 0;
  content: "";
  transition: transform var(--text-anim-duration) var(--ease-out);
  background-color: currentColor;
  position: absolute;
  bottom: -2px;
  left: 0;
  transform: translateY(100%)scaleX(0);
}

.text-anim--loader .text-anim__word--in {
  color: currentColor;
}

.text-anim--loader .text-anim__word--in:after {
  transform: translateY(100%)scaleX(1);
}

.text-anim--loader .text-anim__word--out {
  color: #0000;
}

.text-anim--loader .text-anim__word--out:after {
  transition: none;
  transform: translateY(100%)scaleX(0);
}

.text-anim--slide {
  --text-anim-duration: .5s;
  --text-anim-pause: 2.5s;
}

.text-anim--slide .text-anim__wrapper {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  padding: .1em 0;
}

.text-anim--slide .text-anim__word {
  height: 100%;
  transform: translateY(-100%);
}

.text-anim--slide .text-anim__word--in, .text-anim--slide .text-anim__word--out {
  animation-duration: var(--text-anim-duration);
  animation-timing-function: var(--ease-out-back);
}

.text-anim--slide .text-anim__word--in {
  animation-name: text-anim-slide-in;
  animation-fill-mode: forwards;
}

.text-anim--slide .text-anim__word--out {
  animation-name: text-anim-slide-out;
}

@keyframes text-anim-slide-in {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes text-anim-slide-out {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}

.text-anim--zoom {
  --text-anim-duration: .85s;
  --text-anim-pause: 2.5s;
}

.text-anim--zoom .text-anim__wrapper {
  perspective: 300px;
}

.text-anim--zoom .text-anim__word {
  opacity: 0;
  transition: opacity var(--text-anim-duration), transform var(--text-anim-duration) var(--ease-out);
  transform: translateZ(100px);
}

.text-anim--zoom .text-anim__word--in {
  opacity: 1;
  transform: translateZ(0);
}

.text-anim--zoom .text-anim__word--out {
  opacity: 0;
  transform: translateZ(-100px);
}

.text-anim--push {
  --text-anim-duration: .65s;
  --text-anim-pause: 2.5s;
}

.text-anim--push .text-anim__word {
  opacity: 0;
  transition: none;
  transform: translateX(-100px);
}

.text-anim--push .text-anim__word--in, .text-anim--push .text-anim__word--out {
  transition: opacity var(--text-anim-duration), transform var(--text-anim-duration) var(--ease-out-back);
}

.text-anim--push .text-anim__word--in {
  opacity: 1;
  transform: translateX(0);
}

.text-anim--push .text-anim__word--out {
  opacity: 0;
  transform: translateX(100px);
}

.text-anim--clip {
  --text-anim-duration: .7s;
  --text-anim-pause: 2s;
  --text-anim-border-width: 2px;
}

.text-anim--clip .text-anim__wrapper {
  vertical-align: top;
  overflow: hidden;
}

.text-anim--clip .text-anim__wrapper:after {
  width: var(--text-anim-border-width);
  height: 1.4em;
  background-color: var(--color-accent);
  content: "";
  position: absolute;
  top: calc(50% - .7em);
  right: 0;
}

.text-anim--clip .text-anim__wrapper--pulse:after {
  animation: .8s infinite text-anim-pulse;
}

.text-anim--clip .text-anim__word {
  padding-right: var(--text-anim-border-width);
  opacity: 0;
}

.text-anim--clip .text-anim__word--in {
  opacity: 1;
}

@keyframes text-anim-pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.articles-v3__img {
  border-radius: .1em;
  transition: opacity .3s;
  display: block;
  overflow: hidden;
}

.articles-v3__img img {
  width: 100%;
  display: block;
}

.articles-v3__img:hover {
  opacity: .85;
}

.articles-v3__headline {
  font-size: var(--text-xl);
}

.articles-v3__headline a {
  color: var(--color-contrast-higher);
  text-decoration: none;
}

.articles-v3__headline a:hover {
  text-decoration: underline;
}

.articles-v3__author {
  grid-gap: var(--space-xs);
  margin-top: var(--space-md);
  grid-template-columns: 3em 1fr;
  align-items: center;
  display: grid;
}

.articles-v3__author-img {
  width: 3em;
  height: 3em;
  transition: transform .3s var(--bounce);
  border-radius: 50%;
  display: block;
  overflow: hidden;
}

.articles-v3__author-img img {
  width: inherit;
  height: inherit;
  display: block;
}

.articles-v3__author-img:hover {
  transform: scale(1.1);
}

.articles-v3__author-name {
  color: var(--color-contrast-higher);
  font-weight: bold;
  text-decoration: none;
}

.articles-v3__author-name:hover {
  text-decoration: underline;
}

.story-v2 {
  padding: var(--space-lg) 0;
  border-bottom: 3px solid var(--color-contrast-lower);
}

.story-v2__meta {
  margin-bottom: var(--space-sm);
  font-size: var(--text-sm);
}

.story-v2__meta a {
  color: var(--color-contrast-high);
  transition: all .2s;
}

.story-v2__meta a:hover {
  color: var(--color-primary);
}

.story-v2__meta span[role="separator"] {
  width: 6px;
  height: 6px;
  margin: 0 var(--space-xxxs);
  background-color: var(--color-contrast-low);
  vertical-align: middle;
  border-radius: 50%;
  display: inline-block;
}

.story-v2__headline a {
  background-image: linear-gradient(transparent 50%, hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2) 50%);
  color: var(--color-contrast-higher);
  transition: background-size .3s var(--ease-in-out);
  will-change: background-size;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  text-decoration: underline;
}

.story-v2__headline a:hover {
  background-size: 100% 100%;
}

.story-v2__excerpt {
  color: var(--color-contrast-medium);
}

.story-v2__img {
  transition: opacity .2s;
  display: block;
}

.story-v2__img:hover {
  opacity: .85;
}

.story-v2__img img {
  width: 100%;
  display: block;
}

@media (min-width: 64rem) {
  .story-v2--featured {
    padding-top: 0;
  }

  .story-v2--featured .text-component {
    --text-space-y-multiplier: 1.5;
  }

  .story-v2--featured .story-v2__headline {
    font-size: var(--text-xxxl);
  }

  .story-v2--featured .story-v2__excerpt {
    max-width: 70%;
    margin-left: auto;
  }
}

.t-article-v4__divider {
  align-items: center;
  display: flex;
}

.t-article-v4__divider span {
  width: 10px;
  height: 10px;
  margin: 0 var(--space-xs);
  background-color: var(--color-contrast-lower);
  display: block;
  transform: rotate(45deg);
}

.t-article-v4__divider:before, .t-article-v4__divider:after {
  width: auto;
  height: 1px;
  background-color: var(--color-contrast-lower);
  content: "";
  flex-grow: 1;
  display: block;
}

:root {
  --author-img-size: 4em;
}

.author {
  grid-gap: var(--space-sm);
  grid-template-columns: var(--author-img-size) 1fr;
  display: grid;
}

.author__img-wrapper {
  width: var(--author-img-size);
  height: var(--author-img-size);
  transition: transform .2s var(--bounce);
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
}

.author__img-wrapper:hover {
  transform: scale(1.1);
}

.author__img-wrapper img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  display: block;
}

.author__content a {
  color: inherit;
}

.author__content a:hover {
  color: var(--color-primary);
}

.author--meta {
  --author-img-size: 3em;
  grid-gap: var(--space-xs);
  align-items: center;
}

.author--minimal {
  --author-img-size: 2.4em;
  grid-gap: var(--space-xxs);
  align-items: center;
}

.author--featured {
  --author-img-size: 6em;
  text-align: center;
  grid-template-columns: 1fr;
  justify-content: center;
}

.author--featured .author__img-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.author__social {
  padding: var(--space-xs);
  background: var(--color-contrast-lower);
  border-radius: 50%;
  transition: all .2s;
  display: inline-block;
}

.author__social .icon {
  color: var(--color-contrast-high);
  font-size: 16px;
  transition: color .2s;
  display: block;
}

.author__social:hover {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
}

.author__social:hover .icon {
  color: var(--color-primary);
}

.bg-decoration-v2 {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bg-decoration-v2__svg {
  width: 134%;
  min-width: 1280px;
  max-width: 1920px;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.badge {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  padding: var(--space-xxxxs) var(--space-xxs);
  border-radius: 0;
  border-radius: var(--radius-md);
  background-color: #0000;
  background-color: var(--color-contrast-low);
  color: inherit;
  line-height: inherit;
  border: 0;
  align-items: center;
  display: inline-flex;
}

.badge--outline {
  border: 1px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .25);
  background-color: #0000;
}

.badge--contrast-higher {
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
}

.badge--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.badge--primary-light {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
  color: var(--color-contrast-higher);
}

.badge--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.badge--accent-light {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), .2);
  color: var(--color-contrast-higher);
}

.badge--error {
  background-color: var(--color-error);
  color: var(--color-white);
}

.badge--error-light {
  background-color: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), .2);
  color: var(--color-contrast-higher);
}

.badge--success {
  background-color: var(--color-success);
  color: var(--color-white);
}

.badge--success-light {
  background-color: hsla(var(--color-success-h), var(--color-success-s), var(--color-success-l), .2);
  color: var(--color-contrast-higher);
}

.badge--warning {
  background-color: var(--color-warning);
  color: var(--color-white);
}

.badge--warning-light {
  background-color: hsla(var(--color-warning-h), var(--color-warning-s), var(--color-warning-l), .2);
  color: var(--color-contrast-higher);
}

.banner {
  z-index: 1;
  box-shadow: var(--shadow-sm);
  transition: all .3s;
  position: relative;
}

.banner:hover {
  box-shadow: var(--shadow-md);
}

.banner__link {
  transform-origin: 0 100%;
  color: inherit;
  transition: .3s var(--ease-out);
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.banner__link i {
  z-index: 2;
  transition: .3s var(--ease-out);
  display: inline-block;
  position: relative;
}

.banner__link:after {
  z-index: 1;
  width: 100%;
  height: 2px;
  content: "";
  opacity: .15;
  transition: .3s var(--ease-out);
  background-color: currentColor;
  position: absolute;
  bottom: 0;
  left: 0;
}

.banner__figure {
  height: 0;
  transition: .3s var(--ease-out);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 50%;
}

.banner:hover .banner__link {
  transform: scale(1.3);
}

.banner:hover .banner__link i {
  transform: scale(.7);
}

.banner:hover .banner__link:after {
  height: 100%;
}

.banner:hover .banner__figure {
  transform: scale(1.05);
}

@media (min-width: 64rem) {
  .banner__figure {
    height: 100%;
    -webkit-clip-path: polygon(100px 0%, 100% 0%, 100% 100%, 50px 100%);
    clip-path: polygon(100px 0%, 100% 0%, 100% 100%, 50px 100%);
    padding-bottom: 0;
  }

  .banner--invert .banner__figure {
    -webkit-clip-path: polygon(0% 0%, calc(100% - 100px) 0%, calc(100% - 50px) 100%, 0% 100%);
    clip-path: polygon(0% 0%, calc(100% - 100px) 0%, calc(100% - 50px) 100%, 0% 100%);
  }

  .banner--invert .banner__link {
    transform-origin: 100% 100%;
  }

  .banner:hover .banner__figure {
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.breadcrumbs__item {
  align-items: center;
  display: inline-flex;
}

:root {
  --btns-button-radius: .1em;
  --btns-button-padding-x: var(--space-sm);
  --btns-button-padding-y: var(--space-sm);
}

.btns {
  display: inline-flex;
}

.btns > * {
  display: inline-block;
}

.btns > :first-child, .btns :first-child .btns__btn {
  border-radius: var(--btns-button-radius) 0 0 var(--btns-button-radius);
}

.btns > :last-child, .btns :last-child .btns__btn {
  border-radius: 0 var(--btns-button-radius) var(--btns-button-radius) 0;
}

.btns[class*="gap-xxxxs"], .btns[class*="gap-xxxs"], .btns[class*="gap-xxs"], .btns[class*="gap-xs"], .btns[class*="gap-sm"], .btns[class*="gap-md"], .btns[class*="gap-lg"], .btns[class*="gap-xl"], .btns[class*="gap-xxl"], .btns[class*="gap-xxxl"], .btns[class*="gap-xxxxl"] {
  flex-wrap: wrap;
}

.btns[class*="gap-xxxxs"] .btns__btn, .btns[class*="gap-xxxs"] .btns__btn, .btns[class*="gap-xxs"] .btns__btn, .btns[class*="gap-xs"] .btns__btn, .btns[class*="gap-sm"] .btns__btn, .btns[class*="gap-md"] .btns__btn, .btns[class*="gap-lg"] .btns__btn, .btns[class*="gap-xl"] .btns__btn, .btns[class*="gap-xxl"] .btns__btn, .btns[class*="gap-xxxl"] .btns__btn, .btns[class*="gap-xxxxl"] .btns__btn {
  border-radius: var(--btns-button-radius) !important;
}

.btns__btn {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  padding: var(--space-xs) var(--space-sm);
  background-color: #0000;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .1);
  color: inherit;
  line-height: inherit;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
  position: relative;
}

.btns__btn:hover:not(.btns__btn--selected) {
  background-color: hsl(var(--color-contrast-lower-h), var(--color-contrast-lower-s), calc(var(--color-contrast-lower-l) * .98) );
}

.btns__btn:focus {
  z-index: 1;
  box-shadow: 0 0 0 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
  outline: none;
}

.btns__btn--selected, .btns--radio input[type="radio"]:checked + label, .btns--radio input[type="checkbox"]:checked + label, .btns--checkbox input[type="radio"]:checked + label, .btns--checkbox input[type="checkbox"]:checked + label {
  background-color: var(--color-contrast-higher);
  box-shadow: var(--shadow-sm);
  color: var(--color-bg);
}

.btns__btn--disabled {
  cursor: not-allowed;
  opacity: .7;
  text-decoration: line-through;
}

.btns--radio > *, .btns--checkbox > * {
  position: relative;
}

.btns--radio input[type="radio"], .btns--radio input[type="checkbox"], .btns--checkbox input[type="radio"], .btns--checkbox input[type="checkbox"] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.btns--radio input[type="radio"] + label, .btns--radio input[type="checkbox"] + label, .btns--checkbox input[type="radio"] + label, .btns--checkbox input[type="checkbox"] + label {
  -webkit-user-select: none;
  user-select: none;
}

.btns--radio input[type="radio"]:focus + label, .btns--radio input[type="checkbox"]:focus + label, .btns--checkbox input[type="radio"]:focus + label, .btns--checkbox input[type="checkbox"]:focus + label {
  z-index: 1;
  box-shadow: 0 0 0 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
}

.btns__btn--icon {
  padding: var(--btns-button-padding-x);
}

.btns__btn--icon .icon {
  color: inherit;
  display: block;
}

.card {
  border-radius: var(--radius-md);
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  overflow: hidden;
}

.card__img img {
  width: 100%;
  display: block;
}

.card__content {
  padding: var(--space-xs);
}

.card--is-link {
  will-change: box-shadow;
  transition: box-shadow .2s;
}

.card--is-link:hover {
  box-shadow: var(--inner-glow), var(--shadow-md);
}

.card--is-link:hover .card__img:after {
  opacity: 1;
}

.card--is-link .card__img {
  position: relative;
}

.card--is-link .card__img:after {
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .6);
  content: "";
  opacity: 0;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.card__link {
  color: inherit;
  text-decoration: none;
  display: block;
}

.card__link-icon {
  z-index: 1;
  width: 64px;
  height: 64px;
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), .9);
  opacity: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: opacity .2s;
  display: flex;
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}

.card__link-icon .icon {
  width: 32px;
  height: 32px;
  color: var(--color-black);
  display: block;
}

.card--is-link:hover .card__link-icon {
  opacity: 1;
}

.card-v8 {
  text-decoration: none;
  transition: all .2s;
  display: block;
  overflow: hidden;
}

.card-v8 img {
  width: 100%;
  display: block;
}

.card-v8:hover {
  box-shadow: var(--shadow-md);
}

.card-v8:hover .card-v8__title {
  background-size: 100% 100%;
}

.card-v8__title {
  background-image: linear-gradient(transparent 50%, hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2) 50%);
  transition: background-size .3s var(--ease-in-out);
  will-change: background-size;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  text-decoration: none;
}

.card-v9 {
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-contrast-lower);
  box-shadow: var(--shadow-sm);
  color: inherit;
  text-decoration: none;
  transition: all .3s;
  display: block;
  position: relative;
  overflow: hidden;
}

.card-v9:hover {
  box-shadow: var(--shadow-md);
}

.card-v9__content {
  z-index: 2;
  height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card-v9__btn {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--space-xs) var(--space-sm);
  display: inline-block;
  position: relative;
}

.card-v9__btn i {
  z-index: 2;
  color: var(--color-bg);
  opacity: 0;
  transition: opacity .3s;
  position: relative;
}

.card-v9__btn:after {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .8);
  content: "";
  opacity: 0;
  transition: opacity .3s, transform .3s var(--ease-out);
  border-radius: .1em;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(25%);
}

.card-v9:hover .card-v9__btn i {
  opacity: 1;
}

.card-v9:hover .card-v9__btn:after {
  opacity: 1;
  transform: translateY(0);
}

.card-v9--overlay-bg:before {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), .7);
  content: "";
  transition: background-color .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.card-v9--overlay-bg:hover:before {
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), .85);
}

:root {
  --choice-accordion-border-width: 1px;
  --choice-accordion-border-radius: 0px;
  --choice-accordion-input-size: 20px;
  --choice-accordion-input-icon-size: 16px;
  --choice-accordion-input-icon-color: var(--color-white);
  --choice-accordion-input-border-width: 1px;
  --choice-accordion-input-margin-right: var(--space-xxs);
}

.choice-accordion {
  border-width: var(--choice-accordion-border-width);
  border-radius: var(--choice-accordion-border-radius);
  border-style: solid;
  border-color: var(--color-contrast-lower);
  overflow: hidden;
}

.choice-accordion__btn {
  display: none;
}

.js .choice-accordion__btn {
  grid-gap: var(--choice-accordion-input-margin-right);
  grid-template-columns: var(--choice-accordion-input-size) 1fr;
  border-top-width: var(--choice-accordion-border-width);
  border-style: solid;
  border-color: var(--color-contrast-lower);
  background-color: var(--color-bg);
  cursor: pointer;
  align-items: center;
  display: grid;
}

.js .choice-accordion__item:first-child .choice-accordion__btn {
  border-top: none;
}

.choice-accordion__panel {
  background-color: var(--color-bg-dark);
  will-change: height;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.choice-accordion__panel:before {
  width: 100%;
  height: var(--choice-accordion-border-width);
  background-color: var(--color-contrast-lower);
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.choice-accordion__input {
  width: var(--choice-accordion-input-size);
  height: var(--choice-accordion-input-size);
  border-width: var(--choice-accordion-input-border-width);
  border-style: solid;
  border-color: var(--color-contrast-low);
  background-color: var(--color-bg);
  justify-content: center;
  align-items: center;
  display: flex;
}

.choice-accordion__input .icon {
  color: var(--choice-accordion-input-icon-color);
  font-size: var(--choice-accordion-input-icon-size);
}

.choice-accordion__btn:hover .choice-accordion__input {
  border-color: var(--color-contrast-medium);
}

.choice-accordion__btn--checked .choice-accordion__input, .choice-accordion__btn--focus .choice-accordion__input, .choice-accordion__btn--checked:hover .choice-accordion__input, .choice-accordion__btn--focus:hover .choice-accordion__input {
  border-color: var(--color-primary);
}

.choice-accordion__btn--checked .choice-accordion__input {
  background-color: var(--color-primary);
}

.choice-accordion__btn--focus .choice-accordion__input {
  box-shadow: 0 0 0 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
}

.choice-accordion__input--checkbox {
  border-radius: var(--radius-sm);
}

.choice-accordion__input--checkbox .icon > * {
  stroke-dashoffset: 16px;
  stroke-dasharray: 17;
  transition: stroke-dashoffset .3s;
}

.choice-accordion__btn--checked .choice-accordion__input--checkbox .icon > * {
  stroke-dashoffset: 0;
  stroke-dasharray: 17;
}

.choice-accordion__input--radio {
  border-radius: 50%;
}

.choice-accordion__input--radio .icon {
  transition: transform .3s var(--bounce);
  transform: scale(0);
}

.choice-accordion__btn--checked .choice-accordion__input--radio .icon {
  transform: scale(1);
}

.js .choice-accordion__fallback {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  position: absolute;
}

:root {
  --choice-btn-border-width: 1px;
  --choice-btn-align-items: center;
  --choice-btn-input-size: 20px;
  --choice-btn-input-icon-size: 16px;
  --choice-btn-input-icon-color: var(--color-white);
  --choice-btn-input-border-width: 1px;
  --choice-btn-input-margin-right: var(--space-sm);
  --choice-btn-input-translate-y: 0em;
}

.choice-btns {
  flex-direction: column;
  display: flex;
}

.choice-btn__grid {
  display: none;
}

.js .choice-btn__fallback {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  position: absolute;
}

.js .choice-btn {
  border-width: var(--choice-btn-border-width);
  border-style: solid;
  border-color: var(--color-contrast-low);
  background-color: var(--color-bg);
  cursor: pointer;
  border-radius: 0;
  transition: all .2s;
}

.js .choice-btn:hover {
  border-color: hsl(var(--color-contrast-low-h), var(--color-contrast-low-s), calc(var(--color-contrast-low-l) * .85) );
}

.js .choice-btn.choice-btn--focus, .js .choice-btn.choice-btn--checked {
  border-color: var(--color-primary);
}

.js .choice-btn.choice-btn--focus {
  box-shadow: 0 0 0 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
}

.js .choice-btn__grid {
  grid-gap: var(--choice-btn-input-margin-right);
  grid-template-columns: var(--choice-btn-input-size) 1fr;
  align-items: var(--choice-btn-align-items);
  display: grid;
}

.choice-btn__input {
  top: var(--choice-btn-input-translate-y);
  width: var(--choice-btn-input-size);
  height: var(--choice-btn-input-size);
  border-width: var(--choice-btn-input-border-width);
  border-style: solid;
  border-color: var(--color-contrast-low);
  background-color: var(--color-bg);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.choice-btn__input .icon {
  color: var(--choice-btn-input-icon-color);
  font-size: var(--choice-btn-input-icon-size);
}

.choice-btn--checked .choice-btn__input {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}

.choice-btn__input--checkbox {
  border-radius: var(--radius-sm);
}

.choice-btn__input--checkbox .icon > * {
  stroke-dashoffset: 16px;
  stroke-dasharray: 17;
  transition: stroke-dashoffset .3s;
}

.choice-btn--checked .choice-btn__input--checkbox .icon > * {
  stroke-dashoffset: 0;
  stroke-dasharray: 17;
}

.choice-btn__input--radio {
  border-radius: 50%;
}

.choice-btn__input--radio .icon {
  transition: transform .3s var(--bounce);
  transform: scale(0);
}

.choice-btn--checked .choice-btn__input--radio .icon {
  transform: scale(1);
}

.cl-table {
  z-index: 1;
  position: relative;
}

.cl-table__cell {
  padding: var(--space-sm);
}

.cl-table__cell--th {
  color: var(--color-contrast-higher);
  font-weight: bold;
}

.cl-table__header .cl-table__cell {
  z-index: 10;
  position: relative;
}

.cl-table__list {
  display: none;
}

.cl-table:not(.cl-table--collapsed) {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.cl-table:not(.cl-table--collapsed) .cl-table__header .cl-table__row {
  background-color: var(--color-bg);
}

.cl-table:not(.cl-table--collapsed) .cl-table__header .cl-table__cell {
  z-index: 10;
  border-bottom: 1px solid var(--color-contrast-lower);
  background-color: inherit;
  position: relative;
}

.cl-table:not(.cl-table--collapsed) .cl-table__body .cl-table__row:nth-child(2n+1) {
  background-color: hsla(var(--color-bg-dark-h), var(--color-bg-dark-s), var(--color-bg-dark-l), .85);
}

.cl-table:not(.cl-table--collapsed) .cl-table__input {
  display: none;
}

.cl-table:not(.cl-table--collapsed) .cl-table__header--sticky .cl-table__cell {
  position: sticky;
  top: 0;
}

.cl-table--collapsed .cl-table__body {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  position: absolute;
  overflow: hidden;
}

.cl-table--collapsed .cl-table__cell[aria-hidden="true"] {
  display: none;
}

.cl-table--collapsed .cl-table__row {
  flex-direction: column;
  display: flex;
}

.cl-table--collapsed .cl-table__cell {
  width: 100%;
  margin-bottom: var(--space-xxs);
  border-radius: var(--radius-md);
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow-top), var(--shadow-sm);
  position: relative;
}

.cl-table--collapsed .cl-table__th-inner {
  font-size: var(--text-md);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cl-table--collapsed .cl-table__th-icon {
  --col-table-arrow-width: 2px;
  width: 24px;
  height: 24px;
  position: relative;
}

.cl-table--collapsed .cl-table__th-icon:before, .cl-table--collapsed .cl-table__th-icon:after {
  width: var(--col-table-arrow-width);
  height: 12px;
  transform-origin: 50% 100%;
  content: "";
  background-color: currentColor;
  transition: transform .3s;
  position: absolute;
  top: calc(50% - 6px);
}

.cl-table--collapsed .cl-table__th-icon:before {
  right: 50%;
  transform: translateX(50%)rotate(-45deg);
}

.cl-table--collapsed .cl-table__th-icon:after {
  right: calc(50% + var(--col-table-arrow-width) / 2);
  transform: translateX(50%)rotate(45deg);
}

.cl-table--collapsed .cl-table__list {
  margin-top: var(--space-sm);
  line-height: var(--body-line-height);
}

.cl-table--collapsed .cl-table__item {
  padding: var(--space-sm) 0;
  border-top: 1px solid var(--color-contrast-lower);
  color: var(--color-contrast-high);
  text-align: right;
  justify-content: space-between;
  font-weight: normal;
  display: flex;
}

.cl-table--collapsed .cl-table__label {
  margin-right: var(--space-md);
  color: var(--color-contrast-higher);
  text-align: left;
  font-weight: bold;
}

.cl-table--collapsed .cl-table__cell--show-list .cl-table__th-icon:before {
  transform: translateX(50%)translateY(-80%)rotate(-135deg);
}

.cl-table--collapsed .cl-table__cell--show-list .cl-table__th-icon:after {
  transform: translateX(50%)translateY(-80%)rotate(135deg);
}

.cl-table--collapsed .cl-table__cell--show-list .cl-table__list {
  display: block;
}

.cl-table--collapsed .cl-table__input {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  border: 0;
  padding: 0;
  position: absolute;
}

.cl-table--collapsed .cl-table__input:focus + .cl-table__th-inner {
  color: var(--color-primary);
}

[class*="cl-table--expanded"]:before {
  content: "collapsed";
  display: none;
}

@media (min-width: 32rem) {
  .cl-table--expanded\@xs:before {
    content: "expanded";
  }
}

@media (min-width: 48rem) {
  .cl-table--expanded\@sm:before {
    content: "expanded";
  }
}

@media (min-width: 64rem) {
  .cl-table--expanded\@md:before {
    content: "expanded";
  }
}

@media (min-width: 80rem) {
  .cl-table--expanded\@lg:before {
    content: "expanded";
  }
}

@media (min-width: 90rem) {
  .cl-table--expanded\@xl:before {
    content: "expanded";
  }
}

:root {
  --color-swatches-gap: var(--space-xxs);
  --color-swatch-size: 32px;
  --color-swatch-radius: 50%;
}

.color-swatches__list {
  margin-bottom: calc(-1 * var(--color-swatches-gap, .5em));
  margin-left: calc(-1 * var(--color-swatches-gap, .5em));
  display: flex;
}

.color-swatches__item {
  margin-bottom: var(--color-swatches-gap);
  margin-left: var(--color-swatches-gap);
}

.color-swatches__swatch {
  width: var(--color-swatch-size);
  height: var(--color-swatch-size);
  border-radius: var(--color-swatch-radius);
  cursor: pointer;
  display: block;
  position: relative;
}

.color-swatches__swatch:before {
  width: 100%;
  height: 100%;
  border: 1px solid hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .1);
  border-radius: inherit;
  content: "";
  pointer-events: none;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.color-swatches__swatch:hover:before {
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .3);
}

.color-swatches__item--selected .color-swatches__swatch:before {
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  border-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .8);
  transform: translate(-3px, -3px);
}

.countdown {
  align-items: baseline;
  display: flex;
}

.countdown__timer, .countdown__item {
  display: inline-block;
}

.countdown__item:nth-of-type(2):after, .countdown__item:nth-of-type(3):after {
  content: ":";
}

.countdown__value {
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.countdown--has-labels .countdown__item {
  margin-right: var(--space-xs);
}

.countdown--has-labels .countdown__item:last-child {
  margin-right: 0;
}

.countdown--has-labels .countdown__item:after {
  content: "";
}

.countdown--has-labels .countdown__label {
  font-size: .45em;
}

.countdown--grid .countdown__timer {
  grid-gap: var(--space-xxxs);
  grid-template-columns: repeat(4, 1.6em);
  display: grid;
}

.countdown--grid .countdown__item {
  padding: var(--space-xxxxs) 0;
  background-color: var(--color-bg);
  text-align: center;
}

.countdown--grid .countdown__item:after {
  content: "";
}

.countdown--grid .countdown__label {
  color: var(--color-contrast-medium);
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: .2em;
  display: block;
}

.js .countdown__fallback {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  position: absolute;
}

html:not(.js) .countdown__info {
  display: none;
}

.counter {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--space-xxxxs) var(--space-xxs);
  background-color: var(--color-contrast-low);
  font-size: var(--text-xs);
  border-radius: 50em;
  text-decoration: none;
}

.counter--primary {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.counter--critical {
  background-color: var(--color-error);
  color: var(--color-white);
}

.counter--light {
  background-color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), .9);
  color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .85);
}

.counter--dark {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .8);
  color: hsla(var(--color-white-h), var(--color-white-s), var(--color-white-l), .9);
}

.counter--docked {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.counter-container {
  width: 48px;
  height: 48px;
  border-radius: var(--radius-md);
  background-color: var(--color-contrast-lower);
  position: relative;
}

:root {
  --select-icon-size: 1em;
  --select-icon-right-margin: var(--space-sm);
}

.select {
  position: relative;
}

.select__input {
  width: 100%;
  padding-right: calc(var(--select-icon-size)  + var(--select-icon-right-margin)) !important;
}

.select__icon {
  top: 50%;
  right: var(--select-icon-right-margin);
  width: var(--select-icon-size);
  height: var(--select-icon-size);
  pointer-events: none;
  position: absolute;
  transform: translateY(-50%);
}

:root {
  --select-dropdown-gap: 4px;
}

.select__button {
  width: 100%;
}

.select__dropdown {
  --space-unit: 1rem;
  --text-unit: 1rem;
  visibility: hidden;
  z-index: var(--z-index-popover);
  min-width: 200px;
  max-height: 1px;
  margin-top: var(--select-dropdown-gap);
  margin-bottom: var(--select-dropdown-gap);
  padding: var(--space-xxs) 0;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  font-size: var(--text-unit);
  opacity: 0;
  border-radius: 0;
  transition: visibility 0s .2s, opacity .2s;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: auto;
}

.select__dropdown--right {
  left: auto;
  right: 0;
}

.select__dropdown--up {
  top: auto;
  bottom: 100%;
}

.select__button[aria-expanded="true"] + .select__dropdown {
  visibility: visible;
  opacity: 1;
  transition: visibility, opacity .2s;
}

.select__list {
  list-style: none !important;
}

.select__list:not(:first-of-type) {
  padding-top: var(--space-xxs);
}

.select__list:not(:last-of-type) {
  padding-bottom: var(--space-xxs);
  border-bottom: 1px solid var(--color-contrast-low);
}

.select__item {
  width: 100%;
  padding: var(--space-xs) var(--space-lg) var(--space-xs) var(--space-md);
  color: var(--color-contrast-high);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.select__item--optgroup {
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
}

.select__item--option {
  cursor: pointer;
}

.select__item--option:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .075);
}

.select__item--option:focus {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .1);
  outline: none;
}

.select__item--option[aria-selected="true"] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-primary);
  color: var(--color-white);
  position: relative;
}

.select__item--option[aria-selected="true"]:after {
  top: 50%;
  right: var(--space-sm);
  width: 16px;
  height: 16px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpolyline stroke-width='1.5' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round' points='1,9 5,13 15,3 '/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  transform: translateY(-50%);
}

html:not(.js) .select .icon {
  display: none;
}

.details-list-v2__item {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.details-list-v2__item > * {
  margin-bottom: var(--space-xxs);
}

.details-list-v2__item dd:last-of-type {
  margin-bottom: 0;
}

.details-list-v2__dt {
  font-weight: bold;
}

.details-list-v2__dd {
  line-height: 1.4;
}

@media (min-width: 64rem) {
  @supports (grid-area: auto) {
    .details-list-v2--cols {
      grid-template-columns: repeat(3, 1fr);
      display: grid;
    }

    .details-list-v2--cols .details-list-v2__item {
      padding: var(--space-md);
      border-right: 1px solid var(--color-contrast-lower);
      text-align: center;
      border-bottom-width: 0;
    }

    .details-list-v2--cols .details-list-v2__item:last-child {
      border-right-width: 0;
    }
  }
}

.details__summary {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: inline-block;
}

.details__summary:hover {
  color: var(--color-primary);
}

.details__summary:focus {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
  outline-offset: 4px;
}

.details__summary .icon {
  flex-shrink: 0;
  transition: transform .2s;
}

.js .details__summary {
  list-style: none;
}

.js .details__summary::-webkit-details-marker {
  display: none;
}

.js .details__summary[aria-expanded="true"] .icon {
  transform: rotate(90deg);
}

.js .details__content[aria-hidden="true"], html:not(.js) .details__summary .icon {
  display: none;
}

:root {
  --diagonal-section-offset: 50px;
}

.diagonal-section {
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@supports (clip-path: inset(50%)) {
  .diagonal-section, .diagonal-section-top.diagonal-section-bottom {
    padding-top: calc(var(--diagonal-section-offset) / 2);
    padding-bottom: calc(var(--diagonal-section-offset) / 2);
    clip-path: polygon(0% 0%, 100% var(--diagonal-section-offset), 100% 100%, 0% calc(100% - var(--diagonal-section-offset)) );
  }

  .diagonal-section--flip-x {
    clip-path: polygon(0% var(--diagonal-section-offset), 100% 0%, 100% calc(100% - var(--diagonal-section-offset)), 0% 100%);
  }

  .diagonal-section-top {
    padding-top: calc(var(--diagonal-section-offset) / 2);
    clip-path: polygon(0% 0%, 100% var(--diagonal-section-offset), 100% 100%, 0% 100%);
  }

  .diagonal-section-top--flip-x {
    clip-path: polygon(0% var(--diagonal-section-offset), 100% 0%, 100% 100%, 0% 100%);
  }

  .diagonal-section-top--flip-x.diagonal-section-bottom {
    clip-path: polygon(0% var(--diagonal-section-offset), 100% 0%, 100% 100%, 0% calc(100% - var(--diagonal-section-offset)) );
  }

  .diagonal-section-bottom {
    padding-bottom: calc(var(--diagonal-section-offset) / 2);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - var(--diagonal-section-offset)) );
  }

  .diagonal-section-bottom--flip-x {
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - var(--diagonal-section-offset)), 0% 100%);
  }

  .diagonal-section-bottom--flip-x.diagonal-section-top {
    clip-path: polygon(0% 0%, 100% var(--diagonal-section-offset), 100% calc(100% - var(--diagonal-section-offset)), 0% 100%);
  }

  .diagonal-section-top--flip-x.diagonal-section-bottom--flip-x {
    clip-path: polygon(0% var(--diagonal-section-offset), 100% 0%, 100% calc(100% - var(--diagonal-section-offset)), 0% 100%);
  }
}

:root {
  --drop-cap-lines: 3;
}

.drop-cap:first-letter {
  float: left;
  color: var(--color-contrast-higher);
  font-size: calc(1em * var(--drop-cap-lines) * var(--body-line-height));
  text-transform: uppercase;
  padding: 0 .125em 0 0;
  line-height: 1;
}

.text-component .drop-cap:first-letter {
  font-size: calc(1em * var(--drop-cap-lines) * var(--body-line-height) * var(--line-height-multiplier));
}

.feature-v10 {
  z-index: 1;
  position: relative;
}

.feature-v10__link {
  transform-origin: 0 100%;
  color: inherit;
  transition: .3s var(--ease-out);
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.feature-v10__link i {
  z-index: 2;
  transition: .3s var(--ease-out);
  display: inline-block;
  position: relative;
}

.feature-v10__link:after {
  z-index: 1;
  width: 100%;
  height: 2px;
  content: "";
  opacity: .15;
  transition: .3s var(--ease-out);
  background-color: currentColor;
  position: absolute;
  bottom: 0;
  left: 0;
}

.feature-v10__link:hover {
  transform: scale(1.3);
}

.feature-v10__link:hover i {
  transform: scale(.7);
}

.feature-v10__link:hover:after {
  height: 100%;
}

.feature-v4 {
  --feature-text-offset: 65%;
  z-index: 1;
  position: relative;
}

@media (min-width: 32rem) {
  .feature-v4__text-offset\@xs {
    width: calc(100% + var(--feature-text-offset));
  }

  .feature-v4--invert\@xs .feature-v4__grid > :last-child {
    order: -1;
  }

  .feature-v4--invert\@xs .feature-v4__text-offset\@xs {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 48rem) {
  .feature-v4__text-offset\@sm {
    width: calc(100% + var(--feature-text-offset));
  }

  .feature-v4--invert\@sm .feature-v4__grid > :last-child {
    order: -1;
  }

  .feature-v4--invert\@sm .feature-v4__text-offset\@sm {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 64rem) {
  .feature-v4__text-offset\@md {
    width: calc(100% + var(--feature-text-offset));
  }

  .feature-v4--invert\@md .feature-v4__grid > :last-child {
    order: -1;
  }

  .feature-v4--invert\@md .feature-v4__text-offset\@md {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 80rem) {
  .feature-v4__text-offset\@lg {
    width: calc(100% + var(--feature-text-offset));
  }

  .feature-v4--invert\@lg .feature-v4__grid > :last-child {
    order: -1;
  }

  .feature-v4--invert\@lg .feature-v4__text-offset\@lg {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

@media (min-width: 90rem) {
  .feature-v4__text-offset\@xl {
    width: calc(100% + var(--feature-text-offset));
  }

  .feature-v4--invert\@xl .feature-v4__grid > :last-child {
    order: -1;
  }

  .feature-v4--invert\@xl .feature-v4__text-offset\@xl {
    margin-left: calc(var(--feature-text-offset) * -1);
  }
}

.feature-v9:hover .feature-v9__block:not(:hover) .feature-v9__content {
  opacity: .5;
}

.feature-v9:hover .feature-v9__block:not(:hover):after {
  -webkit-backdrop-filter: grayscale();
  backdrop-filter: grayscale();
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), .8);
}

.feature-v9__block {
  min-height: 350px;
  padding: var(--space-xl) var(--space-md);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  position: relative;
}

.feature-v9__block:before, .feature-v9__block:after {
  z-index: 1;
  width: 100%;
  content: "";
  transition: all .2s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.feature-v9__block:before {
  height: 70%;
  background: linear-gradient(hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0), hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), .8) );
  mix-blend-mode: multiply;
}

.feature-v9__block:after {
  height: 100%;
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), 0);
}

.feature-v9__block:hover .feature-v9__content {
  transform: translateY(-10px);
}

@media (min-width: 48rem) {
  .feature-v9__block {
    min-height: 450px;
  }
}

@media (min-width: 64rem) {
  .feature-v9__block {
    min-height: 600px;
  }
}

@media (min-width: 80rem) {
  .feature-v9__block {
    min-height: 700px;
  }
}

.feature-v9__content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 2;
  margin: auto auto 0;
  transition: all .2s;
  position: relative;
}

.feature-v9__link {
  padding: var(--space-xs) var(--space-md);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .8);
  color: var(--color-bg);
  text-decoration: none;
  transition: background-color .2s;
  display: inline-block;
}

.feature-v9__link:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), 1);
}

.google-maps {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.google-maps--ratio-4\:1 {
  padding-bottom: 25%;
}

.google-maps--ratio-3\:1 {
  padding-bottom: 33%;
}

.img-mag {
  overflow: hidden;
}

.img-mag__asset {
  width: 100%;
  transform-origin: 0 0;
  display: block;
}

.list, .text-component .list {
  --list-v-space: 1;
  --list-offset: 1;
  padding-left: 0;
  list-style: none;
}

.list ul, .list ol, .text-component .list ul, .text-component .list ol {
  margin: 0;
  margin-top: calc(var(--space-xxxs) * var(--list-v-space, 1));
  padding-top: calc(var(--space-xxxs) * var(--list-v-space, 1));
  padding-left: calc(var(--space-xs) * var(--list-offset, 1));
  list-style: none;
}

.list li, .text-component .list li {
  margin-bottom: calc(var(--space-xxxs) * var(--list-v-space, 1));
  padding-bottom: calc(var(--space-xxxs) * var(--list-v-space, 1));
}

.list > li:last-child, .list ul > li:last-child, .list ol > li:last-child, .text-component .list > li:last-child, .text-component .list ul > li:last-child, .text-component .list ol > li:last-child {
  margin-bottom: 0;
}

.list:not(.list--border) > li:last-child, .list ul > li:last-child, .list ol > li:last-child, .text-component .list:not(.list--border) > li:last-child, .text-component .list ul > li:last-child, .text-component .list ol > li:last-child {
  padding-bottom: 0;
}

.list--ul li, .list--ol li {
  padding-left: calc(var(--bullet-size)  + var(--bullet-margin-right));
}

.list--ul li:before, .list--ol li:before {
  left: calc(var(--bullet-margin-right) * -1);
  width: var(--bullet-size);
  height: var(--bullet-size);
  margin-left: calc(var(--bullet-size) * -1);
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.list--ul {
  --bullet-size: 7px;
  --bullet-margin-right: var(--space-xxs);
}

.list--ul li:before {
  content: "";
  color: var(--color-primary);
  background-color: currentColor;
  border: 2px solid;
  border-radius: 50%;
}

.list--ul ul li:before {
  background-color: #0000;
}

.list--ol {
  --bullet-size: 26px;
  --bullet-margin-right: 6px;
  counter-reset: list-items;
}

.list--ol li {
  counter-increment: list-items;
}

.list--ol ol {
  counter-reset: list-items;
}

.list--ol li:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: 2px solid var(--color-contrast-lower);
  background-color: var(--color-contrast-lower);
  content: counter(list-items);
  color: var(--color-contrast-high);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: .75em;
  top: -.1em;
}

.list--ol ol li:before {
  background-color: #0000;
}

.list--border li {
  border-bottom: 1px solid var(--color-contrast-low);
}

.list--border ul, .list--border ol {
  border-top: 1px solid var(--color-contrast-low);
}

.list--border ul li:last-child, .list--border ol li:last-child {
  border-bottom-width: 0;
}

.main-footer {
  z-index: 1;
  position: relative;
}

.main-footer__logo {
  flex-shrink: 0;
}

.main-footer__logo svg, .main-footer__logo img {
  width: inherit;
  height: inherit;
  display: block;
}

@media (min-width: 64rem) {
  .main-footer__items {
    font-size: .8em;
  }
}

.main-footer__item {
  margin-top: var(--space-sm);
}

.main-footer__link {
  color: var(--color-contrast-medium);
  text-decoration: none;
}

.main-footer__link:hover {
  color: var(--color-contrast-high);
  text-decoration: underline;
}

.main-footer__colophon {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.main-footer__social {
  color: var(--color-contrast-medium);
  text-decoration: none;
  display: inline-block;
}

.main-footer__social:hover {
  color: var(--color-contrast-high);
}

.menu {
  --menu-vertical-gap: 4px;
  --menu-item-padding: var(--space-xxs) var(--space-sm);
  --space-unit: 1rem;
  --text-unit: 1rem;
  visibility: hidden;
  z-index: var(--z-index-popover);
  width: 220px;
  margin-top: var(--menu-vertical-gap);
  margin-bottom: var(--menu-vertical-gap);
  padding: var(--space-xxs) 0;
  border-radius: var(--radius-md);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  font-size: var(--text-unit);
  opacity: 0;
  -webkit-user-select: none;
  user-select: none;
  list-style: none;
  transition: visibility 0s .2s, opacity .2s;
  position: fixed;
  overflow: auto;
}

.menu--is-visible {
  visibility: visible;
  opacity: 1;
  transition: visibility, opacity .2s;
}

.menu--overlay {
  z-index: var(--z-index-overlay);
}

.menu__content {
  padding: var(--menu-item-padding);
  color: var(--color-contrast-high);
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.menu__content:hover {
  background-color: var(--color-contrast-lower);
}

.menu__content:focus {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .1);
  outline: none;
}

.menu__label {
  padding: var(--menu-item-padding);
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
}

.menu__separator {
  height: 1px;
  margin: var(--menu-item-padding);
  background-color: var(--color-contrast-low);
}

.menu__icon {
  margin-right: var(--space-xxs);
  color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .7);
}

.modal {
  visibility: hidden;
  z-index: var(--z-index-overlay);
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
}

.modal:not(.modal--is-visible) {
  pointer-events: none;
  background-color: #0000;
}

.modal--is-visible {
  visibility: visible;
  opacity: 1;
}

.modal__content {
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  overflow: auto;
}

.modal__close-btn {
  border-radius: 50%;
  flex-shrink: 0;
  transition: all .2s;
  display: flex;
}

.modal__close-btn .icon {
  margin: auto;
  display: block;
}

.modal__close-btn--outer {
  z-index: var(--z-index-fixed-element);
  top: var(--space-sm);
  right: var(--space-sm);
  width: 3.2em;
  height: 3.2em;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .8);
  position: fixed;
}

.modal__close-btn--outer:hover {
  background-color: var(--color-contrast-higher);
}

.modal__close-btn--outer .icon {
  color: var(--color-bg);
}

.modal__close-btn--inner {
  width: 2em;
  height: 2em;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
}

.modal__close-btn--inner .icon {
  color: inherit;
}

.modal__close-btn--inner:hover {
  box-shadow: var(--shadow-md);
}

:root {
  --modal-transition-duration: .2s;
}

@media (prefers-reduced-motion: no-preference) {
  .modal--animate-fade {
    --modal-transition-duration: .2s;
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }

  .modal--animate-fade.modal--is-visible {
    transition: opacity var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s;
  }

  .modal--animate-scale, .modal--animate-translate-up, .modal--animate-translate-down, .modal--animate-translate-right, .modal--animate-translate-left {
    --modal-transition-duration: .2s;
    transition: opacity var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }

  .modal--animate-scale .modal__content, .modal--animate-translate-up .modal__content, .modal--animate-translate-down .modal__content, .modal--animate-translate-right .modal__content, .modal--animate-translate-left .modal__content {
    transition: transform var(--modal-transition-duration) var(--ease-out);
    will-change: transform;
  }

  .modal--animate-scale.modal--is-visible, .modal--animate-translate-up.modal--is-visible, .modal--animate-translate-down.modal--is-visible, .modal--animate-translate-right.modal--is-visible, .modal--animate-translate-left.modal--is-visible {
    transition: opacity var(--modal-transition-duration), visibility 0s;
  }

  .modal--animate-scale.modal--is-visible .modal__content, .modal--animate-translate-up.modal--is-visible .modal__content, .modal--animate-translate-down.modal--is-visible .modal__content, .modal--animate-translate-right.modal--is-visible .modal__content, .modal--animate-translate-left.modal--is-visible .modal__content {
    transform: scale(1);
  }

  .modal--animate-slide-up, .modal--animate-slide-down, .modal--animate-slide-right, .modal--animate-slide-left {
    --modal-transition-duration: .3s;
    transition: opacity 0s var(--modal-transition-duration), background-color var(--modal-transition-duration), visibility 0s var(--modal-transition-duration);
  }

  .modal--animate-slide-up .modal__content, .modal--animate-slide-down .modal__content, .modal--animate-slide-right .modal__content, .modal--animate-slide-left .modal__content {
    transition: transform var(--modal-transition-duration) var(--ease-out);
    will-change: transform;
  }

  .modal--animate-slide-up.modal--is-visible, .modal--animate-slide-down.modal--is-visible, .modal--animate-slide-right.modal--is-visible, .modal--animate-slide-left.modal--is-visible {
    transition: background-color var(--modal-transition-duration), visibility 0s;
  }

  .modal--animate-slide-up.modal--is-visible .modal__content, .modal--animate-slide-down.modal--is-visible .modal__content, .modal--animate-slide-right.modal--is-visible .modal__content, .modal--animate-slide-left.modal--is-visible .modal__content {
    transform: scale(1);
  }

  .modal--animate-scale .modal__content {
    transform: scale(.95);
  }

  .modal--animate-translate-up .modal__content {
    transform: translateY(40px);
  }

  .modal--animate-translate-down .modal__content {
    transform: translateY(-40px);
  }

  .modal--animate-translate-right .modal__content {
    transform: translateX(-40px);
  }

  .modal--animate-translate-left .modal__content {
    transform: translateX(40px);
  }

  .modal--animate-slide-up .modal__content {
    transform: translateY(100%);
  }

  .modal--animate-slide-down .modal__content {
    transform: translateY(-100%);
  }

  .modal--animate-slide-right .modal__content {
    transform: translateX(-100%);
  }

  .modal--animate-slide-left .modal__content {
    transform: translateX(100%);
  }
}

.modal--is-loading .modal__content {
  visibility: hidden;
}

.modal--is-loading .modal__loader {
  display: flex;
}

.modal__loader {
  width: 100%;
  height: 100%;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.notice {
  z-index: var(--z-index-fixed-element, 10);
  width: 100%;
  pointer-events: none;
  position: fixed;
}

.notice__banner {
  pointer-events: auto;
}

.notice__close-btn {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .5);
  color: var(--color-bg);
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.notice__close-btn:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .7);
}

.notice__close-btn .icon {
  --size: 16px;
}

.notice--hide {
  visibility: hidden;
  opacity: 0;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .notice {
    transition: opacity .3s, visibility 0s .3s;
  }

  .notice__banner {
    transition: transform .3s;
  }

  .notice--hide .notice__banner {
    transform: translateY(20px);
  }
}

.number-input__btn {
  display: none;
}

.js .number-input .form-control::-webkit-inner-spin-button, .js .number-input .form-control::-webkit-outer-spin-button {
  display: none;
}

.js .number-input .form-control {
  -moz-appearance: textfield;
  display: block;
}

.js .number-input__btn {
  display: flex;
}

.js .number-input--v1 {
  --number-input-btn-width: 40px;
  width: 5em;
  position: relative;
}

.js .number-input--v1 .form-control {
  width: 100%;
  padding-right: var(--number-input-btn-width);
}

.js .number-input--v1 .number-input__btns {
  top: var(--space-xxxs);
  width: var(--number-input-btn-width);
  height: calc(100% - var(--space-xxxs) * 2);
  flex-direction: column;
  display: flex;
  position: absolute;
  right: 0;
}

.js .number-input--v1 .number-input__btn {
  width: calc(100% - var(--space-xxxs));
  height: 50%;
  border-radius: var(--radius-sm);
  background: var(--color-contrast-lower);
  position: relative;
}

.js .number-input--v1 .number-input__btn .icon {
  width: 1em;
  height: 1em;
  font-size: .8em;
  display: block;
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
}

.js .number-input--v1 .number-input__btn:hover {
  background-color: var(--color-contrast-low);
}

.js .number-input--v1 .number-input__btn:focus {
  background-color: var(--color-primary);
  outline: none;
}

.js .number-input--v1 .number-input__btn:focus .icon {
  color: var(--color-white);
}

.js .number-input--v1 .number-input__btn:active {
  background-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * .9) );
}

.js .number-input--v1 .number-input__btn--plus {
  margin-bottom: var(--space-xxxxs);
}

.js .number-input--v2 {
  --number-input-btn-width: 1.6em;
  align-items: center;
  font-size: .875em;
  display: flex;
}

.js .number-input--v2 .form-control {
  width: 4em;
  margin: 0 var(--space-xxs);
  text-align: center;
  order: 1;
}

.js .number-input--v2 .number-input__btn {
  width: var(--number-input-btn-width);
  height: var(--number-input-btn-width);
  background-color: var(--color-contrast-lower);
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.js .number-input--v2 .number-input__btn:hover {
  background-color: var(--color-contrast-low);
}

.js .number-input--v2 .number-input__btn:focus {
  background-color: var(--color-primary);
  outline: none;
}

.js .number-input--v2 .number-input__btn:focus .icon {
  color: var(--color-white);
}

.js .number-input--v2 .number-input__btn:active {
  background-color: hsl(var(--color-primary-h), var(--color-primary-s), calc(var(--color-primary-l) * .9) );
}

.js .number-input--v2 .number-input__btn .icon {
  width: 1em;
  height: 1em;
  font-size: 12px;
}

.js .number-input--v2 .number-input__btn--plus {
  order: 2;
}

:root {
  --order-summary-img-size: 3.6em;
  --order-summary-img-margin-right: var(--space-xs);
}

.order-summary__header {
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.order-summary__footer {
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.order-summary__item {
  grid-gap: var(--order-summary-img-margin-right);
  grid-template-columns: var(--order-summary-img-size) 1fr;
  align-items: center;
  display: grid;
}

.order-summary__img {
  width: var(--order-summary-img-size);
  height: var(--order-summary-img-size);
  box-shadow: var(--shadow-md);
  transition: all .2s;
  display: block;
  overflow: hidden;
}

.order-summary__img img {
  width: 100%;
  object-fit: cover;
  display: block;
}

.order-summary__img:hover {
  box-shadow: var(--shadow-sm);
  transform: translateY(-1px);
}

.overscroll-section {
  --overscroll-section-opacity: 0;
  position: relative;
}

.overscroll-section__sticky-content {
  z-index: 1;
  position: sticky;
}

.overscroll-section__scroll-content {
  z-index: 2;
  position: relative;
  transform: translateZ(0);
}

.overscroll-section__scroll-content:before, .overscroll-section__scroll-content:after {
  width: 100%;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.overscroll-section__scroll-content:before {
  z-index: 1;
  height: 100vh;
  background: var(--color-black);
  opacity: var(--overscroll-section-opacity, 0);
}

.overscroll-section__scroll-content:after {
  height: 80px;
  background: linear-gradient(to top, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .045) 0%, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0) 5%), linear-gradient(to top, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .045) 0%, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0) 10%), linear-gradient(to top, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .045) 0%, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0) 20%), linear-gradient(to top, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .045) 0%, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0) 50%), linear-gradient(to top, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .045) 0%, hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0) 100%);
}

.overscroll-section--disabled .overscroll-section__sticky-content {
  position: static;
}

.overscroll-section--disabled .overscroll-section__scroll-content:before, .overscroll-section--disabled .overscroll-section__scroll-content:after {
  display: none;
}

.pagination__list > li {
  display: inline-block;
}

.pagination--split .pagination__list {
  width: 100%;
}

.pagination--split .pagination__list > :first-child {
  margin-right: auto;
}

.pagination--split .pagination__list > :last-child {
  margin-left: auto;
}

.pagination__item {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  padding-top: var(--space-xs);
  padding-right: calc(1.355 * var(--space-xs));
  padding-bottom: var(--space-xs);
  padding-left: calc(1.355 * var(--space-xs));
  color: var(--color-contrast-higher);
  white-space: nowrap;
  border-radius: .1em;
  align-items: center;
  line-height: 1;
  text-decoration: none;
  transition: all .2s;
  display: inline-flex;
}

.pagination__item:hover:not(.pagination__item--selected):not(.pagination__item--ellipsis) {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .1);
}

.pagination__item:active {
  transform: translateY(2px);
}

.pagination__item--selected {
  background-color: var(--color-contrast-higher);
  box-shadow: var(--shadow-sm);
  color: var(--color-bg);
}

.pagination__item--disabled {
  opacity: .5;
  pointer-events: none;
}

.pagination__jumper .form-control {
  width: 3.2em;
  margin-right: var(--space-xs);
  padding: var(--space-xs);
}

.pagination__jumper em {
  white-space: nowrap;
  flex-shrink: 0;
}

.pre-header {
  display: block;
}

.pre-header--is-hidden {
  display: none;
}

.pre-header__close-btn {
  opacity: .85;
  transition: all .2s;
  position: absolute;
  top: calc(50% - .5em);
  right: 0;
}

.pre-header__close-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

.pre-header__close-btn .icon {
  display: block;
}

a.pre-header {
  text-decoration: none;
  transition: background-color .2s;
}

a.pre-header:hover {
  background-color: var(--color-contrast-high);
  text-decoration: underline;
}

:root {
  --checkbox-radio-size: 1em;
  --checkbox-radio-translate-y: .15em;
  --checkbox-radio-gap: var(--space-xxxs);
  --checkbox-radio-border-width: 1px;
  --checkbox-radio-line-height: var(--body-line-height);
  --radio-marker-size: 8px;
  --checkbox-marker-size: 12px;
  --checkbox-radius: .1em;
}

.radio, .checkbox {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
}

.radio + label, .checkbox + label {
  line-height: var(--checkbox-radio-line-height);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: baseline;
  display: inline-flex;
}

.radio + label:before, .checkbox + label:before {
  width: var(--checkbox-radio-size);
  height: var(--checkbox-radio-size);
  margin-right: var(--checkbox-radio-gap);
  border-width: var(--checkbox-radio-border-width);
  border-style: solid;
  border-color: var(--color-contrast-lower);
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--color-bg);
  content: "";
  vertical-align: middle;
  flex-shrink: 0;
  transition: transform .2s, border .2s;
  display: inline-block;
}

.radio:not(:checked):not(:focus) + label:hover:before, .checkbox:not(:checked):not(:focus) + label:hover:before {
  border-color: var(--color-contrast-medium);
}

@supports (grid-area: auto) {
  .radio + label:before, .checkbox + label:before {
    top: var(--checkbox-radio-translate-y);
    position: relative;
  }
}

.radio + label:before {
  border-radius: 50%;
}

.checkbox + label:before {
  border-radius: var(--checkbox-radius);
}

.radio:checked + label:before, .checkbox:checked + label:before {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  box-shadow: none;
  transition: transform .2s;
}

.radio:active + label:before, .checkbox:active + label:before {
  transition: transform .2s;
  transform: scale(.8);
}

.radio:checked:active + label:before, .checkbox:checked:active + label:before {
  transition: none;
  transform: none;
}

.radio:checked + label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23ffffff'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  background-size: var(--radio-marker-size);
}

.checkbox:checked + label:before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg class='nc-icon-wrapper' stroke-width='2' fill='%23ffffff' stroke='%23ffffff'%3E%3Cpolyline fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='1,9 5,13 15,3 ' data-cap='butt'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
  background-size: var(--checkbox-marker-size);
}

.radio:checked:active + label:before, .checkbox:checked:active + label:before, .radio:focus + label:before, .checkbox:focus + label:before {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
}

.radio--bg + label, .checkbox--bg + label {
  padding: var(--space-xxxxs) var(--space-xxxs);
  border-radius: var(--radius-md);
  transition: background .2s;
}

.radio--bg + label:hover, .checkbox--bg + label:hover {
  background-color: var(--color-contrast-lower);
}

.radio--bg:active + label, .checkbox--bg:active + label, .radio--bg:focus + label, .checkbox--bg:focus + label {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .1);
}

:root {
  --rating-icon-size: 1.75em;
}

.rating__control ul {
  vertical-align: top;
  display: inline-flex;
}

.rating__control li {
  width: var(--rating-icon-size);
  height: var(--rating-icon-size);
  float: left;
  cursor: pointer;
  position: relative;
}

.rating__control li:focus {
  outline: none;
}

.rating__control li:focus:before {
  opacity: .2;
  transform: scale(1);
}

.rating__control li:before {
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.rating__control li.rating__item--zero, .rating__control--is-hidden {
  display: none;
}

.rating__icon, .rating__control li:before, .rating[data-animation="on"] .rating__control ul:hover .rating__icon {
  color: var(--color-primary);
}

.rating__icon--inactive, .rating[data-animation="on"] .rating__control li:hover ~ li .rating__icon, .rating__item--checked ~ li .rating__icon {
  color: var(--color-contrast-low);
}

.rating__icon {
  overflow: hidden;
}

.rating__icon svg {
  width: var(--rating-icon-size);
  max-width: var(--rating-icon-size);
  height: var(--rating-icon-size);
  pointer-events: none;
  display: block;
}

.rating--read-only .rating__control li {
  cursor: default;
}

.rating__icon--inactive {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rating__item--half .rating__icon:not(.rating__icon--inactive) {
  z-index: 2;
  width: 50%;
  position: relative;
}

.rating[data-animation="on"] .rating__control li:active .rating__icon {
  transform: scale(.8);
}

.rating[data-animation="on"] .rating__icon {
  transition: all .2s;
}

.js .rating__select {
  display: none;
}

.rating__link {
  transition: opacity .2s;
  display: inline-block;
}

.rating__link:hover {
  opacity: .85;
}

.js .read-more {
  opacity: 0;
}

.js .read-more--loaded {
  opacity: 1;
}

.read-more__btn {
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  color: var(--color-primary);
  line-height: inherit;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  padding: 0;
  text-decoration: underline;
}

.responsive-iframe {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.responsive-iframe iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.responsive-iframe--4\:3 {
  padding-bottom: 75%;
}

.sidebar:not(.sidebar--static) {
  visibility: hidden;
  z-index: var(--z-index-fixed-element);
  width: 100%;
  height: 100%;
  transition: visibility 0s .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.sidebar:not(.sidebar--static):after {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0);
  content: "";
  transition: background-color .3s;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar:not(.sidebar--static) .sidebar__panel {
  -webkit-overflow-scrolling: touch;
  z-index: 2;
  width: 100%;
  max-width: 380px;
  height: 100%;
  background-color: var(--color-bg);
  transition: all .3s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  transform: translateX(-100%);
}

.sidebar:not(.sidebar--static).sidebar--right-on-mobile .sidebar__panel {
  left: auto;
  right: 0;
  transform: translateX(100%);
}

.sidebar:not(.sidebar--static).sidebar--is-visible {
  visibility: visible;
  transition: none;
}

.sidebar:not(.sidebar--static).sidebar--is-visible:after {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .85);
}

.sidebar:not(.sidebar--static).sidebar--is-visible .sidebar__panel {
  box-shadow: var(--shadow-md);
  transform: translateX(0);
}

.sidebar__header {
  padding: var(--space-sm) var(--space-md);
  border-bottom: 2px solid var(--color-contrast-lower);
  background-color: var(--color-bg);
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
}

.sidebar__close-btn {
  width: 2em;
  height: 2em;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  border-radius: 50%;
  flex-shrink: 0;
  transition: all .2s;
  display: flex;
}

.sidebar__close-btn:hover {
  box-shadow: var(--shadow-md);
}

.sidebar__close-btn .icon {
  color: var(--color-contrast-high);
  margin: auto;
  display: block;
}

.sidebar--static, html:not(.js) .sidebar {
  flex-grow: 1;
  flex-shrink: 0;
}

.sidebar--static .sidebar__header, html:not(.js) .sidebar .sidebar__header {
  display: none;
}

.sidebar--sticky-on-desktop {
  -webkit-overflow-scrolling: touch;
  top: var(--space-sm);
  max-height: calc(100vh - var(--space-sm));
  position: sticky;
  overflow: auto;
}

.js .sidebar {
  opacity: 0;
}

.js .sidebar--loaded {
  opacity: 1;
}

html:not(.js) .sidebar {
  position: static;
}

[class*="sidebar--static"]:before {
  display: none;
}

.sidebar--static\@xs:before {
  content: "mobile";
}

@media (min-width: 32rem) {
  .sidebar--static\@xs:before {
    content: "static";
  }
}

.sidebar--static\@sm:before {
  content: "mobile";
}

@media (min-width: 48rem) {
  .sidebar--static\@sm:before {
    content: "static";
  }
}

.sidebar--static\@md:before {
  content: "mobile";
}

@media (min-width: 64rem) {
  .sidebar--static\@md:before {
    content: "static";
  }
}

.sidebar--static\@lg:before {
  content: "mobile";
}

@media (min-width: 80rem) {
  .sidebar--static\@lg:before {
    content: "static";
  }
}

.sidebar--static\@xl:before {
  content: "mobile";
}

@media (min-width: 90rem) {
  .sidebar--static\@xl:before {
    content: "static";
  }
}

:root {
  --reveal-fx-duration: .6s;
  --reveal-fx-timing-function: var(--ease-out);
}

.js .reveal-fx {
  opacity: 0;
  transition: opacity, transform var(--reveal-fx-timing-function);
  transition-duration: var(--reveal-fx-duration);
}

.js .reveal-fx:before {
  content: "reveal-fx";
  display: none;
}

.js .reveal-fx--translate, .js .reveal-fx--translate-up {
  transform: translateY(50px);
}

.js .reveal-fx--translate-right {
  transform: translateX(-50px);
}

.js .reveal-fx--translate-left {
  transform: translateX(50px);
}

.js .reveal-fx--translate-down {
  transform: translateY(-50px);
}

.js .reveal-fx--scale {
  transform: scale(.8);
}

.js .reveal-fx--scale-up {
  transform: translateY(50px)scale(.8);
}

.js .reveal-fx--scale-right {
  transform: translateX(-50px)scale(.8);
}

.js .reveal-fx--scale-left {
  transform: translateX(50px)scale(.8);
}

.js .reveal-fx--scale-down {
  transform: translateY(-50px)scale(.8);
}

.js .reveal-fx--rotate, .js .reveal-fx--rotate-down, .js .reveal-fx--rotate-right, .js .reveal-fx--rotate-left, .js .reveal-fx--rotate-up {
  perspective: 1000px;
}

.js .reveal-fx--rotate > *, .js .reveal-fx--rotate-down > *, .js .reveal-fx--rotate-right > *, .js .reveal-fx--rotate-left > *, .js .reveal-fx--rotate-up > * {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
}

.js .reveal-fx--rotate > *, .js .reveal-fx--rotate-down > * {
  transform-origin: top;
  transform: rotateX(-45deg);
}

.js .reveal-fx--rotate-right > * {
  transform-origin: 0;
  transform: rotateY(45deg);
}

.js .reveal-fx--rotate-left > * {
  transform-origin: 100%;
  transform: rotateY(-45deg);
}

.js .reveal-fx--rotate-up > * {
  transform-origin: bottom;
  transform: rotateX(45deg);
}

.js .reveal-fx--text-mask {
  line-height: 1;
  display: inline-flex;
  overflow: hidden;
}

.js .reveal-fx--text-mask > * {
  line-height: inherit;
  transition: transform var(--reveal-fx-duration) var(--reveal-fx-timing-function);
  will-change: transform;
  display: block;
  transform: translateY(100%);
}

.js [class*="reveal-fx--translate"], .js [class*="reveal-fx--scale"] {
  will-change: opacity, transform;
}

.js .reveal-fx--text-mask > *, .js [class*="reveal-fx--rotate"] > * {
  will-change: transform;
}

.js .reveal-fx--clip-x > *, .js .reveal-fx--clip-y > * {
  transition: opacity, clip-path var(--ease-out);
  transition-duration: var(--reveal-fx-duration);
}

.js .reveal-fx--clip-x > * {
  -webkit-clip-path: polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%);
  clip-path: polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%);
}

.js .reveal-fx--clip-y > * {
  -webkit-clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
}

.js .reveal-fx--is-visible {
  opacity: 1;
}

.js .reveal-fx--is-visible[class*="reveal-fx--translate"], .js .reveal-fx--is-visible[class*="reveal-fx--scale"], .js .reveal-fx--is-visible[class*="reveal-fx--rotate"] > *, .js .reveal-fx--is-visible.reveal-fx--text-mask > * {
  transform: translate(0);
}

.js .reveal-fx--is-visible.reveal-fx--clip-x > *, .js .reveal-fx--is-visible.reveal-fx--clip-y > * {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  opacity: 1;
}

:root {
  --scroll-fx-translate-x: 0;
  --scroll-fx-translate-y: 0;
  --scroll-fx-translate-z: 0;
  --scroll-fx-rotate-x: 0;
  --scroll-fx-rotate-y: 0;
  --scroll-fx-rotate-z: 0;
  --scroll-fx-skew-x: 0;
  --scroll-fx-skew-y: 0;
  --scroll-fx-scale-x: 1;
  --scroll-fx-scale-y: 1;
  --scroll-fx-opacity: 1;
}

.js .scroll-fx {
  transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
  opacity: var(--scroll-fx-opacity) !important;
}

.js .scroll-fx.scroll-fx--theme-transition, .js .scroll-fx.scroll-fx--theme-transition > * {
  transition: all .3s;
}

@media (min-width: 32rem) {
  .js .scroll-fx\@xs {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
  }

  .js .scroll-fx\@xs.scroll-fx--theme-transition, .js .scroll-fx\@xs.scroll-fx--theme-transition > * {
    transition: all .3s;
  }
}

@media (min-width: 48rem) {
  .js .scroll-fx\@sm {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
  }

  .js .scroll-fx\@sm.scroll-fx--theme-transition, .js .scroll-fx\@sm.scroll-fx--theme-transition > * {
    transition: all .3s;
  }
}

@media (min-width: 64rem) {
  .js .scroll-fx\@md {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
  }

  .js .scroll-fx\@md.scroll-fx--theme-transition, .js .scroll-fx\@md.scroll-fx--theme-transition > * {
    transition: all .3s;
  }
}

@media (min-width: 80rem) {
  .js .scroll-fx\@lg {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
  }

  .js .scroll-fx\@lg.scroll-fx--theme-transition, .js .scroll-fx\@lg.scroll-fx--theme-transition > * {
    transition: all .3s;
  }
}

@media (min-width: 90rem) {
  .js .scroll-fx\@xl {
    transform: translate3d(var(--scroll-fx-translate-x), var(--scroll-fx-translate-y), var(--scroll-fx-translate-z)) rotateX(var(--scroll-fx-rotate-x)) rotateY(var(--scroll-fx-rotate-y)) rotateZ(var(--scroll-fx-rotate-z)) skewX(var(--scroll-fx-skew-x)) skewY(var(--scroll-fx-skew-y)) scaleX(var(--scroll-fx-scale-x)) scaleY(var(--scroll-fx-scale-y)) !important;
    opacity: var(--scroll-fx-opacity) !important;
  }

  .js .scroll-fx\@xl.scroll-fx--theme-transition, .js .scroll-fx\@xl.scroll-fx--theme-transition > * {
    transition: all .3s;
  }
}

@supports (--css: variables) {
  [data-theme="demo-dark"] {
    --color-primary-darker: #de1ba1;
    --color-primary-darker-h: 319;
    --color-primary-darker-s: 78%;
    --color-primary-darker-l: 49%;
    --color-primary-dark: #e740b3;
    --color-primary-dark-h: 319;
    --color-primary-dark-s: 78%;
    --color-primary-dark-l: 58%;
    --color-primary: #ec65c1;
    --color-primary-h: 319;
    --color-primary-s: 78%;
    --color-primary-l: 66%;
    --color-primary-light: #ef80cc;
    --color-primary-light-h: 319;
    --color-primary-light-s: 78%;
    --color-primary-light-l: 72%;
    --color-primary-lighter: #f3a0d9;
    --color-primary-lighter-h: 319;
    --color-primary-lighter-s: 78%;
    --color-primary-lighter-l: 79%;
    --color-accent-darker: #5436e7;
    --color-accent-darker-h: 250;
    --color-accent-darker-s: 79%;
    --color-accent-darker-l: 56%;
    --color-accent-dark: #6348ea;
    --color-accent-dark-h: 250;
    --color-accent-dark-s: 79%;
    --color-accent-dark-l: 60%;
    --color-accent: #775fec;
    --color-accent-h: 250;
    --color-accent-s: 79%;
    --color-accent-l: 65%;
    --color-accent-light: #8e7bef;
    --color-accent-light-h: 250;
    --color-accent-light-s: 79%;
    --color-accent-light-l: 71%;
    --color-accent-lighter: #a291f2;
    --color-accent-lighter-h: 250;
    --color-accent-lighter-s: 79%;
    --color-accent-lighter-l: 76%;
    --color-bg: #1c1c21;
    --color-bg-h: 240;
    --color-bg-s: 8%;
    --color-bg-l: 12%;
    --color-contrast-lower: #242429;
    --color-contrast-lower-h: 240;
    --color-contrast-lower-s: 6%;
    --color-contrast-lower-l: 15%;
    --color-contrast-low: #3a3a40;
    --color-contrast-low-h: 240;
    --color-contrast-low-s: 5%;
    --color-contrast-low-l: 24%;
    --color-contrast-medium: #898990;
    --color-contrast-medium-h: 240;
    --color-contrast-medium-s: 3%;
    --color-contrast-medium-l: 55%;
    --color-contrast-high: #d8d8df;
    --color-contrast-high-h: 240;
    --color-contrast-high-s: 10%;
    --color-contrast-high-l: 86%;
    --color-contrast-higher: #f4f4fb;
    --color-contrast-higher-h: 240;
    --color-contrast-higher-s: 47%;
    --color-contrast-higher-l: 97%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.demo-scroll-fx-grid {
  grid-template-rows: repeat(6, 1fr);
  grid-template-columns: repeat(6, 1fr);
  display: grid;
}

.demo-scroll-fx-grid > :nth-child(1) {
  grid-area: 1 / 1 / -1 / 4;
}

.demo-scroll-fx-grid > :nth-child(2) {
  grid-area: 2 / 3 / 5 / 7;
}

:root {
  --search-input-btn-width: 2.8em;
  --search-input-icon-size: 1.25em;
}

.search-input {
  position: relative;
}

.search-input .form-control::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search-input--icon-right .form-control {
  padding-right: var(--search-input-btn-width);
}

.search-input--icon-left .form-control {
  padding-left: var(--search-input-btn-width);
}

.search-input__btn {
  -webkit-appearance: none;
  appearance: none;
  width: var(--search-input-btn-width);
  height: 100%;
  color: inherit;
  line-height: inherit;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.search-input__btn:active .icon {
  transform: scale(.9);
}

.search-input__btn .icon {
  width: var(--search-input-icon-size);
  height: var(--search-input-icon-size);
  color: var(--color-contrast-lower);
  margin-left: auto;
  margin-right: auto;
  transition: transform .2s;
  display: block;
}

.search-input--icon-left .search-input__btn {
  pointer-events: none;
  left: 0;
  right: auto;
}

.search-input__btn:focus .icon, .search-input .form-control:focus + .search-input__btn .icon {
  color: var(--color-primary);
}

.sidenav-v2__control {
  width: 100%;
  padding: var(--space-sm);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sidenav-v2__control-text {
  position: relative;
}

.sidenav-v2__control-text > * {
  transition: opacity .4s, transform .4s var(--ease-out);
  display: inline-block;
}

.sidenav-v2__control-text > :last-child {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-10px);
}

.sidenav-v2__icon-arrow .icon__group {
  transform-origin: 8px 8px;
  transition: transform .3s var(--ease-out);
  will-change: transform;
}

.sidenav-v2__icon-arrow .icon__group > * {
  stroke-dasharray: 17;
  transform-origin: 8px 8px;
  transition: transform .3s, stroke-dashoffset .3s;
  transition-timing-function: var(--ease-out);
  transform: translateY(3px);
}

.sidenav-v2__icon-arrow .icon__group > :first-child, .sidenav-v2__icon-arrow .icon__group > :last-child {
  stroke-dashoffset: 8.5px;
}

.sidenav-v2__list:not(:last-child) {
  margin-bottom: var(--space-sm);
}

.sidenav-v2__label {
  margin-bottom: var(--space-xxxs);
}

.sidenav-v2__label > * {
  color: var(--color-contrast-low);
  font-size: var(--text-sm);
}

.sidenav-v2__link {
  color: var(--color-contrast-high);
  align-items: center;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.sidenav-v2__link:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .05);
}

.sidenav-v2__link[aria-current="page"] {
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
}

.sidenav-v2__link-text {
  margin-right: var(--space-xxxs);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sidenav-v2__counter {
  padding: var(--space-xxxxs) var(--space-xxs);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .15);
  font-size: var(--text-xs);
  border-radius: 50em;
  margin-left: auto;
  display: inline-flex;
}

.sidenav-v2:not(.sidenav-v2--static) {
  border-radius: var(--radius-md);
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  transition: all .3s;
}

.sidenav-v2:not(.sidenav-v2--static):hover {
  box-shadow: var(--shadow-md);
}

.sidenav-v2:not(.sidenav-v2--static) .sidenav-v2__nav {
  margin: var(--space-xxs) 0;
  display: none;
}

.sidenav-v2:not(.sidenav-v2--static) .sidenav-v2__label {
  padding-left: var(--space-sm);
}

.sidenav-v2:not(.sidenav-v2--static) .sidenav-v2__link {
  padding: var(--space-xxs) var(--space-sm);
}

.sidenav-v2:not(.sidenav-v2--static).sidenav-v2--expanded .sidenav-v2__control-text > :first-child {
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
}

.sidenav-v2:not(.sidenav-v2--static).sidenav-v2--expanded .sidenav-v2__control-text > :last-child {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.sidenav-v2:not(.sidenav-v2--static).sidenav-v2--expanded .sidenav-v2__nav {
  animation: sidenav-v2-entry-animation .4s var(--ease-out);
  display: block;
}

.sidenav-v2:not(.sidenav-v2--static).sidenav-v2--expanded .sidenav-v2__icon-arrow .icon__group {
  transform: rotate(-90deg);
}

.sidenav-v2:not(.sidenav-v2--static).sidenav-v2--expanded .sidenav-v2__icon-arrow .icon__group > :first-child, .sidenav-v2:not(.sidenav-v2--static).sidenav-v2--expanded .sidenav-v2__icon-arrow .icon__group :last-child {
  stroke-dashoffset: 0;
  transform: translateY(0);
}

.sidenav-v2--static .sidenav-v2__control, html:not(.js) .sidenav-v2 .sidenav-v2__control {
  display: none;
}

.sidenav-v2--static .sidenav-v2__link, html:not(.js) .sidenav-v2 .sidenav-v2__link {
  padding: var(--space-xxs) var(--space-xs);
  border-radius: .1em;
}

.sidenav-v2--static .sidenav-v2__link[aria-current="page"], html:not(.js) .sidenav-v2 .sidenav-v2__link[aria-current="page"] {
  box-shadow: var(--shadow-sm);
}

@keyframes sidenav-v2-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

[class*="sidenav-v2--static"]:before {
  content: "collapsed";
  display: none;
}

@media (min-width: 32rem) {
  .sidenav-v2--static\@xs:before {
    content: "static";
  }
}

@media (min-width: 48rem) {
  .sidenav-v2--static\@sm:before {
    content: "static";
  }
}

@media (min-width: 64rem) {
  .sidenav-v2--static\@md:before {
    content: "static";
  }
}

@media (min-width: 80rem) {
  .sidenav-v2--static\@lg:before {
    content: "static";
  }
}

@media (min-width: 90rem) {
  .sidenav-v2--static\@xl:before {
    content: "static";
  }
}

:root {
  --slider-width: 200px;
  --slider-track-height: 8px;
  --slide-thumb-size: 20px;
}

.slider {
  width: var(--slider-width);
  position: relative;
}

.slider__range {
  --slider-fill-value: 0%;
  --slider-empty-value: 100%;
  width: 100%;
}

.slider__input {
  -webkit-appearance: none;
  width: 100%;
  background: none;
  display: block;
}

.slider__input:focus {
  outline: none;
}

.slider__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: var(--slide-thumb-size);
  height: var(--slide-thumb-size);
  margin-top: calc((var(--slider-track-height)  - var(--slide-thumb-size)) * .5);
  background-color: var(--color-white);
  box-shadow: var(--shadow-md);
  cursor: grab;
  border: none;
  border-radius: 50%;
}

.slider__input:active::-webkit-slider-thumb {
  cursor: grabbing;
}

.slider__input::-moz-range-thumb {
  width: var(--slide-thumb-size);
  height: var(--slide-thumb-size);
  background-color: var(--color-white);
  box-shadow: var(--shadow-md);
  cursor: grab;
  border: none;
  border-radius: 50%;
}

.slider__input:active::-moz-range-thumb {
  cursor: grabbing;
}

.slider__input:active::-ms-thumb {
  cursor: grabbing;
}

.slider__input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2), var(--shadow-md);
}

.slider__input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 3px hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2), var(--shadow-md);
}

.slider__input::-webkit-slider-runnable-track {
  height: var(--slider-track-height);
  margin-top: calc((var(--slide-thumb-size)  - var(--slider-track-height)) * .5);
  background-image: linear-gradient(to right, var(--color-primary) var(--slider-fill-value), var(--color-contrast-lower) var(--slider-fill-value), var(--color-contrast-lower) var(--slider-empty-value));
  border-radius: 50em;
}

.slider__input::-moz-range-track {
  height: var(--slider-track-height);
  background-image: linear-gradient(to right, var(--color-primary) var(--slider-fill-value), var(--color-contrast-lower) var(--slider-fill-value), var(--color-contrast-lower) var(--slider-empty-value));
  border-radius: 50em;
}

.slider__input::-moz-focus-outer {
  border: 0;
}

.js .slider__input::-ms-tooltip {
  display: none;
}

.slider__input::-ms-thumb {
  width: var(--slide-thumb-size);
  width: 20px;
  height: var(--slide-thumb-size);
  height: 20px;
  background-color: var(--color-white);
  box-shadow: var(--shadow-md);
  cursor: grab;
  border: none;
  border-radius: 50%;
  transform: translateY(0);
  box-shadow: inset 0 0 0 2px #0000001a;
  background-color: #fff !important;
}

.slider__input:focus::-ms-thumb {
  box-shadow: inset 0 0 0 2px #2a6df433;
}

.slider__input::-ms-track {
  height: var(--slider-track-height);
  height: 8px;
  background-image: linear-gradient(to right, var(--color-primary) var(--slider-fill-value), var(--color-contrast-lower) var(--slider-fill-value), var(--color-contrast-lower) var(--slider-empty-value));
  color: #0000;
  background-color: #98989a;
  border-radius: 50em;
}

.slider--floating-value .slider__value {
  top: calc(0px - var(--space-md));
  left: var(--slide-thumb-size);
  font-size: var(--text-sm);
  position: absolute;
  transform: translateX(-50%);
}

.slider--input .form-control {
  width: 4em;
  font-size: var(--text-sm);
  text-align: center;
}

.slider--range-not-supported .slider__value, html:not(.js) .slider__value {
  display: none;
}

html {
  scroll-behavior: smooth;
}

.sharebar__btn {
  padding: var(--space-sm);
  background: var(--color-contrast-lower);
  border-radius: 50%;
  transition: all .2s;
  display: inline-block;
}

.sharebar__btn .icon {
  color: var(--color-contrast-high);
  font-size: 1.2em;
  transition: color .2s;
  display: block;
}

.sharebar__btn:hover {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
}

.sharebar__btn:hover .icon {
  color: var(--color-primary);
}

.socials-v3 {
  z-index: 1;
  position: relative;
}

.socials-v3__btns {
  display: flex;
}

.socials-v3__btns > * {
  flex-grow: 1;
}

.socials-v3__btns:hover .socials-v3__btn {
  opacity: .5;
}

.socials-v3__btns:hover .socials-v3__btn:after {
  opacity: 0;
}

.socials-v3__btns li:last-child .socials-v3__btn:after {
  display: none;
}

.socials-v3__btn {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
  text-align: center;
  transition: all .3s;
  display: block;
  position: relative;
}

.socials-v3__btn .icon {
  color: var(--color-contrast-high);
  font-size: 1.6em;
  transition: color .2s, transform .2s;
}

.socials-v3__btn:after {
  width: 1px;
  height: 100%;
  background-color: var(--color-border-alpha);
  content: "";
  transition: opacity .2s;
  position: absolute;
  top: 0;
  right: 0;
}

.socials-v3__btn:hover {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-md);
  opacity: 1 !important;
}

.socials-v3__btn:hover .icon {
  color: var(--color-primary);
  transform: scale(1.2);
}

@media (min-width: 48rem) {
  .socials-v3__btn .icon {
    font-size: 2em;
  }
}

.socials-v4 {
  z-index: 1;
  position: relative;
}

.socials-v4__btn {
  --btn-size: 40px;
  width: var(--btn-size);
  height: var(--btn-size);
  background: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  border-radius: 50%;
  transition: all .2s;
  display: inline-flex;
}

.socials-v4__btn .icon {
  color: var(--color-contrast-high);
  margin: auto;
  transition: color .2s;
  display: block;
}

.socials-v4__btn:hover {
  background-color: var(--color-bg-lighter);
  box-shadow: var(--inner-glow), var(--shadow-md);
}

.socials-v4__btn:hover .icon {
  color: var(--color-primary);
}

@media screen {
  .socials-v4__btn {
    --btn-size: 48px;
  }
}

.sticky-hero__media {
  z-index: 1;
  width: 100%;
  height: 100vh;
  transition: transform .5s var(--ease-in-out);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: sticky;
  top: 0;
  overflow: hidden;
  transform: translateZ(0);
}

.sticky-hero--overlay-layer .sticky-hero__media:after {
  width: 100%;
  height: 100%;
  background-color: var(--color-bg);
  content: "";
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  top: 0;
  left: 0;
}

.sticky-hero--media-is-fixed.sticky-hero--overlay-layer .sticky-hero__media:after {
  opacity: .75;
}

.sticky-hero--media-is-fixed.sticky-hero--scale .sticky-hero__media {
  transform: scale(.9);
}

.sticky-hero__video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sticky-hero__video video {
  width: auto;
  min-width: 100%;
  max-width: none;
  height: auto;
  min-height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.sticky-hero__content {
  z-index: 2;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  transform: translateZ(0);
}

.table {
  z-index: 1;
  margin-top: calc(-2 * var(--space-md));
  border-collapse: separate;
  border-spacing: 0 var(--space-md);
  position: relative;
}

.table__header {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  position: absolute;
}

.table__row .table__cell:last-child:after {
  display: none;
}

.table__cell {
  padding: var(--space-md);
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), .2);
  justify-content: space-between;
  display: flex;
  position: relative;
}

.table__cell:after {
  bottom: 0;
  left: var(--space-md);
  width: calc(100% - 2 * var(--space-md));
  height: 1px;
  background-color: var(--color-contrast-lower);
  content: "";
  position: absolute;
}

.table__label {
  margin-right: var(--space-md);
  color: var(--color-contrast-higher);
  font-weight: bold;
}

.table:not(.table--expanded) .table__cell {
  width: 100%;
  text-align: right;
}

.table:not(.table--expanded) .table__label {
  text-align: left;
}

.js .table {
  opacity: 0;
}

.js .table--loaded {
  opacity: 1;
}

.table--expanded {
  border-bottom: 1px solid var(--color-contrast-lower);
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 0;
}

.table--expanded .table__header {
  clip: auto;
  -webkit-clip-path: none;
  clip-path: none;
  position: static;
}

.table--expanded .table__header .table__cell {
  z-index: 10;
  background-color: var(--color-bg);
  box-shadow: 0 1px 0 var(--color-contrast-lower);
  color: var(--color-contrast-higher);
  font-weight: bold;
  position: relative;
}

.table--expanded .table__body .table__row {
  transition: background-color .2s;
}

.table--expanded .table__body .table__row:hover {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), .2);
}

.table--expanded .table__body .table__row:not(:last-child) {
  border-bottom: 1px solid hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), .4);
}

.table--expanded .table__cell {
  padding: var(--space-sm);
  background-color: #0000;
  display: table-cell;
  border-radius: 0 !important;
}

.table--expanded .table__cell:after, .table--expanded .table__label {
  display: none;
}

.table--expanded .table__header--sticky .table__cell {
  position: sticky;
  top: 0;
}

[class*="table--expanded"]:before {
  display: none;
}

.table--expanded\@xs:before {
  content: "collapsed";
}

@media (min-width: 32rem) {
  .table--expanded\@xs:before {
    content: "expanded";
  }
}

.table--expanded\@sm:before {
  content: "collapsed";
}

@media (min-width: 48rem) {
  .table--expanded\@sm:before {
    content: "expanded";
  }
}

.table--expanded\@md:before {
  content: "collapsed";
}

@media (min-width: 64rem) {
  .table--expanded\@md:before {
    content: "expanded";
  }
}

.table--expanded\@lg:before {
  content: "collapsed";
}

@media (min-width: 80rem) {
  .table--expanded\@lg:before {
    content: "expanded";
  }
}

.table--expanded\@xl:before {
  content: "collapsed";
}

@media (min-width: 90rem) {
  .table--expanded\@xl:before {
    content: "expanded";
  }
}

.tabs__control {
  color: var(--color-contrast-medium);
  text-decoration: none;
}

.tabs__control:focus {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
  outline-offset: 2px;
}

.tabs__control:hover {
  color: var(--color-contrast-high);
}

.js .tabs__control[aria-selected="true"] {
  color: var(--color-contrast-high);
  text-decoration: underline;
}

.text-bg-fx {
  transition: background-size .3s var(--ease-in-out);
  will-change: background-size;
  background-repeat: no-repeat;
}

.text-bg-fx:hover {
  background-size: 100% 100%;
}

.text-bg-fx--scale-x {
  padding: var(--space-xxxxs) 0;
  background-image: linear-gradient(hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2), hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2) );
  background-size: 0% 100%;
}

.text-bg-fx--scale-y {
  background-image: linear-gradient(hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2), hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2) );
  background-position: bottom;
  background-size: 100% 2px;
  text-decoration: none;
}

.text-bg-fx--underline, .text-bg-fx--underline-bold {
  background-size: 0% 100%;
  text-decoration: none;
}

.text-bg-fx--underline {
  background-image: linear-gradient(#0000 calc(100% - 3px), currentColor calc(100% - 3px) calc(100% - 2px), #0000 2px);
}

.text-bg-fx--underline-bold {
  background-image: linear-gradient(transparent 50%, hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2) 50%);
}

.text-bg-fx--text-shadow {
  text-shadow: 1.5px 0 var(--color-bg), -1.5px 0 var(--color-bg);
}

.thank-you {
  z-index: 1;
  position: relative;
}

.thank-you__icon {
  width: 80px;
  height: 80px;
  animation: thank-you-icon .5s var(--ease-out);
}

.thank-you__icon-group {
  transform-origin: 50%;
}

.thank-you__icon-group > :last-child {
  stroke-dashoffset: 55px;
  stroke-dasharray: 55;
  transform-origin: 50%;
  animation: thank-you-icon-check .5s var(--ease-out) forwards;
}

@keyframes thank-you-icon {
  from {
    opacity: 0;
    transform: scale(.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes thank-you-icon-check {
  from {
    stroke-dashoffset: 55px;
  }

  to {
    stroke-dashoffset: 0;
  }
}

:root {
  --tooltip-triangle-size: 12px;
}

.tooltip-trigger {
  border-bottom: 1px dotted var(--color-contrast-high);
  white-space: nowrap;
  cursor: help;
}

.tooltip {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: var(--z-index-popover, 5);
  max-width: 200px;
  padding: var(--space-xxs) var(--space-xs);
  border-radius: var(--radius-md);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .98);
  box-shadow: var(--shadow-md);
  color: var(--color-bg);
  font-size: var(--text-sm);
  line-height: 1.4;
  transition: opacity .2s, visibility .2s;
  display: inline-block;
  position: absolute;
}

.tooltip a {
  color: inherit;
  text-decoration: underline;
}

@supports (clip-path: inset(50%)) {
  .tooltip:before {
    width: var(--tooltip-triangle-size);
    height: var(--tooltip-triangle-size);
    border: inherit;
    background-color: inherit;
    content: "";
    -webkit-clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0% 100%);
    position: absolute;
  }
}

.tootip:not(.tooltip--sticky) {
  pointer-events: none;
}

.tooltip--sm {
  max-width: 150px;
  padding: var(--space-xxxs) var(--space-xxs);
  font-size: var(--text-xs);
}

.tooltip--md {
  max-width: 300px;
  padding: var(--space-xs) var(--space-sm);
}

.tooltip--lg {
  max-width: 350px;
  padding: var(--space-xs) var(--space-sm);
  font-size: var(--text-base-size);
}

.tooltip {
  --tooltip-triangle-translate: 0px;
}

.tooltip--top:before, .tooltip--bottom:before {
  left: calc(50% - var(--tooltip-triangle-size) / 2);
}

.tooltip--top:before {
  bottom: calc(var(--tooltip-triangle-size) * -.5);
  transform: translateX(var(--tooltip-triangle-translate)) rotate(-45deg);
}

.tooltip--bottom:before {
  top: calc(var(--tooltip-triangle-size) * -.5);
  transform: translateX(var(--tooltip-triangle-translate)) rotate(135deg);
}

.tooltip--left:before, .tooltip--right:before {
  top: calc(50% - var(--tooltip-triangle-size) / 2);
}

.tooltip--left:before {
  right: calc(var(--tooltip-triangle-size) * -.5);
  transform: translateX(var(--tooltip-triangle-translate)) rotate(-135deg);
}

.tooltip--right:before {
  left: calc(var(--tooltip-triangle-size) * -.5);
  transform: translateX(var(--tooltip-triangle-translate)) rotate(45deg);
}

.tooltip--is-hidden {
  visibility: hidden;
  opacity: 0;
}

:root {
  --accordion-v2-icon-size: 1em;
  --accordion-v2-icon-stroke-width: 1.5px;
}

.accordion-v2__item {
  box-shadow: var(--shadow-sm);
  transition: all .3s;
}

.accordion-v2__item:hover {
  box-shadow: var(--shadow-md);
}

.accordion-v2__header {
  width: 100%;
  text-align: left;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.accordion-v2__header .icon {
  font-size: var(--accordion-v2-icon-size);
}

.accordion-v2__header .icon .icon__group {
  stroke-width: var(--accordion-v2-icon-stroke-width);
}

.accordion-v2__icon-arrow .icon__group {
  transform-origin: 8px 8px;
  will-change: transform;
}

.accordion-v2__icon-arrow .icon__group > * {
  stroke-dasharray: 17;
  transform-origin: 8px 8px;
  transform: translateY(3px);
}

.accordion-v2__icon-arrow .icon__group > :first-child, .accordion-v2__icon-arrow .icon__group > :last-child {
  stroke-dashoffset: 8.5px;
}

.accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group {
  transform: rotate(-90deg);
}

.accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group > :first-child, .accordion-v2__item--is-open > .accordion-v2__header > .accordion-v2__icon-arrow .icon__group :last-child {
  stroke-dashoffset: 0;
  transform: translateY(0);
}

.js .accordion-v2__panel {
  will-change: height;
  display: none;
  transform: translateZ(0);
}

.js .accordion-v2__item--is-open > .accordion-v2__panel {
  display: block;
}

.accordion-v2[data-animation="on"] .accordion-v2__item--is-open .accordion-v2__panel > * {
  animation: accordion-v2-entry-animation .4s var(--ease-out);
}

.accordion-v2[data-animation="on"] .accordion-v2__icon-arrow .icon__group {
  transition: transform .3s var(--ease-out);
}

.accordion-v2[data-animation="on"] .accordion-v2__icon-arrow .icon__group > * {
  transition: transform .3s, stroke-dashoffset .3s;
  transition-timing-function: var(--ease-out);
}

@keyframes accordion-v2-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

:root {
  --carousel-grid-gap: var(--space-xs);
  --carousel-item-auto-size: 260px;
  --carousel-transition-duration: .5s;
}

.carousel {
  position: relative;
}

.carousel__list {
  will-change: transform;
  flex-wrap: nowrap;
  display: flex;
}

.carousel__item {
  width: var(--carousel-item-auto-size);
  margin-right: var(--carousel-grid-gap);
  margin-bottom: var(--carousel-grid-gap);
  flex-shrink: 0;
}

.js .carousel__list--animating {
  transition-duration: var(--carousel-transition-duration);
  transition-property: transform;
  transition-timing-function: var(--ease-out);
}

.js .carousel__item {
  opacity: 0;
  margin-bottom: 0;
}

.js .carousel--loaded .carousel__item {
  opacity: 1;
}

.js .carousel:not(.carousel--is-dragging) .carousel__list:not(.carousel__list--animating) .carousel__item[tabindex="-1"] > * {
  visibility: hidden;
}

.js .carousel[data-drag="on"] .carousel__item {
  -webkit-user-select: none;
  user-select: none;
}

.js .carousel[data-drag="on"] .carousel__item img {
  pointer-events: none;
}

.carousel__control {
  z-index: 1;
  width: 40px;
  height: 40px;
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.carousel__control:active {
  transform: translateY(1px);
}

.carousel__control:hover {
  box-shadow: var(--shadow-md);
}

.carousel__control[disabled] {
  background-color: var(--color-contrast-lower);
  box-shadow: none;
  color: var(--color-contrast-low);
  pointer-events: none;
}

.carousel__control .icon {
  font-size: 20px;
  display: block;
}

.carousel__navigation {
  width: 100%;
  margin-top: var(--space-sm);
  justify-content: center;
  align-items: center;
  gap: var(--space-xs);
  grid-template-columns: repeat(auto-fit, 10px);
  display: grid;
}

.carousel__nav-item {
  margin: 0 var(--space-xxxs);
  display: inline-block;
}

@supports (grid-area: auto) {
  .carousel__nav-item {
    margin: 0;
  }
}

.carousel__nav-item button {
  width: 1em;
  height: 1em;
  background-color: var(--color-contrast-high);
  cursor: pointer;
  opacity: .4;
  border-radius: 50%;
  font-size: 10px;
  transition: background .3s;
  display: block;
  position: relative;
}

.carousel__nav-item button:before {
  width: 1em;
  height: 1em;
  border: 1px solid var(--color-contrast-high);
  border-radius: inherit;
  content: "";
  opacity: 0;
  font-size: 16px;
  transition: all .3s;
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  transform: scale(0);
}

.carousel__nav-item button:focus {
  outline: none;
}

.carousel__nav-item button:focus:before {
  opacity: 1;
  transform: scale(1);
}

.carousel__nav-item--selected button {
  opacity: 1;
}

.carousel__navigation--pagination {
  grid-template-columns: repeat(auto-fit, minmax(24px, auto));
}

.carousel__navigation--pagination .carousel__nav-item button {
  width: 100%;
  height: auto;
  padding: var(--space-xxxs) var(--space-xxs);
  border-radius: var(--radius-md);
  color: var(--color-bg);
  font-size: var(--text-xs);
  text-align: center;
}

.carousel__navigation--pagination .carousel__nav-item button:focus {
  outline: 1px solid var(--color-primary);
  outline-offset: 2px;
}

html:not(.js) .carousel__list {
  overflow: auto;
}

.dr-cart__product {
  grid-gap: var(--space-xs);
  padding: var(--space-sm) 0;
  grid-template-columns: 80px 1fr auto;
  align-items: start;
  display: grid;
}

.dr-cart__product:not(:last-child) {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.dr-cart__img {
  box-shadow: var(--shadow-md);
  transition: all .2s;
  display: block;
  overflow: hidden;
}

.dr-cart__img img {
  display: block;
}

.dr-cart__img:hover {
  box-shadow: var(--shadow-sm);
  opacity: .85;
}

.dr-cart__select {
  --select-icon-size: .85em;
  --select-icon-right-margin: var(--space-xxs);
  width: 3.6em;
  font-size: .875em;
}

.dr-cart__select .select__input {
  padding: var(--space-xxxxs) var(--space-xxxs);
}

.dr-cart__remove-btn {
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  color: var(--color-primary);
  font-size: var(--text-sm);
  line-height: inherit;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  padding: 0;
}

.dr-cart__remove-btn:hover {
  text-decoration: underline;
}

.dr-cart__remove-btn:focus {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
  outline-offset: 2px;
}

.prop-table__cell {
  width: 50%;
  padding: var(--space-sm) var(--space-sm) var(--space-sm) 0;
  border-bottom: 1px solid var(--color-contrast-lower);
}

.prop-table__cell--th {
  text-align: left;
  font-weight: bold;
}

.prop-table--v2 {
  border-left: 4px solid var(--color-primary);
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .15);
}

.prop-table--v2 .prop-table__cell {
  padding: var(--space-sm);
  border-width: 0;
}

:root {
  --cart-product-image-size: 96px;
}

@media (min-width: 64rem) {
  :root {
    --cart-product-image-size: 128px;
  }
}

.cart {
  z-index: 1;
  position: relative;
}

.cart__product {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.cart__product:first-child {
  border-top: 1px solid var(--color-contrast-lower);
}

.cart__product-img {
  width: var(--cart-product-image-size);
  flex-shrink: 0;
}

.cart__product-img a, .cart__product-img img {
  width: 100%;
  display: block;
}

.cart__product-img a {
  transition: opacity .2s;
  overflow: hidden;
}

.cart__product-img a:hover {
  opacity: .85;
}

.cart__product-info {
  grid-gap: var(--space-xs);
  flex-grow: 1;
  display: grid;
}

.cart__product-tot {
  grid-gap: var(--space-xs);
  display: grid;
}

.cart__select {
  --select-icon-size: 12px;
  --select-icon-right-margin: var(--space-sm);
  --select-text-icon-gap: var(--space-xxxs);
  width: 6em;
  font-size: var(--text-sm);
}

.cart__remove-btn {
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  color: var(--color-primary);
  font-size: var(--text-sm);
  line-height: inherit;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  padding: 0;
}

.cart__remove-btn:hover {
  text-decoration: underline;
}

.cart__remove-btn:focus {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
  outline-offset: 2px;
}

.cart__gift-message {
  width: 100%;
  margin-top: var(--space-xxs);
  margin-bottom: var(--space-sm);
  display: none;
}

.cart__gift .radio:checked ~ .cart__gift-message {
  display: block;
}

@media (min-width: 32rem) {
  .cart__product-info {
    grid-gap: var(--space-md);
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: start;
  }

  .cart__product-tot {
    text-align: right;
  }

  .cart__select {
    margin-left: auto;
  }
}

@media (min-width: 48rem) {
  .cart__product, .cart__product-info {
    align-items: center;
  }

  .cart__product-tot {
    grid-gap: var(--space-sm);
    grid-template-columns: auto minmax(6em, auto);
  }

  .cart__select {
    align-self: start;
  }
}

@media (min-width: 64rem) {
  .cart__subtotal {
    top: var(--space-md);
    padding: var(--space-sm);
    border-radius: var(--radius-md);
    background-color: var(--color-bg-light);
    box-shadow: var(--inner-glow), var(--shadow-sm);
    position: sticky;
  }
}

.checkout {
  z-index: 1;
  position: relative;
}

.checkout__billing-checkbox {
  display: none;
}

.js .checkout__billing-checkbox {
  display: block;
}

.js .checkout__billing-info {
  display: none;
}

@media (min-width: 80rem) {
  .checkout .order-summary {
    top: calc(var(--space-sm)  + var(--mega-nav-height));
    max-height: calc(100vh - var(--space-sm)  - var(--mega-nav-height));
    position: sticky;
    overflow: auto;
  }

  .checkout .order-summary__header, .checkout .order-summary__footer {
    position: sticky;
  }

  .checkout .order-summary__header {
    top: 0;
  }

  .checkout .order-summary__footer {
    bottom: 0;
  }
}

:root {
  --comments-author-img-size: 2.6em;
  --comments-author-content-gap: var(--space-xs);
  --comments-gap: var(--space-md);
}

.comments__sorting-label {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
}

.comments__sorting-label + label {
  color: var(--color-contrast-medium);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.comments__sorting-label + label:hover {
  color: var(--color-contrast-high);
}

.comments__sorting-label:checked + label {
  color: var(--color-contrast-higher);
  text-decoration: underline;
}

.comments__sorting-label:focus + label {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
  outline-offset: 2px;
}

.comments__comment:not(:last-child) {
  margin-bottom: var(--comments-gap);
}

.comments__author-img {
  margin-right: var(--comments-author-content-gap);
  border-radius: 50%;
  flex-shrink: 0;
  transition: opacity .2s;
  display: block;
  overflow: hidden;
}

.comments__author-img img {
  width: var(--comments-author-img-size);
  height: var(--comments-author-img-size);
  display: block;
}

.comments__author-img:hover {
  opacity: .75;
}

.comments__author-name {
  color: var(--color-contrast-higher);
  font-weight: bold;
  text-decoration: none;
}

.comments__author-name:hover {
  text-decoration: underline;
}

.comments__readmore-btn {
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  color: var(--color-contrast-medium);
  line-height: inherit;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  padding: 0;
}

.comments__readmore-btn:hover {
  color: var(--color-contrast-higher);
  cursor: pointer;
  text-decoration: underline;
}

.comments__vote-btn {
  color: var(--color-contrast-medium);
  align-items: center;
  display: inline-flex;
}

.comments__vote-btn:hover {
  color: var(--color-contrast-higher);
  cursor: pointer;
}

.comments__vote-btn--pressed, .comments__vote-btn--pressed:hover {
  color: var(--color-accent);
}

.comments__vote-btn--pressed .comments__vote-icon-wrapper .icon {
  animation: .3s comments-vote-icon;
}

.comments__vote-btn--pressed .comments__vote-icon-wrapper:before {
  animation: .6s comments-vote-icon-circle;
}

.comments__vote-icon-wrapper {
  position: relative;
}

.comments__vote-icon-wrapper .icon {
  z-index: 2;
  transition: transform .3s;
  position: relative;
}

.comments__vote-icon-wrapper:before {
  z-index: 1;
  width: 2em;
  height: 2em;
  content: "";
  opacity: 1;
  pointer-events: none;
  background-color: currentColor;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 1em);
  left: calc(50% - 1em);
  transform: scale(0);
}

@keyframes comments-vote-icon {
  0%, 100% {
    transform: scale(1);
  }

  30% {
    transform: scale(.8);
  }
}

@keyframes comments-vote-icon-circle {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(1);
  }
}

.comments__label-btn {
  color: var(--color-contrast-medium);
}

.comments__label-btn:hover {
  color: var(--color-contrast-higher);
  cursor: pointer;
  text-decoration: underline;
}

.comments__time {
  color: var(--color-contrast-medium);
}

.comments__inline-divider {
  width: 4px;
  height: 4px;
  background-color: var(--color-contrast-low);
  border-radius: 50%;
}

.comments__details {
  margin-top: var(--space-sm);
  margin-left: calc(var(--comments-author-img-size)  + var(--comments-author-content-gap));
  padding-left: var(--comments-author-content-gap);
  border-left: 3px solid var(--color-contrast-lower);
}

.comments__details .details__content > ul {
  margin-top: var(--space-sm);
}

.comments--no-profile-img {
  --comments-author-img-size: 0;
}

.comments--no-profile-img .comments__author-img {
  display: none;
}

.contact-v2 {
  z-index: 1;
  position: relative;
}

:root {
  --dropdown-item-padding: var(--space-xxs) var(--space-sm);
}

.dropdown {
  position: relative;
}

.dropdown__menu {
  --space-unit: 1rem;
  --text-unit: 1rem;
  visibility: hidden;
  z-index: var(--z-index-popover, 5);
  width: 200px;
  padding: var(--space-xxxs) 0;
  border-radius: var(--radius-md);
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  font-size: var(--text-unit);
  opacity: 0;
  transition: opacity .2s, visibility 0s .2s;
  position: absolute;
  top: 100%;
  left: 0;
}

@media (pointer: fine) {
  .dropdown__wrapper:hover > .dropdown__menu, .dropdown__sub-wrapper:hover > .dropdown__menu {
    visibility: visible;
    opacity: 1;
    transition: opacity .2s;
  }

  .dropdown__sub-wrapper:hover > .dropdown__menu {
    left: 100%;
  }
}

@media not all and (pointer: fine) {
  .dropdown__trigger-icon {
    display: none;
  }
}

.dropdown__item {
  padding: var(--dropdown-item-padding);
  color: var(--color-contrast-high);
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  transition: all .2s;
  display: block;
  overflow: hidden;
}

.dropdown__item:hover, .dropdown__item.dropdown__item--hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .075);
}

.dropdown__separator {
  height: 1px;
  margin: var(--dropdown-item-padding);
  background-color: var(--color-contrast-lower);
}

.dropdown__sub-wrapper {
  position: relative;
}

.dropdown__sub-wrapper > .dropdown__item {
  padding-right: calc(var(--space-sm)  + 12px);
  position: relative;
}

.dropdown__sub-wrapper > .dropdown__item .icon {
  top: calc(50% - 6px);
  right: var(--space-xxs);
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
}

.dropdown__sub-wrapper > .dropdown__menu {
  top: calc(var(--space-xxs) * -1);
  box-shadow: var(--inner-glow), var(--shadow-md);
}

.js .dropdown__menu {
  top: calc(100% + 4px);
}

.js .dropdown__sub-wrapper .dropdown__menu {
  top: calc(var(--space-xxs) * -1);
}

@media (pointer: fine) {
  .js .dropdown__menu--is-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity .2s;
  }
}

.js .dropdown__menu--is-hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s, visibility 0s .2s;
}

.js .dropdown__sub-wrapper > .dropdown__menu--is-visible, .js .dropdown__sub-wrapper > .dropdown__menu--is-hidden {
  left: 100%;
}

.js .dropdown__sub-wrapper > .dropdown__menu--is-visible.dropdown__menu--left, .js .dropdown__sub-wrapper > .dropdown__menu--is-hidden.dropdown__menu--left {
  left: -100%;
}

:root {
  --feature-v8-gap: var(--space-xl);
}

.feature-v8 {
  z-index: 1;
  position: relative;
}

.feature-v8__main-content {
  padding-bottom: calc(var(--feature-v8-gap) * 2);
}

.feature-v8__sub-content {
  margin-top: calc(var(--feature-v8-gap) * -1);
}

:root {
  --gallery-v2-offset: 50px;
}

.gallery-v2 {
  z-index: 1;
  position: relative;
}

@media (min-width: 48rem) {
  .gallery-v2__grid {
    padding-bottom: var(--gallery-v2-offset);
  }

  .gallery-v2__item:nth-child(2n) {
    transform: translateY(var(--gallery-v2-offset));
  }
}

:root {
  --menu-bar-button-size: 2.5em;
  --menu-bar-icon-size: 1em;
  --menu-bar-horizontal-gap: var(--space-xxs);
  --menu-bar-vertical-gap: 4px;
  --menu-bar-label-size: var(--text-xs);
}

.menu-bar {
  align-items: center;
  list-style: none;
  display: inline-flex;
}

.menu-bar--sm {
  --menu-bar-button-size: 2em;
  --menu-bar-icon-size: 16px;
  --menu-bar-horizontal-gap: 4px;
  --menu-bar-vertical-gap: 4px;
  --menu-bar-label-size: var(--text-xs);
}

.menu-bar__item {
  width: var(--menu-bar-button-size);
  height: var(--menu-bar-button-size);
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color .2s;
  display: flex;
  position: relative;
}

.menu-bar__item:not(:last-child) {
  margin-right: var(--menu-bar-horizontal-gap);
}

.menu-bar__item:hover, .menu-bar__item.menu-control--active {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .1);
}

.menu-bar__item:hover > .menu-bar__icon, .menu-bar__item.menu-control--active > .menu-bar__icon {
  color: var(--color-contrast-higher);
}

.menu-bar__item:hover > .menu-bar__label, .menu-bar__item.menu-control--active > .menu-bar__label {
  clip: auto;
  width: auto;
  height: auto;
  -webkit-clip-path: none;
  clip-path: none;
}

.menu-bar__item:focus {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .1);
  outline: none;
}

.menu-bar__item:active {
  background-color: var(--color-contrast-low);
}

.menu-bar__item:focus:active {
  background-color: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
}

.menu-bar__item--trigger {
  display: none;
}

.menu-bar__icon {
  color: var(--color-contrast-high);
  font-size: var(--menu-bar-icon-size);
  transition: color .2s;
  display: block;
}

.menu-bar__label {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1px;
  height: 1px;
  padding: var(--space-xxs) var(--space-xs);
  transform: translateX(-50%) translateY(var(--menu-bar-vertical-gap));
  border-radius: var(--radius-md);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .95);
  color: var(--color-bg);
  font-size: var(--menu-bar-label-size);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 100%;
  left: 50%;
  overflow: hidden;
}

.menu-bar--collapsed .menu-bar__item--hide {
  display: none;
}

.menu-bar--collapsed .menu-bar__item--trigger {
  display: flex;
}

.js .menu-bar {
  opacity: 0;
}

.js .menu-bar:before {
  content: "collapsed";
  display: none;
}

.js .menu-bar--loaded {
  opacity: 1;
}

@media (min-width: 32rem) {
  .js .menu-bar--expanded\@xs:before {
    content: "expanded";
  }
}

@media (min-width: 48rem) {
  .js .menu-bar--expanded\@sm:before {
    content: "expanded";
  }
}

@media (min-width: 64rem) {
  .js .menu-bar--expanded\@md:before {
    content: "expanded";
  }
}

@media (min-width: 80rem) {
  .js .menu-bar--expanded\@lg:before {
    content: "expanded";
  }
}

@media (min-width: 90rem) {
  .js .menu-bar--expanded\@xl:before {
    content: "expanded";
  }
}

.prod-card-v2 {
  --rating-icon-size: 1.475em;
  position: relative;
}

.prod-card-v2__img-link {
  display: block;
  position: relative;
  overflow: hidden;
}

.prod-card-v2__img-link img {
  width: 100%;
  transition: all .3s;
  display: block;
}

.prod-card-v2__img-link img:nth-child(2) {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.prod-card-v2__img-link:hover img:nth-child(1) {
  opacity: .85;
}

.prod-card-v2__img-link:hover img:nth-child(2) {
  opacity: 1;
}

.prod-card-v2__badge {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
  top: var(--space-sm);
  right: var(--space-sm);
  padding: var(--space-xxs) var(--space-sm);
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .9);
  color: var(--color-white);
  font-size: var(--text-sm);
  pointer-events: none;
  position: absolute;
}

.prod-card-v2__badge--discount {
  background-color: hsla(var(--color-accent-h), var(--color-accent-s), var(--color-accent-l), .95);
}

.product-card-v2__title {
  color: var(--color-contrast-higher);
  font-weight: bold;
  text-decoration: none;
}

.product-card-v2__title:hover {
  text-decoration: underline;
}

.prod-card-v2__price {
  text-decoration: none;
}

.prod-card-v2__old-price {
  color: var(--color-contrast-medium);
  text-decoration: line-through;
}

.prod-card-v2__old-price:before {
  clip: rect(1px, 1px, 1px, 1px);
  content: "original price";
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  position: absolute;
}

@media (min-width: 64rem) {
  .product-v3__panel {
    -webkit-overflow-scrolling: touch;
    top: calc(var(--space-md)  + var(--mega-nav-height));
    height: 100%;
    max-height: calc(100vh - var(--space-md)  - var(--mega-nav-height));
    position: sticky;
    overflow: auto;
  }
}

.product-v3__cta-clone {
  visibility: hidden;
  z-index: var(--z-index-fixed-element);
  width: 100%;
  padding: var(--space-sm) var(--space-md);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), .9);
  box-shadow: var(--shadow-lg);
  transition: transform .3s, visibility 0s .3s;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.product-v3__cta-clone--is-visible {
  visibility: visible;
  transition: transform .3s;
  transform: translateY(0);
}

.slider--multi-value {
  position: relative;
}

.slider--multi-value .slider__range {
  pointer-events: none;
}

.slider--multi-value .slider__range:nth-of-type(1) {
  --slider-fill-value-start: 0%;
  --slider-fill-value-end: 100%;
}

.slider--multi-value .slider__range:nth-of-type(2) {
  position: absolute;
  top: 0;
  left: 0;
}

.slider--multi-value .slider__input::-webkit-slider-thumb {
  z-index: 2;
  pointer-events: auto;
  position: relative;
}

.slider--multi-value .slider__input::-moz-range-thumb {
  z-index: 2;
  pointer-events: auto;
  position: relative;
}

.slider--multi-value .slider__input::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, var(--color-contrast-lower) var(--slider-fill-value-start), var(--color-primary) var(--slider-fill-value-start), var(--color-primary) var(--slider-fill-value-end), var(--color-contrast-lower) var(--slider-fill-value-end));
  pointer-events: none;
}

.slider--multi-value .slider__input::-moz-range-track {
  background-image: linear-gradient(to right, var(--color-contrast-lower) var(--slider-fill-value-start), var(--color-primary) var(--slider-fill-value-start), var(--color-primary) var(--slider-fill-value-end), var(--color-contrast-lower) var(--slider-fill-value-end));
  pointer-events: none;
}

.slider--multi-value .slider__range:nth-of-type(2) .slider__input::-moz-range-track {
  background-image: none;
}

:not(*)::-ms-track, .slider--multi-value.slider--ms-fallback {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

:not(*)::-ms-track, .slider--multi-value.slider--ms-fallback .slider__range {
  width: 48%;
  pointer-events: auto;
}

:not(*)::-ms-track, .slider--multi-value.slider--ms-fallback .slider__range:nth-of-type(2) {
  position: relative;
}

.slider__separator {
  margin: auto var(--space-xs);
}

.slider--multi-value.slider--range-not-supported .slider__range {
  width: 45%;
  display: inline-block;
}

.slider--multi-value.slider--range-not-supported .slider__range:nth-of-type(2) {
  position: relative;
}

.slider--range-not-supported .slider__separator, html:not(.js) .slider__separator {
  display: none;
}

:root {
  --slideshow-height: 280px;
  --slideshow-fade-transition-duration: .25s;
  --slideshow-slide-transition-duration: .35s;
  --slideshow-prx-transition-duration: .5s;
  --slideshow-btn-width: 1.6em;
  --slideshow-btn-height: 3.2em;
  --slideshow-btn-icon-size: 1.6em;
  --slideshow-btn-offset: var(--space-xs);
}

@media (min-width: 48rem) {
  :root {
    --slideshow-height: 380px;
  }
}

@media (min-width: 64rem) {
  :root {
    --slideshow-height: 480px;
  }
}

@media (min-width: 80rem) {
  :root {
    --slideshow-height: 75vh;
  }
}

.slideshow__item {
  height: var(--slideshow-height);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
}

.slideshow__item:focus {
  outline: none;
}

.slideshow__item--overlay:after {
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(to top, #040404bf, #36363699);
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow--ratio-16\:9 .slideshow__item {
  height: 0;
  padding-bottom: 56.25%;
}

.slideshow--ratio-4\:3 .slideshow__item {
  height: 0;
  padding-bottom: 75%;
}

.slideshow--ratio-1\:1 .slideshow__item {
  height: 0;
  padding-bottom: 100%;
}

.js .slideshow {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.js .slideshow__content {
  overflow: hidden;
}

.js .slideshow__item {
  visibility: hidden;
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.js .slideshow__item--selected {
  visibility: visible;
  z-index: 3;
  position: relative;
}

.js .slideshow--transition-fade .slideshow__item {
  opacity: 0;
  transition: opacity 0s var(--slideshow-fade-transition-duration), visibility 0s var(--slideshow-fade-transition-duration);
}

.js .slideshow--transition-fade .slideshow__item--selected {
  opacity: 1;
  transition: opacity var(--slideshow-fade-transition-duration), visibility var(--slideshow-fade-transition-duration);
}

.js .slideshow--transition-slide .slideshow__item {
  animation-duration: var(--slideshow-slide-transition-duration);
  animation-timing-function: var(--ease-out);
  animation-fill-mode: forwards;
}

.js .slideshow--transition-slide .slideshow__item > * {
  visibility: hidden;
}

.js .slideshow--transition-slide .slideshow__item--selected > * {
  visibility: visible;
}

.js .slideshow--transition-slide .slideshow__item--slide-in-left {
  animation-name: slide-in-left;
}

.js .slideshow--transition-slide .slideshow__item--slide-in-right {
  animation-name: slide-in-right;
}

.js .slideshow--transition-slide .slideshow__item--slide-out-left {
  animation-name: slide-out-left;
}

.js .slideshow--transition-slide .slideshow__item--slide-out-right {
  animation-name: slide-out-right;
}

.js .slideshow--transition-slide .slideshow__item--slide-out-left, .js .slideshow--transition-slide .slideshow__item--slide-out-right {
  z-index: 2;
}

.js .slideshow--transition-slide .slideshow__item--slide-out-left.slideshow__item--selected, .js .slideshow--transition-slide .slideshow__item--slide-out-right.slideshow__item--selected {
  z-index: 3;
}

.js .slideshow--transition-slide .slideshow__item--slide-out-left > *, .js .slideshow--transition-slide .slideshow__item--slide-out-right > * {
  visibility: visible;
}

@keyframes slide-in-left {
  0% {
    visibility: visible;
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-right {
  0% {
    visibility: visible;
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out-left {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-out-right {
  0% {
    visibility: visible;
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.js .slideshow--transition-prx .slideshow__item {
  animation-duration: var(--slideshow-prx-transition-duration);
  animation-timing-function: var(--ease-out);
  animation-fill-mode: forwards;
}

.js .slideshow--transition-prx .slideshow__item > * {
  visibility: hidden;
}

.js .slideshow--transition-prx .slideshow__item--selected > * {
  visibility: visible;
}

.js .slideshow--transition-prx .slideshow__item--prx-in-left {
  animation-name: prx-in-left;
}

.js .slideshow--transition-prx .slideshow__item--prx-in-right {
  animation-name: prx-in-right;
}

.js .slideshow--transition-prx .slideshow__item--prx-out-left {
  animation-name: prx-out-left;
}

.js .slideshow--transition-prx .slideshow__item--prx-out-right {
  animation-name: prx-out-right;
}

.js .slideshow--transition-prx .slideshow__item--prx-out-left, .js .slideshow--transition-prx .slideshow__item--prx-out-right {
  z-index: 2;
}

.js .slideshow--transition-prx .slideshow__item--prx-out-left.slideshow__item--selected, .js .slideshow--transition-prx .slideshow__item--prx-out-right.slideshow__item--selected {
  z-index: 3;
}

.js .slideshow--transition-prx .slideshow__item--prx-out-left > *, .js .slideshow--transition-prx .slideshow__item--prx-out-right > * {
  visibility: visible;
}

@keyframes prx-in-left {
  0% {
    visibility: visible;
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes prx-in-right {
  0% {
    visibility: visible;
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes prx-out-left {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: .3;
    transform: translateX(40%);
  }
}

@keyframes prx-out-right {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: .3;
    transform: translateX(-40%);
  }
}

.js .slideshow[data-swipe="on"] .slideshow__content {
  -webkit-user-select: none;
  user-select: none;
}

.js .slideshow[data-swipe="on"] .slideshow__content img {
  pointer-events: none;
}

.slideshow__control {
  display: none;
}

.js .slideshow[data-controls="hover"] .slideshow__control {
  opacity: 0;
  transition: opacity .3s;
}

.js .slideshow[data-controls="hover"]:hover .slideshow__control {
  opacity: 1;
}

.js .slideshow[data-swipe="on"] .slideshow__control {
  display: none;
}

.js .slideshow__control {
  z-index: 4;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.js .slideshow__control:first-of-type {
  left: var(--slideshow-btn-offset);
}

.js .slideshow__control:last-of-type {
  right: var(--slideshow-btn-offset);
}

@media (min-width: 64rem) {
  .js .slideshow[data-swipe="on"] .slideshow__control {
    display: block;
  }
}

.slideshow__btn {
  width: var(--slideshow-btn-width);
  height: var(--slideshow-btn-height);
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .75);
  cursor: pointer;
  border-radius: 0;
  transition: background .2s, transform .2s;
  display: block;
}

.slideshow__btn:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .85);
}

.slideshow__btn:hover .icon {
  color: var(--color-bg);
}

.slideshow:not(.slideshow--is-animating) .slideshow__btn:active {
  transform: translateY(2px);
}

.slideshow__btn .icon {
  width: var(--slideshow-btn-icon-size);
  height: var(--slideshow-btn-icon-size);
  color: var(--color-white);
  margin: 0 auto;
  transition: color .2s;
  display: block;
}

@supports (grid-area: auto) {
  .slideshow__btn {
    background-color: #0000;
  }

  .slideshow__btn .icon {
    color: var(--color-contrast-higher);
  }
}

.slideshow__navigation {
  z-index: 4;
  width: 100%;
  height: 32px;
  padding-right: var(--space-md);
  background: none;
  justify-content: end;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
}

.slideshow__nav-item {
  margin: 0 var(--space-xxxs);
  display: inline-block;
}

.slideshow__nav-item button {
  width: 1em;
  height: 1em;
  color: var(--color-contrast-higher);
  cursor: pointer;
  opacity: .4;
  background-color: currentColor;
  border-radius: 50%;
  font-size: 8px;
  transition: background .3s;
  display: block;
  position: relative;
}

.slideshow__nav-item button:before {
  width: 1em;
  height: 1em;
  border: 1px solid var(--color-contrast-high);
  border-radius: inherit;
  content: "";
  opacity: 0;
  font-size: 14px;
  transition: all .3s;
  position: absolute;
  top: calc(50% - .5em);
  left: calc(50% - .5em);
  transform: scale(0);
}

.slideshow__nav-item button:focus {
  outline: none;
}

.slideshow__nav-item button:focus:before {
  opacity: 1;
  transform: scale(1);
}

.slideshow__nav-item--selected button {
  opacity: 1;
}

@media (min-width: 64rem) {
  .slideshow__navigation {
    height: 40px;
  }

  .slideshow__nav-item button {
    font-size: 10px;
  }

  .slideshow__nav-item button:before {
    font-size: 16px;
  }
}

.sticky-sharebar {
  visibility: hidden;
  z-index: var(--z-index-fixed-element);
  top: 0;
  right: var(--space-md);
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: visibility 0s .3s, opacity .3s, transform .3s var(--ease-in-out);
  align-items: center;
  display: flex;
  position: fixed;
  transform: translateX(10%);
}

.sticky-sharebar--on-target {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity .3s, transform .3s var(--ease-in-out);
  transform: translateX(0);
}

.sticky-sharebar__list {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), .95);
  box-shadow: var(--shadow-md);
  pointer-events: auto;
  border-radius: 50em;
  padding: 4px;
}

.sticky-sharebar__btn {
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  display: flex;
  position: relative;
}

.sticky-sharebar__btn .icon {
  z-index: 2;
  color: var(--color-contrast-medium);
  margin: auto;
  transition: color .2s;
  display: block;
  position: relative;
}

.sticky-sharebar__btn:before {
  z-index: 1;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .1);
  content: "";
  transition: transform .3s var(--ease-out);
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.sticky-sharebar__btn:hover .icon {
  color: var(--color-contrast-higher);
}

.sticky-sharebar__btn:hover:before {
  transform: scale(1);
}

:root {
  --toc-border-width: 1px;
}

.toc {
  --space-unit: 1rem;
  box-shadow: inset var(--toc-border-width) 0 0 var(--color-contrast-lower);
}

.toc--sticky {
  -webkit-overflow-scrolling: touch;
  top: calc(var(--space-md)  + var(--mega-nav-height));
  max-height: calc(100vh - var(--space-md)  - var(--mega-nav-height));
  position: sticky;
  overflow: auto;
}

.toc__list {
  position: relative;
}

.toc__list .toc__list .toc__link {
  padding-left: calc(var(--space-sm) * 2);
}

.toc__link, .toc__label {
  padding: var(--space-xxxs) var(--space-sm);
}

.toc__link {
  color: var(--color-contrast-medium);
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.toc__link:before {
  width: var(--toc-border-width);
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}

.toc__link:hover {
  color: var(--color-contrast-high);
}

.toc__link--selected {
  color: var(--color-primary);
}

.toc__link--selected:before {
  background-color: var(--color-primary);
}

.toc__link--selected:hover {
  color: var(--color-primary);
}

.toc__label {
  font-size: var(--text-sm);
  letter-spacing: .1em;
  text-transform: uppercase;
}

.toc-content :target {
  animation: 2s toc-target;
}

@keyframes toc-target {
  0%, 50% {
    outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .5);
  }

  100% {
    outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0);
  }
}

.tabs-v4__control {
  padding: var(--space-xs) var(--space-sm);
  color: var(--color-contrast-medium);
  font-size: var(--text-xs);
  letter-spacing: .1em;
  text-transform: uppercase;
  text-decoration: none;
}

.tabs-v4__control:focus {
  outline: 2px solid hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), .2);
}

.tabs-v4__control:hover {
  color: var(--color-contrast-high);
}

.js .tabs-v4__control[aria-selected="true"] {
  box-shadow: inset 0 0 0 1px var(--color-contrast-higher);
  color: var(--color-contrast-higher);
}

.tabs-v4__panel--is-visible {
  animation: tabs-v4-panel-translate-up .5s var(--ease-out);
}

@keyframes tabs-v4-panel-translate-up {
  from {
    opacity: .5;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.adv-filter .sidebar--static {
  width: 100%;
  max-width: 320px;
}

.adv-filter .accordion {
  --accordion-icon-stroke-width: 1px;
}

.adv-filter .slider {
  --slider-width: 80%;
}

@media (min-width: 64rem) {
  .adv-filter__checkbox-list, .adv-filter__radio-list {
    --checkbox-radio-size: 1.25em;
    --checkbox-radio-translate-y: .25em;
    font-size: var(--text-sm);
  }
}

.t-article-v3 {
  z-index: 1;
  position: relative;
}

.t-article-v3__hero {
  padding: var(--space-xxxl) 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--color-contrast-lower);
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 2em), 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 2em), 0% 100%);
}

.t-article-v3__intro-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: var(--space-lg);
  border-radius: var(--radius-lg);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .75);
  color: var(--color-bg);
}

.t-article-v3__intro-text p {
  opacity: .75;
}

.t-article-v3__divider {
  align-items: center;
  display: flex;
}

.t-article-v3__divider span {
  width: 10px;
  height: 10px;
  margin: 0 var(--space-xs);
  background-color: var(--color-contrast-low);
  display: block;
  transform: rotate(45deg);
}

.t-article-v3__divider:before, .t-article-v3__divider:after {
  width: auto;
  height: 1px;
  background-color: var(--color-contrast-lower);
  content: "";
  flex-grow: 1;
  display: block;
}

.carousel-v3 {
  --carousel-grid-gap: var(--space-xs);
  --carousel-item-auto-size: 260px;
  --carousel-transition-duration: .5s;
}

.carousel-v3__control {
  width: 3em;
  height: 3em;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .7);
  cursor: pointer;
  pointer-events: auto;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.carousel-v3__control:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .9);
}

.carousel-v3__control[disabled] {
  display: none;
}

.carousel-v3__control:active {
  transform: translateY(2px);
}

.carousel-v3__control .icon {
  color: var(--color-bg);
  display: block;
}

.hide-nav {
  width: 100%;
  will-change: transform;
  transition: transform .3s, background-color .3s;
  position: sticky;
  top: 0;
  left: 0;
}

.hide-nav--fixed {
  background-color: #0000;
}

.hide-nav--has-bg {
  background-color: var(--color-bg);
}

.loop-slideshow {
  --slideshow-slide-transition-duration: .3s;
}

.loop-slideshow .slideshow__content {
  -webkit-clip-path: none;
  clip-path: none;
}

.loop-slideshow__navigation {
  background: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.loop-slideshow__nav-item {
  --loop-slideshow-filling: 0;
  margin: var(--space-sm) calc(var(--space-sm) / 2) 0;
}

.loop-slideshow__nav-item button {
  width: 60px;
  height: 4px;
  background-color: var(--color-contrast-lower);
  cursor: pointer;
  border-radius: 50em;
  transition: all .2s;
  display: block;
  position: relative;
  overflow: hidden;
}

.loop-slideshow__nav-item button:before {
  width: 100%;
  height: 100%;
  transform: scaleX(var(--loop-slideshow-filling));
  transform-origin: 0;
  background-color: var(--color-primary);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}

.loop-slideshow__nav-item button:hover {
  background-color: hsla(var(--color-contrast-lower-h), var(--color-contrast-lower-s), var(--color-contrast-lower-l), .7);
}

@media (min-width: 64rem) {
  .loop-slideshow__nav-item button {
    width: 90px;
    height: 5px;
  }
}

.loop-slideshow-cursor.c-cursor--right .c-cursor__img {
  --rotate: 180deg;
}

.loop-slideshow__pause-btn {
  z-index: 3;
  top: var(--space-sm);
  right: var(--space-sm);
  width: 48px;
  height: 48px;
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .85);
  color: var(--color-white);
  cursor: pointer;
  transition: background .3s, transform .3s var(--ease-out-back);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.loop-slideshow__pause-btn:hover {
  background-color: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .95);
  transform: scale(1.1);
}

:root {
  --mega-nav-height: 50px;
  --mega-nav-content-max-width: var(--max-width-lg);
}

@media (min-width: 64rem) {
  :root {
    --mega-nav-height: 70px;
  }
}

.mega-nav {
  z-index: var(--z-index-header);
  width: 100%;
  height: var(--mega-nav-height);
}

.mega-nav--border-bottom {
  box-shadow: inset 0px -1px 0px hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .1);
}

.mega-nav--expanded {
  background-color: var(--color-bg);
}

.mega-nav__container {
  width: calc(100% - 2 * var(--component-padding));
  max-width: var(--mega-nav-content-max-width);
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.mega-nav__logo {
  display: block;
}

.mega-nav__logo > * {
  width: inherit;
  height: inherit;
}

.mega-nav__icon-btns {
  align-items: center;
  display: flex;
}

.mega-nav__icon-btn {
  width: 40px;
  height: 40px;
  color: var(--color-contrast-high);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.mega-nav__icon-btn .icon {
  display: block;
}

.mega-nav__icon-btn .icon__group {
  stroke-width: 1px;
}

.mega-nav__icon-btn .icon__group > * {
  transition: transform .3s var(--ease-in-out), stroke-dashoffset .3s, opacity .3s;
}

.mega-nav__icon-btn .counter {
  font-size: .7rem;
  position: absolute;
  top: -2px;
  right: -2px;
}

.mega-nav__icon-btn--menu .icon__group > * {
  stroke-dasharray: 24;
}

.mega-nav__icon-btn--menu .icon__group > :nth-child(1) {
  transform-origin: 12px 6px;
}

.mega-nav__icon-btn--menu .icon__group > :nth-child(2) {
  stroke-dashoffset: 0;
}

.mega-nav__icon-btn--menu .icon__group > :nth-child(3) {
  transform-origin: 12px 18px;
}

.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > :nth-child(1) {
  transform: translateY(6px)rotate(-45deg);
}

.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > :nth-child(2) {
  stroke-dashoffset: 24px;
}

.mega-nav__icon-btn--menu.mega-nav__icon-btn--state-b .icon__group > :nth-child(3) {
  transform: translateY(-6px)rotate(45deg);
}

.mega-nav__icon-btn--search .icon__group {
  transform-origin: 12px 12px;
  transition: transform .3s var(--ease-out);
  transform: rotate(0);
}

.mega-nav__icon-btn--search .icon__group > :nth-child(1) {
  stroke-dashoffset: 34px;
  stroke-dasharray: 24;
}

.mega-nav__icon-btn--search .icon__group > :nth-child(2) {
  stroke-dashoffset: 24px;
  stroke-dasharray: 24;
}

.mega-nav__icon-btn--search .icon__group > :nth-child(3) {
  stroke-dashoffset: 84px;
  stroke-dasharray: 42;
  transform-origin: 9.5px 9.5px;
  opacity: 1;
  transform: rotate(45deg);
}

.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group {
  transform: rotate(-90deg);
}

.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > :nth-child(1), .mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > :nth-child(2) {
  stroke-dashoffset: 48px;
}

.mega-nav__icon-btn--search.mega-nav__icon-btn--state-b .icon__group > :nth-child(3) {
  stroke-dashoffset: 42px;
  opacity: 0;
}

.mega-nav__arrow-icon {
  color: currentColor;
  font-size: 16px;
  transition: color .2s;
}

.mega-nav__arrow-icon .icon {
  display: block;
}

.mega-nav__arrow-icon .icon__group {
  stroke-width: 1px;
  transform-origin: 8px 8px;
  transition: transform .3s var(--ease-out);
  will-change: transform;
}

.mega-nav__arrow-icon .icon__group > * {
  stroke-dasharray: 17;
  transform-origin: 8px 8px;
  transition: transform .3s, stroke-dashoffset .3s;
  transition-timing-function: var(--ease-out);
  transform: translateY(3px);
}

.mega-nav__arrow-icon .icon__group > :first-child, .mega-nav__arrow-icon .icon__group > :last-child {
  stroke-dashoffset: 8.5px;
}

.mega-nav__label {
  color: var(--color-contrast-medium);
  font-size: var(--text-xs);
  letter-spacing: .1em;
  text-transform: uppercase;
}

.mega-nav__card img {
  transition: opacity .3s;
}

.mega-nav__card img:hover {
  opacity: .85;
}

.mega-nav__card-title {
  color: var(--color-contrast-higher);
  text-decoration: none;
}

.mega-nav__card-title:hover {
  text-decoration: underline;
}

.mega-nav--mobile .mega-nav__icon-btns--desktop {
  display: none;
}

.mega-nav--mobile .mega-nav__nav, .mega-nav--mobile .mega-nav__search {
  -webkit-overflow-scrolling: touch;
  top: var(--mega-nav-height);
  width: 100%;
  height: calc(100vh - var(--mega-nav-height)  - var(--mega-nav-offset-y, 0px));
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
  display: none;
  position: absolute;
  left: 0;
  overflow: auto;
}

.mega-nav--mobile .mega-nav__nav--is-visible, .mega-nav--mobile .mega-nav__search--is-visible {
  display: block;
}

.mega-nav--mobile .mega-nav__nav--is-visible > *, .mega-nav--mobile .mega-nav__search--is-visible > * {
  animation: mega-nav-entry-animation .5s var(--ease-out);
}

.mega-nav--mobile .mega-nav__nav-inner, .mega-nav--mobile .mega-nav__search-inner {
  width: calc(100% - 2 * var(--component-padding));
  max-width: var(--mega-nav-content-max-width);
  padding: var(--space-md) 0;
  margin-left: auto;
  margin-right: auto;
}

.mega-nav--mobile .mega-nav__label {
  margin: var(--space-lg) 0 var(--space-xs);
}

.mega-nav--mobile .mega-nav__item {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.mega-nav--mobile .mega-nav__control {
  width: 100%;
  padding: var(--space-sm) 0;
  color: var(--color-contrast-higher);
  font-size: var(--text-md);
  text-align: left;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.mega-nav--mobile a[aria-current="page"] {
  color: var(--color-primary);
}

.mega-nav--mobile .mega-nav__arrow-icon {
  margin-left: auto;
  margin-right: 12px;
}

.mega-nav--mobile .mega-nav__btn {
  width: 100%;
  margin: var(--space-sm) 0;
  font-size: var(--text-md);
}

.mega-nav--mobile .mega-nav__sub-nav-wrapper {
  padding: 0 var(--space-md) var(--space-lg);
  display: none;
  overflow: hidden;
}

.mega-nav--mobile .mega-nav__sub-items:not(:last-child) {
  margin-bottom: var(--space-lg);
}

.mega-nav--mobile .mega-nav__sub-item {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.mega-nav--mobile .mega-nav__sub-link {
  padding: var(--space-xxs) 0;
  color: var(--color-contrast-higher);
  text-decoration: none;
  display: block;
}

.mega-nav--mobile .mega-nav__quick-link {
  padding: var(--space-xs) 0;
  border-bottom: 1px solid var(--color-contrast-lower);
  color: var(--color-contrast-higher);
  text-decoration: none;
  display: block;
}

.mega-nav--mobile .mega-nav__sub-nav--layout-1 .mega-nav__sub-items {
  margin-bottom: 0;
}

.mega-nav--mobile .mega-nav__sub-nav--layout-1 .mega-nav__tabs {
  display: none;
}

.mega-nav--mobile .mega-nav__sub-nav--layout-3 {
  padding-top: var(--space-md);
  gap: var(--space-md);
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  display: grid;
}

.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > :first-child {
  transform: translateY(-3px)rotate(-90deg);
}

.mega-nav--mobile .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > :last-child {
  transform: translateY(-3px)rotate(90deg);
}

.mega-nav--mobile .mega-nav__item--expanded .mega-nav__sub-nav-wrapper {
  display: block;
}

.mega-nav--mobile .mega-nav__item--expanded .mega-nav__sub-nav-wrapper > * {
  animation: mega-nav-entry-animation .5s var(--ease-out);
}

.mega-nav--desktop .mega-nav__icon-btns--mobile, .mega-nav--desktop .mega-nav__sub-nav-wrapper, .mega-nav--desktop .mega-nav__search, .mega-nav--desktop .mega-nav__label {
  display: none;
}

.mega-nav--desktop .mega-nav__logo {
  margin-right: var(--space-sm);
  flex-shrink: 0;
}

.mega-nav--desktop .mega-nav__nav {
  height: 100%;
  flex-grow: 1;
}

.mega-nav--desktop .mega-nav__nav-inner {
  height: 100%;
  justify-content: space-between;
  display: flex;
}

.mega-nav--desktop .mega-nav__items {
  height: 100%;
  display: flex;
}

.mega-nav--desktop .mega-nav__item {
  align-items: center;
  display: flex;
}

.mega-nav--desktop .mega-nav__control {
  height: 100%;
  padding: 0 var(--space-sm);
  color: var(--color-contrast-higher);
  font-size: var(--text-sm);
  align-items: center;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
}

.mega-nav--desktop .mega-nav__control:after {
  width: 100%;
  height: 1px;
  background-color: var(--color-contrast-higher);
  content: "";
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.mega-nav--desktop .mega-nav__control .mega-nav__arrow-icon {
  margin-left: var(--space-xxs);
}

.mega-nav--desktop .mega-nav__control:hover {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .05);
  cursor: pointer;
}

@media (min-width: 80rem) {
  .mega-nav--desktop .mega-nav__control {
    padding: 0 var(--space-md);
  }
}

.mega-nav--desktop .mega-nav__sub-nav-wrapper, .mega-nav--desktop .mega-nav__search {
  top: var(--mega-nav-height);
  width: 100%;
  max-height: calc(100vh - var(--mega-nav-height)  - var(--mega-nav-offset-y, 0px));
  background-color: var(--color-bg);
  box-shadow: var(--shadow-lg);
  position: absolute;
  left: 0;
  overflow: auto;
}

.mega-nav--desktop .mega-nav__sub-nav, .mega-nav--desktop .mega-nav__search-inner {
  width: calc(100% - 2 * var(--component-padding));
  padding: var(--space-xl) 0;
  margin: 0 auto;
}

.mega-nav--desktop .mega-nav__sub-nav .mega-nav__label, .mega-nav--desktop .mega-nav__search-inner .mega-nav__label {
  display: block;
}

.mega-nav--desktop .mega-nav__sub-nav {
  max-width: var(--mega-nav-content-max-width);
}

.mega-nav--desktop .mega-nav__search-inner {
  max-width: var(--max-width-xs);
}

.mega-nav--desktop .mega-nav__label {
  margin-bottom: var(--space-md);
}

.mega-nav--desktop .mega-nav__sub-item:not(:last-child) {
  margin-bottom: var(--space-xxs);
}

.mega-nav--desktop .mega-nav__sub-link {
  color: var(--color-contrast-higher);
  font-size: var(--text-sm);
  text-decoration: none;
}

.mega-nav--desktop .mega-nav__sub-link:hover {
  text-decoration: underline;
}

.mega-nav--desktop .mega-nav__quick-link {
  margin-bottom: var(--space-xxs);
  color: var(--color-contrast-higher);
  font-size: var(--text-sm);
  text-decoration: none;
  display: inline-block;
}

.mega-nav--desktop .mega-nav__quick-link:hover {
  text-decoration: underline;
}

.mega-nav--desktop .mega-nav__btn {
  margin-left: var(--space-sm);
  font-size: var(--text-sm);
}

.mega-nav--desktop .mega-nav__sub-nav--layout-1 .mega-nav__sub-items {
  display: none;
}

.mega-nav--desktop .mega-nav__sub-nav--layout-1 .mega-nav__tabs {
  display: flex;
}

.mega-nav--desktop .mega-nav__sub-nav--layout-2 {
  gap: var(--space-sm);
  grid-template-columns: repeat(5, 1fr);
  display: grid;
}

.mega-nav--desktop .mega-nav__sub-nav--layout-3 {
  gap: var(--space-md);
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

.mega-nav--desktop .mega-nav__sub-nav--layout-4 {
  text-align: center;
}

.mega-nav--desktop .mega-nav__sub-nav--layout-4 .mega-nav__sub-link {
  font-size: var(--text-lg);
}

.mega-nav--desktop .mega-nav__tabs-controls > :not(:last-child) {
  border-bottom: 1px solid var(--color-contrast-lower);
}

.mega-nav--desktop .mega-nav__tabs-control {
  width: 100%;
  padding: var(--space-xs);
  color: var(--color-contrast-higher);
  cursor: pointer;
  text-decoration: none;
  transition: all .2s;
  display: block;
  overflow: hidden;
}

.mega-nav--desktop .mega-nav__tabs-control .icon {
  opacity: 0;
  transition: transform .5s var(--ease-out), opacity .5s;
  transform: translateX(-10px);
}

.mega-nav--desktop .mega-nav__tabs-control:hover, .mega-nav--desktop .mega-nav__tabs-control[aria-selected="true"] {
  color: var(--color-primary);
}

.mega-nav--desktop .mega-nav__tabs-control[aria-selected="true"] .icon {
  opacity: 1;
  transform: translateX(0);
}

.mega-nav--desktop .mega-nav__tabs-img {
  border-radius: .1em;
  transition: opacity .3s;
  display: block;
  overflow: hidden;
}

.mega-nav--desktop .mega-nav__tabs-img:hover {
  opacity: .85;
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__control {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .05);
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__control:after {
  opacity: 1;
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group {
  transform: rotate(-90deg);
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group > :first-child, .mega-nav--desktop .mega-nav__item--expanded .mega-nav__arrow-icon .icon__group :last-child {
  stroke-dashoffset: 0;
  transform: translateY(0);
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__sub-nav-wrapper {
  display: block;
}

.mega-nav--desktop .mega-nav__item--expanded .mega-nav__sub-nav {
  animation: mega-nav-entry-animation .5s var(--ease-out);
}

.mega-nav--desktop .mega-nav__icon-btn {
  margin-left: var(--space-xxxxs);
  border-radius: 50%;
}

.mega-nav--desktop .mega-nav__icon-btn:hover, .mega-nav--desktop .mega-nav__icon-btn--state-b {
  background-color: hsla(var(--color-contrast-higher-h), var(--color-contrast-higher-s), var(--color-contrast-higher-l), .05);
}

.mega-nav--desktop .mega-nav__search--is-visible {
  display: block;
}

.mega-nav--desktop .mega-nav__search--is-visible .mega-nav__search-inner {
  animation: mega-nav-entry-animation .5s var(--ease-out);
}

@keyframes mega-nav-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

[class*="mega-nav--desktop"]:before {
  content: "mobile";
  display: none;
}

@media (min-width: 32rem) {
  .mega-nav--desktop\@xs:before {
    content: "desktop";
  }
}

@media (min-width: 48rem) {
  .mega-nav--desktop\@sm:before {
    content: "desktop";
  }
}

@media (min-width: 64rem) {
  .mega-nav--desktop\@md:before {
    content: "desktop";
  }
}

@media (min-width: 80rem) {
  .mega-nav--desktop\@lg:before {
    content: "desktop";
  }
}

@media (min-width: 90rem) {
  .mega-nav--desktop\@xl:before {
    content: "desktop";
  }
}

html:not(.js) .mega-nav--mobile .mega-nav__logo {
  margin: var(--space-md) 0;
}

html:not(.js) .mega-nav--mobile .mega-nav__icon-btns {
  display: none;
}

html:not(.js) .mega-nav--mobile .mega-nav__container, html:not(.js) .mega-nav--mobile .mega-nav__sub-nav-wrapper {
  display: block;
}

html:not(.js) .mega-nav--mobile .mega-nav__nav, html:not(.js) .mega-nav--mobile .mega-nav__search {
  height: auto;
  border-top: 1px solid var(--color-contrast-lower);
  box-shadow: none;
  display: block;
  position: static;
}

.prod-color-feature {
  --slideshow-height: auto;
}

@media (min-width: 64rem) {
  .prod-color-feature .color-swatches {
    --color-swatch-size: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 !important;
  }

  .prod-color-feature .color-swatches__legend {
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    position: absolute;
  }

  .prod-color-feature .color-swatches__list {
    flex-direction: column;
  }
}

:root {
  --testimonial-slideshow-profile-img-size: 48px;
}

@media (min-width: 64rem) {
  :root {
    --testimonial-slideshow-profile-img-size: 64px;
  }
}

.testimonial-slideshow {
  --slideshow-height: 320px;
}

@media (min-width: 64rem) {
  .testimonial-slideshow {
    --slideshow-height: 420px;
  }
}

.testimonial-slideshow__profile-img {
  width: var(--testimonial-slideshow-profile-img-size);
  height: var(--testimonial-slideshow-profile-img-size);
  overflow: hidden;
}

.testimonial-slideshow__profile-img img {
  width: 100%;
  display: block;
}

:root {
  --thumbslide-thumbnail-auto-size: 100px;
  --thumbslide-thumbnail-grid-gap: var(--space-xs);
}

.js .thumbslide .slideshow {
  --slideshow-fade-transition-duration: .3s;
  --slideshow-slide-transition-duration: .3s;
}

html:not(.js) .thumbslide__nav-wrapper {
  display: none;
}

.thumbslide__nav {
  padding: var(--thumbslide-thumbnail-grid-gap) 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.thumbslide__nav:after, .thumbslide__nav:before {
  z-index: 2;
  width: 0;
  height: 80%;
  content: "";
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s;
  position: absolute;
  top: 10%;
  box-shadow: 0 0 8px 2px #000000e6;
}

.thumbslide__nav:before {
  left: 0;
}

.thumbslide__nav:after {
  right: 0;
}

.thumbslide__nav--scroll-end:after, .thumbslide__nav--scroll-start:before {
  opacity: 1;
}

.thumbslide__nav-list {
  z-index: 1;
  will-change: transform;
  flex-wrap: nowrap;
  align-items: center;
  transition: transform .5s;
  display: inline-flex;
  position: relative;
}

.thumbslide__nav-list:hover .thumbslide__nav-item {
  opacity: .6;
}

.thumbslide__nav-list--dragging {
  cursor: grabbing;
}

.thumbslide__nav-list--no-transition {
  transition: none;
}

.thumbslide__nav-item {
  width: var(--thumbslide-thumbnail-auto-size);
  margin-right: var(--thumbslide-thumbnail-grid-gap);
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform, opacity;
  -webkit-user-select: none;
  user-select: none;
  flex-shrink: 0;
  transition: opacity .3s, transform .3s;
}

.thumbslide__nav-item img {
  pointer-events: none;
  display: block;
}

.thumbslide__nav-item:hover {
  opacity: 1 !important;
}

.thumbslide__nav-item--active {
  position: relative;
  opacity: 1 !important;
}

.thumbslide__nav-item--active:after {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .7) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg stroke-width='1.5' stroke='%23ffffff'%3E%3Cpolyline fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='1,9 5,13 15,3 ' %3E%3C/polyline%3E%3C/g%3E%3C/svg%3E") no-repeat center center;
  content: "";
  background-size: 1.25em;
  position: absolute;
  top: 0;
  right: 0;
}

.thumbslide__caption {
  width: 100%;
  padding: var(--component-padding);
  background-color: hsla(var(--color-bg-h), var(--color-bg-s), var(--color-bg-l), .85);
  position: absolute;
  bottom: 0;
  left: 0;
}

.thumbslide--top {
  flex-direction: column;
  display: flex;
}

.thumbslide--top .slideshow {
  order: 1;
}

.thumbslide--vertical {
  display: flex;
}

@media not all and (min-width: 48rem) {
  .thumbslide--vertical {
    --thumbslide-thumbnail-auto-size: 50px;
  }
}

.thumbslide--vertical .slideshow {
  flex-grow: 1;
  display: inline-block;
}

.thumbslide--vertical .thumbslide__nav-wrapper {
  width: var(--thumbslide-thumbnail-auto-size);
  float: right;
  flex-shrink: 0;
}

.thumbslide--vertical .thumbslide__nav {
  width: 100%;
  height: 100%;
  padding: 0 var(--thumbslide-thumbnail-grid-gap);
}

.thumbslide--vertical .thumbslide__nav:after, .thumbslide--vertical .thumbslide__nav:before {
  width: 80%;
  height: 0;
  left: 10%;
}

.thumbslide--vertical .thumbslide__nav:before {
  top: 0;
}

.thumbslide--vertical .thumbslide__nav:after {
  top: auto;
  bottom: 0;
}

.thumbslide--vertical .thumbslide__nav-list {
  flex-direction: column;
  position: absolute;
  top: 0;
}

.thumbslide--vertical .thumbslide__nav-item {
  margin-right: 0;
  margin-bottom: var(--thumbslide-thumbnail-grid-gap);
}

.thumbslide--vertical .thumbslide__nav-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbslide--left .slideshow {
  order: 1;
}

:root {
  --password-btn-width: 3.5em;
  --password-icon-size: 1.5em;
}

.password {
  position: relative;
}

.password__input {
  height: 100%;
}

.password__btn {
  -webkit-appearance: none;
  appearance: none;
  z-index: 1;
  width: var(--password-btn-width);
  height: 100%;
  color: inherit;
  line-height: inherit;
  background-color: #fff0;
  border: 0;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.password__btn:focus {
  color: var(--color-primary);
}

.password__btn-label:last-child {
  display: none;
}

.password__btn-label .icon {
  width: var(--password-icon-size);
  height: var(--password-icon-size);
}

.password--text-is-visible .password__btn-label:first-child {
  display: none;
}

.password--text-is-visible .password__btn-label:last-child {
  display: inline-block;
}

.password__input {
  padding-right: calc(var(--space-sm)  + var(--password-btn-width));
}

.password__input::-ms-reveal {
  display: none;
}

.password__btn {
  display: flex;
}

:root {
  --password-strength-meter-height: 8px;
  --password-strength-meter-radius: 50em;
  --password-strength-icon-size: 16px;
  --password-strength-icon-margin-right: 4px;
  --password-strength-icon-stroke-width: 2px;
}

.password-strength__req {
  margin-bottom: var(--space-xxxxs);
  align-items: center;
  line-height: 1.2;
  display: flex;
}

.password-strength__req .icon {
  margin-right: var(--password-strength-icon-margin-right);
  font-size: var(--password-strength-icon-size);
}

.password-strength__icon-group {
  stroke-width: var(--password-strength-icon-stroke-width);
}

.password-strength__icon-group * {
  stroke-dasharray: 16;
  transform-origin: 8px 8px;
  transition: transform .3s var(--ease-out-back), stroke-dashoffset .3s var(--ease-out-back);
}

.password-strength__icon-group :first-child {
  stroke-dashoffset: 24px;
}

.password-strength__icon-group :last-child {
  stroke-dashoffset: 10px;
}

.password-strength__req--met {
  color: var(--color-success-darker);
  transition: color .3s;
}

.password-strength__req--met .password-strength__icon-group :first-child {
  stroke-dashoffset: 23px;
  transform: translateX(-2px)translateY(4px)rotate(45deg);
}

.password-strength__req--met .password-strength__icon-group :last-child {
  stroke-dashoffset: 5px;
  transform: translateX(-2px)translateY(4px)rotate(-45deg);
}

.password-strength__req--no-met {
  color: var(--color-error);
}

.password-strength__req--no-met .password-strength__icon-group :first-child {
  stroke-dashoffset: 32px;
  transform: translateX(5px)translateY(5px)rotate(45deg);
}

.password-strength__req--no-met .password-strength__icon-group :last-child {
  stroke-dashoffset: 2px;
  transform: translateX(-5px)translateY(5px)rotate(-45deg);
}

.password-strength__meter {
  height: var(--password-strength-meter-height);
  border-radius: var(--password-strength-meter-radius);
  overflow: hidden;
}

.password-strength__meter * {
  transition: width .3s var(--ease-in-out), background-color .3s;
  will-change: width;
}

.password-strength__meter--fill-1 * {
  background-color: var(--color-error);
}

.password-strength__meter--fill-2 * {
  background-color: var(--color-warning);
}

.password-strength__meter--fill-3 *, .password-strength__meter--fill-4 * {
  background-color: var(--color-success);
}

.file-upload__input {
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  position: absolute;
}

.file-upload__text--has-max-width {
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

:root {
  --choice-img-input-size: 100px;
  --choice-img-input-icon-size: 26px;
}

.choice-img {
  box-shadow: var(--shadow-sm);
  -webkit-user-select: none;
  user-select: none;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.choice-img:hover {
  box-shadow: var(--shadow-md);
  cursor: pointer;
}

.choice-img:active {
  transform: translateY(2px);
}

.choice-img[aria-checked="true"] {
  box-shadow: var(--shadow-sm), 0 0 0 2px var(--color-primary);
  transition: transform .3s;
}

.choice-img__input {
  width: var(--choice-img-input-size);
  height: var(--choice-img-input-size);
  background-color: var(--color-primary);
  -webkit-clip-path: polygon(15% 0%, 100% 0%, 100% 85%);
  clip-path: polygon(15% 0%, 100% 0%, 100% 85%);
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(1px, -1px);
}

.choice-img__input .icon {
  top: calc(var(--choice-img-input-size) / 4 - var(--choice-img-input-icon-size) / 2);
  right: calc(var(--choice-img-input-size) / 4 - var(--choice-img-input-icon-size) / 2);
  color: var(--color-white);
  font-size: var(--choice-img-input-icon-size);
  position: absolute;
}

.choice-img__input .icon > * {
  stroke-dasharray: 18;
  stroke-dashoffset: 18px;
  transition: stroke-dashoffset .3s;
}

.choice-img[aria-checked="true"] .choice-img__input {
  opacity: 1;
}

.choice-img[aria-checked="true"] .choice-img__input .icon > * {
  stroke-dashoffset: 0;
}

:root {
  --steps-v2-steps-nr: 10;
}

@supports (--css: variables) {
  .steps-v2__indicator {
    height: 8px;
    background-color: var(--color-contrast-lower);
    border-radius: 50em;
    position: relative;
  }

  .steps-v2__indicator:after {
    width: calc(100% / var(--steps-v2-steps-nr) * var(--step-v2-current-step, 1));
    height: 100%;
    border-radius: inherit;
    background-color: var(--color-primary);
    content: "";
    transition: width .2s;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.form-validate__error-msg {
  display: none;
}

.form-validate__input-wrapper--error .form-validate__error-msg {
  display: block;
}

.wiz-form {
  flex-direction: column;
  display: flex;
}

.wiz-form, .wiz-form__body {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.wiz-form__body {
  flex-grow: 1;
}

.wiz-form__footer {
  flex-shrink: 0;
}

.wiz-form__step {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  transition: transform .3s var(--ease-out), opacity .3s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.wiz-form__step--prev, .wiz-form__step--next {
  visibility: hidden;
  opacity: 0;
  transition: transform .3s var(--ease-out), opacity .3s, visibility 0s .3s;
}

.wiz-form__step--prev {
  transform: translateX(-40px);
}

.wiz-form__step--next {
  transform: translateX(40px);
}

.wiz-form--fixed-height {
  height: 480px;
}

@media (min-width: 64rem) {
  .wiz-form--fixed-height {
    height: 620px;
  }
}

.intro {
  padding-top: var(--mega-nav-height);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.intro--top-overlay {
  position: relative;
}

.intro--top-overlay:before {
  width: 100%;
  height: 120px;
  background: linear-gradient(hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), .85), hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0) );
  content: "";
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  left: 0;
}

.product-v2 .thumbslide {
  --thumbslide-thumbnail-auto-size: 90px;
  --thumbslide-thumbnail-grid-gap: var(--space-xxxs);
}

/*# sourceMappingURL=app.css.map */
