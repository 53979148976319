@use '../base' as *;

/* -------------------------------- 

File#: _1_socials-v4
Title: Socials v4
Descr: Section with newsletter form and links to social media accounts
Usage: codyhouse.co/license

-------------------------------- */

.socials-v4 {
  position: relative;
  z-index: 1;
}

.socials-v4__btn {
  --btn-size: 40px;
  width: var(--btn-size);
  height: var(--btn-size);

  display: inline-flex;

  background: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-sm);
  border-radius: 50%;
  
  transition: .2s;

  .icon {
    display: block;
    margin: auto;
    color: var(--color-contrast-high);
    transition: color .2s;
  }

  &:hover {
    background-color: var(--color-bg-lighter);
    box-shadow: var(--inner-glow), var(--shadow-md);

    .icon {
      color: var(--color-primary);
    }
  }

  @media screen {
    --btn-size: 48px;
  }
}