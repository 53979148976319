@use '../base' as *;
/* -------------------------------- 

File#: _1_pre-header
Title: Pre-header 
Descr: Pre-header (top) banner
Usage: codyhouse.co/license

-------------------------------- */

.pre-header {
  display: block;
}

.pre-header--is-hidden {
  display: none;
}

.pre-header__close-btn {
  position: absolute;
  right: 0;
  top: calc(50% - 0.5em);
  opacity: 0.85;
  transition: .2s;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  .icon {
    display: block;
  }
}

// --link
a.pre-header {
  text-decoration: none;
  transition: background-color .2s;

  &:hover {
    text-decoration: underline;
    background-color: var(--color-contrast-high);
  }
}