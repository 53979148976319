@use '../base' as *;
/* -------------------------------- 

File#: _1_article-v4
Title: Article v4
Descr: Article template
Usage: codyhouse.co/license

-------------------------------- */

.t-article-v4__divider {
  display: flex;
  align-items: center;

  span {
    display: block;
    margin: 0 var(--space-xs);
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    background-color: var(--color-contrast-lower);
  }

  &::before, &::after {
    content: '';
    display: block;
    height: 1px;
    width: auto;
    flex-grow: 1;
    background-color: var(--color-contrast-lower);
  }
}