@use '../base' as *;
/* -------------------------------- 

File#: _1_card-v8
Title: Card v8
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card-v8 {
  display: block;
  overflow: hidden;
  text-decoration: none;
  transition: .2s;

  img {
    display: block;
    width: 100%;
  }

  &:hover {
    box-shadow: var(--shadow-md);

    .card-v8__title {
      background-size: 100% 100%;
    }
  }
}

.card-v8__title {
  background-repeat: no-repeat;
  will-change: background-size;
  transition: background-size .3s var(--ease-in-out);
  text-decoration: none;
  background-image: linear-gradient(transparent 50%, alpha(var(--color-primary), 0.2) 50%);
  background-size: 0% 100%;
}