@use '../base' as *;
/* -------------------------------- 

File#: _1_feature-v10
Title: Feature v10
Descr: A feature section containing two adjacent content blocks
Usage: codyhouse.co/license

-------------------------------- */

.feature-v10 {
  position: relative;
  z-index: 1;
}

.feature-v10__link {
  position: relative;
  text-decoration: none;
  color: inherit;
  display: inline-block;
  transition: .3s var(--ease-out);
  transform-origin: left bottom;

  i { // label
    position: relative;
    z-index: 2;
    display: inline-block;
    transition: .3s var(--ease-out);
  }

  &::after { // animated border
    content: '';
    background-color: currentColor;
    height: 2px;
    width: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    transition: .3s var(--ease-out);
    opacity: 0.15;
  }

  &:hover {
    transform: scale(1.3);

    i {
      transform: scale(0.7); // reverse parent transformation
    }

    &::after {
      height: 100%;
    }
  }
}