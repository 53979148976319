@use '../base' as *;
/* -------------------------------- 

File#: _1_responsive-iframe
Title: Resposive Iframe
Descr: Responsive wrapper for iframe elements (e.g., YouTube and Vimeo videos)
Usage: codyhouse.co/license

-------------------------------- */

.responsive-iframe {
  position: relative;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// create variations for different aspect ratios
.responsive-iframe--4\:3 {
  padding-bottom: 75%; // 4:3 aspect ratio
}