@use '../base' as *;
/* -------------------------------- 

File#: _2_sign-up-form
Title: Sign Up Form
Descr: Sign up form template
Usage: codyhouse.co/license

-------------------------------- */

.sign-up-form {}