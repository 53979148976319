@use '../base' as *;
@use '_1_password.scss' as *;

/* -------------------------------- 

File#: _2_password-strength
Title: Password Strength Indicator
Descr: Password requirements and strength indicator
Usage: codyhouse.co/license

-------------------------------- */

:root {
  // general
  --password-strength-meter-height: 8px;
  --password-strength-meter-radius: 50em;

  // icons
  --password-strength-icon-size: 16px;
  --password-strength-icon-margin-right: 4px;
  --password-strength-icon-stroke-width: 2px;
}

.password-strength {}

.password-strength__req {
  line-height: 1.2;
  margin-bottom: var(--space-xxxxs);
  display: flex;
  align-items: center;

  .icon {
    font-size: var(--password-strength-icon-size);
    margin-right: var(--password-strength-icon-margin-right);
  }
}

.password-strength__icon-group {
  stroke-width: var(--password-strength-icon-stroke-width);

  * {
    transition: transform .3s var(--ease-out-back), stroke-dashoffset .3s var(--ease-out-back);
    transform-origin: 8px 8px;
    stroke-dasharray: 16;
  }

  *:first-child {
    stroke-dashoffset: 24;
  }

  *:last-child {
    stroke-dashoffset: 10;
  }
}

.password-strength__req--met { // icon = check
  color: var(--color-success-darker);
  transition: color .3s;

  .password-strength__icon-group *:first-child {
    stroke-dashoffset: 23;
    transform: translateX(-2px) translateY(4px) rotate(45deg);
  }

  .password-strength__icon-group *:last-child {
    stroke-dashoffset: 5;
    transform: translateX(-2px) translateY(4px) rotate(-45deg);
  }
}

.password-strength__req--no-met { // icon = X
  color: var(--color-error);
  
  .password-strength__icon-group *:first-child {
    stroke-dashoffset: 32;
    transform: translateX(5px) translateY(5px) rotate(45deg);
  }

  .password-strength__icon-group *:last-child {
    stroke-dashoffset: 2;
    transform: translateX(-5px) translateY(5px) rotate(-45deg);
  }
}

.password-strength__meter {
  height: var(--password-strength-meter-height);
  border-radius: var(--password-strength-meter-radius);
  overflow: hidden;

  * {
    will-change: width;
    transition: width .3s var(--ease-in-out), background-color .3s;
  }
}

.password-strength__meter--fill-1 * { // customize meter fill based on strength value
  background-color: var(--color-error);
}

.password-strength__meter--fill-2 * {
  background-color: var(--color-warning);
}

.password-strength__meter--fill-3 * {
  background-color: var(--color-success);
}

.password-strength__meter--fill-4 * {
  background-color: var(--color-success);
}