@use '../base' as *;
/* -------------------------------- 

File#: _2_sticky-sharebar
Title: Sticky Sharebar
Descr: Sticky social sharing bar
Usage: codyhouse.co/license

-------------------------------- */

.sticky-sharebar {
  display: flex;
  align-items: center;
  position: fixed;
  height: 100%;
  top: 0;
  right: var(--space-md);
  pointer-events: none;
  z-index: var(--z-index-fixed-element);

  transition: visibility 0s .3s, opacity .3s, transform .3s var(--ease-in-out);
  transform: translateX(10%);
  opacity: 0;
  visibility: hidden;
}


.sticky-sharebar--on-target {
  transition: visibility 0s, opacity .3s, transform .3s var(--ease-in-out);
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.sticky-sharebar__list {
  pointer-events: auto;
  background-color: alpha(var(--color-bg), 0.95);
  backdrop-filter: blur(5px);
  border-radius: 50em;
  box-shadow: var(--shadow-md);
  padding: 4px;
}

.sticky-sharebar__btn {
  position: relative;
  display: block; // fallback
  display: flex;
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  
  .icon {
    position: relative;
    color: var(--color-contrast-medium); // icon color
    display: block;
    margin: auto;
    z-index: 2;
    transition: color .2s;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: inherit;
    background-color: alpha(var(--color-contrast-higher), 0.1);
    transform: scale(0);
    transition: transform .3s var(--ease-out);
  }

  &:hover {
    .icon {
      color: var(--color-contrast-higher);
    }

    &::before {
      transform: scale(1);
    }
  }
}