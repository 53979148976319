@use '../base' as *;
/* -------------------------------- 

File#: _1_sticky-hero
Title: Sticky Hero
Descr: A sticky hero section that reveals its content on scroll
Usage: codyhouse.co/license

-------------------------------- */

.sticky-hero {}

.sticky-hero__media { // img or video
  position: relative; // fallback
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  transition: transform 0.5s var(--ease-in-out);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: translateZ(0); // fix issue on iOS - content not visible on sticky element
}

.sticky-hero--overlay-layer .sticky-hero__media::after { // overlay layer
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: var(--color-bg);
  transition: opacity 1s;
}

.sticky-hero--media-is-fixed.sticky-hero--overlay-layer .sticky-hero__media::after {
  opacity: 0.75;
}

.sticky-hero--media-is-fixed.sticky-hero--scale .sticky-hero__media {
  transform: scale(0.9);
}

.sticky-hero__video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    max-width: none;
  }
}

.sticky-hero__content {
  position: relative;
  z-index: 2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0); // fix issue on iOS - content not visible on sticky element
}